import React from "react";
import "./styles/hero.css";
import "./styles/index.css";
import Germany from "./assets/images/germany.png";
import USA from "./assets/images/usa.png";
// import Mail from "./assets/images/mail.png";
// import Denmark from "./assets/images/denmark.png";
import Bg from "./assets/images/bg.png";
import Img from "./assets/images/img.png";
import PlayBtn from "./assets/images/play_btn.png";
import PlayBtnHover from "./assets/images/play_btn-1.png";
import ModalVideo from "react-modal-video";
// import * as typeformEmbed from '@typeform/embed';
import { Link, withRouter, history } from "react-router-dom"; 
import { dropdownOptions } from "../../global";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import mapboxgl from "mapbox-gl"

class Hero extends React.Component {

  constructor () {
    super()
    this.state = {
      isOpen: false,
      btnImg: PlayBtn, 
      options: dropdownOptions,
      selectedCountry: 'de',
      zipcode: null, 
      invalidZipcode: false
    }
    this.el = null;
    this.openModal = this.openModal.bind(this)
    this.playOver = this.playOver.bind(this)
    this.playOut = this.playOut.bind(this)
  }

  componentDidMount() {
    const geocoder = new MapboxGeocoder({
      accessToken:
        "pk.eyJ1IjoibWotZXBzaWxvbiIsImEiOiJja24wcWV5eTcwcTY5MnZwazA0cTRxMTlwIn0.powZMmJIS2FoR4JY1DFSGg",
      mapboxgl: mapboxgl,
      marker: false,
      placeholder: 'search any zip code (or postal code)', 
      types: 'postcode' , 
      flyTo: false,
      countries: 'DE'
    })
    geocoder.addTo('#geocoder');
    // document.getElementById("geoc").appendChild(geocoder.onAdd(thisMap))
    geocoder.on("result", (e) => {
      // console.log('result', e)
      setTimeout(() => {
        geocoder.clear()
      }, 10000);
      
      const lng = e.result.center[0] ; 
      const lat=  e.result.center[1] ; 

      const endpoint='de' ; 
      const zipcode = e.result.text ; 

      return window.open(`/search/${endpoint}/${zipcode}/${lng}/${lat}`, "_blank");

    })

  }


  openModal () {
    this.setState({isOpen: true})
  }

  playOver () {
    this.setState({btnImg: PlayBtnHover})
  }

  playOut () {
    this.setState({btnImg: PlayBtn})
  }

  regionChange = (field, value) => {
    this.setState({
        ...this.state,
        [field]: value 
    })
}

  enterZipcode = (val) => {
    this.validateZipcode(val)
  }

  validateZipcode = (val) => {

    if (val.length > 5 ){
        this.setState({
          invalidZipcode: !this.state.invalidZipcode, zipcode: null
        })
    } else {
      this.setState({ zipcode: val, invalidZipcode: false })
    }
  }

  render() {

    const { selectedCountry, options , zipcode , invalidZipcode } = this.state ; 

    return (
      <div className="l-hero">
        <div className="left mt-0">
          
            {/* <h1>Demographics for Any Location</h1> */}
            <h1>Analyse Any Location</h1>

              <div className="mt-4" id="geocoder"></div>
              <div className="mt-2 mb-3">
                  <span className="startFreeTextHero "> Example: 10117, Berlin </span>
              </div>
              {/* <div className="text-center mr-5">
                  OR 
              </div> */}
            <div className="mt-5 subtextsHero">
            <hr/>
                <Link to='/areas/de/income' target='_blank'>
                  <span style={{color: '#665992' /*'#798584'*/, fontSize:'smaller'}} className="hover-underline-animation"> 
                    Which areas have high household income? 
                  </span>
                </Link>
                <Link to='/areas/de/young-consumers' target='_blank'>
                  <span style={{color:'#665992' /*'#7E7E7E'*/, fontSize:'smaller'}} className="hover-underline-animation"> 
                    Where do young consumers live? 
                  </span>
                </Link>
                <Link to='/areas/de/more-females-population' target='_blank'>
                  <span style={{color:'#665992', fontSize:'smaller'}} className="hover-underline-animation"> 
                    Which areas have high proportion of females ? 
                  </span>
                </Link>
            </div>

            {/* <div className="mt-3 ml-3">
              <span style={{ marginLeft: '20px'}}> High female population</span> <br/>
            </div>
            <div className="mt-3">
              <span> Babies </span>
              <span className="ml-5"> Millenials </span>
            </div> */}


                {/* <HomeCard
                  endpoint= {selectedCountry}
                  showDropdown = {true}
                  options={options}
                  zipcode={zipcode}
                  invalidZipcode={invalidZipcode}
                  onZipcodeEnter={(value) => this.enterZipcode(value)}
                  onChange={(value) => this.regionChange('selectedCountry' , value)}
                /> */}
                      
         
            {/* <div className="mt-2 d-flex">
              <div className="input">   
                <form> <input type="text" placeholder="Enter Zip code..." />  </form> 
              </div>
              <div className="ml-5"> <button id="bt-popup" className="btn primary hero-cta-btn">Go</button> </div>
            </div> */}

          {/* <div className="d-flex ">
            <Link to="/register">
              <button className="btn primary hero-cta-btn">Get Started</button> 
            </Link>
            
            <span className="ml-3 startFreeTextHero"> 
              START FREE<br/>
              NO CREDIT CARD. 
            </span>

          </div> */}
          <div className="countries mt-5">
              Live in <img src={Germany}  alt="germany" />
          </div>
           <div className="countries">
              Coming soon to <img src={USA} alt='usa' />
          </div> 
        </div>

          <div className="right">
            <img src={Bg} className="bg"  alt=" background " />
            <img src={Img} className="img"  alt="img hero" />
            {/* <img src={VideoImgBg} className="img" /> */}
            <img 
            src={this.state.btnImg} 
            className="play-btn" 
            onClick={this.openModal} 
            onMouseOver={this.playOver} 
            onMouseOut={this.playOut}
            alt="titles img"
            />
         </div>
        <ModalVideo channel='vimeo' isOpen={this.state.isOpen} autoplay={true} autopause={false} videoId='558486464' onClose={() => this.setState({isOpen: false})} />
        
      </div>
    );
  }
}

export default Hero ;
//export default withRouter(Hero) ;

