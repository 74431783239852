import React, { useState } from "react";
import { useForm } from 'react-hook-form' ; 
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import styles from "./adduser.module.css" ;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { addUser, getCurrentUser } from "../../services/userService";
// import Success from "../../common/tools-ui/success-check";
import Success from "../common/tools-ui/success-check";
import AddUserIcon from "../common/NavBar/add-user.svg" 

const schema = yup.object({
    email: yup.string().email('Invalid email format').required('Required'), 
}).required();

const AddUserForm =({ handleCloseModal , setWs, wsId })=> {
  const [loading, setLoading] = useState(false)
  const [message, setMessage]= useState("")
  const [userAdded, setUserAdded] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm({
      resolver: yupResolver(schema)
  });

  const onSubmit = async (data)=> {

    // setLoading(true);
    // setMessage(`Please contact us at support@zipscore.ai to add ${data.email} to this account. Ensure the user has a premium subscription.`)
    // setMessage(`Please contact us at support@zipscore.ai to add ${data.email} to this account.<br />Ensure the user has a premium subscription.`);
    // setLoading(false);
    // setUserAdded(true);

    /* ====== only for AppSumo launch uncomment  when done .. 
    */ 
   try {
       setLoading(true);
       const resp = await addUser(data.email, getCurrentUser().firstname );
       if (resp.status == 200) {
         setMessage(`Invitation link has been sent to ${data.email}.
         The link will expire in 30 days.`)
         setLoading(false);
         setUserAdded(true); 
         setTimeout(() => {
             setUserAdded(false); 
             setMessage('')
         }, 5000);
         
       }
     } catch (ex) {
       setLoading(false);
       if (ex.response && ex.response.status === 400) {
         setMessage("This email is already registered with us.");
       }
     }
  }

  return  <>

    {
        userAdded && 
        <>
            {/* remove this comment later  <Success /> */}
            {/* <span style={{color: '#0066CC' , fontSize : '12px'}} className="text-center mb-4 mt-4"> { message } </span> */}
            <div style={{color: '#0066CC' , fontSize : '12px'}} className="text-center mb-4 mt-4" dangerouslySetInnerHTML={{ __html: message }} />
        </>
    }
    {
        !userAdded && 
        <form
            className="form-row align-items-center p-4"
            style={{ width: "100%"}}
            onSubmit={handleSubmit(onSubmit)}
        >       
                <div className={`ml-5 mr-2 mt-1 ${styles.displayFlex}`}>
                    <input 
                        className={ `form-control mt-1 ${styles.textAreaPlaceholder}` }
                        type="text"
                        {...register("email", { required: true })}
                        placeholder='name@example.com' 
                    />
                    {errors.email && <span className='errorTextForm ml-2'> {errors.email.message} </span>}
                    { message &&  <span className='errorTextForm'> { message } </span> }
                </div>

                {/* <button className= {styles.addition_card} type="submit">
                  <img src={ AddUserIcon } />
                  <p style={{ color: "#333333" }}>Add User</p>
                </button> */}
                                    
                <div className= {``} >
                    <button className={`${styles.loginButton} ${styles.loginText}`} type="submit" >
                        <img src={ AddUserIcon } style={{ width: '25px'}} />
                    </button>
                </div>
        </form>  
    }
  </>
}

export default AddUserForm;