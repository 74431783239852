import React, { useEffect, useState } from 'react';
import { useHistory , Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { geoCentroid } from "d3-geo";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    Annotation
} from "react-simple-maps";

import '../style.css';
import Header from "../../../datas/de/Header"
import DashboardInfo from "../../../datas/de/DashboardInfo"
import Info from "../../../datas/de/Info"
import Demowall from "../../../datas/de/Demowall"
import allStates from "../allstates.json";
import { getStatesList } from "../../../../../services/browseService"; 

import LoadingPage from '../../../../common/tools-ui/loadingPage';
import { getCurrentUser } from '../../../../../services/userService';

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets = {
    VT: [50, -8],
    NH: [34, 2],
    MA: [30, -1],
    RI: [28, 2],
    CT: [35, 10],
    NJ: [34, 1],
    DE: [33, 0],
    MD: [47, 10],
    DC: [49, 21]
};

const States = () => {

    const [stateData, setStateData]= useState([]); 
    const [loading, setLoading] = useState(true) ; 
    const [exception, setException] = useState(false) ; 
    const history = useHistory() ; 

    useEffect(() => {
        getStates() 
    }, [])



    const getStates = async() => {
        try {
            const resp = await getStatesList("de")  
            setStateData([...resp.data])
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setException(true)
        }
    }

    const onClickHandler = async (data) => {    
        const cur = allStates.find(s => s.val === data.id);
        const searchparam = stateData.find(s => s.state === cur.name) ; 
        history.push(`/de/${ encodeURIComponent(searchparam.state_key) }`) ; 
    }

    const text=  <React.Fragment>
        <div className="text-justify">
            {/* <h2 className="customh2fontsize mt-4"> Demographic Market Segmentation by States of Germany  </h2> */}
            <p className="mt-3 mb-2">
            Businesses not only need the demographic data of the states in Germany including age, gender, 
            education, ethnicity and employment statistics but also need other location data such as footfall traffic 
            and consumer styles. Also, easy access to the location data and demographic data allows businesses to
            make informed decisions on market trends and consumer behaviour. To access location and demographic data,
            choose any German state from the list below, and you will have access to demographic and location data which can be drilled down to the 
            postal codes and cities.
            </p>
        </div>
    </React.Fragment>  

    const textlist = <React.Fragment>
        <div className="d-flex align-items-center mt-2 mb-2">
            <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662810349/free-tools/list.svg' style={{width:"47px", marginRight:"12px"}} alt="list "/>
            <h2 className="customh2fontsize"> Demographics by Germany's Postal Codes, Cities & States </h2>
        </div>
        <div className="text-justify">
            <p className="mt-3 mb-2"> 
            Government departments like Federal Statistical Office of Germany (Statistisches Bundesamt) release the data that are important to the individual department. 
            But Marketing Researchers, Data Analysts or Data Scientists often have to comb through tons of websites, use complicated data analytics tools to input and interpret data 
            and use visualization tools to utilize this data for geo marketing, market segmentation or finding the best suited audience for their businesses. 
            Zipscore makes this location and demorgaphic data more accesible - that is, users can access the location data for all states, cities and postal codes of Germany
            in less than 30 seconds. 

            </p>
            <hr/>
            <div className='state-city mt-4 justify-content-left align-items-center'>             
                    {              
                        stateData?.map((item) => { 
                            return <div key={item.state_key}>
                                        <a href={`/de/${encodeURIComponent(item.state_key)}`}> {item.state} </a> 
                                </div>
                        })             
                    }
            </div>
        </div>
    </React.Fragment>
    
    if(exception) return <Redirect to="/404"/> ; 
    if (getCurrentUser()) history.push("/home")      

    return (
        <div className='content-wrapper'>
            <Header />
            <Helmet> <title> {`Germany Census Data`} </title> </Helmet>
            <DashboardInfo title={'Demographics by State, City or ZIP codes in Germany '} description={text} />
            {   !loading && 
                <>
                <div className='container'>
                    <div className='state-wrapper'>
                        <div className='state-map'>
                            
                            {/* <ComposableMap projection="geoAlbersUsa">
                                <Geographies geography={geoUrl}>
                                    {({ geographies }) => (
                                        <>
                                            {geographies.map(geo => (
                                                <Geography
                                                    key={geo.rsmKey}
                                                    stroke="#FFF"
                                                    geography={geo}
                                                    fill="#d3d6be"
                                                    onClick={() => onClickHandler(geo)}
                                                />
                                            ))}
                                            {geographies.map(geo => {
                                                const centroid = geoCentroid(geo);
                                                const cur = allStates.find(s => s.val === geo.id);
                                                return (
                                                    <g>
                                                        {cur &&
                                                            centroid[0] > -160 &&
                                                            centroid[0] < -67 &&
                                                            (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                                                                <Marker coordinates={centroid}>
                                                                    <text y="2" fontSize={14} textAnchor="middle">
                                                                        {cur.id}
                                                                    </text>
                                                                </Marker>
                                                            ) : (
                                                                <Annotation
                                                                    subject={centroid}
                                                                    dx={offsets[cur.id][0]}
                                                                    dy={offsets[cur.id][1]}
                                                                >
                                                                    <text x={4} fontSize={14} alignmentBaseline="middle">
                                                                        {cur.id}
                                                                    </text>
                                                                </Annotation>
                                                            ))
                                                        }
                                                    </g>
                                                );
                                            })}
                                        </>
                                    )}
                                </Geographies>
                            </ComposableMap> */}
                        </div>
                    </div>
                </div>
            <Demowall title={`Predict success of your business.`}/>
            <Info description={textlist} />
            </>
            }
            { loading && <LoadingPage /> }
        </div>

    )
}

export default States ; 


/* 

Se

Zipcodes in texas
You identify potential new markets and understand your current customer base. Differentiation is key when it comes to marketing opportunities - knowing your customer base and being able to segment the different ZIP codes in Texas accordingly is of the utmost importance.
*/ 