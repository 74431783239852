import React, { useEffect , useState , useRef  } from "react";
import ReactFlagsSelect from "react-flags-select"
import { useParams } from "react-router-dom";
import { Loader, Message , Button } from 'rsuite';
import { useForm } from 'react-hook-form' ; 
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import mapboxgl from "mapbox-gl";
import './main1.css'
import { Link, useHistory } from "react-router-dom";
import Bottom from './Bottom'; 
import NavBar from "../common/NavBar/navbar";
import { getCopilotCompletion, getCopilotConversationHistory , archiveConversationHistory , getCopilotCompletionStream } from "../../services/exploreService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import aiProfile from "./ai.png" ; 
import SendIcon from './sendIcon' ; 
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { getCurrentUser } from "../../services/userService" ; 


import PromptSuggestions from "./promptSuggestions" ; 

const schema = yup.object({
        prompt: yup.string().required('Prompt is required').max(3200, 'Max length exceeded')
}).required();


const Copilot = () => {
    const [isOpenSidePanel, setisOpenSidePanel] = React.useState(false);
    const [loading, setLoading]= useState(false);  
    const { name, copilotName, task } = useParams();
    const [beastMode, setBeastMode]= useState(false);
    const [conversation, setConversation]=useState([]); 
    const [copilotDetails, setCopilotDetails]= useState([])
    const [prompt, setPrompt]= useState('')
    const [completion, setCompletion]= useState('')
    const [completionLoading, setCompletionLoading] = useState(false) ;
    const [completionError, setCompletionError] = useState(true)
    const [openDrawer, setOpenDrawer] = useState(!true);
    const [completionSettings , setCompletionSettings] = useState({
        temperature: 1,
        max_tokens: 256,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        best_of: 1
    })
    const [client, setClient] = useState(""); 
    const [ completionSettingsExists, setCompletionSettingsExists ] = useState(false) ;
    const [fileId, setFileId]= useState('')
    const [ sourceBlob, setSourceBlob ]= useState('')
    const [ asset, setAsset ]= useState('')
    const [ assetSaved, setAssetSaved] = useState(false)
    const [err, setErr]= useState('');
    const [lastwords, setLastwords] = useState('') ; 
    
    const chatContainerRef = useRef(null);
    const chatEndRef = useRef(null); 


    const history= useHistory();

    const { register, handleSubmit, formState: { errors } , reset } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        const fetchModelDetails = async () => {
            try {
                // let resp_ft = await getFinetuneHyperparameters(name, copilotName);
                setLoading(true);
                const clientDetails = await getCurrentUser()  ;
                setClient(clientDetails.clientId) ; 
                let resp2= await getCopilotConversationHistory()
                
                let convHistory = [] ;

                resp2.data.conv.forEach(item => {
                    item.choices.forEach(choice => {
                        convHistory.push(choice.message);
                    });
                });
                setConversation([...convHistory])
                setPrompt('resp_card.data.prompt');
                setLoading(false);
            } catch (error) {
                console.log(error)
                if (error.response && error.response.status === 404) {
                    history.push("/404");
                }
            }
        };

         fetchModelDetails();
        }, []);    

      const onSubmit = async (data) => {

          setErr('')
          setCompletionLoading(true)
         
          try {
            setConversation(prevConversation => [
                ...prevConversation,
                { role: 'user', content: data.prompt }
            ]);

            /*
            const resp = await getCopilotCompletion('us', data.prompt )
            if (!resp.data.err){

                let compl = resp.data.completionObj.choices[0].message.content ;

                setCompletion(compl)
                setPrompt(data.prompt)
                setCompletionLoading(false)
                setConversation(prevConversation => [
                    ...prevConversation,
                    { role: 'assistant', content: compl }
                ]);
                reset();
            } else if (resp.data.err){
                setCompletionError(true)
                setConversation(prevConversation => [
                    ...prevConversation,
                    { role: 'assistant', content: resp.data.errmessage , err: true}
                ]);
                setCompletionLoading(false) 
            }
            */

            const resp= await getCopilotCompletionStream('us' , data.prompt)

            if (!resp.ok || !resp.body) {
                setCompletionError(true)
                setConversation(prevConversation => [
                    ...prevConversation,
                    { role: 'assistant', content: resp.data.errmessage , err: true}
                ]);
                setCompletionLoading(false) 
                throw resp.statusText;
            }
            // Here we start prepping for the streaming response
            const reader = resp.body.getReader();
            const decoder = new TextDecoder();
            const loopRunner = true;
            let result = "";
        
            while (loopRunner) {
                const { value, done } = await reader.read();
                if (done) {
                    break;
                }
                const decodedChunk = decoder.decode(value, { stream: true });
                let compl = decodedChunk ;
                result += compl;

                setLastwords(result)
                reset();
            }
            setLastwords("")
            setCompletion(result)
            setPrompt(data.prompt)
            setCompletionLoading(false)
            setConversation(prevConversation => [
                ...prevConversation,
                { role: 'assistant', content: result }
            ]);
            
        } catch (ex) {
            if (ex.response && ex.response.status === 404){
                setErr(ex.response.data)
              }
            // setCompletion('error occured')
            setCompletionLoading(false)
        }
    }

    useEffect(() => {
        // chatEndRef.current.scrollIntoView();

        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView();
        }
        
      }, [conversation, lastwords]);


    const handleCountry = (val) =>{ 
        localStorage.setItem('user-country', val.toLowerCase())
        let path = `/home/${val.toLowerCase()}` ; 
        history.push(path);  
    }

    const handleArchive = async () =>{ 
        const userConfirmed = window.confirm("Are you sure you want to delete the conversation history?");
        
        if (userConfirmed) {
            await archiveConversationHistory()
            setConversation([])
        }
    }

    

    return (
        <>  

            <div className="custom-background-copilot">

                <NavBar />  
                <div className="container">
                <div className="chatBox mt-3 justify-content-center" ref={chatContainerRef} >  
                    {
                        !conversation.length >= 1 && 
                        <PromptSuggestions                
                            err={err} 
                            setErr={setErr} 
                            completionLoading={completionLoading} 
                            setCompletionLoading={setCompletionLoading} 
                            conversation={conversation} 
                            setConversation={setConversation} 
                            completion={completion} 
                            setCompletion={setCompletion} 
                            prompt={prompt} 
                            setPrompt={setPrompt} 
                            setCompletionError={setCompletionError} 
                            lastwords={lastwords} setLastwords={setLastwords}
                        />
                    }
                    <div className="chatData" style={{height: '100%', overflowY: 'auto'}}>
                    {
                        conversation.map((item, index) => (
                            <React.Fragment key={index}>
                                {
                                    item.role === 'user' &&
                                    <div className="msg msgadd">
                                    <p> {item.content} </p>
                                    
                                    </div>
                                }
                                {
                                    item.role === 'assistant' && !item.err && 
                                    <>                                                            
                                        <div className="msg msgin">
                                                <img src={aiProfile} className="mt-2" alt="aiProfile" />
                                                {/* <img src={Logo} className="mt-2" alt="aiProfile" /> */}
                                                <div className="">
                                                    {/* <p>{item.content} </p> */}
                                                    {/* <p> <ReactMarkdown>{item.content}</ReactMarkdown> </p> */}
                                                    {/* <p> <ReactMarkdown children={item.content} remarkPlugins={[remarkGfm]} /> </p> */}
                                                    <p> 
                                                        <ReactMarkdown 
                                                            children={item.content} 
                                                            remarkPlugins={[gfm]} 
                                                        /> 
                                                    </p>
                                                    {/* <ReactMarkdown plugins={[gfm]}>{item.content}</ReactMarkdown> */}
                                                </div>
                                        </div>
                                    
                                    </>
                                }
                                {
                                    item.role === 'assistant' && item.err && 
                                    <> 
                                        <div className="text-center mb-2">
                                            <p className="errorTextForm">{item.content} </p>
                                        </div>
                                    </>
                                }
                            </React.Fragment>
                        ))
                    }  
                    {
                        lastwords && (
                            <div className="msg msgin">
                                <img src={aiProfile} className="mt-2" alt="aiProfile" />
                                {/* <img src={Logo} className="mt-2" alt="aiProfile" /> */}
                                <div className="">
                                    {/* <p>{item.content} </p> */}
                                    {/* <p> <ReactMarkdown>{item.content}</ReactMarkdown> </p> */}
                                    {/* <p> <ReactMarkdown children={item.content} remarkPlugins={[remarkGfm]} /> </p> */}
                                    <p> 
                                        <ReactMarkdown 
                                            children={lastwords} 
                                            remarkPlugins={[gfm]} 
                                        /> 
                                    </p>
                                    {/* <ReactMarkdown plugins={[gfm]}>{item.content}</ReactMarkdown> */}
                                </div>
                            </div>
                        )
                    } 
                        <div ref={chatEndRef}></div>  
                    </div>
                </div>
                { !completionLoading && 
                    <>
                {/* <div style={{marginLeft : '130px'}} className="prompt-library-link-color">
                    <a target="_blank" href="/copilot/us/prompt-library" >
                        Prompt Library 
                    </a>
                    <i style={{color:'#ED5E68', fontSize: '18px' , marginLeft:'400px'}} className="fa fa-trash-o" aria-hidden="true"></i>
                </div> */}

                <div 
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginLeft: '130px'
                    }}
                    className="prompt-library-link-color mt-1"
                >
                    <a target="_blank" rel="noopener noreferrer" href="/ai-copilot/market-analysis/prompts/library">
                        Prompt Library 
                    </a>
                    <i 
                        style={{
                            color: '#ED5E68', 
                            fontSize: '18px',
                            fontWeight: '400',
                            marginRight: '85px'
                        }} 
                        className="fa fa-trash-o" 
                        aria-hidden="true"
                        onClick={handleArchive}
                    ></i>
                </div>

            <form onSubmit={handleSubmit(onSubmit)} className="mt-1">
                <div className="msgBox d-flex align-items-center"> 
                    
                    <ReactFlagsSelect
                        className="flag-select mr-3"
                        style={{fontSize: '80px'}}
                        countries={["US", "DE"]}
                        selected={'US'}
                        showSelectedLabel={false}
                        showOptionLabel={false}
                        alignOptionsToRight
                        // selected={country.toUpperCase()}
                        onSelect={(val) => handleCountry(val)}
                    />
                    <div className="containt d-flex">
                        <textarea
                            type="text"
                            {...register("prompt")} 
                            className='increasing'
                            placeholder="Write here..." 
                        >
                        </textarea>
                        { errors.prompt && <span className='errorTextForm mt-1'> {errors.prompt.message} </span> } 
                    </div>
                    
                    <button className="sendBtn"><SendIcon style={{border: 'none!important'}}/></button>
                </div>
            </form>
                    </>
                }
                {
                    completionLoading && <div className="text-center align-items-center mb-4">
                        <div class="spinner-grow spinner-grow-sm text-primary mr-3 " role="status"> <span class="sr-only"></span> </div>
                        <div class="spinner-grow spinner-grow-sm text-primary mr-3" role="status"> <span class="sr-only"></span> </div>
                        <div class="spinner-grow spinner-grow-sm text-primary" role="status"> <span class="sr-only"></span> </div>
                    </div>
                }

                {err && !completion && <span className="errorTextForm" dangerouslySetInnerHTML={{ __html: err }}/>} 
            <Bottom country= "us" client ={client} />                                        
            </div> 
        </div>
    </>

    )

}




export default Copilot; 