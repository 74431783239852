import React from "react";
import "./styles/usecases.css"
import AboutImg from "./assets/images/about-img.png";


class About extends React.Component {

  render() {


    return (
      <div className="l-about" id="usecases">
        <div className="left">
          <img style={{width:'80%' , marginTop: '-50' , marginRight:'10%'}} src={AboutImg} alt= 'about-img' />
        </div>
        
          <div className="right">

            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
              <div  className="carousel-inner">

                
                <div className="carousel-item active">
                      <h4 className="mr-5 ml-5">
                        How to Target Zip Codes in Google Ads
                      </h4>
                      <p className="mr-5 ml-5 mt-5" >
                        <a href="blog/how-to-target-zip-codes-in-google-ads" target="_blank" rel="noopener noreferrer">
                         <span style={{color:"#636566"}}> 
                          Zip code targeting is a marketing segmentation technique that involves using zip codes to segment content or ads to a specific audience.
                          It allows marketers and advertisers to customize their ads to make them appear 
                         </span> 
                         <span style={{color: "#1B72F5"}}> <b> [...] </b> </span>
                        </a>
                      </p>
                </div>


                <div className="carousel-item">
                    <h4 className="mr-5 ml-5">
                      How To Target Demographics by Zip Codes On Facebook Ads
                    </h4>
                    {/* --- medium blog => https://blogs.zipscores.ai/it-all-begins-here-14ec9c970e8d  */}
                    <p className="mr-5 ml-5 mt-5">
                      <a href="blog/how-to-target-demographics-by-zip-codes-on-facebook-ads" target="_blank" rel="noopener noreferrer">
                        <span style={{color:"#636566"}}> 
                        Zip code targeting is the act of targeting specific audiences online based on zip codes. It is a tactic used by marketers and advertisers to deliver content to specific audiences
                        </span>
                        <span style={{color: "#1B72F5"}}> <b> [...] </b> </span>
                      </a>
                    </p>
                </div>


                <div className="carousel-item">
                      <h4 className="mr-5 ml-5">
                        Point of Interest
                      </h4>
                      <p className="mr-5 ml-5 mt-5" >
                        <a href="blog/point-of-interest" target="_blank" rel="noopener noreferrer">
                         <span style={{color:"#636566"}}> 
                          Are you a retail outlet chain that caters to the needs of consumers belonging to a certain age group, 
                          or supply products that fit the lifestyle of households with kids, or a threshold income level?  The POI analysis can be very 
                         </span> 
                         <span style={{color: "#1B72F5"}}> <b> [...] </b> </span>
                        </a>
                      </p>
                </div>
                
 
              </div>

                <a className="mr-5 carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span className="mr-5 carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="mr-5 sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span className=" ml-5 carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="ml-5 sr-only">Next</span>
                </a>

            </div>

            <a href="/blog" target="_blank" rel="noopener noreferrer"> 
              <button className="btn primary mt-5">Read here</button> 
            </a>

          </div>
          
      </div>
    );
  }
}

export default About;
