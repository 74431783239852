import React from 'react';
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import ExploreContainer from "./exploreContainer";
import { live } from "../../global.js"
import { toast } from "react-toastify"; 
import { Helmet } from "react-helmet";

const Explore = (props) => {

    let country = props.match.params.country ; 

    if (!live.includes(country)) {
        props.history.push("/home"); 
        toast.error("You have selected wrong country", {
            autoClose: 5000,
            closeOnClick: true
        })
    }

    return <DndProvider backend={HTML5Backend}>
        <Helmet> <title> Zipscore | Fast Filters </title> </Helmet>
      <ExploreContainer country = {country} />
    </DndProvider>
}

export default Explore ;