import React , { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { Link, useHistory , Redirect } from 'react-router-dom';
import GoogleIcon from './icons/gicon.png'
import RightArrow from './icons/icons8-right-arrow-50 (2).png'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { googleAuth , getCurrentUser, login, loginWithJwt } from '../../services/userService';
import { Loader } from 'rsuite';
import toast from 'react-hot-toast';
import NavBar from '../common/NavBar/navbar-center';
import { gapi } from 'gapi-script';


const schema = yup.object({
    email: yup.string().email('Invalid email format').required('Required'), 
    pwd: yup.string().min(8).required('Required'),
}).required();

const SignIn = () => {
    
    const history = useHistory() ; 
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
   
    const responseGoogleSuccess = async (response) => {
        setLoading(true); 
        const args = { profile: response.profileObj , oneTap: false}
        const resp = await googleAuth( args )   
        
        setTimeout(() => {
            loginWithJwt(resp.headers['x-auth-zips'])
            if (resp.data === "new") {
                // send this to scribble page 
                window.location.href = `/user/form`
            } else {
                window.location.href = `/home`
            }
        }, 1000);

    }

    const responseGoogleFail = (response) => {
        setLoading(false); 
        toast.error("We could'nt authenticate your google account!")
    }

    const onSubmit = async (data) =>  {
        try {
            setLoading(true); 
            const resp = await login(data.email, data.pwd) ;
            if (!resp.data.user.firstname) {
                return window.location.href = `/user/form`
            } else {
                return window.location.href = `/home`
            }       

            /* == with set timeout ===
            setTimeout(() => {
                if (!resp.data.user.firstname) {
                    window.location.href = `/user/form`
                } else {
                    window.location.href = `/home`
                }
            }, 1500);
            */ 
             
        } catch (ex) {
            setLoading(false)
            if (ex.response && ex.response.status === 400){
                setErrorMsg(ex.response.data)
            }
        }
    };
    
    if (getCurrentUser()) history.push("/home")
    return (
        <>
        <NavBar />
        <div id='signIn' className="signIn-Page">
            <div className="signin-inner">
            {
                errorMsg && 
                <div className="mb-3 text-center">
                    <div className="alert alert-danger mb-3" role="alert">
                        {errorMsg}
                    </div>
                </div>
            }

                <div className='googlebtn-block'>
                    <GoogleLogin
                       clientId= {process.env.REACT_APP_GAUTH}
                       // clientId= {gclientId}
                       buttonText="Enable"
                        render={renderProps => (
                            <button onClick={renderProps.onClick} className='googlebtn'>
                                <img src={GoogleIcon} className="mr-2" alt="google icon"/> 
                                Sign In With Google 
                            </button>
                        )}
                        onSuccess={responseGoogleSuccess}
                        onFailure={responseGoogleFail}
                        cookiePolicy={'single_host_origin'}
                        //uxMode="redirect"
                    /> 
                </div>

                <div className='or-contain'>
                    <p>or</p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-icons">
                        <i className="fa fa-envelope icon">
                        </i>
                        <input className="input-field"
                            type="text"
                            {...register("email", { required: true })}
                            placeholder='name@example.com' 
                        />
                        {errors.email && <span className='errorTextForm'> {errors.email.message} </span>}
                    </div>

                    <div className='password-submit-block'>
                        <div className="input-icons mt-3">
                        <i className="fa fa-key icon"></i>                  
                            <input className="input-field password" 
                            type="password"
                                {...register("pwd", { required: true })} placeholder='Password' 
                            />
                            {errors.pwd && <span className='errorTextForm'> {errors.pwd.message} </span>}
                        </div>
                        <button type="submit" className='submitbtn mt-3'>
                            { !loading && <i className="fa fa-arrow-right"></i> }
                            { loading && <Loader /> }
                        </button>

                    </div>
                </form>
                <div className='sign-forgot-link-block mt-3'>
                    <Link to='/register'>
                        Sign Up
                    </Link>
                    <Link to='/forgotpassword'>Forgot Your Password?</Link>
                </div>
            </div>
        </div>
        </>
    )
}

export default SignIn
