import React, { useEffect, useState } from "react";
import styles from "./navbar.module.css";
import { Nav, Navbar, NavDropdown} from "react-bootstrap";
import logo from "../../../containers/logo.svg";
import _ from "lodash"; 
import { getCurrentUser } from "../../../services/userService";
import { Link } from "react-router-dom";
import { createCheckoutSession } from "../../../services/checkoutService";
import { getSubsDetails } from "../../../services/clientService";
import { compareDates } from "../../../utils/tools";

import AddUser from "./add-user.svg" ; 
import Premium from "./premium.svg" ; 

const NavBar = ({ isAdd , bgwhite }) => {

  const [freeVersion, setFreeVersion] = useState(true); 
  const [initial, setInitial] = useState("")
  
  useEffect(()=> {

    const user = getCurrentUser() ; 
    const word = user.firstname ? _.first(user.firstname.split("")) : _.first(user.email.split(""))  
    setInitial(word)

    async function getClientSubscription() {
      const resp = await getSubsDetails()
      const subscription = resp.data[0]; 
      const validate = compareDates( subscription.expiry  , new Date() )
      if (validate === 1 ){
          setFreeVersion(!freeVersion)
      }
    }; 

    getClientSubscription() ;


  }, [])



  const handleCheckoutSession = async() => {

    const returnUrl = window.location.href ; 
    const res = await createCheckoutSession(returnUrl) ;
    const sessionUrl = res.data.url ; 

    window.location.href= sessionUrl ;
  }

  return (
    <Navbar style={{ zIndex: 95 }} className={`${ bgwhite ? styles.whited : `` }`}>
      <Navbar.Brand className="ml-5" href="/home">
        <img className={styles.logoDimensions} src={logo} alt="" />
      </Navbar.Brand>

      <div className="d-flex ml-auto">

      <div className="mr-4">
        {/* <div id="geocoderNavbar"></div> */}
      </div>
        {
          isAdd && 
          <Link to="/account">
            <div className="user mr-4">
                <div className= {styles.addition_card}>
                  <img src={AddUser} alt="logo"/>
                  <p style={{ color: "#333333" }}>Add User</p>
                </div>
            </div>
          </Link>

        }
        {
          freeVersion && 
          <div className="user mr-4" onClick={handleCheckoutSession}>
            <div className={`${styles.upgradePremium}`} >
                <img src={Premium}  alt="premium"/>
            </div>
          </div>
        }

        <NavDropdown className={ ` ${styles.dropdown}` } title={
            <span style={{textTransform: "capitalize"}}>
              { initial }
            </span>
        } >
          <NavDropdown.Item href="/account" >Account</NavDropdown.Item>
          <NavDropdown.Item href="/logout" >Logout</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item target="_blank" href="/blog">Blog</NavDropdown.Item>
        </NavDropdown>
      </div>
      


    </Navbar>
  );
};

export default NavBar;
