import React, { Component } from 'react';
import styles from "./tutorials.module.css" ; 
import ReactModal from "react-modal" ;
import PopupVideo from "./popupVideo"
import "./rsuite-default-custom.css" ; //important because it includes table component from rsuite 

class TutorialsModal extends React.Component {
    state = {
        showModal: true 
    };


    handleModal = () => {
        localStorage.setItem('NoScribblePopUp', true)
        this.setState({ showModal: !this.state.showModal }); 
    }

    render() { 
        return <div>
                     <ReactModal 
                        isOpen={this.state.showModal}
                        className={ styles.Modal }
                        overlayClassName= {styles.Overlay}
                        onRequestClose={this.handleModal}
                        ariaHideApp={false}
                    >   
                        <div className="mt-2"> 
                            <PopupVideo handleCloseModal = {this.handleModal} />
                        </div>
                    </ReactModal>
        </div>;
    }
}

export default TutorialsModal;