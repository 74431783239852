import React, { useState , useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { logout } from "../../services/userService"


const Logout =()=> {
    
    useEffect(() => {
        logout() ; 
    }, [])
    return <Redirect to="/login" />
}

export default Logout ; 