import React, { useState , useEffect } from 'react' ; 
import { useParams , Redirect , useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import '../main.css';
import Header from '../../Header';
import Map from '../../Map/many.js';
import LivingWith from '../../LivingWith';
import ApartMent from '../../Apartment';
import Education from '../../Education';
import Household from '../../Household';
import DashboardInfo from '../../DashboardInfo';
import Info from '../../Info';
import Races from '../../Races';
import Demowall from "../../Demowall/index.js"; 
import Authwall from "../../Authwall/index.js"; 
import Limitwall from "../../Authwall/indexlimit.js"; 
import OneTap from "../../../../../common/Onetap.jsx" ;
import LoadingPage from "../../../../../common/tools-ui/loadingPage" ;

import allStates from "../../../../browse/de/allstates.json";
import { getState } from "../../../../../../services/lookupService" ; 
import { getCurrentUser } from "../../../../../../services/userService" ; 

const Main = () => {

  const [limitExceeded, setLimitExceeded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [exception, setException] = useState(false)
  const [dts, setDts] = useState([]) ; 
  const [areas, setAreas] = useState([]); 
  const [attributes, setAttributes] = useState({}) ;
  const [ loc, setLoc ] = useState({
    statekey : useParams().statekey
  }); 
  const [state, setState]= useState('') ;
  const [titletext, setTitletext] = useState('') ;
  const [living, setLiving] = useState('') ; 
  const [hhunit, setHhunit] = useState('') ;
  const [ageinc, setAgeinc] = useState('') ;
  const [race, setRace] = useState('') ;
  const history = useHistory() ; 
  const nf = new Intl.NumberFormat() ;

  useEffect(() => {

    async function getData() {

        try {
          const resp = await getState("de", encodeURIComponent(loc.statekey) )
          setLoading(false)
          const obj = resp.data[0].proportions[0] ; 
          const cur = allStates.find(s => s.name === resp.data[0].state);
          setDts([...resp.data[0].proportions])
          setAreas([...resp.data[0].zcta])

            setAttributes({
              state: resp.data[0].state , 
              statekey: resp.data[0].statekey , 
              population: resp.data[0].population ,
              households : resp.data[0].households , 
              old: resp.data[0].old , 
              young: resp.data[0].young , 
              center: [cur.lng, cur.lat ]
            })
      
            // === header text 
            setTitletext(
              <div className="text-justify">
                  <p className="mt-2 mb-2">
                  ZIP Codes in {`${resp.data[0].state}`} are home to {` ${nf.format(resp.data[0].population)} `} residents and 
                  {` ${nf.format(resp.data[0].households)} `} households.
                  In this area, {` ${obj.gender.male.toFixed(2)}`} percent of the population identify their gender as male 
                  and are majorly {`${ resp.data[0].young > resp.data[0].old ? `under the age of 40 years.`: `above the age of 40 years.` } `}
                  Gain access to real-time demographics, socio-demographic, and location data for the state of {`${resp.data[0].state}`} such as age, gender, household income, 
                  household living arrangement, total persons in household and type of housing units in less than 30 seconds on Zipscore.AI.
                  </p>
              </div>
            )
      
            //=== living arrangement + Persons in Household  
            setLiving(
              <div className="p-2 text-justify">  
                <div className="d-flex align-items-center">
                  <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1665159527/free-tools/debuildings.svg' style={{width:"47px", marginRight:"12px"}} alt="living"/>
                  <h2 className="customh2fontsize"> Buildings in {`${resp.data[0].state}`} ZIP Codes, Germany </h2>
                </div>
                <p className="mt-2 mb-3" >
                  According to the Federal Statistical Office of Germany (Statistisches Bundesamt) the buildings can be primarily classified into commercial or residential buildings. 
                  Commercial buildings are buildings that are used for commercial purposes, and include office buildings, warehouses, retail buildings etc., 
                  residential buildings are any building that is used primarily for residential purposes.
                  In the state of {` ${resp.data[0].state}`}, {` ${resp.data[0].proportions[0].buildings['residential']}`} percent of the buildings are 
                  residential whereas {` ${resp.data[0].proportions[0].buildings['commercial']}`} percent are commercial buildings. 
                </p>
                <div className="d-flex align-items-center">
                  <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/house_1.svg' style={{width:"47px", marginRight:"12px"}} alt="household"/>
                  <h2 className="customh2fontsize"> Persons in Household in {`${resp.data[0].state}`} ZIP Codes, Germany </h2>
                </div>
                <p className="mt-2 mb-3" >
                The share of household types can be classified into three categories - single person households, multiple person households with children and multiple person households without children. 
                In the state of {`${resp.data[0].state}`}, {` ${ resp.data[0].proportions[0].livingPerson["single"]}`} percent 
                of the households are single-person households. The househeolds with children account for 
                {` ${resp.data[0].proportions[0].livingPerson["m pers with children"]} `} percent. 
                </p>
              </div>
            )
            
            //=== HH unit structure type 
            setHhunit(
              <div className="p-2 text-justify">
                <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/building_1.svg' style={{width:"47px", marginRight:"12px"}} alt="housing" />
                    <h2 className="customh2fontsize"> Housing Unit Structures of ZIP Codes in {`${resp.data[0].state}`}, Germany </h2>
                </div>
              <p className="mt-2 mb-2">
                A housing unit is a single unit within a larger structure that can be used by an individual or
                household to eat, sleep, and live. The unit can be in any type of residence, such as a house, apartment, 
                or mobile home, and may also be a single unit in a group of rooms. 
                In the state of {`${resp.data[0].state}`},  {` ${resp.data[0].proportions[0].housingType['1-2 Family']}`} percent 
                of 1-2 family household housing units. The housing units which have 3-6 family household unit structures account for 
                {` ${resp.data[0].proportions[0].housingType['3-6 Family']}`}  percent 
                of all housing units in this state whereas 
                {` ${(resp.data[0].proportions[0].housingType['20+ Family']).toFixed(2)} `} 
                percent are 20 plus family housing units. 
              </p>
              </div> 
            )
            
            //===  Income + Age group 
            setAgeinc(
              <div className="p-2 text-justify">
                <div className="d-flex align-items-center">
                  <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/salary_1.svg' style={{width:"50px", marginRight:"12px"}} alt="age"/>
                  <h2 className="customh2fontsize"> Monthly Household Income for {`${resp.data[0].state}`} Postal Codes, Germany </h2>
                </div>
                <p className="mt-2 mb-3" >
                  Monthly household income generally is defined as the total gross income before taxes, 
                  received within a calendar month period by all members of a household above a specified age 
                  (age 15 and older). It includes — but is not limited to wage, salary, self-employment earnings, social security, 
                  pension, and income from other sources. 
                  In the state of {`${resp.data[0].state}`}, {` ${resp.data[0].proportions[0].income['below-1500']} `} percent have monthly household income below €1500 
                  and {` ${(resp.data[0].proportions[0].income['1500-2599'] + resp.data[0].proportions[0].income['2600-3999']).toFixed(2)} `} percent have 
                  household monthly income between €1500 and €4000. The proportion of high-income households above €7500 monthly which is usually low in most states of the  
                  Germany account for {` ${resp.data[0].proportions[0].income['above-7500']} `} percent in the state of {`${resp.data[0].state}`}. 
                </p>
                <div className="d-flex align-items-center">
                  <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/age-group_1.svg' style={{width:"50px", marginRight:"12px"}} alt="income"/>
                  <h2 className="customh2fontsize"> Age Distribution of {`${resp.data[0].state}`} ZIP Codes, Germany </h2>
                </div>
                <p className="mt-2 mb-2">
                The age distribution, also called Age Composition,
                in population studies, is the proportionate numbers of persons in successive 
                age categories in a given population. Age distribution affects many macroeconomic equations 
                such as consumer behaviour, consumer styles , housing-investment, money-demand, labor-force-participation etc.  
                In the state of {`${resp.data[0].state}`}, about {`${resp.data[0].proportions[0].age['10-24']}`} percent of the population 
                is between 10 to 24 years category, 
                {` ${resp.data[0].proportions[0].age['40-65']}`} percent into the 40 to 64 years age group and {` ${resp.data[0].proportions[0].age['above 65']}`} percent of 
                the population are over 65 years of age.
                </p>
                
              </div>
            )

        } catch (error) {
              setLoading(false)
          if (error.response.status === 429) {
              setLimitExceeded(true)
          } else {
            setException(true)
          }
        }
      }

      getData() ;

  }, [])
  
  const limitwall= <React.Fragment>   
      <div className="mt-3 mb-4 text-center " >
          <img style={{width:'100%' , maxWidth:'550px'}} src='https://res.cloudinary.com/zipscores-collection/image/upload/v1663332859/11132.jpg' alt="limits"/>
          <h2 className="mt-1 mb-5 text-center "> Please log in to view {`${loc.statekey}`} data </h2>
      </div>
    </React.Fragment>

  const authwall= <React.Fragment>   
      <p className="mt-3 mb-4 text-center "> Register for a free account to accesss all the features of Zipscore.AI. </p>
    </React.Fragment>

  if(exception) return <Redirect to="/404"/> ;
  if (getCurrentUser()) history.push("/home")

      
  return (
    <div className='content-wrapper'>
      <OneTap />
      <Header />
      { dts.length > 0 && loc.statekey && Object.keys(attributes).length && !limitExceeded && !loading &&
        <>
          <Helmet> <title> {`${attributes.state}, Germany`} </title> </Helmet>
          <DashboardInfo 
            title={`${attributes.state} ZIP Code Map, Germany`} 
            description={titletext} 
            link1={`/browse/de/${ encodeURIComponent(attributes.statekey) }/zipcodes`} 
            link1title={`Demographics by ZIP codes in ${attributes.state}`}
            link2={`/browse/de/${ encodeURIComponent(attributes.statekey) }/cities`} 
            link2title={`Demographics by Cities in ${attributes.state}`}
          />
          <Map dts={dts}  areas={areas} center={attributes.center} country={'de'} attributes={attributes} zoom={7} />
          <Info description={living} />
          <LivingWith dts={dts} />
          <Authwall description={authwall}/>
          <Info description={hhunit} />
          <ApartMent dts={dts} />
          <Demowall title={`Predict success of your business in ${attributes.state}.`}/>
          <Info description={ageinc} />
          <Household dts={dts} />
        </>
      }
      { loading && dts.length == 0 && !limitExceeded && <LoadingPage /> }
      { dts.length == 0 && limitExceeded && !loading && <Limitwall description={limitwall}/> }
    </div>
  )
}

export default Main ; 
