import React from "react";
import Switch from "react-switch";
// import PlanCard from "./planCard"

import styles from "./styles/pricing.module.css";
//import * as typeformEmbed from '@typeform/embed';
import { Link } from "react-router-dom"; 
import SignUp from "../auth/Signup.reuse.jsx";
import { FreePlan, PremiumPlan, EnterprisePlan } from "./planCard";



class Pricing extends React.Component {

  constructor() {
    super();
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
  }
  

  handleChange(checked) {
    this.setState({ checked });
  }


  render() {

    return (
      <div className={styles.l_pricing} id="pricing">
          
          <span className={styles.headline}> Start for free now, pick a plan later </span>
          <h3 className={styles.heading}>No credit card required. No risk.</h3>
        
          <div className={`mt-5 ${styles.plans}`}>

                  <div className={styles.planslist}>
                       <FreePlan />
      
                      <div className="ml-3">
                        <PremiumPlan />
                      </div>

                       <div className="ml-3">
                          {/* <PlanCard bestvalue={0} title={"Premium"} price={12}/> */}
                          <EnterprisePlan />
                          <div className="mt-1 text-right" style={{ marginBottom: '-12px' , color: '#8492a5' , fontSize: '12px'}}>
                            * exclusive tax
                          </div>  
                       </div>
                  </div>   
                  
          </div>

      </div>
    );
  }
}

export default Pricing;

