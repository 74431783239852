import http from "../services/httpService";

import config from "../config/api.js";
const { apiUrl_dev, apiUrl_prod } = config;

const apiUrl = process.env.REACT_APP_ENVIRON === "development" 
               ? apiUrl_dev
               : apiUrl_prod


/*
export function createCustomerSession( returnUrl ) {
    return http.post(`${apiUrl}/checkout/create-customer-session`, {
        returnUrl: returnUrl
    });    
}
*/


export function createCheckoutSession( returnUrl ) {
    return http.post(`${apiUrl}/checkout/create-checkout-session`, {
        returnUrl: returnUrl
    });    
}



export function createAppsumoCheckout( couponcode ) {
    return http.post(`${apiUrl}/checkout/appsumo`, {
        couponcode: couponcode
    });    
}

