import React, { Component } from 'react' ; 
import styles from "./loadingPage.module.css" ; 

class LoadingPage extends Component {
    state = {  }
    render() { 
        return ( 
            <>
                {/* <div className={ styles.loading }>
                    <span>
                        Loading&#8230;
                    </span>
                    <span className=""> yes </span>
                </div> */}
                <div className={`${styles.loading}`}>Loading&#8230;</div>
                {this.props.text && 
                    <div className={`${styles.message}`}> <h5> {this.props.text} </h5> </div>
                }
        
            </>
         );
    }
}
 
export default LoadingPage;