import React from 'react';
import { PieChart, Pie, Cell , Tooltip } from 'recharts';


// let colorList = ["#E05A38" , "#E0D765" , "#E06C4F" , "#38E0A9" ]
let colorList = ["#3C76AE" , "#E0D765" , "#E05A38", "#38E0A9" ]


const LivingWithChart = ({dts}) => {
    const data = [];
    for (let i in dts) {
        data.push({ name: i, value: dts[i] })
    }

    const CustomTooltip = ({ active, payload }) => {
      if (active) {
          return (
              <div
                  //className="custom-tooltip"
                  style={{
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      textAlign: "center" ,
                      padding: "8px" ,
                      borderRadius: "6px", 
                      fontFamily: "Axiform" ,
                      // transform: "translate(100%) !important" 
                  }}
              >
                  <label style={{color: "#FFFFFF"}}>{`${payload[0].value}%`}</label>
              </div>
          );
      }
      return null;
  };


    return (
        <div className='Living-description'>
            
            <PieChart width={260} height={200}>
                <Pie
                    data={ data}
                    isAnimationActive={false}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    // label={renderCustomizedLabel(newLiving)}
                    innerRadius={60}
                    outerRadius={100}
                    stroke="none"
                    // fill="#8884d8"
                    dataKey="value"
                >
                    {data.map((item, index) => {
                        return <Cell key={`cell-${index}`} fill={`${colorList[index]}`} />
                    })}
        
                </Pie>
                <Tooltip content={<CustomTooltip />} />
            </PieChart>

            <div className='Living-content'>
                {Object.keys(dts).map(function(key, index) {
                    return  <div key={key} className='Living-desc-wrapper'>
                        <div className='Living-desc' key={key}>{key}</div>
                        <div className='Living-desc-after' style={{ backgroundColor : `${colorList[index]}` }} ></div>
                    </div>
                    
                })}
            </div>
        </div>
    )
}

export default LivingWithChart