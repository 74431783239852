import React from 'react'
import './style.css';
import SignUp from "../../../../auth/Signup.reuse.jsx" ; 

function index({ attributes , zip , description , showsingup }) {
    return (
        <div className='dashboard-wrapper-info'>
            <div className='container' >
                <div className='dashboard-content-wrapper-main-info'>
                    <div className='dashboard-content-wrapper-info-noborder'>
                        <div className='dashboard-info-block-info'>
                            <div className="mt-1"> { description } </div>
                        </div>
                        { !showsingup && 
                            <div className="mt-3 text-center align-items-center justify-content-center">
                                <SignUp />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default index


// it costs less than how much it will take for you hire a good data analyst or scientist. 
// If you would like to compare your KPI's against the ZIP Codes