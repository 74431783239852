import React, { Component } from 'react';
import "./notfound.css"
import Header from "../../look-up/datas/us/Header"

class NotFound extends React.Component {
    render() { 
        return (
            <>
            <Header /> 
            <section className="page_404">
                <div className="container">
                    <div className="row">	
                    <div className="col-sm-12 ">
                    <div className="col-sm-10 col-sm-offset-1  text-center">
                    <div className="four_zero_four_bg">
                        <h1 className="text-center ">404</h1>
                    </div>
                    <div className="contant_box_404">
                    <h3 className="h2">
                    Looks like we do not have the data for this location.
                    </h3>    
                    Or something went wrong! 
                    {/* <p>We're live only in Germany for now.</p> */}
                    <div>
                        <a href="/home" className="link_404"> Home </a>
                    </div>
                </div>
                    </div>
                    </div>
                    </div>
                </div>
            </section>
            </>
        )
    }
}
 
export default NotFound;