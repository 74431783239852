import http from "../services/httpService";
// import { apiUrl_dev, apiUrl_prod  } from "../config/config.json";

import config from "../config/api.js";
const { apiUrl_dev, apiUrl_prod } = config;

const apiUrl = process.env.REACT_APP_ENVIRON === "development" 
               ? apiUrl_dev
               : apiUrl_prod


export function addEntry() {

    const date= new Date(); 
    const today = date.setHours(0,0,0,0) ; 
    var entry = JSON.parse(localStorage.getItem("entry"));
    var newentry = { "ts" : today , val: 1 } ;
  
    if (entry == null || entry["ts"] !== today  ) {
        localStorage.setItem("entry", JSON.stringify(newentry));
        return 1; 
    } else {
        var append = { "ts" : entry["ts"] , val: entry["val"]+1 } ; 
        localStorage.setItem("entry", JSON.stringify(append));
        return entry["val"]+1 ; 
    }
};

// change to getZipcodeResultFree 
export function getZipcodeResult (country , zipcode) {
    return http.post(`${apiUrl}/lookup/${country}/state/city/zipcode`, {
        country: country , 
        zipcode: zipcode ,
        entry : addEntry() 
    });   
}

export function getState (country , statekey) {

        return http.post(`${apiUrl}/lookup/${country}/state`, {
            country: country , 
            state_key: statekey ,
            entry : addEntry() 
        });

}


export function getStateCity (country , statekey, citykey) {

    return http.post(`${apiUrl}/lookup/${country}/state/city`, {
        country: country , 
        state_key: statekey, 
        city_key: citykey,
        entry : addEntry() 
    });
      
}

export function getScore (country , score) {

    return http.post(`${apiUrl}/lookup/${country}/score`, {
        country: country , 
        score: score 
    });
      
}





// ================ AUTH API's =========================== 
// =======================================================

export function getZipcodeResultAuth (country , zipcode) {
    return http.post(`${apiUrl}/lookup/auth/${country}/state/city/zipcode`, {
        country: country , 
        zipcode: zipcode ,
        entry : 1
    });   
}

export function getStateAuth (country , statekey) {

        return http.post(`${apiUrl}/lookup/auth/${country}/state`, {
            country: country , 
            state_key: statekey ,
            entry : 1
        });

}

export function getStateCityAuth (country , statekey, citykey) {

    return http.post(`${apiUrl}/lookup/auth/${country}/state/city`, {
        country: country , 
        state_key: statekey, 
        city_key: citykey,
        entry : 1
    });
      
}