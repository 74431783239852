import './main.css';
import React, { useState , useEffect } from 'react' ; 
import { useParams , Redirect , useHistory } from "react-router-dom";
import Map from '../../Map/many.js';
import LivingWith from '../../LivingWith';
import ApartMent from '../../Apartment';
import Education from '../../Education';
import Household from '../../Household';
import DashboardInfo from '../../DashboardInfo';
import Info from '../../Info';
import Races from '../../Races';
import Demowall from "../../Demowall/index.js"; 
import Occupations from '../../Occupation';
import LoadingPage from "../../../../../common/tools-ui/loadingPage" ;
import NavBar from "../../../../../common/NavBar/navbar.jsx" ;
import { Helmet } from "react-helmet";
import { createCheckoutSession } from "../../../../../../services/checkoutService";
import { getStateCityAuth } from "../../../../../../services/lookupService" ; 
import _ from "lodash" ; 

const Main = () => {

  const [limitExceeded, setLimitExceeded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [exception, setException] = useState(false)
  const [dts, setDts] = useState([]) ; 
  const [areas, setAreas] = useState([]); 
  const [attributes, setAttributes] = useState({}) ;
  const [ loc, setLoc ] = useState({
    statekey : useParams().statekey, 
    citykey: useParams().citykey
  }); 
  const [titletext, setTitletext] = useState('') ;
  const [living, setLiving] = useState('') ; 
  const [hhunit, setHhunit] = useState('') ;
  const [ageinc, setAgeinc] = useState('') ;
  const [race, setRace] = useState('') ;
  const [education, setEducation] = useState('') ; 
  const [occupations, setOccupations ] = useState('') ; 
  const [textpopulated, setTextpopulated] = useState('') ; 
  const history = useHistory() ; 
  const nf = new Intl.NumberFormat() ;

  useEffect(() => {

    async function getData() {
      
      try {   

          const resp = await getStateCityAuth("us", encodeURIComponent(loc.statekey) , encodeURIComponent(loc.citykey) )
          const obj = resp.data[0].proportions[0] ; 
          setDts([...resp.data[0].proportions])
          setLoading(false)
          /*
          setAreas([...resp.data[0].zcta])
          setAttributes({
            state: resp.data[0].state , 
            city: resp.data[0].city , 
            population: resp.data[0].population ,
            households : resp.data[0].households , 
            old: resp.data[0].old , 
            young: resp.data[0].young , 
            center: [resp.data[0].lng, resp.data[0].lat ]
          })
          */
          const plzs = _.map(resp.data[0].zcta, 'plz')
          setAreas([...plzs])
          setAttributes({
            state: resp.data[0].state , 
            city: resp.data[0].city , 
            population: resp.data[0].population ,
            households : resp.data[0].households , 
            old: resp.data[0].old , 
            young: resp.data[0].young , 
            center: [resp.data[0].lng, resp.data[0].lat ]
          })
          setTitletext(
            <div className="text-justify">
                <p className="mt-2 mb-2">
                {`${resp.data[0].city}`} is located in the state of {`${resp.data[0].state}`}. The city of {`${resp.data[0].city}`} has {`${resp.data[0].zipcounts > 1 ? `${resp.data[0].zipcounts} ZIP Codes`: `${resp.data[0].zipcounts} ZIP Code` }`}. 
                There are {` ${nf.format(resp.data[0].population)} `} residents and 
                {` ${nf.format(resp.data[0].households)} `} households in {`${resp.data[0].city}`}. In this city, 
                {` ${obj.gender.male.toFixed(2)}`} percent of the population identify their gender as male 
                and the majority are {`${ resp.data[0].young > resp.data[0].old ? `under 40 years of age.`: `over 40 years of age.` } `}
                </p>
    
            </div>
          )
    
          //=== living arrangement + Persons in Household  
          setLiving(
            <div className="p-2 text-justify">  
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658682911/free-tools/family_1.svg' style={{width:"47px", marginRight:"12px"}}/>
                <h5> Household Living Arrangement in {`${resp.data[0].city}, ${resp.data[0].state} `}, US </h5>
              </div>
              <p className="mt-2 mb-3" >
                The U.S. Census Bureau defines a household as follows: “a household includes all the persons who occupy a housing unit as their usual place of residence. 
                Household living arrangements refer to whether or not the person lives with another person or persons and, if so,
                whether or not he or she is related to that person or persons. Household living arrangements can be categorized into four main categories 
                - living alone, living with children under 18, living without children all 18 plus and living with a person 60 plus age.  
                In the city of {` ${resp.data[0].city}`}, {` ${resp.data[0].proportions[0].livingWith['Living Alone']}`} percent 
                of households live alone whereas {` ${resp.data[0].proportions[0].livingWith['Living with Children under 18']}`} percent of 
                households live with children under 18. 
                Also, {`${resp.data[0].proportions[0].livingWith['Living with person 60 plus age']}`} percent of households in 
                the {`${resp.data[0].city}`} have at least one person above the age of 60.
              </p>
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/house_1.svg' style={{width:"47px", marginRight:"12px"}}/>
                <h5> Persons in Household in {`${resp.data[0].city}, ${resp.data[0].state} `}, US </h5>
              </div>
              <p className="mt-2 mb-3" >
              According to the U.S. Census Bureau the number of people per household has decreased over the past decades in the U.S.
              In {`${resp.data[0].city}`}, {` ${ resp.data[0].proportions[0].livingPerson["1 per"]}`} percent 
              of the households are one-person households, the two and three-person households 
              together account for {`${(resp.data[0].proportions[0].livingPerson["2 per"] + resp.data[0].proportions[0].livingPerson["3 per"]).toFixed(2)} `} percent  
              whereas {` ${resp.data[0].proportions[0].livingPerson["3 per"]} `} percent of the households have at least 4 or more persons.  
              </p>
            </div>
          )
          
          //=== HH unit structure type 
          setHhunit(
            <div className="p-2 text-justify">
                <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/building_1.svg' style={{width:"47px", marginRight:"12px"}}/>
                    <h5> Housing Unit Structures in {`${resp.data[0].city}, ${resp.data[0].state} `} US </h5>
                </div>
              <p className="mt-2 mb-2">
                A housing unit is a single unit within a larger structure that can be used by an individual or
                household to eat, sleep, and live. The unit can be in any type of residence, such as a house, apartment, 
                or mobile home, and may also be a single unit in a group of rooms. 
                In the city of {`${resp.data[0].city}`},  {` ${resp.data[0].proportions[0].housingType['1 unit, detached']}`} percent 
                of housing units are detached single units. The housing units which have 2, 3, or 4 apartments account for 
                {` ${(resp.data[0].proportions[0].housingType['2 apartments']+resp.data[0].proportions[0].housingType['3 or 4 apartments']).toFixed(2)} `} percent 
                of all housing units in this city whereas 
                {` ${(resp.data[0].proportions[0].housingType['5 or 9 apartments']+resp.data[0].proportions[0].housingType['10 or more apartments']).toFixed(2)} `} 
                percent have at least 5 or more apartments. 
              </p>
            </div> 
          )
          
          //===  Income + Age group 
          setAgeinc(
            <div className="p-2 text-justify">
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/salary_1.svg' style={{width:"50px", marginRight:"12px"}}/>
                <h5> Household Income in {`${resp.data[0].city}, ${resp.data[0].state} `}, US </h5>
              </div>
              <p className="mt-2 mb-3" >
                Household income generally is defined as the total gross income before taxes, 
                received within a 12-month period by all members of a household above a specified age 
                (the Census Bureau specifies age 15 and older). 
                It includes — but is not limited to wage, salary, self-employment earnings, social security, 
                pension, and income from other sources. 
                In {`${resp.data[0].city}`}, 
                {` ${(resp.data[0].proportions[0].income['below-35,000'] + resp.data[0].proportions[0].income['35,000-49,999']).toFixed(2)} `} percent 
                of households have a gross annual income below $50,000 
                and {` ${(resp.data[0].proportions[0].income['50,000-99,999'] + resp.data[0].proportions[0].income['100,000-149,000']).toFixed(2)} `} 
                percent of households have a gross annual income between $50,000 and $150,000. 
                The proportion of high-income households above $150,000 which is usually low in most of the cities of the 
                US account for {` ${resp.data[0].proportions[0].income['above-150,000']} `} percent in {`${resp.data[0].city}`}. 
              </p>
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/age-group_1.svg' style={{width:"50px", marginRight:"12px"}}/>
                <h5> Age Distribution in {`${resp.data[0].city}, ${resp.data[0].state} `}, US </h5>
              </div>
              <p className="mt-2 mb-2">
              The age distribution, also called Age Composition,
              in population studies, is the proportionate numbers of persons in successive 
              age categories in a given population. Age distribution affects many macroeconomic equations 
              such as consumer behaviour, consumer styles , housing-investment, money-demand, labor-force-participation etc.  
              In {`${resp.data[0].city}`}, about {`${resp.data[0].proportions[0].age['10-24']}`} percent of the population 
              is between 10 to 24 years category, 
              {` ${resp.data[0].proportions[0].age['40-64']}`} percent into the 40 to 64 years age group and {` ${resp.data[0].proportions[0].age['above 64']}`} percent of 
              the population are over 65 years of age.
              </p>
              
            </div>
          )
    
          //=== race
          setRace(
            <div className="p-2 text-justify">
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662210778/free-tools/ethnicity.png' style={{width:"49px", marginRight:"12px"}}/>
                <h5> Ethnicity Groups for {`${resp.data[0].city}, ${resp.data[0].state} `}, US </h5>
              </div>
              <p className="mt-2 mb-2">
                The racial data collected by the US census bureau are based on self-identification. 
                The racial categories included in the census questionnaire are - White, 
                Black or African American, American Indian or Alaska Native, Asian, 
                and Native Hawaiian or Other Pacific Islander. 
                The white population alone in the city of {`${resp.data[0].city}`} is 
                {` ${resp.data[0].proportions[0].races['White']}`} percent and 
                about {` ${resp.data[0].proportions[0].races['Black or African American alone']} `} 
                percent of the population is Black or African American alone.
                Moreover, the Asian alone ethnicity accounts for {` ${resp.data[0].proportions[0].races['Asian alone']}`} percent in {` ${resp.data[0].city}`}.
              </p>
            </div>
          )

          //===  Education
          setEducation(
            <div className="p-2 text-justify">
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1665248778/free-tools/education.svg' style={{width:"50px", marginRight:"14px"}}/>
                <h2 className="customh2fontsize"> Education Attainment in {`${resp.data[0].city} , ${resp.data[0].state} `}, US </h2>
              </div>
              <p className="mt-2 mb-3" >

              Educational attainment refers to the highest level of education that an individual has completed. 
              This is distinct from the level of schooling that an individual is attending.
                This data is reported by US Census Bereau on three levels - School level, Bachelor and Graduate level education. 
              
              In the state of {`${resp.data[0].state}`}, {` ${resp.data[0].proportions[0].education['School level']}`} percent
              have completed the school level education whereas {` ${resp.data[0].proportions[0].education['Bachelor']}`} have finished their Bachlor strudies. 
              The porportion of graduates in {`${resp.data[0].state}`} is {` ${resp.data[0].proportions[0].education['Graduate']}`} percent. 
              </p>   
            </div>
          )
          
          //===  occupation 
          setOccupations(
            <div className="p-2 text-justify">
            <div className="d-flex align-items-center">
              <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1665249595/free-tools/occupation.png' style={{width:"49px", marginRight:"14px"}}/>
              <h2 className="customh2fontsize"> Employment by Industry Sector in {`${resp.data[0].city} , ${resp.data[0].state} `}, US </h2>
            </div>
            <p className="mt-2 mb-2">
            The U.S. Bureau of Labor Statistics provides data on employment by industry sectors.
            Workplaces are classified into industries based on their principal product or activity. 
            This statistic shows the distribution of the workforce across different economic sectors.
            In the state of {`${resp.data[0].state}`} , {`${resp.data[0].proportions[0].occupations['Educational services, and health care and social assistance']}`}  percent of the workforce 
            is employed in Educational services, and health care and social assistance
            sector, {`${resp.data[0].proportions[0].occupations['Construction']}`} percent in Construction and 
            {` ${resp.data[0].proportions[0].occupations['Manufacturing']} `} percent in Manufacturing. 
            The Industry sector "Professional, scientific, and management, and administrative and waste management services" 
            which includes some of the highest paid proffessions such as Software Developers, Other Engineering Managers account 
            for {`${resp.data[0].proportions[0].occupations['Professional, scientific, and management, and administrative and waste management services']} `} 
            percent in the state of {`${resp.data[0].state}`}.
            </p>
          </div>
          )

          setTextpopulated (
            <div className="p-2 text-justify">
                <>
                  <div className="d-flex align-items-center">
                      <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1666108937/free-tools/populatedzips.svg' style={{width:"49px", marginRight:"12px"}} alt="population"/>
                      <h2 className="customh2fontsize"> ZIP Codes in {`${resp.data[0].city} , ${resp.data[0].state} `}, US </h2>
                  </div>
                  <div className="text-justify">
                      <p className="mt-2 mb-2"> 
                          According to the US Census Bereau, the city of {`${resp.data[0].city}`} have {`${resp.data[0].zipcounts}`} ZIP code tabulation areas (ZCTA's). 
                          The list of the ZIP Codes in {`${resp.data[0].city}`} are as follows -    
                      </p>
                      <div className='state-city mt-1 mb-3 justify-content-left align-items-center'>    
                           {
                              resp.data[0].zcta.map((item) => { 
                                  return <div key={item.key} >
                                              <a href={`/lookup/us/${item.plz}/${item.lng}/${item.lat}`}> {item.plz} </a> 
                                    </div>
                              })  
                            }
                      </div>
                      <hr className=" mb-2"/>
                      <div className="mt-2 hover-underline-animation  dashboard-content-wrapper-link" style={{borderBottom: "1.2px solid #007bff"}}>
                        <a href={`/lookup/browse/us/${ encodeURIComponent(loc.statekey)}/zipcodes`}> List of all ZIP codes in {`${resp.data[0].state}`} </a>
                        {/* <a className="mr-5" href={`/browse/us/${ encodeURIComponent(loc.statekey) }/cities`} > List of all cities in {`${resp.data[0].state}`} </a> */}
                      </div>
                  </div>     
                </>
            </div>
          )
      } catch (error) {
        setLoading(false)
        if (error.response.status == 429) {

            setLimitExceeded(true)
            const returnUrl = window.location.protocol + '//' + window.location.host + '/lookup/browse/us/states'; 
            const res = await createCheckoutSession(returnUrl) ;
            const sessionUrl = res.data.url ; 
            window.open(sessionUrl, "_blank");
            history.goBack()

        } else {
          setException(true)
        }
      }


    }
    getData() ;

  }, [])


if(exception) return <Redirect to="/404"/> ; 

  return (

    <div className='content-wrapper'>
      <NavBar isAdd={true} bgwhite={true}/>
      { dts.length > 0 && loc.statekey && Object.keys(attributes).length && !limitExceeded && !loading && 
        <>
          <Helmet> <title> {`Lookup | ${attributes.city}, ${attributes.state}, US`} </title> </Helmet>
          <DashboardInfo 
            title={`${attributes.city} ZIP Codes, ${attributes.state}, US`} 
            description={titletext} 
            link1={`/lookup/browse/us/${ encodeURIComponent(loc.statekey) }/zipcodes`} 
            link1title={`Demographics by ZIP codes in ${attributes.state}`}
            link2={`/lookup/us/${ encodeURIComponent(loc.statekey) }`} 
            link2title={`Demographics of ${attributes.state}`}
            />
          <Map dts={dts}  areas={areas} center={attributes.center} country={'us'} attributes={attributes} zoom={12}/>
          <Info description={living} />
          <LivingWith dts={dts} />
          <Demowall title={`Predict success of your business in ${attributes.city}.`}/>
          <Info description={hhunit} />
          <ApartMent dts={dts} />
          <Info description={ageinc} />
          <Household dts={dts} />
          <Info description={education} />
          <Education dts={dts} />
          <Info description={occupations} />
          <Occupations dts={dts} />
          <Info description={race} />
          <Races dts={dts} />
          <Info description={textpopulated} />
        </>
      }
      { loading && dts.length == 0 && !limitExceeded && <LoadingPage /> }
      { dts.length == 0 && limitExceeded && !loading && <LoadingPage text={'Free limits exceeded'} /> }
    </div>
  )
}

export default Main ; 