import React from 'react'

const Policy = () => {
  const myPolicy = 38906468 // your policy id

  return (
      <div className="embed-responsive embed-responsive-1by1 overflow-hidden ">
        <iframe src={'https://www.iubenda.com/privacy-policy/38906468/full-legal'} allowfullscreen></iframe>
    </div>
  )
}


export default Policy ;