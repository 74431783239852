import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PopupVideo extends React.Component {
    render() { 
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <div className="pull-right" style={{marginTop: "-2rem" , marginRight: "-1rem"}}>
                            <FontAwesomeIcon onClick = { this.props.handleCloseModal }icon={['fa', 'times-circle']} />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center text-center">
                    <div className="col-md-12">
                        <h3 style={{color:"#358df2", padding: '20px'}} > First things first, pick a pen and start scribbling!</h3>

                        <span >
                            Create markers, polygons for your target locations.
                            These scribbles will remain on the map and shared across your team 
                            while you analyze demographic data. Not only this, you can also collaborate with your team in real-time!<br />
                        </span>
                    </div>
                </div>
                <div className="row mt-4 justify-content-center text-center">
                    <div className="col-md-12">
                        <iframe 
                            src="https://player.vimeo.com/video/615135176?h=7e1a4267ef&api=1&background=1&mute=0&quality=1080p" 
                            style={{width : '73vh', height : '58vh', borderRadius: '5px', left:'50%'}}
                            frameBorder="0" 
                            allow="autoplay; fullscreen; picture-in-picture" 
                            title="audienceScoreDes"
                            className="iframeVideoVimeo"
                        /> 
                    </div>
                </div>
            </>
        )
    }
}

export default PopupVideo;