import React from "react";
import "./styles/footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 


import mediumIcon from "./assets/images/medium.png" ;

class Footer extends React.Component {
  render() {
    return (
      <div className="l-footer">

        <div >
          ©2024 Zipscore.ai <br/>
          <div className="mt-2">
            <b> Gigaparsec DataLab GmbH</b><br/>
                Mühlenstraße 8 a, <br/>
                14167 Berlin, Germany 
          </div>
        </div>

        <div className="l-footer-right">

            <div className="socials">
                    <a href="https://www.linkedin.com/company/zipscore-ai" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon className= 'iconLi' icon={['fab', 'linkedin']} />
                    </a>
                    <a href="https://twitter.com/zipscore" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon className= 'ml-3 iconTweet' icon={['fab', 'twitter']}/>
                    </a> 
                    <a href="https://medium.com/zipscores-ai" target="_blank" rel="noopener noreferrer">
                        {/* <FontAwesomeIcon className= 'ml-3 iconMedium' icon={['fab', 'medium']}/> */}
                        <img src={mediumIcon} className="ml-3 mb-3" style={{height: '28px' , width: "28px"}}  alt="medium-icon"/>
                    </a> 
            </div>

            <div className="l-footer-made" style={{color:"grey"}}  >
                
                <div>
                  <a href="/gigaparsec-datalab" target="_blank"> <span className="ml-2 mr-2"> Company </span> </a>|  
                  {/* <a href="/blog" target="_blank"> <span className="ml-2 mr-2"> Our Blog </span> </a>|  */}
                  <a href="/privacy" target="_blank"> <span className="ml-2 mr-2"> Privacy Policy </span> </a> | 
                  <a href="/terms" target="_blank"> <span className="ml-2"> Terms of Service </span> </a>
                </div>
              

            </div> 
        </div>

      </div>
    );
  }
}

export default Footer;


