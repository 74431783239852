import L from "leaflet";
import markerIcon from "./mapbox-marker-icon-blue.svg"




export const VenueLocationIcon = L.icon({
  iconUrl: markerIcon ,
  iconRetinaUrl: markerIcon,
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(45, 45),
  className: "leaflet-venue-icon",
});

/*
export const VenueLocationIcon = L.icon({
  iconUrl: require("./mapbox-marker-icon-blue.svg"),
  iconRetinaUrl: require("./mapbox-marker-icon-blue.svg"),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(45, 45),
  className: "leaflet-venue-icon",
});
*/ 