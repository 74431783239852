import React, { useEffect, useState } from "react";

const Sticky = () => {

    return (

        <div className="sticky-header-message text-center">
        <div className="alert alert-info p-2" role="alert" style={{ marginBottom: 0 }}>
            <span className="">
                <span style={{fontSize:'18px'}}> 🚀 </span>    
                <strong>
                <span style={{textDecoration: 'underline'}}>  Important Update:</span>
                      {'    '} Zipscore Introduces AI-Co-Pilot for Market Analysis & Location Planning. 
                    <a href="/" target="_blank" rel="noopener noreferrer"> Discover Now</a>!
                </strong>
            </span>
        </div>
        </div> 

    )
}


export default Sticky ; 