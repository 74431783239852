import React from 'react';


const textStyle = {
        fontSize: '0.72rem', 
        letterSpacing: '0.03rem' , 
}

export const de_placeholdersContent = [
    <React.Fragment> 
            <span className="ml-2 mt-1" style ={textStyle}> Age Group </span>
    </React.Fragment>
    ,
    <React.Fragment> 
            <span className="ml-2 mt-1" style ={textStyle}> Gender </span>
    </React.Fragment>
    ,
    <React.Fragment> 
            <span className="ml-2 mt-1" style ={textStyle} > Household Income (Monthly) </span>
    </React.Fragment> 

]

export const us_placeholdersContent = [
    <React.Fragment> 
            <span className="ml-2 mt-1" style ={textStyle}> Age Group </span>
    </React.Fragment>
    ,
    <React.Fragment> 
            <span className="ml-2 mt-1" style ={textStyle}> Gender </span>
    </React.Fragment>
    ,
    <React.Fragment> 
            <span className="ml-2 mt-1" style ={textStyle} > Household Income (Annual) </span>
    </React.Fragment> 

]

