import React, { useEffect, useState } from 'react';
import { useParams ,  useHistory , Redirect } from "react-router-dom";
import _ from "lodash"
import '../style.css';
import Header from "../../../datas/us/Header"
import DashboardInfo from "../../../datas/us/DashboardInfo/index.js" ; 
import Info from "../../../datas/us/Info/index.js" ; 
import Authwall from "../../../datas/us/Authwall/index.js"
import Demowall from "../../../datas/us/Demowall/index.js"
import { Helmet } from "react-helmet";
import { getStateZipcodes } from "../../../../../services/browseService"
import LoadingPage from '../../../../common/tools-ui/loadingPage';
import { getCurrentUser } from '../../../../../services/userService';
import Footer from "../../../../landing-page/Footer";
import Tabular from "./tables/zipscode-table"
import Sticky from '../../../../common/StickyHeader';

const Browsezipcode = () => {

    const [statekey, setStatekey ] = useState(useParams().statekey);
    const [state, setState ] = useState('');
    const [zipcodes, setZipcodes]= useState([])
    const [populated, setPopulated]= useState([])
    const [youngest, setYoungest]= useState([])
    const [oldest, setOldest]= useState([])
    const [richest, setRichest]= useState([])

    const [loading, setLoading] = useState(true) ; 
    const [exception, setException] = useState(false) ; 
    const history = useHistory() ; 

    const nf = Intl.NumberFormat();

    useEffect(() => {
        getZipcodes()
    }, [])

    const getZipcodes = async() => {
        try {
            const resp = await getStateZipcodes("us", encodeURIComponent(statekey))
            setLoading(false)
            setState(resp.data[0].all[0].state)
            setZipcodes([...resp.data[0].all])
            setPopulated([...resp.data[0].toppopulated])
            setYoungest([...resp.data[0].topyoungest])
            setOldest([...resp.data[0].topoldest])
        } catch (error) {
            setLoading(false)
            setException(true)
        }
    }

    const text1= <React.Fragment>     
        <div className="text-justify">
            <p className="mt-2 mb-2">

            Explore comprehensive demographic details and ZIP Code directories for {`${state}`}. 
            Delve into key metrics such as income levels, employment statistics, household earnings, resident counts, housing 
            unit numbers, total population figures, and more. This rich data is invaluable for Marketing Professionals, Data Analysts, and 
            Data Scientists seeking to refine product strategies and optimize marketing campaigns. By segmenting ZIP codes 
            in {`${state}`} based on these demographics, businesses can achieve sharper targeting and better ROI. 
            Unlike other platforms that offer only basic ZIP code lookups, our platform presents deep-dive analytics 
            into location-specific data. Access these insights by selecting any ZIP code from the list below.

            </p>
        </div>
    </React.Fragment>

    const textpopulated= <React.Fragment>
            <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806287/free-tools/mostpopulated.svg' style={{width:"49px", marginRight:"12px"}} alt="population"/>
                <h2 className="customh2fontsize"> {`${state}`} Most Populated ZIP Codes List</h2>
            </div>
            <div className="text-justify">
                <p className="mt-2 mb-2"> 
                In the state of {`${state}`}, ZIP Code {`${populated[0]?.plz}`} tops the list with {`${nf.format(populated[0]?.population)} `} residents. 
                Coming in second is ZIP Code {`${populated[1]?.plz}`} with a population of {`${nf.format(populated[1]?.population)}`}. 
                The ten ZIP Codes with the highest populations in {`${state}`} include: {`${_.map(populated, 'plz').join(', ')}`}.
                <br/>
                For a comprehensive list of the most populated ZIP Codes in {`${state}`}, see below.
                </p>
                <hr/>
                <div className='state-city mt-4 justify-content-left align-items-center'>             
                    {
                        populated?.map((item) => { 
                            return <div key={item.key}>
                                        <a href={`/us/${item.plz}/zip-code/${item.lng}/${item.lat}`}> {item.plz} </a> 
                                </div>
                        })  
                    }
                </div>
            </div>
    </React.Fragment>

    const textyoungest= <React.Fragment>
    <div className="d-flex align-items-center">
        <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806442/free-tools/mostyoungest.svg' style={{width:"49px", marginRight:"12px"}} alt="young"/>
        <h2 className="customh2fontsize"> ZIP Codes with the youngest populations in {`${state}`}</h2>
    </div>
    <div className="text-justify">
        <p className="mt-2 mb-2"> 

        In {`${state}`}, among ZIP Codes with a population of at least 9,842 residents (equivalent to the average US ZIP Code populace),
         {`${youngest[0]?.plz}`} is noteworthy for its dominant percentage of individuals below the age of 25. 
         Pinpointing the top 10 ZIP Codes, each with a population of at least 9,842, 
         that have the most significant young resident ratio,
          they include - { _.map(youngest, 'plz').join(' , ')}. Intriguingly, many of these ZIP Codes are hubs for academic institutions.


        </p>
        <hr/>
        <div className='state-city mt-4 justify-content-left align-items-center'>             
            {
                youngest?.map((item) => { 
                    return <div key={item.key}>
                                <a href={`/us/${item.plz}/zip-code/${item.lng}/${item.lat}`}> {item.plz} </a> 
                        </div>
                })  
            }
        </div>
    </div>
    </React.Fragment>

    const textoldest= <React.Fragment>
    <div className="d-flex align-items-center">
        <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806778/free-tools/mostoldest.svg' style={{width:"49px", marginRight:"12px"}} alt="old"/>
        <h2 className="customh2fontsize"> ZIP Codes with the oldest populations in {`${state}`}</h2>
    </div>
    <div className="text-justify">
        <p className="mt-2 mb-2"> 

        Within each ZIP Code of {`${state}`} that boasts a minimum of 9,842 inhabitants 
        (equivalent to the typical population in US ZIP Code regions), {`${oldest[0]?.plz}`} is 
        distinguished for having the predominant share of individuals who are 65 years or older. 
        Highlighting the premier 10 ZIP Codes in {`${state}`} known for their substantial senior citizen count, 
        they encompass - { _.map(oldest, 'plz').join(' , ')}. <br/> Delve below to explore the ZIP Code list in {`${state}`} with the most seasoned residents.

        </p>
        <hr/>
        <div className='state-city mt-4 justify-content-left align-items-center'>             
            {
                oldest?.map((item) => { 
                    return <div key={item.key}>
                                <a href={`/us/${item.plz}/zip-code/${item.lng}/${item.lat}`}> {item.plz} </a> 
                        </div>
                })  
            }
        </div>
    </div>
    </React.Fragment>

    const textlist = <React.Fragment>
        <div className="d-flex align-items-center">
            <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662810349/free-tools/list.svg' style={{width:"47px", marginRight:"12px"}} alt="list of zipcodes"/>
            <h2 className="customh2fontsize"> Overall number of ZIP Codes in {`${state}`}</h2>
        </div>
        <div className="text-justify">
            <p className="mt-2 mb-2"> 
            Per the data from the US Census Bureau, {`${state}`} is home to {Object.keys(zipcodes).length} ZIP Code Tabulation Areas (ZCTA's). 
            This compilation showcases every ZIP Code in {`${state}`} accompanied by its city, state, population demographics, and household details. 
            Users can effortlessly download the ZIP Code directory of {`${state}`} at no charge, available in both Excel and CSV formats below.

            </p>
            {/* <hr/> */}
            <div className=' mt-4 justify-content-left align-items-center'>             
                {
                    
                    <Tabular data={zipcodes && zipcodes}/>
                    /*
                        zipcodes?.map((item) => { 
                            return <div key={item.key}>
                                        <a href={`/us/${item.plz}/zip-code/${item.lng}/${item.lat}`}> {item.plz} </a> 
                                </div>
                    })
                    */  
                }
            </div>
        </div>
    </React.Fragment>

    const authwall= <React.Fragment>    
        <div className="d-flex align-items-center">
            <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662816675/free-tools/highincome.svg' style={{width:"49px", marginRight:"12px"}} alt="authentication"/>
            <h2 className="customh2fontsize"> High household income ZIP Codes in {`${state}`} </h2>
        </div>
        <hr/>
        <p className="mt-2 mb-3 text-center "> Register for a free account to view the high income ZIP Codes in {`${state}`} and other US states. </p>
    </React.Fragment>

    if(exception) return <Redirect to="/404"/> ; 
    if (getCurrentUser()) history.push(`/lookup/browse/us/${statekey}/zipcodes`) 

    return (
        <>
            <Sticky />
            <div className='content-wrapper'>
                <Header className="mb-5"/>
                {
                    !loading && 
                    <>
                        <Helmet> <title> {`${state} ZIP Codes List, US`} </title> </Helmet>
                        <DashboardInfo 
                            title={`${state} ZIP Codes List `} 
                            description={text1} 
                            link1={`/us/${ encodeURIComponent(statekey)}/zip-code-map`} 
                            link1title={`Demographics of ${state}`}
                            link2={`/browse/us/${ encodeURIComponent(statekey) }/cities`} 
                            link2title={`Demographics by Cities in ${state}`}
                        />
                        <Info description={textpopulated}/>
                        <Authwall description={authwall}/>
                        <Info description={textyoungest}/>
                        <Demowall title={`Predict success of your business in ${state}.`}/>
                        <Info description={textoldest}/>
                        <Info description={textlist}/>
                    </>
                }
                { loading &&  <LoadingPage /> }
            </div>
            <Footer />
        </>

    )
}

export default Browsezipcode ;