import React, { Component } from 'react';
import Select, { components } from 'react-select';
// import { colourOptions } from './docs/data';

// https://codesandbox.io/s/6n8m1?module=/example&file=/example.js

// country flag option
// https://codesandbox.io/s/2x16vkqkop?file=/src/select/menu/countryFooterMenu/countryFooterMenu.js

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>{children}</components.SingleValue>
);


export default class CustomControl extends Component {

  selectionChangeHandler = (event, selection) => {
    if (selection.action === 'select-option') {
        event && this.props.onSelect(event.value)
    } else if (selection.action === 'clear') {
        this.props.onClear(this.props.field);
    }
  }

  render() {

    const stylesD = {
        
        padding: '0.30rem' ,
        borderRadius: '5px',
        background: '#F16022',
        color: 'white',
        display: 'flex',
        fontSize: '0.70rem',
        letterSpacing: '0.03rem' 
    }

    return (
     <>
        <Select
            placeholder= { this.props.placeholder }
            isClearable
            styles={{
                singleValue: base => ({
                ...base,
                ...stylesD,
                }),
            }}
            components={{ SingleValue }}
            isSearchable
            name="color"
            options={ this.props.data }
            onChange={this.selectionChangeHandler}
        />
     </>

    );
  }
}

