import React , { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link , Redirect } from 'react-router-dom';
import { Message , Loader } from 'rsuite';
import { getResetPwdLink , getCurrentUser } from '../../services/userService';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import NavBar from '../common/NavBar/navbar-center';

const schema = yup.object({
    email: yup.string().email('Invalid email format').required('Required') 
}).required();

const ForgotPassword = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const [loading, setLoading ] = useState(false)
    const [message, setMessage]= useState("")
    const [success, setSuccess]= useState(true)
    
    const onSubmit = async(data) => {
    
        try {
            setLoading(true);
            const resp = await getResetPwdLink(data.email) ; 
            if (resp.status == 200) {
              setMessage("You will receive an email with instructions on how to reset your password in a few minutes. Token expires in 24 Hours.");
            }
            setSuccess(true)
            setLoading(false);
          } catch (ex) {
            setSuccess(false)
            setLoading(false);
            if (ex.response && ex.response.status === 400) {
              setMessage(ex.response.data);
            } else if (ex.response && ex.response.status === 404){
              setMessage(ex.response.data);
            }
          }
    }

    if (getCurrentUser()) return <Redirect to="/home" />

    return (

        <div id='Forgot-password' className="signIn-Page">
            <NavBar />
            <div className="Forgot-password-inner">
                <div className='Forgot-password-title-block'>
                {
                    message && 
                    <>
                        {
                            !success ? 
                            <div className="alert alert-danger mb-3" role="alert">
                                {message}
                            </div>
                            :
                            <div className="alert alert-success" role="alert">
                                {message}
                            </div>

                        }
                    </>
                }
                    <h3 className="mb-4">
                        Forgot Your Password?
                    </h3>
                    <p className="mb-2">Enter Your Email Address below and we'll send you insructions for reseting your Password</p>

                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-forgotpassword'>
                        <div className="input-icons">
                            <i className="fa fa-envelope icon">
                            </i>
                            
                            <input className="input-field"
                                type="text"
                                {...register("email", { required: true })}
                                placeholder='name@example.com' />
                            {errors.email && <span className='errorTextForm'> {errors.email.message} </span>}
                        </div>
                        <button type="submit" className='submitbtn'>
                            { !loading && <i className="fa fa-arrow-right"></i> }
                            { loading && <Loader /> }
                        </button>
                    </div>

                </form>
                <div className='sign-In-link-block mt-3'>
                    <Link to='/login'>
                        Sign In
                    </Link>
                    <Link to='/register'>Sign Up</Link>
                </div>


            </div>

        </div>
    )
}

export default ForgotPassword
