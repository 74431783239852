import React, { Component } from 'react' ;
import { Table } from "rsuite" ; 
import {CSVLink } from 'react-csv';
import ReactExport from "react-export-excel";
import "./bottomPanel.css"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const { Column, HeaderCell, Cell  } = Table;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Download extends Component {
    state = { 
    } ; 


    render() { 

        const { data , type } = this.props ; 

        const headersCsv = [
            { label: "State", key: "state" },
            { label: "City", key: "city" },
            { label: "ZipCodes", key: "plz" },
            { label: "Population", key: "population" },
            { label: "Households", key: "households" },
        ]

        return ( 
            <>
                <div className="row">
                    <div className="col-md-12">
                        <div className="pull-right" style={{marginTop: "-2.5rem" , marginRight: "-1.2rem" , color:"#424B4F" }}>
                            <FontAwesomeIcon onClick = { this.props.handleCloseModal } icon={['fa', 'times-circle']} />
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    {/* <hr className="hrModal" /> */}
                    <div className="col-md-12 ModalTableBorder">
                        <Table
                            virtualized = { type==="audience" ? false : true }
                            height={300}
                            width = {600}
                            data={ data }
                            style={{
                                fontSize: "12px" ,
                                color:"#6b6d6d", 
                                cursor: "default" ,  
                                borderTop: "1px solid #F2F2F5"
                            }}
                        >
                                <Column width={150} align="left" >
                                <HeaderCell className="ModalTableHeaderCell">State</HeaderCell>
                                <Cell dataKey="state" />
                                </Column>
                    
                                <Column width={150} align="left">
                                <HeaderCell className="ModalTableHeaderCell">City</HeaderCell>
                                <Cell dataKey="city" />
                                </Column>

                                <Column width={100} align="left">
                                <HeaderCell className="ModalTableHeaderCell">Zip code</HeaderCell>
                                <Cell dataKey="plz" />
                                </Column>

                                <Column width={100} align="left">
                                    <HeaderCell className="ModalTableHeaderCell">Population</HeaderCell>
                                    <Cell dataKey="population" />
                                </Column>

                                <Column width={100} align="left" >
                                    <HeaderCell className="ModalTableHeaderCell">Households</HeaderCell>
                                    <Cell dataKey="households" />
                                </Column>

                            </Table>

                        </div>

                    </div>
                 
                    <div className="row mt-4">
                        <hr className="hrModal" />
                        <div className="col-md-12 mt-2 mb-3">
                            <span className="">
                                <ExcelFile element={<span className="hover-underline-animation">Download XLSX</span>} filename= {"zipscoresExplore"}>

                                    <ExcelSheet data={ data } name="selections">
                                        <ExcelColumn label="State" value="state"/>
                                        <ExcelColumn label="City" value="city"/>
                                        <ExcelColumn label="zipCode" value="plz"/>
                                        <ExcelColumn label="Population" value="population"/>
                                        <ExcelColumn label="Households" value="households"/>
                                    </ExcelSheet>

                                </ExcelFile>
                            </span>
                            <span className="ml-5">
                                <CSVLink data={ data } headers={ headersCsv } separator={";"} filename={"zipscoresExplore.csv"} >
                                    <span className="hover-underline-animation"> Download CSV </span>
                                </CSVLink>
                            </span>
                        </div>

                    </div>
               
            </>
         );
    }
}
 
export default Download;