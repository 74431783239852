import React, { Component } from "react";
import '.././exploreStyle.css'
import { selectImgVal } from "./utils.js" ;
import { Box } from "../box";
import Selections from "../selections";
import ToolTip from "../../common/toolTip/tooltip"
import {ExploreContext} from "./exploreContext";
import PaywallUpgradeLight from "../../common/subscription/paywallUpgradeLight"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Placeholder } from "rsuite" ; 

const { Paragraph } = Placeholder;

const selectionList = [
    { label: '>=' ,value: 'greaterThanEqualTo' , key: 1 },
    { label: '<=' ,value: 'lessThanEqualTo' , key: 2 },
]

class Tab1 extends Component {

    onSelectChange = (value, field) => {
        const updatedData = this.props.filterList
        updatedData[field].data.forEach(data => {
            if (data.value === value) {
                data.selected = true
            } else {
                data.selected = false
            }
        });
        this.props.onSelectionChange(updatedData)
    }

    clearSelection = (field) => {
        const updatedData = this.props.filterList;
        updatedData[field].data.forEach(data => {
        data.selected = false
        });
        this.props.onSelectionChange(updatedData, false)
    }

    getSelectedData = () => {
        const { selectedData } = this.getSelectedFilterData()
        if (selectedData) {
            return selectedData
        } else {
            return {};
        }
    }

    getSelectedFilterData = () => {
        const { filterList } = this.props;
        const visibleData = Object.keys(filterList).filter(item => {
            if (filterList[item].visible) {
                return item
            }
        })
        const selectedData = {
            selection: {},
            percentile: this.props.percentile,
            percentileSelect: this.props.percentileSelect
        };
        visibleData.forEach(item => {
            const data = filterList[item].data.filter(con => con.selected)
            if(data.length > 0) {
                selectedData.selection[item] = data[0]
            }
        })
        return { visibleData, selectedData }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { image, changeImage } = this.props;
        const { visibleData, selectedData } = this.getSelectedFilterData() ; 

        if (visibleData.length === Object.keys(selectedData.selection).length && !image) {
            
            const { country } = this.props ; 

            const folder= country==="de" ? "de_percentileCategories": "us_percentileCategories"
            let imgUrl = `https://res.cloudinary.com/zipscores-collection/image/upload/png-audience-categories/${folder}/`
            let imgValues = selectImgVal(visibleData, selectedData.selection) ; 
            let imgSrc= `${imgUrl}${imgValues}.png`
            changeImage('')
            changeImage(imgSrc)
        }

    }

    onPercentileChange(textValue) {
        if (textValue >= 0 && textValue <= 100) {
            this.props.percentileChange(+textValue)
        } else {
            window.alert("Percentile must be between 0 to 100");
        }
    }

    onDropHandler = (data) => {
        const { percentile } = this.props
        if (percentile > 0 && percentile <= 100) {
            this.props.setData(data)
        } else {
            window.alert("Percentile must be between 0 to 100");
        }
    }

    render() {
        const { filterList, image, data , textAttributes , freeVersion } = this.props ;
        const nf = new Intl.NumberFormat() ; 

        return (
                <>
                    {!data ?
                        <>
                            <div className={'percentileContainer mt-3'}>
                                
                                <div 
                                 style={{
                                   display: "flex"
                                 }}
                                >   
                                    
                                    <select
                                        className={'percentileTextInput'}
                                        style={{ width: "auto", marginRight: 5 }}
                                        onChange={(event) => this.props.percentileSelectChange(event.target.value)}
                                    >
                                        { selectionList.map((optionItem) => {
                                            return <option style={{color : "black"}} value={optionItem.value} key={optionItem.key} >{optionItem.label}</option>
                                        })}
                                    </select>
                                    <input 
                                        className={'percentileTextInput'} 
                                        type={"number"} value={this.props.percentile.toString()} 
                                        onChange={(e) => this.onPercentileChange(e.target.value)} 
                                    />
                                    <span className="ml-2 mb-1 mr-1" style={{ fontSize : "1.5rem" , letterSpacing:"0.03rem"}}> percentile </span>
                                    {/* <ToolTip  
                                        label=
                                            {
                                                <div>   
                                                    The value below which a percentage of data falls.<br />
                                                    Example: If the zip code has gender as female greater than equal to 75th percentile,
                                                    then 75% of the other zip codes have proportion of female less than that.<br />
                                                </div>
                                            } 
                                    />  */}
                                </div>

                            </div>
                            <div className="mt-3 hover-underline-animation" style={{ fontSize : "0.75rem" , letterSpacing:"0.03rem" , textDecoration:"underline"}}>
                                <a href="https://vimeo.com/547921189/b9be474d78" target="_blank" rel="noopener noreferrer">
                                   <span className=""> what is percentile? </span>
                                </a>
                            </div>

                            {
                                 (  !filterList.age.visible && !filterList.gender.visible && !filterList.income.visible ) ?
                                    <div className="mt-3 ml-3" style={{ fontSize : "0.8rem" , letterSpacing:"0.03rem"}}>
                                        No selections from settings tab will filter zip codes selections on total population 
                                        by default for selected percentile value. Try changing the percentile value if image below does not appear. 
                                    </div>
                                    : <></>
                            }

                            {

                                Object.keys(filterList).map((filterItem , i) => filterList[filterItem].visible &&
                                    <div key={i} className="row">
                                        <div className="col-md-12 mt-5">
                                            <Selections 
                                            data={filterList[filterItem].data} 
                                            field={filterItem}
                                            onSelect={(value) => this.onSelectChange(value, filterItem)}
                                            placeholder={filterList[filterItem].placeholder}
                                            onClear={this.clearSelection}
                                            />
                                        </div>
                                    </div>
                                ) 

                            }
                            {   image &&
                                <div className="row">
                                    <div className="col-md-12 avatarContainer">
                                        <Box
                                            type={'img'}
                                            srcData={image}
                                            data={this.getSelectedData()}
                                            onDrop={this.onDropHandler}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                freeVersion && 
                                <>
                                    
                                    <div className="tab1PremiumFeatureText">
                                       <PaywallUpgradeLight text={'to unlock Gender & Household Income Filters'}/>
                                    </div>

                                </>
                            }
                        </> :
                        <>
                            { textAttributes.selectedPlz.length < 1 && <Paragraph rows={8} style={{ marginTop: 40, marginRight: 30 }} /> }
                            {
                                textAttributes.selectedPlz.length >= 1 && 
                                <>
                                    <div className={'selectionDataContainer'}>
                                        <img src={image} className={'imageStyle'} alt={"FilterData"} /> 
                                        <FontAwesomeIcon onClick = { this.props.clearData } className={'clearSelection'} icon={['fa', 'times-circle']} />

                                    </div>
                                    {
                                        data && <div className={'d-flex justify-content-center mt-1 selectedPercentileText'}> 
                                                    <div className=""> { data.percentileSelect === "greaterThanEqualTo" ? ">=" : "<=" } </div> 
                                                    <div className="ml-1"> {`${data.percentile} percentile`} </div>
                                                </div>
                                    }
                                    <div className="d-flex justify-content-around mt-1">
                                        {
                                            data && Object.values(data.selection).map(value => 
                                                <div className={'selectedAttributes'}> 
                                                    { value['label'] } 
                                                </div>
                                                )
                                        }
                                    </div>

                                    <div className="d-flex justify-content-center mt-1">
                                        <div className="selectedPlzOnMapText" >
                                            <span className="selectionsHighlightText"> {nf.format(textAttributes.selectedPlz.length)} </span>
                                            of 
                                            <span className="selectionsHighlightText"> {textAttributes.countryZipcodes}  </span>
                                            zip codes 
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center mt-1">
                                        <div className="selectedPlzOnMapText" >
                                            <span className="selectionsHighlightText">  {nf.format(textAttributes.selectedPopulation)} population {' '} </span>
                                            <ToolTip  
                                                label= { 'The total population in the selected zip codes' } 
                                                size= {'0.7rem'}
                                            /> 
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex justify-content-center mt-1">
                                        <div className="selectedPlzOnMapText" >
                                            <span className="selectionsHighlightText"> {nf.format(textAttributes.selectedHouseholds)} households {' '} </span>
                                            {/* of 
                                            <span className="selectionsHighlightText"> {textAttributes.countryHouseholds} </span>
                                            households{' '} */}
                                            <ToolTip  
                                                label= { 'The total households in the selected zip codes' } 
                                                size= {'0.7rem'}
                                            /> 
                                        </div>
                                    </div> 
                                </>
                            }
                            
                        </>
                    }
                </>
        );
    }
}

Tab1.contextType = ExploreContext ;
export default Tab1;