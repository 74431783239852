import React, { useState , useEffect } from 'react' ; 
import { useForm, Controller } from 'react-hook-form'; 
import { Link, useHistory } from 'react-router-dom';
import "./styles/details.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { getCurrentUser, loginWithJwt, updateDetails } from '../../services/userService';
import { Loader } from 'rsuite';
import { Helmet } from "react-helmet";

const schema = yup.object({
    firstname: yup.string().required(), 
    lastname: yup.string().required(), 
    jobtitle: yup.string().required(),
    company: yup.string().required()
}).required() ;

const Details = () => {
    const [ loading, setLoading ] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    /*
    const [token, setToken] = useState('')
    const history = useHistory() ;
    const sources= ["select","linkedin", "Twitter" , "Google/Search Engine", "Other"]
    const [source, defaultSource]= useState("")
    */
   
    const onSubmit = async (data) => {
        try {
            setLoading(true)
            const resp = await updateDetails(data) ;
            loginWithJwt(resp.headers['x-auth-zips']); 

            setTimeout(() => {
                // Redirect to scribble POPUP 
                window.location.href = `/collaborate/${getCurrentUser().clientId}`
                // window.location.href = `/home`
            }, 1000);
            
        } catch (error) {
            setLoading(false)
            console.log('something went wrong contact support ')
        } 
    };

    return (
        <>
            <Helmet> <title> {`Zipscore | User Form`} </title> </Helmet>
            <div id="detailsForm" className="details-page">
                    <div className="details-inner">

                        <form onSubmit={handleSubmit(onSubmit)} >
                            <h3 className="text-center"> Welcome to Zipscore &#127881;</h3>

                            <div className="form-items-detail mt-5">
                                <div className="form-item name">
                                    <div className="title">First name</div>
                                    <div className="field">
                                        <input placeholder="" {...register("firstname")} />
                                    </div>
                                    <div style={{minHeight: "20px"}}>
                                        {errors.firstname && <span className='errorTextForm'> {errors.firstname.message} </span>}
                                    </div>
                                    
                                </div>

                                <div className="form-item lastname">
                                    <div className="title">Last name</div>
                                    <div className="field">
                                        <input {...register("lastname")}/>
                                    </div>
                                    <div style={{minHeight: "20px"}}>
                                        {errors.lastname && <span className='errorTextForm'> {errors.lastname.message} </span>}
                                    </div>
                                </div>

                            </div>

                            <div className="form-items-detail mt-3">
                                <div className="form-item company">
                                    <div className="title">Job title</div>
                                    <div className="field">
                                        <input placeholder="" {...register("jobtitle")} />
                                    </div>
                                    <div style={{minHeight: "20px"}}>
                                        {errors.jobtitle && <span className='errorTextForm'> {errors.jobtitle.message} </span>}
                                    </div>
                                </div>
                            </div>
                            <div className="form-items-detail mt-3">
                                <div className="form-item company">
                                    <div className="title">Company</div>
                                    <div className="field">
                                        <input placeholder="" {...register("company")} />
                                        {errors.company && <span className='errorTextForm'> {errors.company.message} </span>}
                                    </div>
                                </div>
                            </div>

                            {/* <div className="form-items-detail mt-3">
                                <div className="form-item company">
                                    <div className="title">How did you hear about us ?</div>
                                    <div className="field">
                                     <select defaultValue={source} {...register("source")}>
                                        {
                                            sources.map((value) => {
                                                return <option value={value}> {value} </option>
                                            })
                                        }
                                    </select>
                                    </div>
                                </div>
                            </div> */}

                            <div onClick={handleSubmit(onSubmit)} className="details-button mt-4 ">
                                <button type="submit">
                                   { loading && <Loader className=''/>}  
                                   { !loading && 'Get started' }
                                </button>
                            </div> 
                        </form>
                    </div>

                </div>
        </>

    )
}

export default Details ; 
