import React, { Component }  from 'react'
import ReactModal from "react-modal" ; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./bottomPanel.css" ; 
import Download from "./download" ; 
import duplicate from "../../../containers/duplicate.png"
import spreadsheet from "../../../containers/spreadsheet.png"

class BottomPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // bottomPanel: false, 
            bottomPanel: !false, 
            copySuccess : "" , 
            showModal: false
        }
    }

   copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      this.setState({ copySuccess : "copied" });
      
      setTimeout((() => {
        this.setState({ copySuccess : "" });
      }), 4000)

    } catch (err) {
        this.setState({ copySuccess : "failed to copy" });
    }
  };

    onBottomPanelExpand = () => {
        this.setState({
            ...this.state,
            bottomPanel: !this.state.bottomPanel
        })
    }

    handleOpenModal = () => {
        this.setState({ showModal: true });
    }
    
    handleCloseModal = () => {
        this.setState({ showModal: false });
    }

    render() {

        const { bottomPanel , copySuccess } = this.state ; 
        const { data , download  } = this.props ; 

        return (
            <>  

                <div
                    style={{
                        overflow: 'hidden',
                        clear: 'both',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100vw',
                        height: !bottomPanel ? 30 : 120,
                        zIndex: 10,
                        backgroundColor: 'white',
                        color: "",
                        font: "12px/20px Helvetica Neue,Arial,Helvetica,sans-serif" , 
                        borderTop: "2px solid #EAF3F9"
                }}
                
                >   
                    <div 
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '5px',
                            color : "black",
                            marginRight : "7px",
                            marginBottom : "1px",
                        }}
                        onClick={this.onBottomPanelExpand} 
                    >   
                        <div className={'bottomPanelHeaderText'}>
                            {
                                bottomPanel ? `` 
                                  : `Copy or download the data for selected ${data.length} zip codes.`
                            }
                        </div>
                        <div>
                            {   bottomPanel ?  
                                    <FontAwesomeIcon size="1x" icon={['fa', 'window-minimize']}/> 
                                :   <FontAwesomeIcon size="1x" icon={['fa', 'window-maximize']}/>                 
                            }
                        </div>
                    </div>
                    <div className="row mb-2" id="copy-text">
                        <div className= { "col-md-9 ml-5 mt-2" } >
                            <div className= { "showPlzSelected" }  style={{ color: "#363636" , padding: "10px" , textAlign: "justify" }}>
                                    { data && data.join(", ") }
                                    {/* { data && console.log(data)} */}
                            </div>
                        </div>
                        <div className="col-md-2 mt-3 mr-3">

                            { copySuccess === "copied" ?
                                <span style={{color: "green"}}> {copySuccess} </span>
                            : 
                             <img 
                                src={duplicate }
                                style={{ width: "2.5rem" , height: "auto" , marginLeft: "-10px"}} 
                                onClick={() => this.copyToClipBoard(data)}
                                alt= 'duplicate'
                             />
                            }
                            <img 
                                src={spreadsheet}  
                                style={{ width: "2.5rem" , height: "auto" , marginLeft: "2rem"}} 
                                onClick={this.handleOpenModal}
                                alt='spreadsheet'
                            />
                            <ReactModal 
                                isOpen={this.state.showModal}
                                className={ 'ModalBottomPanelTable' }
                                overlayClassName= {'OverlayBottomPanelTable'}
                                onRequestClose={this.handleCloseModal}
                                ariaHideApp={false}
                            >   
                                <div className={'bottomPanelHeaderText'}> { `Data for ${data.length} zip codes selected.`  } </div>
                                <Download data= { download  } handleCloseModal={ this.handleCloseModal } type= { "none"} />
                            </ReactModal>
                        </div>

                    </div>
            </div>
            </>
        )
    }
}

export default BottomPanel;