import axios from "axios";
import { toast } from "react-toastify";
import logger from "./logger";


axios.interceptors.response.use(null, (error) => {
  
  const expectedError =
  error.response &&
  error.response.status >= 400 &&
  error.response.status < 500;

  if (error.response && error.response.status === 400 && error.response.data === 'invalidToken'){

    setTimeout(() => {
      toast.error("Session Expired.Please Log in again");
      window.location.href = "/logout"
    }, 1000);
    
  }  else if (!expectedError) {
    logger.logException(error);
    toast.error("An unexpected error occured.");
  }

  return Promise.reject(error);

});

function setJwt(jwt) {
  // axios.defaults.headers.common['x-auth-token'] = jwt ;
  axios.defaults.headers.common['x-auth-zips'] = jwt ;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt
};
