import http from "../services/httpService";
// import { apiUrl_dev, apiUrl_prod  } from "../config/config.json";


import config from "../config/api.js";
const { apiUrl_dev, apiUrl_prod } = config;

const apiUrl = process.env.REACT_APP_ENVIRON === "development" 
               ? apiUrl_dev
               : apiUrl_prod

export function getPoiPercentiles(country , radiusPlz) {
    // console.log( 'called service +++++ ' ,  http.get(`${apiUrl}/explore/percentile/${ country }`))
    return http.post(`${apiUrl}/poi/${country}/audience/`, {
        country : country , 
        radiusPlz : radiusPlz
    } , {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    
}

export function getPoiPercentage(country , radiusPlz) {
   
    return http.post(`${apiUrl}/poi/${country}/audience/percentage/`, {
        country : country , 
        radiusPlz : radiusPlz
    } , {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    
}

export function getPoiTable(country , radiusPlz) {
    
    return http.post(`${apiUrl}/poi/${country}/audience/download`, {
        country : country , 
        radiusPlz : radiusPlz
    } , {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    
}


