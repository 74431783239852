import React , { useEffect , useState } from 'react'
import { useForm } from 'react-hook-form'
import Success from "../common/tools-ui/success-check"
import { useLocation , useHistory , Link } from "react-router-dom";
import { getVerifyConfirmation , updatePwd , loginWithJwt, login } from '../../services/userService';
import { Loader } from 'rsuite';
import NavBar from '../common/NavBar/navbar-center';


const Confirm = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(true)
    const [linkValid, setLinkValid ] = useState(false)
    const [ userDt, setUserDt ] = useState({
        email:"", 
        id:""
    })
    const { search } = useLocation() ; 
    const history = useHistory();
    const searchParams = new URLSearchParams( search ) ; 
    const token = searchParams.get("token") ;

    useEffect( () => {

        async function verifyToken() {
            try {
                const resp = await getVerifyConfirmation(token)
                setLoading(true)
                setLinkValid(true)
                setUserDt({
                    email: resp.data.email , 
                    id: resp.data._id
                })
                setLoading(false)
                
            } catch (error) {
                setLoading(false)
            } 
        }
        
        verifyToken();

    }, [] );

    const onSubmit = async(data) => {

        try {
            setLoading(true)
            const resp = await updatePwd( userDt.id, userDt.email, data.password) ;
            // await login(userDt.email, data.password)
            loginWithJwt(resp.headers["x-auth-zips"]) 
            setTimeout(() => {
                //history.push({ pathname: '/user/form' })
                window.location.href = `/user/form`
            }, 1500);
            
        } catch (error) {
            setLoading(false)
            console.log('pwd changed not success')
        }

    };

    return (
        <div id='signIn' className="signIn-Page">
         <NavBar />
            {
                loading && <Loader />
            }
           {
               linkValid && !loading &&
                <div className="signin-inner">
                    <Success />
                    <h5 className="mt-2 text-center"> Please set your pasword </h5>
                    <form className="mt-0" onSubmit={handleSubmit(onSubmit)}>
                        <div className='password-submit-block'>
                            <div className="input-icons mt-3">
                            <i className="fa fa-key icon"></i>
                            
                                <input className="input-field password" 
                                 type="password"
                                    {...register("password", { required: true })}  placeholder='Password' />
                            </div>
                            <button type="submit" className='submitbtn mt-3'>
                                <i class="fa fa-arrow-right"></i>
                            </button>
                        </div>
                    </form>
                </div>
           } 
           {
               !linkValid && !loading &&
                   
                <div className="signin-inner">
                    <h3 className='mt-2 text-center expired-token'>
                        The link is expired. 
                    </h3>
                    <div className='sign-In-link-block text-center mt-5'>
                        <Link className="ml-5" to='/confirm/resend'> Resend email confirmation link ?</Link> <br />
                        {/* Forgot Your Password? <Link to='/forgotpassword'>Forgot Your Password?</Link> */}
                     </div>   
                </div>
           }

        </div>
    )
}

export default Confirm ; 
