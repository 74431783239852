import React  from "react";
import Tooltip from 'react-tooltip-lite';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class CustomTooltip extends React.Component {

    render() {

        const { label , size , iconColor } = this.props ; 

        let iconSize = size ? size : '1rem' ; 
        let color = iconColor ? iconColor : "#363636" ; 

        return <Tooltip tagName="span"
                        className="target"
                        tipContentClassName=""
                        color="#FFFFFF"
                        background="#363636"
                        padding={10}
                        content={(
                            <div style={{
                                maxWidth: '250px', 
                                maxHeight: '200px',
                                //overflowY : 'scroll'
                                fontSize: '0.69rem', 
                                letterSpacing: '0.03rem' , 
                                borderRadius: '2px' 
                            }} 
                            >
                                { label }
                            </div>
                        )}
                        >
                <FontAwesomeIcon style={{ color : color , fontSize: iconSize}} icon={['fa', 'info-circle']}/>

        </Tooltip>
    }
}


export default CustomTooltip;
