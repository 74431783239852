import React from "react";
import "./styles/heronew.css";
import "./styles/index.css";
import Header from "./Header";
import Germany from "./assets/images/germany.png";
import USA from "./assets/images/usa.png";
// import Mail from "./assets/images/mail.png";
// import Denmark from "./assets/images/denmark.png";
import Bg from "./assets/images/bg.png";
import PlayBtn from "./assets/images/play_btn.png";
import PlayBtnHover from "./assets/images/play_btn-1.png";
import { Link } from "react-router-dom"; 
import { dropdownOptions } from "../../global";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder" ; 
import mapboxgl from "mapbox-gl" ; 
// import styles from "../look-up/zipLookup.module.css" ; 
import _ from "lodash";
import { withRouter } from "react-router-dom" ; 
// https://docs.mapbox.com/playground/geocoding/
import Typical from "react-typical" ;

class Hero extends React.Component {

  constructor () {
    super()
    this.state = {
      isOpen: false,
      btnImg: PlayBtn, 
      options: dropdownOptions,
      selectedCountry: 'US',
      zipcode: null, 
      invalidZipcode: false
    }
    this.el = null;
    this.openModal = this.openModal.bind(this)
    this.playOver = this.playOver.bind(this)
    this.playOut = this.playOut.bind(this)
  }

  componentDidMount() {

    /*
    const geocoder = new MapboxGeocoder({
      accessToken:
        "pk.eyJ1IjoibWotZXBzaWxvbiIsImEiOiJja24wcWV5eTcwcTY5MnZwazA0cTRxMTlwIn0.powZMmJIS2FoR4JY1DFSGg",
      mapboxgl: mapboxgl,
      marker: false,
      placeholder: 'Search any location in the US', 
      types: "address, postcode" , 
      flyTo: false,
      countries: "US"
    })
    geocoder.addTo('#geocoder');
    geocoder.on("result", (e) => {

      setTimeout(() => {
        geocoder.clear()
      }, 10000);
      
      const lng = e.result.center[0] ; 
      const lat=  e.result.center[1] ;

      const countryObj = _.filter(e.result.context , (obj) => {
        return obj.id.indexOf("country") !== -1;
      });
      const country = countryObj[0].short_code ; 

      if (e.result.place_type[0] && e.result.place_type[0] == "postcode") {
        
        const zipcode = e.result.text ; 
        const url = `/${country}/${zipcode}/zip-code/${lng}/${lat}?hl`
        return window.open( url , "_blank");

      } else {

        const zipObj = _.filter(e.result.context , (obj) => {
          return obj.id.indexOf("postcode") !== -1;
        });

        const zipcode = zipObj[0].text
        // const url = `/search/${country}/${zipcode}/${lng}/${lat}`
        const url = `/${country}/${zipcode}/zip-code/${lng}/${lat}?hl`
        return window.open(url, "_blank");
      }
      

    })
    */

  }


  openModal () {
    this.setState({isOpen: true})
  }

  playOver () {
    this.setState({btnImg: PlayBtnHover})
  }

  playOut () {
    this.setState({btnImg: PlayBtn})
  }

  regionChange = (field, value) => {
    this.setState({
        ...this.state,
        [field]: value 
    })
}

  enterZipcode = (val) => {
    this.validateZipcode(val)
  }

  validateZipcode = (val) => {

    if (val.length > 5 ){
        this.setState({
          invalidZipcode: !this.state.invalidZipcode, zipcode: null
        })
    } else {
      this.setState({ zipcode: val, invalidZipcode: false })
    }
  }

  render() {
    
    return (        
                  <>  
                     <Header country={"us"}/>
                     <div className="mt-5">
                        <div className='herocenteredlanding'>
                                        <div className="text-center textPlz">
                                                <h1 className="mb-2 titlehead">
                                                  AI Co-Pilot for  <br/> 
                                                </h1>
                                                  <Typical
                                                    steps={['Market Analysis', 5000, 'Location Planning', 3000]}
                                                    loop={Infinity}
                                                    // wrapper="h1"
                                                    className="titlehead"
                                                  />
                                        </div>

                                      <div className="mt-1 d-flex flex-sm-row flex-column browsebtncenteralign">
                                          <div className="p-2">
                                            {/* <div className="mt-0" id="geocoder"></div> */}
                                            <div className="text-center">
                                              <h1 className="faqsHero p-1" style={{fontSize:"16px"}}> Built on OpenAI's GPT-4, relying on recent data of 75,000 US locations. </h1>
                                            </div>
                                          </div>

                                          {/* <div className="p-2 setormobile " style={{color: "#AFB3BA"}}> - <span  style={{fontSize: "14px"}}> or </span> - </div> */}
                                          
                                          {/* <div className="ml-1 text-center" style={{marginTop: "-1px"}}>
                                            <div className="browsecta"> <a href="/browse/us/zip-code-map"> Browse </a>  </div>
                                          </div>     */}
                                      </div>
                                      <div  className="mt-0"><hr style={{width: '30vw'}}/> </div>
                                      <div className="text-center startFreeTextHero" style={{fontWeight: '200'}}> 
                                        Check our <a href="/ai-copilot/market-analysis/prompts/library" target="_blank"> prompt library </a>  or 
                                        <a href="/browse/us/zip-code-map" target="_blank"> browse </a> through the location data the usual way.
                                      </div> 
                                    <div className="float-right">
                                        <img src={Bg} className="bgnew"  alt=" background " />
                                    </div>  
                            <div className="countries mt-4">
                                <img className="selectedCountryIcon" src={USA} alt='usa'/>  
                                <img 
                                  className="deselectedCountryIcon" 
                                  onClick={() => this.props.history.push("/de")} 
                                  src={Germany} 
                                  alt="germany"
                                />
                            </div>
                      </div>
                    </div>
                  </>
    );
  }
}

export default withRouter(Hero) ;
