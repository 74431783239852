import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

function init() {
  Sentry.init({
    dsn:
      "https://b1a62de8942a4d9fa6f11a3848221dcc@o895343.ingest.sentry.io/5840761",
    release: "1-0-0",
    environment: process.env.REACT_APP_ENVIRON,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // 20% 
    tracesSampleRate: 0.2,
  });
}


function logException(error) {
  Sentry.captureException(error);
}

function logMessage(message) {
  Sentry.captureMessage(message);
}


export default { init, logException, logMessage };