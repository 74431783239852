import React from 'react';
import sendicon from "./suggestion_icon.svg"
import { getCopilotCompletion, getCopilotConversationHistory , getCopilotCompletionStream } from "../../services/exploreService";


function PromptSuggestion({
    err, setErr,
    completionLoading, setCompletionLoading,
    conversation, setConversation,
    completion, setCompletion,
    prompt, setPrompt,
    setCompletionError, lastwords, setLastwords
}) {

    
    const handleSubmit = async (prompt) => {
    
        setErr('')
        setCompletionLoading(true)
        
        try {
          setConversation(prevConversation => [
              ...prevConversation,
              { role: 'user', content: prompt }
          ]);
          
          /*
          const resp = await getCopilotCompletion('us', prompt)
          if (!resp.data.err){
              let compl = resp.data.completionObj.choices[0].message.content;
              setCompletion(compl)
              setPrompt(prompt)
              setCompletionLoading(false)
              setConversation(prevConversation => [
                  ...prevConversation,
                  { role: 'assistant', content: compl }
              ]);
            //   reset();
          } else if (resp.data.err){
              setCompletionError(true)
              setConversation(prevConversation => [
                  ...prevConversation,
                  { role: 'assistant', content: 'Bad request. Please Try again', err: true}
              ]);
              setCompletionLoading(false)
          }
          */
          const resp= await getCopilotCompletionStream('us' , prompt)

            if (!resp.ok || !resp.body) {
                setCompletionError(true)
                setConversation(prevConversation => [
                    ...prevConversation,
                    { role: 'assistant', content: resp.data.errmessage , err: true}
                ]);
                setCompletionLoading(false) 
                throw resp.statusText;
            }
            // Here we start prepping for the streaming response
            const reader = resp.body.getReader();
            const decoder = new TextDecoder();
            const loopRunner = true;
            let result = "";
        
            while (loopRunner) {
                const { value, done } = await reader.read();
                if (done) {
                    break;
                }
                const decodedChunk = decoder.decode(value, { stream: true });
                let compl = decodedChunk ;
                result += compl;

                setLastwords(result)
            }
            setLastwords("")
            setCompletion(result)
            setPrompt(prompt)
            setCompletionLoading(false)
            setConversation(prevConversation => [
                ...prevConversation,
                { role: 'assistant', content: result }
            ]);
          
        } catch (ex) {
          if (ex.response && ex.response.status === 404){
              setErr(ex.response.data)
            }
          setCompletionLoading(false)
        }
      }


    const suggestions = [

        {
          "title": "Advertising Location Analysis for Product Promotion",
          "subtitle": "Artisanal coffee advertising: Beverly Hills (ZIP Code 90210) vs. Fontana (ZIP Code 92336). Which is more suitable?"
        },
        {
          "title": "SWOT Analysis for a Bookstore Location",
          "subtitle": "Assess SWOT for opening a new bookstore in Miami, Florida."
        },
        {
          "title": "Market Potential Analysis for Sustainable Yoga Mats",
          "subtitle": "Estimate the market potential for sustainable yoga mats in Austin, Texas, focusing on the 25-39 age group's population size."
        },
        {
          "title": "Cultural Influence on Cuisine Preference",
          "subtitle": "Assess the potential success of an authentic Asian restaurant in Albuquerque, New Mexico based on its ethnic demographic composition."
        },
      ]
      
    return (
        <div className="prompt-suggestion-grow" >
            <div className="prompt-suggestion-container">
                <div className="prompt-suggestion-grid">
                    {suggestions.map((suggestion, index) => (
                        <div key={index} className="prompt-suggestion-item" onClick={ ()=>{ handleSubmit(suggestion.subtitle) } }>
                            <button className="prompt-suggestion-btn">
                                <div className="prompt-suggestion-content">
                                    <div className="prompt-suggestion-tex text-center">
                                        <div className="prompt-suggestion-title">
                                            {suggestion.title}
                                        </div>
                                        <div className="prompt-suggestion-subtitle">
                                            {suggestion.subtitle}
                                        </div>
                                    </div>
                                    <div className="prompt-suggestion-icon">
                                        {/* SVG icon here */}
                                        <img src={sendicon} style={{width: '25px'}} alt="" />
                                    </div>
                                </div>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PromptSuggestion;
