import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { PieChart, Pie, Cell } from 'recharts';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GenderChart = ({dts}) => {

    const data = [
        { name: "Male", value: dts.male },
        { name: "Female", value: dts.female },
    ];
    const RADIAN = Math.PI / 190;

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index
        }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
        
            return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
                style={{fontStyle: 'Axiform' , fontSize: '12px' , color: '#E0E0E0'}}
            >
                {`${(percent * 100).toFixed(2)} %`}
            </text>
            );
        };

   // const COLORS = ["#3B75AF", "#B3C5E5"];
    const COLORS = ["#3B75AF", "#99AFD6"];
    

    return (
    <>
        <PieChart width={200} height={200} >
            <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                isAnimationActive={false}
                outerRadius={90}
                fill="#8884d8"
                dataKey="value"
            >
                
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} nameKey="name" />
                ))}
            </Pie>
        </PieChart>
        <div className='gender-block mt-5'>
            <div className='female-gender-block'>
                <div className='female-color' style={{backgroundColor:"#B3C5E5"}}></div>
                <div className='female-title'>Female</div>
            </div>
            <div className='male-gender-block ml-2'>
                <div className='male-color' style={{backgroundColor:"#3B75AF"}}></div>
                <div className='male-title'>Male</div>
            </div>
        </div>
    </>
  )
}

export default GenderChart