import React from "react"

export const AreaLabels = 
[    
    { 
        id: 1, 
        country: 'de' ,
        isPremium: !true , 
        text: "Where do more young consumers live?" ,
        label: "Age group 10-24 >= 90 percentile" ,
        filters: [
            { value: "Age_10to24_pct"  , label: "10-24" },
        ] ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo", 
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes (or postal codes) <br/>
            have highest proportion of population between <br/>
            the age group of 10-24 years old. 
        </React.Fragment>
    },
    { 
        id: 2,
        country: 'de' ,
        isPremium: !true , 
        text: "Which areas have more young adults?" ,
        filters: [
            { value: "Age_25to39_pct"  , label: "25 to 39" },
        ] ,
        label: "Age 25-39 >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo", 
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523354/personas/young-adults_ufaa9n.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes (or postal codes) <br/>
            have highest proportion of population between<br/>
            the age group of 25-39 years old. 
        </React.Fragment>
    },
    { 
        id: 3,
        country: 'de' ,
        isPremium: !true , 
        text: "What areas have a high percentage of middle-aged adults?" ,
        filters: [
            { value: "Age_40to65_pct"  , label: "40 to 65" },
        ] ,
        label: "Age group 40 to 65 years >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523359/personas/middle-aged_o7oetj.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes (or postal codes) <br/>
            have highest proportion of population between<br/>
            the age group of 40-65 years old. 
        </React.Fragment>
    },

    { 
        id: 4,
        country: 'de' ,
        isPremium: true , 
        text: "Where do you find a high percentage of older consumers?" ,
        filters: [
            { value: "Age_65_plus_pct"  , label: "> 65" },
        ] ,
        label: "Age group 65 years & above >= 90 percentile" , 
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes (or postal codes) <br/>
            have highest proportion of population <br/>
            above 65 years. 
        </React.Fragment>
    },
    { 
        id: 5,
        country: 'de' ,
        isPremium: true , 
        text: "Where do most high-income households reside?" ,
        filters: [
            { value: "income_7500_and_more_pct"  , label: "> 7500" },
        ],
        label: "Monthly household income 7500 & above >= 90 Percentile " ,
        percentile : 90, 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes (or postal codes) <br/>
            have highest proportion of households with <br/>
            monthly income of 7500€ & above. 
        </React.Fragment>
    },
    //include high, low , very low
    { 
        id: 6,
        country: 'de' ,
        isPremium: true , 
        text: "Where do most low-income households reside?" ,
        filters: [
            { value: "income_below_1500_pct"  , label: "< 1500 €" },
        ],
        label: "Monthly household income below 1500€ >= 90 Percentile " ,
        percentile : 90, 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes (or postal codes) <br/>
            have highest proportion of households with <br/>
            monthly income below 1500€. 
        </React.Fragment>
    },
    {
        id: "de106",
        country: 'de' ,
        isPremium: true , 
        text: "Which areas have the most households with children?" ,
        filters: [
            { value: "Age_0to9_pct"  , label: "0 to 9"},
        ] ,
        label: "Age group 0 to 9 years >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644524732/personas/children_loffui.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes (or postal codes) <br/>
            high proportion of population between <br/>
            the age group 0 to 9 years. 
        </React.Fragment>
    },
    { 
        id: 7,
        country: 'de' ,
        isPremium: true , 
        text: "Which areas have the highest proportion of females?" ,
        filters: [
            { value: "prop_female_pct"  , label: "female" },
        ] ,
        label: "Female population >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523470/personas/females_ypxhux_hc0mqd.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes (or postal codes) <br/>
            have highest proportion of female population<br/>
        </React.Fragment>
    },
    { 
        id: 8,
        country: 'de' ,
        isPremium: true , 
        text: "Which areas have the highest proportion of males?" ,
        filters: [
            { value: "prop_male_pct"  , label: "male" },
        ] ,
        label: "Male population >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523361/personas/male_nr8tww.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes (or postal codes) <br/>
            have highest proportion of male population <br/>
        </React.Fragment>
    },
    { 
        id: 9,
        country: 'de' ,
        isPremium: true , 
        text: "Where are the most young male adults residing?" ,
        filters: [
            { value: "Age_25to39_pct"  , label: "25 to 39" },
            { value: "prop_male_pct"  , label: "male" },
        ] ,
        label: "Age group 25 to 39 years & Male population >= 75 percentile" ,
        percentile: 75 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523361/personas/male_nr8tww.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes (or postal codes) <br/>
            have highest proportion of male population <br/>
            between age group 25-39
        </React.Fragment>
    },
    { 
        id: 10,
        country: 'de' ,
        isPremium: true , 
        text: "Which areas have the highest proportion of young female adults?" ,
        filters: [
            { value: "Age_25to39_pct"  , label: "25 to 39" },
            { value: "prop_female_pct"  , label: "female" },
        ] ,
        label: "Age group 25 to 39 years & female population >= 75 percentile" ,
        percentile: 75 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523470/personas/females_ypxhux_hc0mqd.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes (or postal codes) <br/>
            have highest proportion of female population <br/>
            between age group 25-39
        </React.Fragment>
    },
    { 
        id: 11,
        country: 'de' ,
        isPremium: true , 
        text: "Which areas have high proportion of middle-aged male adults?" ,
        filters: [
            { value: "Age_40to65_pct"  , label: "40 to 65" },
            { value: "prop_male_pct"  , label: "male" },
        ] ,
        label: "Age group 40 to 65 years & male population >= 75 percentile" ,
        percentile: 75 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644525514/personas/middle-aged-male_utdppt.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes (or postal codes) <br/>
            have highest proportion of male population <br/>
            between age group 40 to 65 years
        </React.Fragment>
    },
    { 
        id: 12,
        country: 'de' ,
        isPremium: true , 
        text: "Which areas have highest proportion of middle-aged female adults?"  ,
        filters: [
            { value: "Age_40to65_pct"  , label: "40 to 65" },
            { value: "prop_female_pct"  , label: "female" },
        ] ,
        label: "Age group 40 to 65 years & female population >= 75 percentile" ,
        percentile: 75 , 
        percentileSelect : "greaterThanEqualTo", 
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523470/personas/females_ypxhux_hc0mqd.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes (or postal codes) <br/>
            have highest proportion of female population <br/>
            between age group 40 to 65 years
        </React.Fragment>
    }, 
    /*
    =============================================================
                US (500 to 999 ==> US on percentile rules )
    =============================================================
    */
    { 
        id: 500, 
        country: 'us' ,
        isPremium: !true , 
        text: "US ZIP codes with the highest proportion of young consumers" ,
        label: "Age group 10 to 24 years >= 90 percentile" ,
        filters: [
            { value: "pct_Age_10to24"  , label: "10 to 24" },
        ] ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo", 
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes have <br/>
            highest proportion of population between <br/>
            the age group of 10-24 years old. 
        </React.Fragment>
    },
    { 
        id: 501,
        country: 'us' ,
        isPremium: !true , 
        text: "US ZIP codes with the highest proportion of young adults" ,
        filters: [
            { value: "pct_Age_25to39"  , label: "25 to 39" },
        ] ,
        label: "Age group 25 to 39 years >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo", 
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523354/personas/young-adults_ufaa9n.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes have <br/>
            highest proportion of population between<br/>
            the age group of 25-39 years old. 
        </React.Fragment>
    },
    { 
        id: 502,
        country: 'us' ,
        isPremium: !true , 
        text: "US ZIP codes with the highest proportion of middle-aged adults" ,
        filters: [
            { value: "pct_Age_40to64"  , label: "40 to 64" },
        ] ,
        label: "Age group 40 to 64 years >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523359/personas/middle-aged_o7oetj.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes have <br/>
            highest proportion of population between<br/>
            the age group of 40-64 years old. 
        </React.Fragment>
    },
    { 
        id: 503,
        country: 'us' ,
        isPremium: !true , 
        text: "US ZIP codes with the highest proportion of older consumers" ,
        filters: [
            { value: "pct_Age_64_plus"  , label: "> 64" },
        ] ,
        label: "Age group 65 years & above >= 90 percentile" , 
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes have <br/>
            highest proportion of population <br/>
            above 64 years. 
        </React.Fragment>
    },
    { 
        id: 504,
        country: 'us' ,
        isPremium: !true , 
        text: "US ZIP codes with the high household income" ,
        filters: [
            { value: "pct_above_150K"  , label: "> 150,000" },
        ],
        label: "Annual household income 150k & above >= 90 Percentile " ,
        percentile : 90, 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes have <br/>
            highest proportion of households with <br/>
            annual income of $150k & above. 
        </React.Fragment>
    },
    //include high, low , very low
    { 
        id: 505,
        country: 'us' ,
        isPremium: !true , 
        text: "US ZIP codes with low household income" ,
        filters: [
            { value: "pct_below_35K"  , label: "< 35,000" },
        ],
        label: "Monthly household income below 35k >= 90 Percentile " ,
        percentile : 90, 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes have <br/>
            highest proportion of households with <br/>
            annual income below $35k. 
        </React.Fragment>
    },
    {
        id: 506,
        country: 'us' ,
        isPremium: !true , 
        text: "US ZIP codes with the highest proportion of households with children" ,
        filters: [
            { value: "pct_Age_0to9"  , label: "0 to 9"},
        ] ,
        label: "Age group 0 to 9 years >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644524732/personas/children_loffui.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes have <br/>
            high proportion of population between <br/>
            the age group 0 to 9 years. 
        </React.Fragment>
    },
    { 
        id: 507,
        country: 'us' ,
        isPremium: !true , 
        text: "US ZIP codes with the highest proportion of females" ,
        filters: [
            { value: "pct_female"  , label: "female" },
        ] ,
        label: "Female population >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523470/personas/females_ypxhux_hc0mqd.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes have <br/>
            highest proportion of female population<br/>
        </React.Fragment>
    },
    { 
        id: 508,
        country: 'us' ,
        isPremium: !true , 
        text: "US ZIP codes with the highest proportion of males" ,
        filters: [
            { value: "pct_male"  , label: "male" },
        ] ,
        label: "Male population >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523361/personas/male_nr8tww.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes have <br/>
            highest proportion of male population <br/>
        </React.Fragment>
    },
    { 
        id: 509,
        country: 'us' ,
        isPremium: !true , 
        text: "US ZIP codes with the highest proportion of young male adults" ,
        filters: [
            { value: "pct_Age_25to39"  , label: "25 to 39" },
            { value: "pct_male"  , label: "male" },
        ] ,
        label: "Age group 25 to 39 years & Male population >= 75 percentile" ,
        percentile: 75 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523361/personas/male_nr8tww.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes have <br/>
            highest proportion of male population <br/>
            between age group 25-39
        </React.Fragment>
    },
    { 
        id: 510,
        country: 'us' ,
        isPremium: !true , 
        text: "US ZIP codes with the highest proportion young female adults" ,
        filters: [
            { value: "pct_Age_25to39"  , label: "25 to 39" },
            { value: "pct_female"  , label: "female" },
        ] ,
        label: "Age group 25 to 39 years & female population >= 75 percentile" ,
        percentile: 75 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523470/personas/females_ypxhux_hc0mqd.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes have <br/>
            highest proportion of female population <br/>
            between age group 25-39
        </React.Fragment>
    },
    { 
        id: 511,
        country: 'us' ,
        isPremium: !true , 
        text: "US ZIP codes with the highest proportion of middle-aged male adults" ,
        filters: [
            { value: "pct_Age_40to64"  , label: "40 to 64" },
            { value: "pct_male"  , label: "male" },
        ] ,
        label: "Age group 40 to 64 years & male population >= 75 percentile" ,
        percentile: 75 , 
        percentileSelect : "greaterThanEqualTo",
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644525514/personas/middle-aged-male_utdppt.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes have <br/>
            highest proportion of male population <br/>
            between age group 40 to 64 years
        </React.Fragment>
    },
    { 
        id: 512,
        country: 'us' ,
        isPremium: !true , 
        text: "US ZIP codes with the highest proportion of middle-aged female adults"  ,
        filters: [
            { value: "pct_Age_40to64"  , label: "40 to 64" },
            { value: "pct_female"  , label: "female" },
        ] ,
        label: "Age group 40 to 65 years & female population >= 75 percentile" ,
        percentile: 75 , 
        percentileSelect : "greaterThanEqualTo", 
        imgSource: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1644523470/personas/females_ypxhux_hc0mqd.jpg' , 
        desc: 
        <React.Fragment>
            ZIP codes have <br/>
            highest proportion of female population <br/>
            between age group 40 to 64 years
        </React.Fragment>
    },
    /*
    =============================================================
                US (5000 to ... ==> state based questions )
    =============================================================
    */
    /* === populated ZIP Codes ===== */
    {
        "id": 1101,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Massachusetts",
        "statekey": "massachusetts",
        "state": "Massachusetts",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Massachusetts"
      },
      {
        "id": 1102,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Rhode Island",
        "statekey": "rhode-island",
        "state": "Rhode Island",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Rhode Island"
      },
      {
        "id": 1103,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in New Hampshire",
        "statekey": "new-hampshire",
        "state": "New Hampshire",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of New Hampshire"
      },
      {
        "id": 1104,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Maine",
        "statekey": "maine",
        "state": "Maine",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Maine"
      },
      {
        "id": 1105,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Vermont",
        "statekey": "vermont",
        "state": "Vermont",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Vermont"
      },
      {
        "id": 1106,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Connecticut",
        "statekey": "connecticut",
        "state": "Connecticut",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Connecticut"
      },
      {
        "id": 1107,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in New York",
        "statekey": "new-york",
        "state": "New York",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of New York"
      },
      {
        "id": 1108,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in New Jersey",
        "statekey": "new-jersey",
        "state": "New Jersey",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of New Jersey"
      },
      {
        "id": 1109,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Pennsylvania",
        "statekey": "pennsylvania",
        "state": "Pennsylvania",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Pennsylvania"
      },
      {
        "id": 1110,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Delaware",
        "statekey": "delaware",
        "state": "Delaware",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Delaware"
      },
      {
        "id": 1111,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in District of Columbia",
        "statekey": "district-of-columbia",
        "state": "District of Columbia",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of District of Columbia"
      },
      {
        "id": 1112,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Virginia",
        "statekey": "virginia",
        "state": "Virginia",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Virginia"
      },
      {
        "id": 1113,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Maryland",
        "statekey": "maryland",
        "state": "Maryland",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Maryland"
      },
      {
        "id": 1114,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in West Virginia",
        "statekey": "west-virginia",
        "state": "West Virginia",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of West Virginia"
      },
      {
        "id": 1115,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in North Carolina",
        "statekey": "north-carolina",
        "state": "North Carolina",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of North Carolina"
      },
      {
        "id": 1116,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in South Carolina",
        "statekey": "south-carolina",
        "state": "South Carolina",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of South Carolina"
      },
      {
        "id": 1117,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Georgia",
        "statekey": "georgia",
        "state": "Georgia",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Georgia"
      },
      {
        "id": 1118,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Florida",
        "statekey": "florida",
        "state": "Florida",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Florida"
      },
      {
        "id": 1119,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Alabama",
        "statekey": "alabama",
        "state": "Alabama",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Alabama"
      },
      {
        "id": 1120,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Tennessee",
        "statekey": "tennessee",
        "state": "Tennessee",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Tennessee"
      },
      {
        "id": 1121,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Mississippi",
        "statekey": "mississippi",
        "state": "Mississippi",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Mississippi"
      },
      {
        "id": 1122,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Kentucky",
        "statekey": "kentucky",
        "state": "Kentucky",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Kentucky"
      },
      {
        "id": 1123,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Ohio",
        "statekey": "ohio",
        "state": "Ohio",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Ohio"
      },
      {
        "id": 1124,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Indiana",
        "statekey": "indiana",
        "state": "Indiana",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Indiana"
      },
      {
        "id": 1125,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Michigan",
        "statekey": "michigan",
        "state": "Michigan",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Michigan"
      },
      {
        "id": 1126,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Iowa",
        "statekey": "iowa",
        "state": "Iowa",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Iowa"
      },
      {
        "id": 1127,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Wisconsin",
        "statekey": "wisconsin",
        "state": "Wisconsin",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Wisconsin"
      },
      {
        "id": 1128,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Minnesota",
        "statekey": "minnesota",
        "state": "Minnesota",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Minnesota"
      },
      {
        "id": 1129,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in South Dakota",
        "statekey": "south-dakota",
        "state": "South Dakota",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of South Dakota"
      },
      {
        "id": 1130,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in North Dakota",
        "statekey": "north-dakota",
        "state": "North Dakota",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of North Dakota"
      },
      {
        "id": 1131,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Montana",
        "statekey": "montana",
        "state": "Montana",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Montana"
      },
      {
        "id": 1132,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Illinois",
        "statekey": "illinois",
        "state": "Illinois",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Illinois"
      },
      {
        "id": 1133,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Missouri",
        "statekey": "missouri",
        "state": "Missouri",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Missouri"
      },
      {
        "id": 1134,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Kansas",
        "statekey": "kansas",
        "state": "Kansas",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Kansas"
      },
      {
        "id": 1135,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Nebraska",
        "statekey": "nebraska",
        "state": "Nebraska",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Nebraska"
      },
      {
        "id": 1136,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Louisiana",
        "statekey": "louisiana",
        "state": "Louisiana",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Louisiana"
      },
      {
        "id": 1137,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Arkansas",
        "statekey": "arkansas",
        "state": "Arkansas",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Arkansas"
      },
      {
        "id": 1138,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Oklahoma",
        "statekey": "oklahoma",
        "state": "Oklahoma",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Oklahoma"
      },
      {
        "id": 1139,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Texas",
        "statekey": "texas",
        "state": "Texas",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Texas"
      },
      {
        "id": 1140,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Colorado",
        "statekey": "colorado",
        "state": "Colorado",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Colorado"
      },
      {
        "id": 1141,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Wyoming",
        "statekey": "wyoming",
        "state": "Wyoming",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Wyoming"
      },
      {
        "id": 1142,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Idaho",
        "statekey": "idaho",
        "state": "Idaho",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Idaho"
      },
      {
        "id": 1143,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Utah",
        "statekey": "utah",
        "state": "Utah",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Utah"
      },
      {
        "id": 1144,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Arizona",
        "statekey": "arizona",
        "state": "Arizona",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Arizona"
      },
      {
        "id": 1145,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in New Mexico",
        "statekey": "new-mexico",
        "state": "New Mexico",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of New Mexico"
      },
      {
        "id": 1146,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Nevada",
        "statekey": "nevada",
        "state": "Nevada",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Nevada"
      },
      {
        "id": 1147,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in California",
        "statekey": "california",
        "state": "California",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of California"
      },
      {
        "id": 1148,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Hawaii",
        "statekey": "hawaii",
        "state": "Hawaii",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Hawaii"
      },
      {
        "id": 1149,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Oregon",
        "statekey": "oregon",
        "state": "Oregon",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Oregon"
      },
      {
        "id": 1150,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Washington",
        "statekey": "washington",
        "state": "Washington",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Washington"
      },
      {
        "id": 1151,
        "country": "us",
        "isPremium": false,
        "text": "Most populated ZIP Codes in Alaska",
        "statekey": "alaska",
        "state": "Alaska",
        "zip": true,
        "label": "populated",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1666615511/personas/populateddts.jpg",
        "desc": "most populated ZIP Codes in the state of Alaska"
      }, 

      /* === YOUNGEST ZIP Codes ===== */
      {
        "id": 1801,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Massachusetts",
        "statekey": "massachusetts",
        "state": "Massachusetts",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Massachusetts with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1802,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Rhode Island",
        "statekey": "rhode-island",
        "state": "Rhode Island",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Rhode Island with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1803,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in New Hampshire",
        "statekey": "new-hampshire",
        "state": "New Hampshire",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in New Hampshire with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1804,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Maine",
        "statekey": "maine",
        "state": "Maine",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Maine with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1805,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Vermont",
        "statekey": "vermont",
        "state": "Vermont",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Vermont with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1806,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Connecticut",
        "statekey": "connecticut",
        "state": "Connecticut",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Connecticut with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1807,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in New York",
        "statekey": "new-york",
        "state": "New York",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in New York with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1808,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in New Jersey",
        "statekey": "new-jersey",
        "state": "New Jersey",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in New Jersey with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1809,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Pennsylvania",
        "statekey": "pennsylvania",
        "state": "Pennsylvania",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Pennsylvania with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1810,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Delaware",
        "statekey": "delaware",
        "state": "Delaware",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Delaware with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1811,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in District of Columbia",
        "statekey": "district-of-columbia",
        "state": "District of Columbia",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in District of Columbia with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1812,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Virginia",
        "statekey": "virginia",
        "state": "Virginia",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Virginia with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1813,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Maryland",
        "statekey": "maryland",
        "state": "Maryland",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Maryland with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1814,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in West Virginia",
        "statekey": "west-virginia",
        "state": "West Virginia",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in West Virginia with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1815,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in North Carolina",
        "statekey": "north-carolina",
        "state": "North Carolina",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in North Carolina with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1816,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in South Carolina",
        "statekey": "south-carolina",
        "state": "South Carolina",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in South Carolina with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1817,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Georgia",
        "statekey": "georgia",
        "state": "Georgia",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Georgia with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1818,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Florida",
        "statekey": "florida",
        "state": "Florida",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Florida with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1819,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Alabama",
        "statekey": "alabama",
        "state": "Alabama",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Alabama with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1820,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Tennessee",
        "statekey": "tennessee",
        "state": "Tennessee",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Tennessee with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1821,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Mississippi",
        "statekey": "mississippi",
        "state": "Mississippi",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Mississippi with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1822,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Kentucky",
        "statekey": "kentucky",
        "state": "Kentucky",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Kentucky with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1823,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Ohio",
        "statekey": "ohio",
        "state": "Ohio",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Ohio with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1824,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Indiana",
        "statekey": "indiana",
        "state": "Indiana",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Indiana with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1825,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Michigan",
        "statekey": "michigan",
        "state": "Michigan",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Michigan with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1826,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Iowa",
        "statekey": "iowa",
        "state": "Iowa",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Iowa with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1827,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Wisconsin",
        "statekey": "wisconsin",
        "state": "Wisconsin",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Wisconsin with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1828,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Minnesota",
        "statekey": "minnesota",
        "state": "Minnesota",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Minnesota with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1829,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in South Dakota",
        "statekey": "south-dakota",
        "state": "South Dakota",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in South Dakota with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1830,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in North Dakota",
        "statekey": "north-dakota",
        "state": "North Dakota",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in North Dakota with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1831,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Montana",
        "statekey": "montana",
        "state": "Montana",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Montana with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1832,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Illinois",
        "statekey": "illinois",
        "state": "Illinois",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Illinois with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1833,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Missouri",
        "statekey": "missouri",
        "state": "Missouri",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Missouri with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1834,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Kansas",
        "statekey": "kansas",
        "state": "Kansas",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Kansas with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1835,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Nebraska",
        "statekey": "nebraska",
        "state": "Nebraska",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Nebraska with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1836,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Louisiana",
        "statekey": "louisiana",
        "state": "Louisiana",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Louisiana with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1837,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Arkansas",
        "statekey": "arkansas",
        "state": "Arkansas",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Arkansas with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1838,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Oklahoma",
        "statekey": "oklahoma",
        "state": "Oklahoma",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Oklahoma with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1839,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Texas",
        "statekey": "texas",
        "state": "Texas",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Texas with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1840,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Colorado",
        "statekey": "colorado",
        "state": "Colorado",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Colorado with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1841,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Wyoming",
        "statekey": "wyoming",
        "state": "Wyoming",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Wyoming with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1842,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Idaho",
        "statekey": "idaho",
        "state": "Idaho",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Idaho with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1843,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Utah",
        "statekey": "utah",
        "state": "Utah",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Utah with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1844,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Arizona",
        "statekey": "arizona",
        "state": "Arizona",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Arizona with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1845,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in New Mexico",
        "statekey": "new-mexico",
        "state": "New Mexico",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in New Mexico with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1846,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Nevada",
        "statekey": "nevada",
        "state": "Nevada",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Nevada with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1847,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in California",
        "statekey": "california",
        "state": "California",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in California with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1848,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Hawaii",
        "statekey": "hawaii",
        "state": "Hawaii",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Hawaii with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1849,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Oregon",
        "statekey": "oregon",
        "state": "Oregon",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Oregon with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1850,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Washington",
        "statekey": "washington",
        "state": "Washington",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Washington with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },
      {
        "id": 1851,
        "country": "us",
        "isPremium": !true,
        "text": "Youngest population ZIP Codes in Alaska",
        "statekey": "alaska",
        "state": "Alaska",
        "zip": true,
        "label": "young",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523458/personas/young-consumers_1_pwtuuf.jpg",
        "desc": "Out of all the ZIP Codes in Alaska with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population younger than 25 years of age."
      },    

      /* ==== Oldest ZIP Codes ======= */ 

      {
        "id": 2201,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Massachusetts",
        "statekey": "massachusetts",
        "state": "Massachusetts",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Massachusetts with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2202,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Rhode Island",
        "statekey": "rhode-island",
        "state": "Rhode Island",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Rhode Island with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2203,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in New Hampshire",
        "statekey": "new-hampshire",
        "state": "New Hampshire",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in New Hampshire with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2204,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Maine",
        "statekey": "maine",
        "state": "Maine",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Maine with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2205,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Vermont",
        "statekey": "vermont",
        "state": "Vermont",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Vermont with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2206,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Connecticut",
        "statekey": "connecticut",
        "state": "Connecticut",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Connecticut with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2207,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in New York",
        "statekey": "new-york",
        "state": "New York",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in New York with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2208,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in New Jersey",
        "statekey": "new-jersey",
        "state": "New Jersey",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in New Jersey with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2209,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Pennsylvania",
        "statekey": "pennsylvania",
        "state": "Pennsylvania",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Pennsylvania with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2210,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Delaware",
        "statekey": "delaware",
        "state": "Delaware",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Delaware with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2211,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in District of Columbia",
        "statekey": "district-of-columbia",
        "state": "District of Columbia",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in District of Columbia with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2212,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Virginia",
        "statekey": "virginia",
        "state": "Virginia",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Virginia with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2213,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Maryland",
        "statekey": "maryland",
        "state": "Maryland",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Maryland with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2214,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in West Virginia",
        "statekey": "west-virginia",
        "state": "West Virginia",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in West Virginia with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2215,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in North Carolina",
        "statekey": "north-carolina",
        "state": "North Carolina",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in North Carolina with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2216,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in South Carolina",
        "statekey": "south-carolina",
        "state": "South Carolina",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in South Carolina with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2217,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Georgia",
        "statekey": "georgia",
        "state": "Georgia",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Georgia with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2218,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Florida",
        "statekey": "florida",
        "state": "Florida",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Florida with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2219,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Alabama",
        "statekey": "alabama",
        "state": "Alabama",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Alabama with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2220,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Tennessee",
        "statekey": "tennessee",
        "state": "Tennessee",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Tennessee with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2221,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Mississippi",
        "statekey": "mississippi",
        "state": "Mississippi",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Mississippi with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2222,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Kentucky",
        "statekey": "kentucky",
        "state": "Kentucky",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Kentucky with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2223,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Ohio",
        "statekey": "ohio",
        "state": "Ohio",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Ohio with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2224,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Indiana",
        "statekey": "indiana",
        "state": "Indiana",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Indiana with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2225,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Michigan",
        "statekey": "michigan",
        "state": "Michigan",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Michigan with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2226,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Iowa",
        "statekey": "iowa",
        "state": "Iowa",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Iowa with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2227,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Wisconsin",
        "statekey": "wisconsin",
        "state": "Wisconsin",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Wisconsin with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2228,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Minnesota",
        "statekey": "minnesota",
        "state": "Minnesota",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Minnesota with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2229,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in South Dakota",
        "statekey": "south-dakota",
        "state": "South Dakota",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in South Dakota with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2230,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in North Dakota",
        "statekey": "north-dakota",
        "state": "North Dakota",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in North Dakota with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2231,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Montana",
        "statekey": "montana",
        "state": "Montana",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Montana with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2232,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Illinois",
        "statekey": "illinois",
        "state": "Illinois",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Illinois with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2233,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Missouri",
        "statekey": "missouri",
        "state": "Missouri",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Missouri with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2234,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Kansas",
        "statekey": "kansas",
        "state": "Kansas",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Kansas with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2235,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Nebraska",
        "statekey": "nebraska",
        "state": "Nebraska",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Nebraska with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2236,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Louisiana",
        "statekey": "louisiana",
        "state": "Louisiana",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Louisiana with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2237,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Arkansas",
        "statekey": "arkansas",
        "state": "Arkansas",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Arkansas with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2238,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Oklahoma",
        "statekey": "oklahoma",
        "state": "Oklahoma",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Oklahoma with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2239,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Texas",
        "statekey": "texas",
        "state": "Texas",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Texas with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2240,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Colorado",
        "statekey": "colorado",
        "state": "Colorado",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Colorado with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2241,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Wyoming",
        "statekey": "wyoming",
        "state": "Wyoming",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Wyoming with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2242,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Idaho",
        "statekey": "idaho",
        "state": "Idaho",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Idaho with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2243,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Utah",
        "statekey": "utah",
        "state": "Utah",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Utah with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2244,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Arizona",
        "statekey": "arizona",
        "state": "Arizona",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Arizona with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2245,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in New Mexico",
        "statekey": "new-mexico",
        "state": "New Mexico",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in New Mexico with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2246,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Nevada",
        "statekey": "nevada",
        "state": "Nevada",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Nevada with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2247,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in California",
        "statekey": "california",
        "state": "California",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in California with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2248,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Hawaii",
        "statekey": "hawaii",
        "state": "Hawaii",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Hawaii with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2249,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Oregon",
        "statekey": "oregon",
        "state": "Oregon",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Oregon with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2250,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Washington",
        "statekey": "washington",
        "state": "Washington",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Washington with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      },
      {
        "id": 2251,
        "country": "us",
        "isPremium": !true,
        "text": "Oldest population ZIP Codes in Alaska",
        "statekey": "alaska",
        "state": "Alaska",
        "zip": true,
        "label": "old",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523372/personas/older-consumers_s1gwk9.jpg",
        "desc": "Out of all the ZIP Codes in Alaska with at least 9,842 residents (average population in US ZIP Code areas), these ZIP Codes have the highest proportion of population 65 years of age and older."
      } , 
      /* ==== HIGH HH Income ZIP Codes ======= */ 
      {
        "id": 3001,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Massachusetts",
        "statekey": "massachusetts",
        "state": "Massachusetts",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Massachusetts with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3002,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Rhode Island",
        "statekey": "rhode-island",
        "state": "Rhode Island",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Rhode Island with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3003,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in New Hampshire",
        "statekey": "new-hampshire",
        "state": "New Hampshire",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in New Hampshire with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3004,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Maine",
        "statekey": "maine",
        "state": "Maine",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Maine with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3005,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Vermont",
        "statekey": "vermont",
        "state": "Vermont",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Vermont with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3006,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Connecticut",
        "statekey": "connecticut",
        "state": "Connecticut",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Connecticut with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3007,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in New York",
        "statekey": "new-york",
        "state": "New York",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in New York with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3008,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in New Jersey",
        "statekey": "new-jersey",
        "state": "New Jersey",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in New Jersey with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3009,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Pennsylvania",
        "statekey": "pennsylvania",
        "state": "Pennsylvania",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Pennsylvania with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3010,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Delaware",
        "statekey": "delaware",
        "state": "Delaware",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Delaware with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3011,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in District of Columbia",
        "statekey": "district-of-columbia",
        "state": "District of Columbia",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in District of Columbia with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3012,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Virginia",
        "statekey": "virginia",
        "state": "Virginia",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Virginia with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3013,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Maryland",
        "statekey": "maryland",
        "state": "Maryland",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Maryland with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3014,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in West Virginia",
        "statekey": "west-virginia",
        "state": "West Virginia",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in West Virginia with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3015,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in North Carolina",
        "statekey": "north-carolina",
        "state": "North Carolina",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in North Carolina with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3016,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in South Carolina",
        "statekey": "south-carolina",
        "state": "South Carolina",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in South Carolina with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3017,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Georgia",
        "statekey": "georgia",
        "state": "Georgia",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Georgia with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3018,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Florida",
        "statekey": "florida",
        "state": "Florida",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Florida with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3019,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Alabama",
        "statekey": "alabama",
        "state": "Alabama",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Alabama with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3020,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Tennessee",
        "statekey": "tennessee",
        "state": "Tennessee",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Tennessee with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3021,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Mississippi",
        "statekey": "mississippi",
        "state": "Mississippi",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Mississippi with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3022,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Kentucky",
        "statekey": "kentucky",
        "state": "Kentucky",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Kentucky with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3023,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Ohio",
        "statekey": "ohio",
        "state": "Ohio",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Ohio with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3024,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Indiana",
        "statekey": "indiana",
        "state": "Indiana",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Indiana with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3025,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Michigan",
        "statekey": "michigan",
        "state": "Michigan",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Michigan with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3026,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Iowa",
        "statekey": "iowa",
        "state": "Iowa",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Iowa with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3027,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Wisconsin",
        "statekey": "wisconsin",
        "state": "Wisconsin",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Wisconsin with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3028,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Minnesota",
        "statekey": "minnesota",
        "state": "Minnesota",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Minnesota with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3029,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in South Dakota",
        "statekey": "south-dakota",
        "state": "South Dakota",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in South Dakota with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3030,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in North Dakota",
        "statekey": "north-dakota",
        "state": "North Dakota",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in North Dakota with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3031,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Montana",
        "statekey": "montana",
        "state": "Montana",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Montana with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3032,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Illinois",
        "statekey": "illinois",
        "state": "Illinois",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Illinois with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3033,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Missouri",
        "statekey": "missouri",
        "state": "Missouri",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Missouri with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3034,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Kansas",
        "statekey": "kansas",
        "state": "Kansas",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Kansas with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3035,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Nebraska",
        "statekey": "nebraska",
        "state": "Nebraska",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Nebraska with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3036,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Louisiana",
        "statekey": "louisiana",
        "state": "Louisiana",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Louisiana with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3037,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Arkansas",
        "statekey": "arkansas",
        "state": "Arkansas",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Arkansas with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3038,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Oklahoma",
        "statekey": "oklahoma",
        "state": "Oklahoma",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Oklahoma with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3039,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Texas",
        "statekey": "texas",
        "state": "Texas",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Texas with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3040,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Colorado",
        "statekey": "colorado",
        "state": "Colorado",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Colorado with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3041,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Wyoming",
        "statekey": "wyoming",
        "state": "Wyoming",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Wyoming with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3042,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Idaho",
        "statekey": "idaho",
        "state": "Idaho",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Idaho with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3043,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Utah",
        "statekey": "utah",
        "state": "Utah",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Utah with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3044,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Arizona",
        "statekey": "arizona",
        "state": "Arizona",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Arizona with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3045,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in New Mexico",
        "statekey": "new-mexico",
        "state": "New Mexico",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in New Mexico with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3046,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Nevada",
        "statekey": "nevada",
        "state": "Nevada",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Nevada with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3047,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in California",
        "statekey": "california",
        "state": "California",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in California with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3048,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Hawaii",
        "statekey": "hawaii",
        "state": "Hawaii",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Hawaii with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3049,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Oregon",
        "statekey": "oregon",
        "state": "Oregon",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Oregon with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3050,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Washington",
        "statekey": "washington",
        "state": "Washington",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Washington with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      },
      {
        "id": 3051,
        "country": "us",
        "isPremium": !true,
        "text": "High Household Income ZIP Codes in Alaska",
        "statekey": "alaska",
        "state": "Alaska",
        "zip": true,
        "label": "income",
        "imgSource": "https://res.cloudinary.com/zipscores-collection/image/upload/v1644523465/personas/hign-income_pz4rrh_twi2qq.jpg",
        "desc": "Out of all the ZIP Codes in Alaska with at least 3,661 households (average number of households in a ZIP Code), these ZIP Codes have the highest proportion of households with an annual gross income of $150,000 and above."
      }

]