import React, { Component } from "react" ; 
import _ from "lodash" ; 
import UserImg from "./user.png" ; 
import scribbleImage from "./drawing.png"
import { Link } from "react-router-dom";

//import { PUBLIC_URL } from "../../src/config"
class SideBar extends Component {
  renderIcon(k) {
    let path = process.env.PUBLIC_URL
    switch (k.type) {
      case "marker":
        path += "/collab/solid-icons/marker-tool-solid.svg"
        break
      case "pencil":
        path += "/collab/solid-icons/pen-tool-solid.svg"
        break
      case "area":
        path += "/collab/solid-icons/area-tool-solid.svg"
        break
      case "path":
        path += "/collab/solid-icons/path-tool-solid.svg"
        break
    }
    return <img className="pull-left" src={path} alt="tool"/>
  }
  

  render() {
    const { instances, trackFunc , showGuide } = this.props ; 
    const sortedInstances = _.orderBy(instances, [(obj) => new Date(obj.created)], ['desc'])

    return (
      <>  
      <div style={{ height:'10vh'}}></div>

      {
       instances.length >= 1 && 
        <>
        <div className={ showGuide? "scrollBehaviourInstancesShort " : "scrollBehaviourInstancesLong"}>
          { sortedInstances.map((k) => {
            return (
              <div key={k.instID} className="mt-2 ms-2 mb-1 ">
                <div
                  style={{ cursor: "default"}}
                  onClick={() => trackFunc(JSON.parse(k.center))}
                  className="d-flex align-items-center "
                >
                  <div style={{width:'15%'}} className="flex-shrink-0 pt-1 ">{this.renderIcon(k)}</div>
                  <div className="flex-grow-1 ms-2 ">
                      <p className="p-3" style={{ backgroundColor : '#f4f4f4' , fontSize: '11px'}}>
                      {/* <img src={ `${process.env.PUBLIC_URL}/collab/marker-small-icon.svg` }/> */}
                        <span style={{ color:'#484848'}} className="changecolorhoverscribble" >{k.vicinity}</span> <br/>
                        <span style={{position:'relative', top:'4px'  }}>
                          <img width='12px' src={UserImg} alt='user' /> <span style={{position:'relative', top:'1px' ,left:'5px'}}> {k.by} </span> 
                        </span>  
                      </p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {
          !showGuide && 
          <>
            <hr/>
            <div className="text-center justify-content-center mt-2"> 
                <strong className="">{instances.length}</strong> scribbles 
            </div>
          </>
        }
        </>
      }
      {
        instances.length < 1 && 
        <div className="scrollBehaviourInstances text-center justify-content-center">
            <div className="mt-5 ms-2 mb-1">
                {/* <p> No scribbles. </p> */}
            </div>
            <div className="mt-4 ms-2 mb-1">
              <img width='80%' src={scribbleImage} alt="scribble"/>
            </div>
        </div>
      }

      {
        showGuide && 
        <div className="text-center justify-content-center p-2 mt-3" >
            <hr />
           <Link to="/home">
            <span className="hover-underline-animation" style={{color:"#358df2", fontSize: '1.2rem', lineHeight:'2rem'}} > Skip scribbling </span><br/> 
            <span style={{ color: '#363636'}} >  I would like to continue later! </span>
           </Link>
          
        </div>
      }
      </>
    )
  }
}

export default SideBar
