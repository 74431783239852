import React, { useEffect, useState } from 'react';
import { useHistory , Redirect } from "react-router-dom";
import { geoCentroid } from "d3-geo";
import { Helmet } from "react-helmet";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    Annotation
} from "react-simple-maps";

import '../style.css';
import Header from "../../../datas/us/Header"
import DashboardInfo from "../../../datas/us/DashboardInfo/index.js" ; 
import Info from "../../../datas/us/Info/index.js" ; 
import Demowall from "../../../datas/us/Demowall/index.js" ; 
import allStates from "../allstates.json";
import { getStatesList } from "../../../../../services/browseService"
import LoadingPage from '../../../../common/tools-ui/loadingPage';
import { getCurrentUser } from '../../../../../services/userService';
import Footer from "../../../../landing-page/Footer";
import Tabular from "./tables/state-table"
import Sticky from '../../../../common/StickyHeader';

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets = {
    VT: [50, -8],
    NH: [34, 2],
    MA: [30, -1],
    RI: [28, 2],
    CT: [35, 10],
    NJ: [34, 1],
    DE: [33, 0],
    MD: [47, 10],
    DC: [49, 21]
};

const States = () => {
    const [stateData, setStateData]= useState([]); 
    const [loading, setLoading] = useState(true) ; 
    const [exception, setException] = useState(false) ; 
    const history = useHistory() ; 


    useEffect(() => {
        getStates()
    }, [])

    const handler = (event) => {
        // console.log('event in handler', event)
    };

    const getStates = async() => {

        try {
            const resp = await getStatesList("us")
            setLoading(false)
            setStateData([...resp.data])
        } catch (error) {
            setLoading(false)
            setException(true)
            
        }
    }

    const onClickHandler = async (data) => {
        
        const cur = allStates.find(s => s.val === data.id);
        const searchparam = stateData.find(s => s.state === cur.name) ; 
        history.push(`/us/${ encodeURIComponent(searchparam.state_key) }/zip-code-map`) 
    }

    const text=  <React.Fragment>
        <div className="text-justify">
            <p className="mt-3 mb-2">
                Market researchers, Data Analysts, and Data Scientists often find themselves sifting through numerous websites and navigating intricate business intelligence tools to decode demographic data from authoritative sources like the Census Bureau, US Data and Statistics, and the US Bureau of Labor Statistics. With Zipscore, this journey is simplified. In under 30 seconds, you gain access to demographics, consumer styles, footfall traffic, and other critical location data.
                <br/>
                To explore a state's census data, simply click on the map below. You can then dive deeper into specific cities or ZIP codes for more granular insights.            
            </p>
        </div>
    </React.Fragment>  

    const textlist = <React.Fragment>
        <div className="d-flex align-items-center mt-2 mb-2">
            <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662810349/free-tools/list.svg' style={{width:"47px", marginRight:"12px"}} alt="list"/>
            <h2 className="customh2fontsize"> Demographic Data from the US Census Bureau: Exploring ZIP Codes, Cities, and States </h2>
        </div>
        <div className="text-justify">
            <p className="mt-3 mb-2"> 
            Select a state from the table below to access detailed demographic data from the US Census Bureau, encompassing ZIP codes, cities, and states. 
            Additionally, you can freely export data on total ZIP Codes, cities, populations, and households in either Excel or CSV format.
            </p>
            <hr/>
            <div className=' mt-4 justify-content-left align-items-center'>  
                                 
                {  
                    <Tabular data={stateData && stateData}/>         
                }           
                    
            </div>
        </div>
    </React.Fragment>

    if(exception) return <Redirect to="/404"/> ; 
    if (getCurrentUser()) history.push("/lookup/browse/us/states/") ; 

    return (
        <>
        <Sticky />
        <div className='content-wrapper'> 
            <Header />
            <Helmet> 
                <title> {`US Census Data | Zipscore.ai`} </title>
            </Helmet>
            <DashboardInfo title={'US Census Data'} description={text} />
            { !loading && 
                <>
                    <div className='container mb-3'>
                        <div className='state-wrapper'>
                            <div className='state-map'>
                                <ComposableMap projection="geoAlbersUsa">
                                    <Geographies geography={geoUrl}>
                                        {({ geographies }) => (
                                            <>
                                                {geographies.map(geo => (
                                                    <Geography
                                                        key={geo.rsmKey}
                                                        stroke="#FFF"
                                                        geography={geo}
                                                        fill="#d3d6be"
                                                        onClick={() => onClickHandler(geo)}
                                                    />
                                                ))}
                                                {geographies.map(geo => {
                                                    const centroid = geoCentroid(geo);
                                                    const cur = allStates.find(s => s.val === geo.id);
                                                    return (
                                                        <g  key={geo.rsmKey} /*onClick={() => handler(cur?.id)} key={geo.rsmKey + "-name"}*/>
                                                            {cur &&
                                                                centroid[0] > -160 &&
                                                                centroid[0] < -67 &&
                                                                (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                                                                    <Marker coordinates={centroid}>
                                                                        <text y="2" fontSize={14} textAnchor="middle">
                                                                            {cur.id}
                                                                        </text>
                                                                    </Marker>
                                                                ) : (
                                                                    <Annotation
                                                                        subject={centroid}
                                                                        dx={offsets[cur.id][0]}
                                                                        dy={offsets[cur.id][1]}
                                                                    >
                                                                        <text x={4} fontSize={14} alignmentBaseline="middle">
                                                                            {cur.id}
                                                                        </text>
                                                                    </Annotation>
                                                                ))
                                                            }

                                                        </g>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </Geographies>
                                </ComposableMap>
                            </div>
                        </div>
                    </div>
                    <Demowall title={`Predict success of your business.`}/>
                    <Info description={textlist} />
                </>
            }
            { loading && <LoadingPage /> }
        </div>
        <Footer />
        </>
    )
}

export default States ;  