import React, { Component } from 'react';
import Collaborate from "./Collaborate"; 
import { getAllUsers } from "../../services/clientService";
import { getCurrentUser } from '../../services/userService';
import { withRouter } from 'react-router-dom';

class CollaborateWrap extends Component {

    state = {
        user: null , 
        allUers: null, 
        showTutorialModal: false
    }

    async componentDidMount() {

        const details = await getCurrentUser() ; 
        this.setState({ user : details }) ;  
    }
    render() { 

        return <div>
           {
               this.state.user && 
               <Collaborate room={this.props.match.params.client } user={this.state.user} />
           } 
        </div>;
    }
}
 
export default withRouter(CollaborateWrap);