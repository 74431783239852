import React, { useState, useRef, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select"
import Autocomplete from 'react-autocomplete'
import "./auto.css";
import { Link, useHistory } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { mapboxToken } from "../../../global" ;
import { AreaLabels } from "./questions";
import NavBar from "../../common/NavBar/navbar";
import { getCurrentUser } from "../../../services/userService";
import Premium from "./assets/images/premium.svg"
import { createCheckoutSession } from "../../../services/checkoutService";
import { getGptCompletion} from "../../../services/exploreService";
import LoadingPage from "../../common/tools-ui/loadingPage";
import { getSubsDetails } from "../../../services/clientService";
import { compareDates } from "../../../utils/tools";
import { Helmet } from "react-helmet";
import { Toggle } from "rsuite"; 
import Typical from 'react-typical' ;
import ReactGA from "react-ga4" ;
import toast from 'react-hot-toast';
import PoiIcon from "./assets/images/poiny.svg"
import scribbleIcon from './assets/images/scribbe.svg' ; 
import rightArrow from './assets/images/right_arrow.svg' ; 
import fastFiltersIcon from './assets/images/filter.svg' ; 
import LookUpIcon from "./assets/images/look_up.svg"

const manyplaceholders_us = ['Massachusetts ...', 'New York ...' , 'Michigan ...' , 'South Carolina ...' , 'Arizona ...' ] ; 
const manyplaceholders_de = "which areas .." ; 

const AutoCompleteNew = () => {
  
  const defaultCountry= localStorage.getItem('user-country') ? localStorage.getItem('user-country') : 'us'
  mapboxgl.accessToken = mapboxToken ;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const history= useHistory() ;

  const [country, setCountry] = useState(defaultCountry );
  const [suggestions, setSuggestions] = useState([])
  // const [suggestions, setSuggestions] = useState([...AreaLabels.filter((k) => k.country === "us")])
  const [value, setValue] = useState("");
  const [gptSearchInput, setGptSearchInput] = useState("")
  const [client, setClient] = useState(""); 
  const [bg , setBg] = useState({}); 
  const [loading, setLoading] = useState(false); 
  const [freeVersion, setFreeVersion] = useState(true); 
  // const [placeholder, setPlaceholder] = useState(manyplaceholders[Math.floor(Math.random() * 5) + 1])
  const [placeholder, setPlaceholder] = useState(""); 
  const [progress, setProgress] = useState(false); 
  const [gptResponse, setGptResponse]= useState(""); 
  const [gptResponseError, setGptResponseError]= useState(""); 
  const [chatGptActive, setChatGptActive] = useState(true); 
  const [lastQuery, setLastQuery] = useState(""); 


  const prop_us1 = {
    center : [-73.971835, 40.762412],
    zoom: 15.43, 
    pitch: 58.00,
    bearing: -7.20
  }

  const prop_de1 = {
      center : [8.671393, 50.112278], 
      zoom: 16.49, 
      bearing: 91.51, 
      pitch: 57.02
  }

  useEffect(() => {

    if (map.current) return; // initialize map only once
    setSuggestions( [...AreaLabels.filter((k) => k.country === country)].sort( () => .5 - Math.random() ) )
    const prop = country === "de" ? prop_de1 : prop_us1 ; 
    setBg(prop)
    setPlaceholder(country === "us" ? manyplaceholders_us[Math.floor(Math.random() * 5) + 1] : manyplaceholders_de)
    setChatGptActive(country ==="de" ? false : true)

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mj-epsilon/ckzf82tt9000m14qlpx6y8fkk',
      attributionControl: false, 
      ...prop   
    });    

    async function getClientName() {
      const clientDetails = await getCurrentUser()  ;
      setClient(clientDetails.clientId) ; 
    } ;

    async function getClientSubscription() {
      const resp = await getSubsDetails()
      const subscription = resp.data[0]; 
      const validate = compareDates( subscription.expiry  , new Date() )
      if (validate === 1 ){
          setFreeVersion(!freeVersion)
      }
    }; 

    setTimeout(() => {
      setPlaceholder('California...')
    }, 10000);

    getClientName() ; 
    getClientSubscription() ; 

  }, []);

  const handleInput = async (val) => {   
    setLoading(!loading)
    setValue(val)
    // const selected = suggestions.filter((k) => k.label.toLowerCase() === val.toLowerCase());
    const selected = suggestions.filter((k) => k.text.toLowerCase() === val.toLowerCase());

    if (selected[0].isPremium && freeVersion) {
        const returnUrl = window.location.href ; 
        const res = await createCheckoutSession(returnUrl) ;
        const sessionUrl = res.data.url ; 
        window.location.href= sessionUrl ;

    } else {
      history.push(`/explore/search/${selected[0].country}/${selected[0].id}`)
    }

  }

  const handleCountry = (val) => {

    if (val.toLowerCase() === "de"){

        const prop = val.toLowerCase() === "de" ? prop_de1 : prop_us1 ; 
        setBg(prop)

        map.current.flyTo({
          ...prop, 
          speed: 3, 
          curve: 7, 
          easing: (t) => t,
          essential: true
        })
    
        localStorage.setItem('user-country', val.toLowerCase())
        setCountry(val.toLowerCase())
        setSuggestions( [...AreaLabels.filter((k) => k.country === val.toLowerCase())].sort( () => .5 - Math.random() ) )
        setPlaceholder(val.toLowerCase() === "us" ? manyplaceholders_us[Math.floor(Math.random() * 5) + 1] : manyplaceholders_de)
        setChatGptActive(val.toLowerCase() ==="de" ? false : chatGptActive)
    } else {
       let path = `/home` ; 
       localStorage.setItem('user-country', val.toLowerCase())
        setCountry(val.toLowerCase())
       history.push(path);
    }

  }
  
  const routeChange = () =>{ 
    let path = `/collaborate/${country}/${client}` ; 
    history.push(path);
  }

  const handleGptInput = (e) => {
    e.preventDefault();
    setGptSearchInput(e.target.value);
    setGptResponseError("")
    setGptResponse("")
    setProgress(false)
  };

  const getCompletion= async(e) => {

    if ( e.key === 'Enter' || e.keyCode === 13 ) {

      if (gptSearchInput.length < 5) {
        setProgress(true)
        setGptResponseError("The question must be minimum 5 characters.") ; 
      }
       else if (gptSearchInput.length > 100) {
        setGptResponseError("The question must not be minmore than 100 characters.") ; 
      } else {
        setProgress(true)
        setLastQuery(gptSearchInput)
        const resp = await getGptCompletion( 'us' , gptSearchInput)
        setGptResponse(resp.data[0].text)
        setProgress(false)
        sendEvent(gptSearchInput)
        setGptSearchInput('')
      }
    }
  }
  

  const activateGpt= () => {
    setChatGptActive(!chatGptActive)
  }


  const sendEvent=(query) => {
    
    if (process.env.REACT_APP_ENVIRON === "production" ) {
      
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID) ; 
      ReactGA.event({
        category: "gpt" , 
        action: query
      });

    } 
  }

  return (

        <> 
          <Helmet> <title> Zipscore.ai | Home </title> </Helmet>
          { loading && <LoadingPage text={'Unlock the rest of list with Premium'} />}    
          {
            !loading && 
            <div className="" id="auto-complete-home">
                  {/* <Loader center content="loading" /> */}
                  {/* <Loader style={{zIndex: 10}} backdrop content="loading..." vertical /> */}
             <div> 
              <div ref={mapContainer} className="map-container">
                <div style={{position: 'absolute' , zIndex: 222 , width:'100%'}}>
                    <NavBar isAdd={true}/>
                </div>
                <div className="wrapper container">
                  <div className="user"></div>
                  <div className="cta">

                    <div className="auto-com">

                      {
                        !chatGptActive && 
                        <div>
                            <img className="prefix_search" src="data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E"
                              alt=""
                            />
                            { country !== "de" && 
                            <div className="motherfuckingtoggler"> <Toggle onChange={ activateGpt } /> <span className="GptText"> ChatGPT-3 </span> </div>
                            
                            }
                            {/* <span className="sufix_search mr-2 " style={{ fontSize:""}}> <kbd style={{backgroundColor:"#E3E6E8" , color:"#555659"}}> ↵ Enter</kbd> </span> */}
                            <Autocomplete
                              getItemValue={(item) => item.text}
                              // items={ suggestions }
                              items={ suggestions }
                              
                              renderItem={(item, isHighlighted ) => {
                                return (
                                    <div className="suggestions " style={{ background: isHighlighted ? '#f5f6f7' : 'white', fontWeight: isHighlighted ? '400' : '400', padding: '8px', textAlign: 'left' , cursor: 'default' }}>
                                      <img style={{width: '16px', marginRight: '10px'}} src="data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E"
                                          alt=""
                                      />                                 
                                          <span style={{ fontSize: '14px'}}> {item.text} </span> 
                                          {
                                            item.isPremium && freeVersion && 
                                            <img style={{marginBottom: '0.4rem' , float: 'right', marginRight: '0.8rem'}} src={Premium} alt="" />
                                          }
                                    </div>
                                )
                              }
                              }
                              shouldItemRender={(item, value) => item.text.toLowerCase().indexOf(value.toLowerCase()) > -1}
                              value={value}
                              onChange={(e, newValue) => { setValue(e.target.value) }}
                              onSelect={(v) => handleInput(v)}
                              inputProps={{ placeholder: placeholder }}
                            />
                        </div>
                      }

                      {
                        chatGptActive && 
                        <div >
                              <img className="prefix_search" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1674311900/free-tools/ChatGPT.png" alt=""/>
                              <input onChange={handleGptInput} placeholder="Demographics of Chicago, Illinois"  value={gptSearchInput} onKeyPress={getCompletion}/>
                              <div className="motherfuckingtoggler-gpt"> <Toggle onChange={ activateGpt } defaultChecked /> <span className="GptText"> ChatGPT-3 </span> </div>
                              <span className="sufix_search mr-2 " > <kbd style={{backgroundColor:"#E3E6E8" , color:"#555659"}}> ↵ Enter</kbd> </span>
                              { (progress || gptResponse ) &&
                                <div className="gptresponsetext-parent ">                               
                                          <div className="gptresponsetext">
                                            {
                                              progress && !gptResponse && <p className="text-center">
                                                <span className="spinner-grow spinner-grow-sm "></span>
                                                <span className="spinner-border spinner-border-sm ml-2"></span>
                                              </p>
                                            }
                                            {
                                              !progress && gptResponse && <div className="text-left">
                                                  <div> Q: {lastQuery} </div>
                                                  <div> A: { gptResponse } </div>
                                                  <div className="mt-2">Having trouble getting the desired response? <a target="_blank" rel="noopener noreferrer" href="/blog/introducing-gpt-3-powered-demographic-search"> Check out our Prompts guide for assistance </a>. <br/></div>
                                                  <hr/>
                                                  <div style={{fontSize: '.75rem'}}> 
                                                    
                                                    Fine-tuned using OpenAI's GPT-3 base model (Davinci) <br/> 
                                                    Warning: AI feature responses are probabilistic and not always 100% accurate. Use with caution for critical tasks. <br/>
                                                    
                                                  </div>
                                              </div>
                                            }
                                          </div> 
                                  </div>
                              }
                              {
                                gptResponseError && !gptResponse &&
                                  <div className="gptresponsetext-parent ">                               
                                      <div className="gptresponsetext">
                                        <div className="alert alert-danger"> { gptResponseError } </div>
                                      </div>
                                  </div>
                              }
                        </div>
                      }

                    </div> 
                    <ReactFlagsSelect
                      className="flag-select"
                      countries={["US", "DE"]}
                      selected={country.toUpperCase()}
                      onSelect={(val) => handleCountry(val)}
                    />
                    
                  </div>
          
                  
                  <div className="card_wrap">    
                  <Link to={`/lookup/search/${country}`}>
                      <div className="one_card">
                        <img src={LookUpIcon} alt="" />
                        <div>
                          <h6>Look Up</h6>
                          <small> Get data for 75,159 locations in less than 30 seconds!  </small>
                          
                            <p>
                              Go {' '}  
                              <img src={rightArrow} alt="" />
                            </p>
                          
                        </div>
                      </div>
                    </Link>

                    <Link to={`/poi/${country}`}>
                      <div className="one_card">
                        <img src={PoiIcon} alt="" />
                        <div>
                          <h6>Point-of-interest</h6>
                          <small>Analyze demographics for a selected point on the map</small>
                          <p>Go <img src={rightArrow} alt="" /></p>
                        </div>
                      </div>
                    </Link>

                    <Link to={`/explore/filter/${country}`}>
                      <div className="one_card">
                        <img src={fastFiltersIcon} alt="" />
                        <div>
                          <h6>Fast Filters</h6>
                          <small>Drag & drop the target audience using filters</small>
                          <p>Go <img src={rightArrow } alt="" /></p>
                        </div>
                      </div>
                    </Link>
                    
                    <div className="addition_card" onClick={ routeChange }>
                          <p>Scribble</p>
                          <img src={scribbleIcon} alt="scribble icon"/>
                    </div>

                  </div> 
              
                </div>
              </div>
            </div>

            </div>

          } 
        </>


  );
};

export default AutoCompleteNew;
