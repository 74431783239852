import React from "react";
import {Container} from "./container";
import './exploreStyle.css'
import FilterContainer from "./filterTabs/filterContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toggle } from 'rsuite';
import { withRouter } from 'react-router-dom';


class ExploreContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expand: true,
            data: null,
            textAttributes : {
                selectedPlz: [], 
                countryZipcodes : 0,   
                selectedPopulation: 0, 
                countryPopulation :  0 , 
                selectedHouseholds: 0, 
                countryHouseholds : 0, 
            }
             
        }
    }

    panelToggle = () => {
        this.setState({
            ...this.state,
            expand: !this.state.expand
        })
    }

    setData = (value) => {
        this.setState({
            ...this.state,
            data: value
        })
    }

    onFilterOptionChange = (updatedData) => {
        this.setState({
            ...this.state,
            selections: {...updatedData}
        })
    }

    setDropData = (data) => {
        this.setState({
            ...this.state,
            data: data
        })
    }

    
    plzChangeHandler = (data) => {

        const textAttributes = {
            selectedPlz: data.selectedPlz , 
            countryZipcodes : data.countryZipcodes,   
            selectedPopulation: data.selectedPopulation, 
            countryPopulation :  data.countryPopulation , 
            selectedHouseholds: data.selectedHouseholds, 
            countryHouseholds : data.countryHouseholds, 
        }
        
        this.setState({
            ...this.state,
            textAttributes: textAttributes

        })
    }
    
    
    clearPlzOnMap = () => {

        const textAttributes = {
            selectedPlz: [], 
            countryZipcodes : 0,   
            selectedPopulation: 0, 
            countryPopulation :  0 , 
            selectedHouseholds: 0, 
            countryHouseholds : 0, 
        }

        this.setState({
            ...this.state,
            textAttributes: textAttributes
        })

    }



    renderExplore = () => {
        const { expand, data , textAttributes } = this.state
        return <div className="row">
            <div className={`paneled ${expand ? 'visible' : ''}  ${data ? 'selectedPanel' : ''}`} >
                {
                    !this.state.data && 
                    <>
                        <div className={"headerContainer"} /* onClick={this.panelToggle}*/ >
                            <div className="headerCountrySelection mt-1"> <span>{this.props.country}</span> </div>
                            {/* <FontAwesomeIcon 
                                className="togglePanelLines"
                                size="2x" icon={['fa', 'grip-lines']}
                            />  */}
                            <span className="toggle-search ml-auto mr-2 mt-2"> 
                                <span className="mr-1"> Search </span>
                                <Toggle onChange={()=> { this.props.history.push(`/explore/search/${this.props.country}`) }} size="md" defaultChecked />
                        </span>
                        </div>
                    </>
                }
                <div style={{
                    width: 300,
                    height: '100%',
                }}>
                    <FilterContainer 
                      data={this.state.selections} 
                      onDataDrop={this.setDropData} 
                      onFilterSelection={this.onFilterOptionChange} 
                      clearPlzOnMap= {this.clearPlzOnMap}
                      textAttributes = { textAttributes }
                      country = {this.props.country} 
                    />
                </div>
            </div>
        </div>
    }



    render() {

        return (
            
            <div style={{
                margin: 0,
            }}>
               
                <Container data={ this.state.data &&  this.state.data} country = {this.props.country} changePlz={ this.plzChangeHandler } />

                <div style={{
                    overflow: 'hidden',
                    clear: 'both',
                    position: 'absolute',
                    top: '4rem',
                    left: 20,
                    width: 'auto',
                    height: 'auto',
                    zIndex: 10
                }}>
                    {this.renderExplore()}
                </div>
                {/* <BottomPanel data={this.state.data && JSON.stringify(this.state.data)} /> */}
                
            </div>
        )
    }
}

export default withRouter(ExploreContainer) ; 