import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './bottom.css'

// Assuming the images are located at './assets/images/' in your project structure.
import lookUpImage from '../explore/autocomplete/assets/images/look_up.svg';
import rightArrowImage from '../explore/autocomplete/assets/images/right_arrow.svg';
import pointOfInterestImage from '../explore/autocomplete/assets/images/poiny.svg';
import fastFiltersImage from '../explore/autocomplete/assets/images/filter.svg';
import scribbleImage from '../explore/autocomplete/assets/images/scribbe.svg';

const CustomComponent = ({ country , client }) => {

  const history= useHistory() ;
  const routeChange = () =>{ 

        let path = `/collaborate/${country}/${client}` ; 
        history.push(path);
   }    

  return (
    <div className="copilotcard_wrap">

      <Link to={`/lookup/search/${country}`}>
        <div className="copilotone_card">
          <img src={lookUpImage} alt="Look Up" />
          <div>
            <h6>Look Up</h6>
            
          </div>
        </div>
      </Link>

      <Link to={`/poi/${country}`}>
        <div className="copilotone_card">
          <img src={pointOfInterestImage} alt="Point-of-interest" />
          <div>
            <h6>Point-of-interest</h6>
          </div>
        </div>
      </Link>

      <Link to={`/explore/filter/${country}`}>
        <div className="copilotone_card">
          <img src={fastFiltersImage} alt="Fast Filters" />
          <div>
            <h6>Fast Filters</h6>
          </div>
        </div>
      </Link>
      
      <div className="copilotaddition_card" onClick={routeChange}>
        <p>Scribble</p>
        <img src={scribbleImage} alt="Scribble" />
      </div>

    </div>
  );
}

export default CustomComponent;
