import React, { useEffect, useState } from 'react';
import _ from "lodash" ; 
import { useParams , Redirect , useHistory } from "react-router-dom";

import '../style.css';
import DashboardInfo from "../../../datas/de/DashboardInfo" ; 
import { getStateCities } from "../../../../../services/browseService"; 
import Info from "../../../datas/de/Info"
import Demowall from "../../../datas/de/Demowall"

import LoadingPage from '../../../../common/tools-ui/loadingPage';
import NavBar from '../../../../common/NavBar/navbar.jsx';


const Browsecity = () => {

    const [state, setState ] = useState('');
    const [statekey, setStatekey]= useState(useParams().statekey);
    const [cities, setCities]= useState([]);
    const [populated, setPopulated]= useState([]) ; 
    const [youngest, setYoungest]= useState([]);
    const [oldest, setOldest]= useState([]);
    const [richest, setRichest]= useState([]) ;
    const nf = Intl.NumberFormat();

    const [loading, setLoading] = useState(true) ; 
    const [exception, setException] = useState(false) ; 
    const history = useHistory() ; 

    useEffect(() => {
        getCities()
    }, [])

    const getCities = async() => {
        try {
            const resp = await getStateCities("de", encodeURIComponent(statekey))
            setLoading(false)
            setState(resp.data[0].all[0].state)
            setCities([...resp.data[0].all])
            setPopulated([...resp.data[0].toppopulated])
            setYoungest([...resp.data[0].topyoungest])
            setOldest([...resp.data[0].topoldest])
        } catch (error) {
            setLoading(false)
            setException(true)
        }
    }

    
    const text1= <React.Fragment>     
        <div className="text-justify">
            <p className="mt-3 mb-2">
            The demographic data of the cities in {`${state}`} documents income, employment status, household income, 
            residents, housing units, total population, and other demographic data that Marketing Researchers, Data Analysts and Data Scientists can utilize. 
            Product development and marketing efforts can be targeted, precise, and generate more significant ROI when a company can segment 
            the city areas in the state of {`${state}`} by it demographics. While many other websites only allow a basic city demographics lookup feature, our powerful features provide insightful analytics into the location data. 
            To access these analytics, you can click on any of the cities from the lists below.
            </p>
        </div>
    </React.Fragment>

    const textpopulated= <React.Fragment>
            <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806287/free-tools/mostpopulated.svg' style={{width:"49px", marginRight:"12px"}}/>
                <h2 className="customh2fontsize">Most Populated Cities in {`${state}`} </h2>
            </div>
            <div className="text-justify">
                <p className="mt-3 mb-2"> 
                        With {` ${ nf.format(populated[0]?.population )} `} residents, 
                        {` ${ populated[0]?.city } `} is the most populated city in the state of {` ${state} `}. 
                        {` ${ populated[1]?.city } `} has a population of {`${ nf.format(populated[1]?.population )} `}, making it the second most populous city. 
                        The top ten most populous cities in {`${state} `} are - { _.map(populated, 'city').join(' , ')}.
                </p>
                <hr/>
                <div className='state-city mt-4 justify-content-left align-items-center'>             
                    {
                        populated?.map((item) => { 
                            return <div key={item.citykey}>
                                      <a href={`/lookup/de/${encodeURIComponent(statekey)}/${encodeURIComponent(item.citykey)}`}> {item.city} </a>  
                                </div>
                        })  
                    }
                </div>
            </div>
        </React.Fragment>

        const textyoungest= <React.Fragment>
                <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806442/free-tools/mostyoungest.svg' style={{width:"49px", marginRight:"12px"}}/>
                    <h2 className="customh2fontsize"> Youngest Population cities by ZIP Codes in {`${state}`} </h2>
                </div>
                <div className="text-justify">
                    <p className="mt-3 mb-2"> 
                            Out of all the cities in {`${state}`} which have population of at least 13,447 (average population of city areas in Germany),
                            the city {` ${ youngest[0]?.city } `} have highest proportion of population younger than 25 years of age.
                            The top 10 youngest cities in the state of {`${state}`} that have at least 13,447 residents are - { _.map(youngest, 'city').join(' , ')}.
                    </p>
                    <hr/>
                    <div className='state-city mt-4 justify-content-left align-items-center'>             
                        {
                            youngest?.map((item) => { 
                                return <div key={item.citykey}>
                                    <a href={`/lookup/de/${encodeURIComponent(statekey)}/${encodeURIComponent(item.citykey)}`}> {item.city} </a>  
                                </div>
                            })  
                        }
                    </div>
                </div>
            </React.Fragment>

    
        const textoldest= <React.Fragment>
                        <div className="d-flex align-items-center">
                            <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806778/free-tools/mostoldest.svg' style={{width:"49px", marginRight:"12px"}}/>
                            <h2 className="customh2fontsize"> Oldest Population Cities in {`${state}`}  </h2>
                        </div>
                        <div className="text-justify">
                            <p className="mt-3 mb-2"> 
                                Out of all the cities in {`${state}`} which have population of at least 13,447 (average population of city areas in Germany) ,
                                the city {` ${ oldest[0]?.city } `} have highest proportion of population 65 years of age and older.
                                The top 10 oldest city areas in the state of {`${state}`} that have at least 13,447 residents 
                                are - { _.map(oldest, 'city').join(' , ')}.
                            </p>
                            <hr/>
                            <div className='state-city mt-4 justify-content-left align-items-center'>             
                                {
                                    oldest?.map((item) => { 
                                        return <div key={item.citykey}>
                                                   <a href={`/lookup/de/${encodeURIComponent(statekey)}/${encodeURIComponent(item.citykey)}`}> {item.city} </a>  
                                            </div>
                                    })  
                                }
                            </div>
                        </div>
                </React.Fragment>

    const textlist= <React.Fragment>
            <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662810349/free-tools/list.svg' style={{width:"47px", marginRight:"12px"}}/>
                <h2 className="customh2fontsize"> Cities in {`${state}`} </h2> 
            </div>
            <div className="text-justify">
                <p className="mt-3 mb-2"> 
                    According to the Federal Statistical Office of Germany (Statistisches Bundesamt), the state of {`${state}`} have {Object.keys(cities).length} city areas.  
                    The list of all the City Areas by ZIP Codes in {`${state}`} are as follows - 
                </p>
                <hr/>
                <div className='state-city mt-4 justify-content-left align-items-center'>             
                    {
                        cities?.map((item) => { 
                            return <div key={item.citykey}>
                                    <a href={`/lookup/de/${encodeURIComponent(statekey)}/${encodeURIComponent(item.citykey)}`}> {item.city} </a> 
                                </div>
                        })  
                    }
                </div>
            </div>
        </React.Fragment>


    if(exception) return <Redirect to="/404"/> ; 

    return (
        <div className='content-wrapper'>
            <NavBar isAdd={true} bgwhite={true}/>
            <DashboardInfo 
                title={`ZIP Codes by Cities of ${state}`} 
                description={text1} 
                link1={`/lookup/de/${ encodeURIComponent(statekey) }`} 
                link1title={`Demographics of ${state}`}
                link2={`/lookup/browse/de/${ encodeURIComponent(statekey) }/zipcodes`} 
                link2title={`Demographics by ZIP codes in ${state}`}    
            />
            {
                !loading && 
                <>
                    <Info description={textpopulated}/>
                    <Info description={textyoungest}/>
                    <Demowall title={`Predict success of your business in ${state}.`}/>
                    <Info description={textoldest}/> 
                    <Info description={textlist}/>
                </>
            }
            { loading && <LoadingPage /> }
        </div>
    )
}

export default Browsecity ; 