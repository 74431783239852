import React from "react";
import "./styles/slider.css";
import { ReactComponent as Tab2 } from "./assets/images/edit.svg";
import { ReactComponent as Tab3 } from "./assets/images/explore.svg";
import { ReactComponent as Tab4 } from "./assets/images/poi.svg";
// import { ReactComponent as Tab4 } from "./assets/images/audienceScore.svg";
import { ReactComponent as Tab1 } from "./assets/images/search.svg";
import Carousel, { consts } from "react-elastic-carousel";
import { ReactComponent as Left } from "./assets/images/arrow-left.svg";
import { ReactComponent as Right } from "./assets/images/arrow-right.svg";
import Item from "./Item";
import Typical from 'react-typical'
import MediaQuery from "react-responsive";


class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { slide: 0 };
  }

  myArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ? <Left /> : <Right />;
    return (
      <button onClick={onClick} disabled={isEdge} className="l-slider-btn">
        {pointer}
      </button>
    );
  }

  goto(target) {
    this.setState({ slide: target });
    this.carousel.goTo(target);
  }


  render() {

  
    return (
      <div className="l-slider-cont" id="features">
        <MediaQuery minDeviceWidth={1224} >
          <Typical 
            wrapper='h2'
            steps={[ "Census Data" , 5000, "Location Intelligence", 5000, "ZIP Code Maps", 5000]}
            loop={2}
          />
        </MediaQuery>
        <MediaQuery maxDeviceWidth={'767px'}>
            <h3> Location Intelligence </h3> 
        </MediaQuery>

        {/* 
          <Typical 
            wrapper='h2'
            steps={[ "Location Intelligence" , 5000, "Zip Codes Analytics", 5000]}
            loop={3}
          /> 
        */}
        <div className="tabs">
          <div
            className={`${this.state.slide === 0 ? "tab active tab1" : "tab"}`}
            onClick={() => this.goto(0)}
          >
            <Tab1 />
          </div>
          <div
            className={`${this.state.slide === 1 ? "tab active tab2" : "tab"}`}
            onClick={() => this.goto(1)}
          >
            <Tab2 />
          </div>
          <div
            className={`${this.state.slide === 2 ? "tab active tab3" : "tab"}`}
            onClick={() => this.goto(2)}
          >
            <Tab3 />
          </div>
          <div
            className={`${this.state.slide === 3 ? "tab active tab4" : "tab"}`}
            onClick={() => this.goto(3)}
          >
            <Tab4 />
          </div>
          {/* <div
            className={`${this.state.slide === 4 ? "tab active tab5" : "tab"}`}
            onClick={() => this.goto(4)}
          >
            <Tab5 />
          </div> */}
        </div>
        <Carousel
          itemsToShow={1}
          renderArrow={this.myArrow}
          ref={(ref) => (this.carousel = ref)}
          className="l-slider"
          onChange={(currentItem, pageIndex) =>
            this.setState({ slide: currentItem.index })
          }
        >       
                <Item name="AI Co-Pilot">
                      <p className="audience_des">
                      Market Analysis and Location Planning on auto-pilot, powered by OpenAI's GPT-4.
                      </p>

                          <MediaQuery minDeviceWidth={1224} >
                            <iframe 
                                  // src="https://player.vimeo.com/video/726093137?h=57dcd5983b&api=1&background=1&mute=0&quality=1080p" 
                                  src="https://player.vimeo.com/video/858644269?h=57dcd5983b&api=1&background=1&mute=0&quality=1080p" 
                                  style={{width : '88.8vh', height : '50vh', borderRadius: '5px'}}
                                  frameBorder="0" 
                                  allow="fullscreen; picture-in-picture" 
                                  title="audienceScoreDes"
                                  className="iframeVideoVimeo"
                            /> 
                          </MediaQuery>

                          <MediaQuery maxDeviceWidth={'767px'}>
                                  <iframe 
                                    src="https://player.vimeo.com/video/858644269?h=57dcd5983b&quality=1080p" 
                                    frameBorder="0" 
                                    style={{width : '100%', height:'auto', borderRadius: '5px'}} 
                                    title="audienceScore"
                                    allowFullScreen
                                /> 
                      </MediaQuery> 
                </Item>
                <Item name="Scribble + Realtime Collaboration">
                  <p className="audience_des">
                    Draw polygons or paths for your target locations which is shared among your other team members. Not only this, collaborate with your team in real-time. 
                    <a href="/blog/scribble-and-realtime-collaboration" target="_blank" rel="noopener noreferer" > Read more &#xbb; </a>
                  </p>
                  <MediaQuery minDeviceWidth={1224} >
                    <iframe 
                          src="https://player.vimeo.com/video/726106097?h=2e91642d2b&api=1&background=1&mute=0&quality=1080p" 
                          style={{width : '82vh', height : '54vh', borderRadius: '5px'}}
                          frameBorder="0" 
                          allow="autoplay; fullscreen; picture-in-picture" 
                          title="audienceScoreDes"
                          className="iframeVideoVimeo"
                    /> 
                  </MediaQuery>
                  <MediaQuery maxDeviceWidth={'767px'}>
                          <iframe 
                            src="https://player.vimeo.com/video/726106097?h=2e91642d2b&api=1&background=1&mute=0&quality=1080p" 
                            frameBorder="0" 
                            style={{width : '100%', height:'auto', borderRadius: '5px'}}
                            title="audienceScore"
                            allowFullScreen
                        /> 
                  </MediaQuery> 
                </Item>

              <Item name="Fast Filters">
                  <p className="audience_des mt-2">
                    Drag & drop the target audience on the map and get the zip codes having highest percentile of chosen demographics for the country of your choice.
                    <a href="/blog/explore-and-analyze-demographic-data" target="_blank" rel="noopener noreferer" > Read more &#xbb; </a>
                  </p>
                  <MediaQuery minDeviceWidth={1224} >
                        <iframe 
                          src="https://player.vimeo.com/video/726113958?h=f07e30a3ea&api=1&background=1&mute=0&quality=1080p" 
                          // style={{width : '75vh', height : '50vh', borderRadius: '8px'}} // 80 53
                          style={{width : '85vh', height : '52vh', borderRadius: '5px'}}
                          frameBorder="0" 
                          allow="autoplay; fullscreen; picture-in-picture" 
                          title="audienceScoreDes"
                          className="iframeVideoVimeo"
                        />    
                </MediaQuery>
                <MediaQuery maxDeviceWidth={'767px'}>
                        <iframe 
                          src="https://player.vimeo.com/video/726113958?h=f07e30a3ea&quality=1080p" 
                          frameBorder="0" 
                          title="audienceScore"
                          allowFullScreen
                      /> 
                </MediaQuery>
            </Item>

              <Item name="Point-of-Interest">
                <p className="audience_des">
                  Understand the demographics by geo-fencing the zip codes within certain Kilometers from your physical store location or point-of-interest.
                <a href="blog/point-of-interest" target="_blank" rel="noopener noreferer" > Read more &#xbb; </a>
                </p>
                 <MediaQuery minDeviceWidth={1224} >
                    <iframe 
                          src="https://player.vimeo.com/video/726108585?h=eab95aebd3&api=1&background=1&mute=0&quality=1080p" 
                          // style={{width : '80vh', height : '58vh', borderRadius: '5px'}}
                          style={{width : '80vh', height : '54vh', borderRadius: '5px'}}
                          frameBorder="0" 
                          allow="autoplay; fullscreen; picture-in-picture" 
                          title="audienceScoreDes"
                          className="iframeVideoVimeo"
                    /> 
                  </MediaQuery>

                  <MediaQuery maxDeviceWidth={'767px'}>
                          <iframe 
                            src="https://player.vimeo.com/video/726108585?h=eab95aebd3&quality=1080p" 
                            frameBorder="0" 
                            style={{width : '100%', height:'auto', borderRadius: '5px'}} 
                            title="audienceScore"
                            allowFullScreen
                        /> 
              </MediaQuery> 
          </Item>

        </Carousel>
      </div>
    );
  }
}

export default Slider;