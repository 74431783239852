import React from 'react'
import '../../styles/dashboardinfo.css';

function index({ title , description , link1 , link1title , link2 , link2title }) {

    return (
        <div className='dashboard-wrapper'>
            <div className='container'>
                <div className='dashboard-content-wrapper-main'>
                    <div className='dashboard-content-wrapper'>
                        <div className='dashboard-title'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 0C7.20726 0 3.30811 3.89916 3.30811 8.69184C3.30811 14.6397 11.0865 23.3715 11.4177 23.7404C11.7287 24.0868 12.2718 24.0862 12.5823 23.7404C12.9135 23.3715 20.6919 14.6397 20.6919 8.69184C20.6918 3.89916 16.7927 0 12 0ZM12 22.0185C9.38217 18.909 4.87336 12.774 4.87336 8.69194C4.87336 4.76222 8.07033 1.56525 12 1.56525C15.9297 1.56525 19.1266 4.76222 19.1266 8.69189C19.1266 12.7741 14.6185 18.908 12 22.0185Z" fill="#F06022" />
                                <path d="M12 4.31885C9.58867 4.31885 7.62695 6.28061 7.62695 8.69196C7.62695 11.1033 9.58872 13.0651 12 13.0651C14.4113 13.0651 16.373 11.1033 16.373 8.69196C16.373 6.28061 14.4113 4.31885 12 4.31885ZM12 11.4998C10.4517 11.4998 9.1922 10.2402 9.1922 8.69196C9.1922 7.14368 10.4518 5.8841 12 5.8841C13.5483 5.8841 14.8078 7.14368 14.8078 8.69196C14.8078 10.2402 13.5483 11.4998 12 11.4998Z" fill="#F06022" />
                            </svg>
                            <h1> { title } </h1>
                        </div>
                        <div className='dashboard-info-block mt-3'>
                            <div> { description } </div>
                        </div>
                        {
                            link1 && 
                            <div className="d-flex dashboard-content-wrapper-link mt-1">
                                <div className="mr-3 hover-underline-animation"> <a href={link1}> {link1title} </a> </div>
                                <div className="hover-underline-animation"> <a href={link2}> {link2title} </a> </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default index