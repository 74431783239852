import React, { Component } from "react";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import './styles/FilterTabsStyle.css';
import '.././exploreStyle.css'; 
//import '../../HomeLoggedIn/rsuite-custom.css'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeSelections , UsaSelections, DeFreeSelections , UsFreeSelections } from "./attributes"
import { getSubsDetails } from "../../../services/clientService";
import { compareDates } from "../../../utils/tools"

const Tabs = [
    <FontAwesomeIcon style={{ color: "#0377BE"}} size="2x" icon={['fa', 'globe-americas']}/> , 
    // <FontAwesomeIcon style={{ color: "#ED2E48"}} size="2x" icon={['fa', 'heart']}/> , 
    <FontAwesomeIcon style={{ color: "black"}} size="2x" icon={['fa', 'sliders-h']}/> , 
]


class FilterContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selections: this.props.country === "de" ? DeSelections[0] : UsaSelections[0] ,
            selectionsFreeVersion: this.props.country === "de" ? DeFreeSelections[0] : UsFreeSelections[0] , 
            percentile: 75,
            percentileSelection: 'greaterThanEqualTo',
            image: null,
            expand: false,
            data: null,
            selectedTab: 1,
            // freeVersion : true
            freeVersion : false
        }
    }

    async componentDidMount() {

        const resp = await getSubsDetails()
        const subscription = resp.data[0]; 
        const validate = compareDates( subscription.expiry  , new Date() )
        if (validate === 1 ){
            //this.setState({freeVersion : !this.state.freeVersion})
            this.setState({freeVersion : false})
        }
    }
    

    onSelection = (item, checked) => {
        const Data = this.state.selections
        Object.keys(Data).forEach(key => {
            Data[key].data.forEach(data => {
                data.selected = false
            })
        })
        Data[item].visible = checked
        this.setState({
            ...this.state,
            selections: Data,
            image: '',
            data: null
        })
    }

    onFilterSelect = (data, image = true) => {
        if (image) {
            this.setState({
                ...this.state,
                selections: data
            })
        } else {
            this.setState({
                ...this.state,
                selections: data,
                image: '',
                data: null,
            })
        }
    }

    onChangeImage = (img) => {
        this.setState({
            ...this.state,
            image: img
        })
    }

    setData = (value) => {

        this.setState({
            ...this.state,
            data: value
        })
    }

    percentileSelectChange = (value) => {
        this.setState({
            ...this.state,
            percentileSelection: value,
            image: '',
            data: null,
        })
    }

    onChangePercentile = (percentile) => {
        this.setState({
            ...this.state,
            percentile: percentile
        })
    }

    renderTabs = () => {

        switch (this.state.selectedTab) {
            case 1:
                return <Tab1
                        filterList={ this.state.freeVersion ? this.state.selectionsFreeVersion : this.state.selections}
                        image={this.state.image}
                        onSelectionChange={this.onFilterSelect}
                        changeImage={this.onChangeImage}
                        data={this.state.data}
                        setData={this.setData}
                        clearData={this.clearData}
                        percentile={this.state.percentile}
                        percentileSelect={this.state.percentileSelection}
                        percentileSelectChange={this.percentileSelectChange}
                        percentileChange={this.onChangePercentile}
                        textAttributes= {this.props.textAttributes }
                        freeVersion= { this.state.freeVersion }
                        country= {this.props.country}
                    />
            // case 2:
            //     return <Tab2 
            //             allFavorites = { this.state.favorites }
            //             setData={this.setData}
            //             data={this.state.data}
            //             clearData={this.clearData} 
            //             textAttributes= {this.props.textAttributes }
            //             freeVersion= { this.state.freeVersion }
            //            />
            case 2:
                return <Tab3 
                        filterList={this.state.selections} 
                        onSelectionChange={this.onSelection}
                        freeVersion= { this.state.freeVersion } 
                        />
        }
    }

    tabChangeHandler = (index) => {
        this.setState({
            selectedTab: index
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.data !== prevState.data) {
            this.props.onDataDrop(this.state.data)
        }
    }

    clearData = () => {
        this.setState({
            ...this.state,
            selections: {
                age : {
                    ...this.state.selections.age,
                    data : this.state.selections.age.data.map(item => {
                        item.selected = false
                        return item
                    })
                },
                gender : {
                    ...this.state.selections.gender,
                    data : this.state.selections.gender.data.map(item => {
                        item.selected = false
                        return item
                    })
                },
                income : {
                    ...this.state.selections.income,
                    data : this.state.selections.income.data.map(item => {
                        item.selected = false
                        return item
                    })
                }
            },
            percentile: 75,
            image: '',
            expand: false,
            data: null,
        }) ;
        this.props.clearPlzOnMap() ;
    }

    render() {
        const { data } = this.state
        return (
            <div className={`FilterTabsContainer`}>
                <div className={"selectedFilterContainer"}>
                    {this.renderTabs()}
                </div>
                {!data &&
                <div className={"ContainerFilter"}>
                    {Tabs.map((tab, index) => <div key={index} className={`tabItem ${this.state.selectedTab === index + 1 ? 'tabItemSelected' : ''}`} onClick={() => this.tabChangeHandler(index + 1)}>{tab}</div> )}
                </div>}
            </div>
        )
    }
}

export default FilterContainer