import React from 'react';
import { useDrag } from 'react-dnd';
import ToolTip from "../common/toolTip/tooltip" ;
import styles from "./box.module.css"

const style = {
    border: '1px solid white',
    backgroundColor: 'white',
    padding: '0.5rem 1rem',
    textAlign: 'center',
    cursor: 'move',
    float: 'left',
    zIndex: 977
};

export const Box = ({ srcData, onDrop, data, type = 'text'  }) => {
    // console.log("BOX ===>", data)
    const [{ isDragging }, drag] = useDrag({
        item: { src: srcData , type: 'box' },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                onDrop(data)
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0.4 : 1;
    return (
        <div ref={drag} style={{ ...style, opacity }}>
            {
               type === 'img' ? 
               <>
                <img src={srcData} style={{ height: '10.5em' , width: '10em' }} alt={"Drop Data"} /> 
                <div style={{fontSize:'0.8rem' , marginTop:'1em' ,color:'#c9c7c7' , letterSpacing: '0.04rem'}}>
                Drag the above image on Map.
                </div>
               </>
               :
               <div>
                <span className={`${styles.favoritesText} mt-3 ml-0 mr-1`}>
                        {srcData.text}
                </span>
                <ToolTip size={'0.7rem'} label={srcData.label} />
               </div>
            }
        </div>
    );
};
