export const live = ["de", "us"]
export const dropdownOptions =  [
    { label: 'select' ,value: 'no' },
    { label: 'Germany' ,value: 'de' },
    { label: 'USA' ,value: 'us' }
]

export const dropzoneCountries = [
    { label: 'select country' ,value: 'no' },
    { label: 'Germany' ,value: 'de' , lat:50.690426, lng:10.492188, zoom: 5.3 , tileset: 'mapbox://mj-epsilon.duvx7wfm' },
    { label: 'USA' ,value: 'us' , lat:38.11, lng:-101.299, zoom: 3.36, tileset: 'mapbox://mj-epsilon.dvu6xhab' }, 

]

export const colorsMap = [
    {
        pencilColor : '#007bff' , 
        pathColor : '#ffc107' , 
        areaColor : '#dc3545'
    }
]
export const mapboxStyle= "mapbox://styles/mj-epsilon/ckn1s6a7n2eyi17prjy5els6g"
export const mapboxToken = "pk.eyJ1IjoibWotZXBzaWxvbiIsImEiOiJja24wcWV5eTcwcTY5MnZwazA0cTRxMTlwIn0.powZMmJIS2FoR4JY1DFSGg"
