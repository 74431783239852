import axios from "axios";


const baseUrl = `https://cdn.contentful.com`
const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE ; 
const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESSTOKEN ;

const all =  [
    { "entryId": "RscWEvcABBK7Hm0bkrd93", "title": "introducing-gpt-3-powered-demographic-search"}, 
    { "entryId": "1uU072bK0rOPiNyYlQ4wib", "title": "explore-&-analyze-understanding-use-cases-by-industries" }, 
    { "entryId": "K640HqTXJMsqvhP150GVF", "title": "explore-and-analyze-demographic-data"}, 
    { "entryId": "2U3jvsPJ8ZPYNkyIzER1nK", "title": "audience-scores-understanding-use-cases-by-industries-&-functions" },
    { "entryId": "7vOHaO6YjOaPGBMwNahS9e", "title": "a-fresh-take-on-demographic-intelligence-using-zipscore" },
    { "entryId": "2KBgSQglojorZmy6c0ckFc", "title": "point-of-interest-analysis---understanding-use-cases-by-industries"},
    { "entryId": "5s6uWbltuK9wjjAdqlWgoC", "title": "leveraging-demographic-data-to-one-up-your-business"},
    { "entryId": "3Hxcx1QAjOLPmGilaJhoj4", "title": "scribble-and-realtime-collaboration-on-map"},
    { "entryId": "4ylwCVJm2fGKM8R1Hvx8px", "title": "point-of-interest" },
    { "entryId": "3hXbBeI1O5eJnb5H1LLuhD", "title": "how-to-target-demographics-by-zip-codes-on-facebook-ads" }, 
    { "entryId": "FWBLt83HhAhoAGR1ivKdW", "title": "how-to-target-zip-codes-in-google-ads" }
]

export function getEntries(){

    const url = 
        `${baseUrl}/spaces/${spaceId}/environments/master/entries?access_token=${accessToken}` ;
    
    return axios.get(url, {
        transformRequest: (data, headers) => {
            delete headers.common['x-auth-zips'];
            return data;
        }
    })

}

export function getEntryId(/* entryId */ title){

    const entryId = all.find(s => s.title === title).entryId ;

    const url= 
        `${baseUrl}/spaces/${spaceId}/environments/master/entries/${entryId}?access_token=${accessToken}` ; 

    return axios.get(url, {
        transformRequest: (data, headers) => {
            delete headers.common['x-auth-zips'];
            return data;
        }
    })
}