import React from "react";
import "./styles/index.css";
// import Header from "./Header";
import Hero from "./Hero";
import HeroNew from "./HeroNew";
import HeroNewDe from "./HeroNewDe";
import Slider from "./Slider";
import Usecases from "./Usecases";
import Footer from "./Footer";
// import Contact from "./Contact";
import Pricing from "./Pricing";
import { Redirect } from "react-router-dom"; 
import { getCurrentUser } from "../../services/userService" ;
import "react-modal-video/scss/modal-video.scss"
import "video-react/dist/video-react.css"
import OneTap from "../common/Onetap";
import { Helmet } from "react-helmet";

class Landing extends React.Component {

  state = {
    country: null
  }  

  componentDidMount() {
    const country = this.props.match.params.country ? this.props.match.params.country : 'us' ;
    this.setState({country : country })
  }
  
  render() {

    if (getCurrentUser()) return <Redirect to="/home" />

    return (
      <div className="Landing">
        <Helmet> <title> Zipscore.ai | Locate target market areas in less than 30 seconds </title> </Helmet>
        <OneTap />
        {/* <Header country={this.state.country} /> */}
        { this.props.match.params.country == "de" ? <HeroNewDe /> : <HeroNew /> }
        <Slider />
        <Usecases />
        <Pricing />
        <Footer />
      </div>
    );
  } 
}

export default Landing;
