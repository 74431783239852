import React, { Component } from 'react';
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder" ;
import mapboxgl from "mapbox-gl" ; 
import _ from "lodash";
import NavBar from '../common/NavBar/navbar';
// import styles from "./zipLookup.module.css"
import "./lookupAuth.css"
import Germany from "../landing-page/assets/images/germany.png"
import USA from "../landing-page/assets/images/usa.png"
import { Helmet } from "react-helmet";

class Lookup extends Component {
    state = { 
        zipcode: null, 
        country: null , 
    } 

    componentDidMount() {

        this.setState({ country : this.props.match.params.country })

        const geocoder = new MapboxGeocoder({
            accessToken:
              "pk.eyJ1IjoibWotZXBzaWxvbiIsImEiOiJja24wcWV5eTcwcTY5MnZwazA0cTRxMTlwIn0.powZMmJIS2FoR4JY1DFSGg",
            mapboxgl: mapboxgl,
            marker: false,
            placeholder: 'Search any location', 
            types: "address, postcode" , 
            flyTo: false,
            // countries: "US"
            countries: this.props.match.params.country
          })
          geocoder.addTo('#geocoder');
          geocoder.on("result", (e) => {
      
            setTimeout(() => {
              geocoder.clear()
            }, 10000);
            
            const lng = e.result.center[0] ; 
            const lat=  e.result.center[1] ;
      
            const countryObj = _.filter(e.result.context , (obj) => {
              return obj.id.indexOf("country") !== -1;
            });
            const country = countryObj[0].short_code ; 
      
            if (e.result.place_type[0] && e.result.place_type[0] == "postcode") {
              
              const zipcode = e.result.text ; 
              const url = `/lookup/${country}/${zipcode}/${lng}/${lat}`
              // const url = `lookup/search/${country}/${zipcode}/${lng}/${lat}`
              return window.open( url , "_self");
      
            } else {
      
              const zipObj = _.filter(e.result.context , (obj) => {
                return obj.id.indexOf("postcode") !== -1;
              });
      
              const zipcode = zipObj[0].text
              // const url = `/search/${country}/${zipcode}/${lng}/${lat}`
              const url = `/lookup/${country}/${zipcode}/${lng}/${lat}`
              return window.open(url, "_self");
            }
      
          })
    }

    switchCountry = () => {
        let selection = this.state.country != "de" ? "de" : "us" ; 
        let url = `/lookup/search/${selection}`
        return window.open( url, "_self");
    }

    render() { 
        const { country } = this.state ; 

        return (
            <>
            <Helmet> <title> {`Zipscore | ${country && country.toUpperCase()} lookup `} </title> </Helmet>
            <NavBar isAdd={true} />
            <div className='lookupauthcenteredlanding mb-4'>
                <div className="mb-5 titlehead text-center">
                    <h1> Analyse any location in { country == "de" ? "Germany." : "the US." } </h1>
                </div>
                <div className="mb-5  mt-3 d-flex ">
                    <div className="p-2">
                        <div className="mt-0" id="geocoder"></div>
                        <div className="text-center">
                            <span className="startFreeTextHero">
                                { country === "de" ? "Example: 10117, Berlin" : "Example: 10019, New York" }
                            </span>
                        </div>
                    </div>
                    <div className="p-2 setormobile " style={{color: "#AFB3BA"}}> - <span  style={{fontSize: "14px"}}> or </span> - </div> 
                    <div className="ml-1 text-center" style={{marginTop: "-1px"}}>
                        <div className="browsecta"> <a href={`/lookup/browse/${country}/states`}> Browse </a>  </div>
                    </div>    
                </div>
                <div className="countries mt-2">
                    <img className="selectedCountryIcon" src={country== "de" ? Germany : USA } alt={country}/>  
                    <img 
                        className="deselectedCountryIcon" 
                        onClick={ this.switchCountry } 
                        src={country!= "de" ? Germany : USA } alt="germany"
                    />
                </div>
            </div>
            </>
        );
    }
}
 
export default Lookup;