import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OneTap from '../../common/Onetap';
import LoadingPage from "../../common/tools-ui/loadingPage"  ;
import Desc from "./desc" ; 
import { AreaLabels } from "./zipcodes" ; 


class Areas extends React.Component {

    state = {
        loading: true ,  
        areas: [] , 
        country: null , 
        label: null , 
        lat: null, 
        lng : null , 
        desc: null , 
        imgUrl: null
    }

    async componentDidMount() {
        const country = this.props.match.params.country ; 
        const label = this.props.match.params.label ;
        
        const dts = AreaLabels.filter( k => { return k.country === country && k.label === label}) 

        this.setState({ areas:[...dts[0].zips] , country : country , loading: !true , desc: dts[0].desc  , imgUrl: dts[0].imgSource})
    }
    

    render() {
        const { loading, favorite, areas , desc , imgUrl, country } = this.state ; 
        
        return (
            <>  
                {
                    loading && 
                    <LoadingPage />
                }
                {
                    !loading && 
                    <>
                        <OneTap />
                        <Desc favorite={favorite} areas={areas} desc={ desc } imgUrl={imgUrl } country={country} />
                    </>
                }
              
            </>
        )
        
    }
}
 
export default Areas ;