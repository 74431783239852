import moment from "moment"

export function compareDates ( dateTimeA , dateTimeB ) {
 
    const expiry = moment(dateTimeA,"YYYY-MM-DD");
    const today = moment(dateTimeB,"YYYY-MM-DD");

    if (expiry >= today) return 1; // expiry date is due 
    else if (expiry < today) return -1; //expiry date passed 
    else return 0 ;
    
}

export function generateLightColorHex() {
    let color = "#";
    for (let i = 0; i < 3; i++)
      color += ("0" + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
    return color;
  }

  export function cohorts(data) {
 
    const { age , income, gender } = data[0] ; 
  
    const cohortData = [
      {
        min: [
          Object.keys(age).reduce(function (a, b) {
            return age[a] < age[b] ? a : b;
          }),
          Object.keys(income).reduce(function (a, b) {
            return income[a] < income[b] ? a : b;
          }),
          Object.keys(gender).reduce(function (a, b) {
            return gender[a] < gender[b] ? a : b;
          }),
        ],
      },
      {
        max: [
          Object.keys(age).reduce(function (a, b) {
            return age[a] > age[b] ? a : b;
          }),
          Object.keys(income).reduce(function (a, b) {
            return income[a] > income[b] ? a : b;
          }),
          Object.keys(gender).reduce(function (a, b) {
            return gender[a] > gender[b] ? a : b;
          }),
        ],
      },
    ];
    
    const cohortres = {
      min: cohortData[0].min, 
      max: cohortData[1].max
    }

    return cohortres ;
  }


  export function transform(data) {

    // console.log('data in transform', data)

    return data.reduce((acc, rec) => {
       return [
         ...acc,
         Object.keys(rec).reduce((iacc, irec) => {
           return {
             ...iacc,
             [irec]: Object.keys(rec[irec]).map((item) => {
               return { name: item, value: parseFloat(parseFloat(rec[irec][item]).toFixed(2)) };
             }),
           };
         }, {}),
       ];
     }, []);
     
   }

   
   export function transform2(data) {

    // console.log('data in transform', data)

    return data.reduce((acc, rec) => {
       return [
         ...acc,
         Object.keys(rec).reduce((iacc, irec) => {
           return {
             ...iacc,
             [irec]: Object.keys(rec[irec]).map((item) => {
               return { name: item, value: parseFloat(parseFloat(rec[irec][item]).toFixed(2)) };
             }),
           };
         }, {}),
       ];
     }, []);
     
   }
   