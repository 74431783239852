import React, { useState , useEffect } from 'react' ; 
import { useParams , Redirect , useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import '../main.css';
import Header from '../../Header';
import Map from '../../Map/one.js';
import LivingWith from '../../LivingWith';
import ApartMent from '../../Apartment';
import Education from '../../Education';
import Household from '../../Household';
import DashboardInfo from '../../DashboardInfo';
import Info from '../../Info';
import Billboardinfo from "../../Info/billboardinfo"
import Races from '../../Races';
import Occupations from '../../Occupation';
import Demowall from "../../Demowall/index.js"; 
import Authwall from "../../Authwall/index.js"; 
import Limitwall from "../../Authwall/indexlimit.js"; 
import OneTap from "../../../../../common/Onetap.jsx" ;
import LoadingPage from "../../../../../common/tools-ui/loadingPage" ;

import { getZipcodeResult } from "../../../../../../services/lookupService" ; 
import { getCurrentUser } from "../../../../../../services/userService" ; 
import Footer from "../../../../../landing-page/Footer" ; 

import Sticky from "../../../../../common/StickyHeader" ;

const Main = () => {

  const [limitExceeded, setLimitExceeded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [exception, setException] = useState(false)
  const [dts, setDts] = useState([]) ; 
  const [attributes, setAttriburtes] = useState({}) ;
  const [ loc, setLoc ] = useState({
    zipcode : useParams().zipcode , 
    lats : useParams().lat , 
    lngs: useParams().lng
  }); 
  const [titletext, setTitletext] = useState('') ;
  const [living, setLiving] = useState('') ; 
  const [hhunit, setHhunit] = useState('') ;
  const [ageinc, setAgeinc] = useState('') ;
  const [race, setRace] = useState('') ;
  const [education, setEducation] = useState('') ; 
  const [occupations, setOccupations ] = useState('') ; 
  const [textpopulated, setTextpopulated] = useState('') ; 
  const history = useHistory() ; 
  const nf = new Intl.NumberFormat() ;

  useEffect(() => {

    async function getData() {

      try {
          const resp = await getZipcodeResult("us", loc.zipcode)
          const obj = resp.data[0].proportions[0]
          setDts([...resp.data[0].proportions])
          setLoading(false)
          setAttriburtes({
            city: resp.data[0].city ,  
            citykey: resp.data[0].citykey ,  
            state: resp.data[0].state , 
            statekey: resp.data[0].statekey , 
            county: resp.data[0].county , 
            population: resp.data[0].population ,
            households : resp.data[0].households , 
            old: resp.data[0].old , 
            young: resp.data[0].young
          })
    
          setTitletext(
            <div className="text-justify">
                {/* <h2 className="mt-4 customh2fontsize" style={{color: "#4F4F4F"}}> Where is ZIP Code {`${loc.zipcode}`} located ?</h2> */}
                <p className="mt-3 mb-3">
                ZIP Code {`${loc.zipcode}`} resides within the {`${resp.data[0].county}`} county of the 
                city {`${resp.data[0].city}`} in {`${resp.data[0].state}`}. This area houses approximately {` ${nf.format(resp.data[0].population)} `} inhabitants 
                and {` ${nf.format(resp.data[0].households)} `} family units. 
                Within its confines, {` ${obj.gender.male.toFixed(2)}`} percent identify as male,
                with the predominant age group being {`${ resp.data[0].young > resp.data[0].old ? `below 40 years` : `above 40 years` } `}. Dive into real-time
                demographic insights, socio-economic profiles, and 
                geolocation specifics, encompassing factors like age, gender, income, and more for ZIP Code {`${loc.zipcode}`} on Zipscore with a complimentary sign-up.
                </p>
    
            </div>
          )
    
          //=== living arrangement + Persons in Household  
          setLiving(
            <div className="p-2">  
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658682911/free-tools/family_1.svg' style={{width:"47px", marginRight:"12px"}} alt="living"/>
                <h2 className="customh2fontsize"> Household Living Arrangement in {`${resp.data[0].zipcode} ZIP Code, ${resp.data[0].state}`}, US </h2>
              </div>
              <p className="mt-2 mb-3" >
              The U.S. Census Bureau defines a household as a collection of people living together in a house.
              {` ${resp.data[0].proportions[0].livingWith['Living Alone']}`} percent of Zip Code {` ${resp.data[0].zipcode}`} households live alone 
              while {` ${resp.data[0].proportions[0].livingWith['Living with Children under 18']}`} percent live with children under the age of 18, 
              and {`${resp.data[0].proportions[0].livingWith['Living with person 60 plus age']}`} percent live with someone of age 60 years and above.

              </p>
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/house_1.svg' style={{width:"47px", marginRight:"12px"}} alt="household"/>
                <h2 className="customh2fontsize"> Persons in Household in Area Code {`${resp.data[0].zipcode}, ${resp.data[0].state}`}, US </h2>
              </div>
              <p className="mt-2 mb-3" >
              {` ${ resp.data[0].proportions[0].livingPerson["1 per"]}`} percent of the households in the Zip Code {`${resp.data[0].zipcode} `} 
              are one-person households while 
              {` ${(resp.data[0].proportions[0].livingPerson["2 per"] + resp.data[0].proportions[0].livingPerson["3 per"]).toFixed(2)} `} percent accounts for two and three-person households, and 
              {` ${resp.data[0].proportions[0].livingPerson["4 or more"]} `} percent comprises of at least 4 persons per household.
              </p>
            </div>
        )
          
          //=== HH unit structure type 
          setHhunit(
            <div className="p-2">
              <div className="d-flex align-items-center">
                  <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/building_1.svg' style={{width:"47px", marginRight:"12px"}} alt="housing unit"/>
                  <h2 className="customh2fontsize"> Housing Unit Structures by {`${resp.data[0].zipcode} ZIP Code Demographics, ${resp.data[0].state}`}, US </h2>
              </div>
            <p className="mt-2 mb-2">
              
              A place where an individual or a group of persons live is known as a housing or single unit. 
              In the ZIP Code {`${resp.data[0].zipcode}`},  {` ${resp.data[0].proportions[0].housingType['1 unit, detached']}`} percent 
              of housing units are detached single units while 
              {` ${(resp.data[0].proportions[0].housingType['2 apartments']+resp.data[0].proportions[0].housingType['3 or 4 apartments']).toFixed(2)} `} 
              percent of housing units have 2 - 4 apartments, 
              and {` ${(resp.data[0].proportions[0].housingType['5 or 9 apartments']+resp.data[0].proportions[0].housingType['10 or more apartments']).toFixed(2)} `}
               percent have 5 or more apartments.

            </p>
            </div> 
          )
          
          //===  Income + Age group 
          setAgeinc(
            <div className="p-2">
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/salary_1.svg' style={{width:"50px", marginRight:"12px"}} alt="age"/>
                <h2 className="customh2fontsize"> Household Income Lookup {`${resp.data[0].zipcode} Area Code, ${resp.data[0].state}`}, US </h2>
              </div>
              <p className="mt-2 mb-3" >

                The total gross income before tax deduction 
                within a 12-month duration received by every member of a household above the age of 15 is referred to as the household income. 
                For ZIP Code {`${resp.data[0].zipcode}`}, 
                {` ${(resp.data[0].proportions[0].income['below-35,000'] + resp.data[0].proportions[0].income['35,000-49,999']).toFixed(2)} `} percent of its households earn below $50,000, 
                {` ${(resp.data[0].proportions[0].income['50,000-99,999'] + resp.data[0].proportions[0].income['100,000-149,000']).toFixed(2)} `} percent earns between $50,000 and $150,000, 
                while {` ${resp.data[0].proportions[0].income['above-150,000']} `} percent have a gross annual income of above $150,000. 

              </p>
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/age-group_1.svg' style={{width:"50px", marginRight:"12px"}} alt="income"/>
                <h2 className="customh2fontsize"> Age Distribution in {`${resp.data[0].zipcode} ZIP Code Location, ${resp.data[0].state}`}, US </h2>
              </div>
              <p className="mt-2 mb-2">

              Age distribution is the breaking down of the population of a 
              given area say Zip Code {`${resp.data[0].zipcode}`} by age to determine the proportion of 
              individuals in each age group within the population.
              {` ${resp.data[0].proportions[0].age['10-24']}`} percent of the people in ZIP Code {`${resp.data[0].zipcode}`} are 
              aged 10 to 24 years while {` ${resp.data[0].proportions[0].age['40-64']}`} percent are aged 40 to 64 years, and 
              {` ${resp.data[0].proportions[0].age['above 64']}`} percent are over 64 years of age.  

              </p>
              
            </div>
          )

          //===  Education
          setEducation(
            <div className="p-2 ">
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1665248778/free-tools/education.svg' style={{width:"50px", marginRight:"14px"}} alt="education"/>
                <h2 className="customh2fontsize"> Education Attainment in {`${resp.data[0].zipcode} Area Code, ${resp.data[0].state}`}, US </h2>
              </div>
              <p className="mt-2 mb-3" >

              The highest educational level attained by a person is referred to 
              as the education attainment of such an individual.
              {` ${resp.data[0].proportions[0].education['School level']}`} percent of ZIP Code {`${resp.data[0].zipcode}`} population have completed the school level education,
               while {` ${resp.data[0].proportions[0].education['Bachelor']} `}
                percent have finished their Bachelor’s studies, 
                and {` ${resp.data[0].proportions[0].education['Graduate']}`} percent have completed a graduate level education. 

              </p>   
            </div>
          )

        //=== occupation
        setOccupations(
          <div className="p-2">
          <div className="d-flex align-items-center">
            <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1665249595/free-tools/occupation.png' style={{width:"49px", marginRight:"14px"}} alt="occupation"/>
            <h2 className="customh2fontsize"> Employment by Industry Sector in {`${resp.data[0].zipcode} ZIP Code, ${resp.data[0].state}`}, US </h2>
          </div>
          <p className="mt-2 mb-2">

          Employment data by the U.S. Bureau of Labor Statistics are classified by industry sectors to reflect the distribution of workforce across different economic sectors.
          {` ${resp.data[0].proportions[0].occupations['Educational services, and health care and social assistance']} `} percent of the population of {`${resp.data[0].zipcode}`} ZIP Code 
          work in the educational, healthcare and social assistance sector, 
          while {`${resp.data[0].proportions[0].occupations['Construction']}`} percent and 
          {` ${resp.data[0].proportions[0].occupations['Manufacturing']} `} percent account for the construction and manufacturing industry respectively. 
          Also, {`${resp.data[0].proportions[0].occupations['Professional, scientific, and management, and administrative and waste management services']} `} 
           percent of the population focuses on professional, scientific, management, administrative, and waste management services. 

          </p>
        </div>
      )

      //=== race
      setRace(
        <div className="p-2">
          <div className="d-flex align-items-center">
            <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662210778/free-tools/ethnicity.png' style={{width:"49px", marginRight:"12px"}} alt="race"/>
            <h2 className="customh2fontsize"> Ethnicity Groups by {`${resp.data[0].zipcode} ZIP Code Demographics, ${resp.data[0].state}`}, US </h2>
          </div>
          <p className="mt-2 mb-2">
            Collected by the U.S. Census Bureau through self-identification method, 
            racial data in ZIP Code {`${resp.data[0].zipcode}`} shows that {` ${resp.data[0].proportions[0].races['White']}`} percent of the population are white, 
            {` ${resp.data[0].proportions[0].races['Black or African American alone']} `} percent are Black or African American, 
            and Asian are {` ${resp.data[0].proportions[0].races['Asian alone']}`} percent. 
          </p>
        </div>
      )

      setTextpopulated (
        <div className="p-2">
            <>
              <div className="d-flex align-items-center">
                  <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1666108945/free-tools/populatedcity.svg' style={{width:"49px", marginRight:"12px"}} alt="population"/>
                  <h2 className="customh2fontsize"> City of {`${loc.zipcode}`} ZIP Code Area </h2>
              </div>
              <div className="text-justify">
                  <p className="mt-2 mb-2"> 
                      The ZIP Code {`${loc.zipcode}`} belongs to the {`${resp.data[0].city} `} area in the state of {`${ resp.data[0].state}`}. <br/>
                      Check the demographics data for the city of {`${resp.data[0].city} `} by clicking on the link below - 
                  </p>
                  <div className='state-city mt-1 mb-3 justify-content-left align-items-center'>    
                      <div>
                          <a href={`/us/${encodeURIComponent(resp.data[0].statekey)}/${encodeURIComponent(resp.data[0].citykey)}/zip-code-map`}> {resp.data[0].city} </a>  
                      </div>
                  </div>
              </div>
              <hr className="mb-2"/>
              <div className="mt-2 hover-underline-animation dashboard-content-wrapper-link" style={{borderBottom: "1.2px solid #007bff"}}>
                  <a href={`/browse/us/${ encodeURIComponent(resp.data[0].statekey) }/zip-codes`} > List of all ZIP codes in {`${resp.data[0].state}`}  </a>
              </div>
            </>
        </div>
      )

      } catch (error) {
        setLoading(false)
        if (error.response.status == 429) {
            setLimitExceeded(true)
        } else {
          setException(true)
        }
      }
    }
    getData() ;

  }, [])
  
  const limitwall= <React.Fragment>   
    <div className="mt-3 mb-4 text-center " >
        <img style={{width:'100%' , maxWidth:'550px'}} src='https://res.cloudinary.com/zipscores-collection/image/upload/v1663332859/11132.jpg' alt="limits"/>
        <h2 className="mt-1 mb-5 text-center "> Please log in to view {`${loc.zipcode}`} ZIP Code data </h2>
    </div>
  </React.Fragment>

  const authwall= <React.Fragment>   
      <p className="mt-3 mb-4 text-center "> Register for a free account to accesss all the features of Zipscore.AI. </p>
    </React.Fragment>

  if (exception) return <Redirect to="/404"/> ;
  if (getCurrentUser()) history.push(`/lookup/us/${loc.zipcode}/${loc.lats}/${loc.lngs}`)


  return (
    <>
    <Sticky />
    <div className='content-wrapper'>
      <Header />
      <OneTap />
      { dts.length > 0 && loc.zipcode && Object.keys(attributes).length && !limitExceeded && !loading && 
        <>
          <Helmet> <title> {`${loc.zipcode} ZIP Code Map, ${attributes.city}, US`} </title> </Helmet>
          <DashboardInfo 
            title={`${loc.zipcode} ZIP Code Map, ${attributes.city}, ${attributes.state}`} 
            description={titletext} 
            link1={`/us/${ encodeURIComponent(attributes.statekey)}/zip-code-map`}
            link1title={`Demographics of ${attributes.state}`}
            link2={`/browse/us/${ encodeURIComponent(attributes.statekey)}/cities`} 
            link2title={`Demographics by Cities in ${attributes.state}`}
          />
          <Map dts={dts} zip={ loc.zipcode }  lat={ loc.lats } lng={ loc.lngs } country={'us'} attributes={attributes} zoom={15.5} />
          <Info description={living} />
          <LivingWith dts={dts} />
          {/* { dts[0].billboard.length > 0 && 
            <Billboardinfo geolevel={'zipcode'} location={loc.zipcode} description= {`${dts[0].billboard.length } billboards for outdoor advertising in the ZIP Code ${loc.zipcode}`} />
          } */}
          {/* <Demowall title={`Predict success of your business in ZIP Code ${loc.zipcode}.`}/> */}
          <Info description={hhunit} />
          <ApartMent dts={dts} />
          <Authwall description={authwall}/> 
          <Info description={ageinc} />
          <Household dts={dts} />
          <Info description={education} />
          <Education dts={dts} />
          <Info description={occupations} />
          <Occupations dts={dts} />
          <Info description={race} />
          <Races dts={dts} />
          <Info description={textpopulated} />
        </> 
    }
    { loading && dts.length == 0 && !limitExceeded && <LoadingPage /> }
    { dts.length == 0 && limitExceeded && !loading && <Limitwall description={limitwall}/> }
    </div>
    <Footer />
    </>
  )
}

export default Main ; 