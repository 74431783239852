import React from "react";
import styles from "./styles/pricing.module.css";
import checkimg from "./assets/images/check.svg" ; 
import {Link} from "react-router-dom" ; 

export function FreePlan() {
  return <div>
        <div className={ styles.l_Plancard }>
          <div className={styles.lp_planhead}>
              <h3 className={styles.lp_title}>Free</h3>
          </div>
            <div className={styles.lp_price}>
                <div className={styles.lp_pricerow}>

                    <span className={styles.pricevalue}> $0 </span>
                    <span className={styles.pricevaluesubtext}>/user/month</span>

                </div>
            </div>
            <Link to="/register">
              <div className={styles.lp_cta}>
                Start Now
              </div>
            </Link>
            <hr/>
            <div className={styles.lp_details}>
                  <p> <img src={checkimg} alt="" className="mr-2"/> Access to limited data</p>
                  <p><img src={checkimg} alt="" className="mr-2"/> Limited location searches</p>
                  <p><img src={checkimg} alt="" className="mr-2"/> Limited POI analysis</p>
                  <p><img src={checkimg} alt="" className="mr-2"/> 100 scribbles </p>
                  {/* <p> <img src={checkimg} alt="" className="mr-2"/> Real-time Collaboration</p> */}

            </div>
        </div>
    </div>
}

export function PremiumPlan() {
  return <div>
        <div className={ styles.l_Plancard }>
          <div className={styles.lp_planhead}>
              <h3 className={styles.lp_title}>Premium</h3>

                  <span className={styles.lp_save}>
                    early bird <br />
                    Save 30%
                  </span>
          </div>
            <div className={styles.lp_price}>
                <div className={styles.lp_pricerow}>

                    <h1> $9 </h1>
                    <h3>/user/month</h3>

                </div>
            </div>
            <Link to="/register">            
              <div className={styles.lp_cta}>
                Start for free
              </div>
            </Link>
            <hr/>
            <div className={styles.lp_details}>
                  <p> <img src={checkimg} alt="" className="mr-2"/> Dedicated Onboarding </p>
                  <p><img src={checkimg} alt="" className="mr-2"/> Unlimited location searches</p>
                  <p><img src={checkimg} alt="" className="mr-2"/> Unlimited POI analysis</p>
                  <p><img src={checkimg} alt="" className="mr-2"/> Unlimited scribbles </p>
                  <p> <img src={checkimg} alt="" className="mr-2"/> Real-time Collaboration</p>

            </div>
        </div>
    </div>
}

export function EnterprisePlan() {
  return <div>
        <div className={ styles.l_Plancard }>
          <div className={styles.lp_planhead}>
              <h3 className={styles.lp_title}>Enterprise</h3>
          </div>
            <div className={styles.lp_price}>
                <div className={styles.lp_pricerow}>

                    <h1> Custom </h1>
                    <h3 style={{ color: "#FFFFFF"}}> {' .'}</h3>

                </div>
            </div>
            <div className= {styles.enterpriseText}>
              <span >
              Let us build together your own <br /> location data platform from scratch. 
              </span>
            </div>

            <a href="https://calendly.com/zipscore/30min" target="_blank" rel="noopener noreferrer">
              <div className={styles.lp_cta}>
                Book a Demo
              </div>
            </a>
            
            <hr/>
            <div className={styles.lp_details}>
                  <p> <img src={checkimg} alt="" className="mr-2"/> All premium features </p>
                  <p><img src={checkimg} alt="" className="mr-2"/> Integrate business data using 160+ connectors</p>
                  <p><img src={checkimg} alt="" className="mr-2"/> Footfall Traffic </p>
                  <p><img src={checkimg} alt="" className="mr-2"/> Consumer Styles </p>
                  <p><img src={checkimg} alt="" className="mr-2"/>  Audience Scoring </p>
                  <p><img src={checkimg} alt="" className="mr-2"/> Geo Analytics Dashboard </p>
                  <p><img src={checkimg} alt="" className="mr-2"/>  On premise deployment </p>
                  {/* <p><img src={checkimg} alt="" className="mr-2"/> SAML SSO </p> */}
                  {/* <p><img src={checkimg} alt="" className="mr-2"/> Ad Exchange Integration </p> */}
                  {/* <p> <img src={checkimg} alt="" className="mr-2"/> Real-time Collaboration</p> */}

            </div>
        </div>
    </div>
}


