import React from 'react';
import './style.css'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import AgeChart from '../../../../common/shared-charts/AgeChart';
import HourseHoldGross from '../../../../common/shared-charts/HourseHoldGross';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Household = ({dts}) => {
  return (
    <div className='Household-wrapper'>
      <div className='container'>
        <div className='Household-block'>
          <div className='Household-content'>
            <div className='Household-contents'>
              <div className='Household-title'>
                <h6 className="fontstyleaxiform"> Monthly Household Gross Income </h6>
              </div>
              <HourseHoldGross dts={dts[0].income} />
            </div>
          </div>
          <div className='age-content'>
            <div className='age-contents'>
              <div className='Household-title age'>
                <h6 className="fontstyleaxiform"> Age Distribution </h6>  
                <p></p>
                {/* <span>52.13%</span> */}
              </div>

              <AgeChart className="mt-5" dts={dts[0].age} />

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Household