import React, { Component } from "react";
import { Box } from "../box" ;
import ToolTip from "../../common/toolTip/tooltip" ; 
import './styles/TabStyle.css'
import PaywallUpgrade from "../../common/subscription/paywallUpgrade"
import PaywallUpgradeLight from "../../common/subscription/paywallUpgradeLight"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Placeholder } from "rsuite" ; 
const { Paragraph } = Placeholder;

class Tab2 extends Component {

    onDropHandler = (data) => {
        this.props.setData(data)
    }

    render() {

        const { allFavorites, data , textAttributes , freeVersion } = this.props ;
        const nf = new Intl.NumberFormat() ;
        return data ?
                <>
                    {
                        textAttributes.selectedPlz.length >=1 && 
                        <>
                            <div className={'selectionDataContainer'}>
                                <div className={'mt-5 ml-2 favoriteTextAfterDrag'}>
                                    <span className="mr-1"> {data.text} </span>
                                    <ToolTip label={data.label} size={'0.8rem'} /> 
                                </div>
                                <FontAwesomeIcon onClick = { this.props.clearData } className={'clearSelection'} icon={['fa', 'times-circle']} />
                            </div>
                            <div className="d-flex justify-content-center mt-1">
                                <div className="selectedPlzOnMapText" >
                                    <span className="selectionsHighlightText"> {nf.format(textAttributes.selectedPlz.length)} </span>
                                    zip codes 
                                </div>
                            </div>

                            <div className="d-flex justify-content-center mt-1">
                                <div className="selectedPlzOnMapText" >
                                    <span className="selectionsHighlightText">  {nf.format(textAttributes.selectedPopulation)} population {' '} </span>
                                    {/* of 
                                    <span className="selectionsHighlightText"> {textAttributes.countryPopulation} </span>
                                    population{' '} */}
                                    <ToolTip  
                                        label= { 'The total population in the selected zip codes' } 
                                        size= {'0.7rem'}
                                    /> 
                                </div>
                            </div>
                            
                            <div className="d-flex justify-content-center mt-1">
                                <div className="selectedPlzOnMapText" >
                                    <span className="selectionsHighlightText"> {nf.format(textAttributes.selectedHouseholds)} households {' '} </span>
                                    {/* of 
                                    <span className="selectionsHighlightText"> {textAttributes.countryHouseholds} </span>
                                    households */}
                                    <ToolTip  
                                        label= { 'The total households in the selected zip codes' } 
                                        size= {'0.7rem'}
                                    /> 
                                </div>
                            </div> 

                        </>
                    }
                    { 
                        textAttributes.selectedPlz.length < 1 && <Paragraph rows={8} style={{ marginTop: 40, marginRight: 30 }} /> 
                    }
                </>
                :
                
                    <>

                        {
                            freeVersion && 
                            <>
                               <div style={{ display: "flex", flexWrap: "wrap" , flexDirection: "column"}}>
                                {
                                    allFavorites.slice(0,2).map((item , i) => (
                                        <div className="mt-2 font-weight-bold" key={i}>
                                            <Box
                                                type={'text'}
                                                srcData={item}
                                                data={item}
                                                onDrop={this.onDropHandler}
                                            />
                                        </div>
                                    ))   
                                }
                                </div> 
                                <div className="paywall-div-image-tab2"></div>
                                <div className="tab1PremiumFeatureText">
                                    <PaywallUpgradeLight text={"to unlock all favorite audience segments"}/>
                                </div>
                                {/* <PaywallUpgrade /> */}
                            </>
                        }
                        
                        <div style={{ display: "flex", flexWrap: "wrap" , flexDirection: "column"}}>
                        {   
                            !freeVersion && allFavorites.map((item , i) => (
                                <div className="mt-2 font-weight-bold" key={i}>
                                    <Box
                                        type={'text'}
                                        srcData={item}
                                        data={item}
                                        onDrop={this.onDropHandler}
                                    />
                                </div>
                            ))  
                        }
                        </div>
                </>
    }
}

export default Tab2;
