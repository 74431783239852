import _ from "lodash" ; 

export const selectImgVal = (visibleData , selectedData ) => {

    let data = {
        selections : [ selectedData ] 
    }

    let imgVal
    const filteredData = visibleData.filter((visible) => {
        if (visible === "age" || visible === "gender") { return visible }
    })
    
    
    if (filteredData.length > 0 ){
    
        let selectedage = {} ; 
        if (filteredData.includes('age')) {
            selectedage = _.pick(data.selections[0], [`age.value`]) ;
        } else {
            selectedage.age = {'value' : 'no'};
        }
    
        let selectedgender = {} ; 
        if (filteredData.includes('gender')) {
            selectedgender = _.pick(data.selections[0], [`gender.value`]) ;
        } else {
            selectedgender.gender = {'value' : 'no'};
        }

        if (selectedage.age.value.length > 2 && selectedgender.gender.value.length > 2 ){
            
            imgVal = `${selectedage.age.value}-${selectedgender.gender.value}` ;
        
        } else if ( selectedage.age.value.length > 2 && selectedgender.gender.value.length <= 2  ) { 
            
            // imgVal = `${selectedage.age.value}-onlyAge`
            imgVal = `onlyage_${selectedage.age.value}`

        } else if ( selectedage.age.value.length <= 2 && selectedgender.gender.value.length > 2  ) {
 
            imgVal = `onlygender_${selectedgender.gender.value}`
        }  
    } else  {
        imgVal = "allneutral"
    } 
    
    return imgVal ; 
}


export const filterPercentiles = (contextData, keysArray , percentileValue , filterOperator) => {

        const hasValAboveThreshold = (item, threshold) => {
            return keysArray.every((key) => item[key] >= threshold);
        }

        const hasValBelowThreshold = (item, threshold) => {
            return keysArray.every((key) => item[key] <= threshold);
        }

        if ( filterOperator === "greaterThanEqualTo") {

            const output = contextData.reduce((acc, val) => {
                
                if (hasValAboveThreshold(val, percentileValue)) {
                acc.push(val);
                }  
                return acc;
            }, []);
            
            return output ; 

        } else {

            const output = contextData.reduce((acc, val) => {
                
                if (hasValBelowThreshold(val, percentileValue)) {
                acc.push(val);
                }  
                return acc;
            }, []);
            
            return output ; 

        }
        

}

export const convertFirendlyFormat = (value) => {
    var pow = Math.pow, 
    floor = Math.floor, 
    abs = Math.abs, 
    log = Math.log;
    var abbrev = 'kmb'; // could be an array of strings: [' m', ' Mo', ' Md']

    function round(n, precision) {
        var prec = Math.pow(10, precision);
        return Math.round(n*prec)/prec;
    }

    function format(n) {
        var base = floor(log(abs(n))/log(1000));
        var suffix = abbrev[Math.min(2, base - 1)];
        base = abbrev.indexOf(suffix) + 1;
        return suffix ? round(n/pow(1000,base),2)+suffix : ''+n;
    }

    return format(value)
}