import http from "../services/httpService";
// import { apiUrl_dev, apiUrl_prod  } from "../config/config.json";


import config from "../config/api.js";
const { apiUrl_dev, apiUrl_prod } = config;

const apiUrl = process.env.REACT_APP_ENVIRON === "development" 
               ? apiUrl_dev
               : apiUrl_prod

export function getAllInstances() {

    return http.get(
        `${apiUrl}/scribble/all`
    )

}