import React, { useState , useEffect } from 'react' ; 
import { useLocation, useHistory , Redirect } from "react-router-dom";
import Header from '../../Header';
import Map from '../../Map/many.js';
import LivingWith from '../../LivingWith';
import ApartMent from '../../Apartment';
import Household from '../../Household';
import DashboardInfo from '../../DashboardInfo';
import Info from '../../Info';

import LoadingPage from "../../../../../common/tools-ui/loadingPage" ;
import NavBar from "../../../../../common/NavBar/navbar.jsx" ;
import { createCheckoutSession } from "../../../../../../services/checkoutService";
import { getPoiPercentage } from "../../../../../../services/poiService" ;

const PoiDashboard = () => {

    const location = useLocation() ; 
    const history= useHistory(); 
    if (!location.state) history.push("/poi/us")

    const [limitExceeded, setLimitExceeded] = useState(false)
    const [loading, setLoading] = useState(true)
    const [exception, setException] = useState(false)
    const [dts, setDts] = useState([]) ; 
    const [areas, setAreas] = useState([]); 
    const [attributes, setAttributes] = useState({}) ;
    const [titletext, setTitletext] = useState('') ;
    const [living, setLiving] = useState('') ; 
    const [hhunit, setHhunit] = useState('') ;
    const [ageinc, setAgeinc] = useState('') ;

    const nf = new Intl.NumberFormat() ;

    useEffect(() => {

        async function getData() {

          try {
              const { zcta , radius, place, lng , lat } = location.state ;  
              const resp = await getPoiPercentage("de", zcta )
            
              const obj = resp.data[0].proportions[0] ; 
              setDts([...resp.data[0].proportions]) ; 
              setAreas([...zcta]); 
        
              setAttributes({
                population: resp.data[0].population ,
                households : resp.data[0].households , 
                old: resp.data[0].old , 
                young: resp.data[0].young , 
                center: [lng, lat ]
              })
            
              setTitletext(
                <div className="text-justify">
                  <p className="mt-2 mb-2">
                        You have selected {`${zcta.length}`} postal codes in the radius of {`${radius}`} kilometers from the {`${place}`}, 
                        The population in this area is {`${nf.format(resp.data[0].population)}`}, 
                        of which {`${obj.gender.male.toFixed(2)}% `} identify their gender as male. 
                        The majority of the population living in this area {` ${ resp.data[0].young > resp.data[0].old ? `under the age of 40.`: `over the age of 40.` } `}. 
                  </p>
              </div>
              )
    
              setLiving(
                <div className="p-2 text-justify">  
                  <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658682911/free-tools/family_1.svg' style={{width:"47px", marginRight:"12px"}}/>
                    <h2 className="customh2fontsize"> Buildings </h2>
                  </div>
                  <p className="mt-2 mb-3" >
                    According to the Federal Statistical Office of Germany (Statistisches Bundesamt) the buildings can be primarily classified into commercial or residential buildings. 
                    Commercial buildings are buildings that are used for commercial purposes, and include office buildings, warehouses, retail buildings etc., 
                    residential buildings are any building that is used primarily for residential purposes.
                    In this area, {` ${resp.data[0].proportions[0].buildings['residential']}`} percent of the buildings are 
                    residential whereas {` ${resp.data[0].proportions[0].buildings['commercial']}`} percent are commercial buildings. 
                  </p>
                  <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/house_1.svg' style={{width:"47px", marginRight:"12px"}}/>
                    <h2 className="customh2fontsize"> Persons in Household</h2>
                  </div>
                  <p className="mt-2 mb-3" >
                  The share of household types can be classified into three categories - single person households, multiple person households with children and multiple person households without children. 
                  In theis area, {` ${ resp.data[0].proportions[0].livingPerson["single"]}`} percent 
                  of the households are single-person households. The households with children account for 
                  {` ${resp.data[0].proportions[0].livingPerson["Multi-person with children"]} `} percent. 
                  </p>
                </div>
              )
                
              setHhunit(
                <div className="p-2 text-justify">
                  <div className="d-flex align-items-center">
                      <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/building_1.svg' style={{width:"47px", marginRight:"12px"}}/>
                      <h2 className="customh2fontsize"> Housing Unit Structures </h2>
                  </div>
                <p className="mt-2 mb-2">
                  A housing unit is a single unit within a larger structure that can be used by an individual or
                  household to eat, sleep, and live. The unit can be in any type of residence, such as a house, apartment, 
                  or mobile home, and may also be a single unit in a group of rooms. 
                  In theis area,  {` ${resp.data[0].proportions[0].housingType['1-2 Family']}`} percent 
                  of 1-2 family household units. The housing units which have 3-6 family household structures account for 
                  {` ${resp.data[0].proportions[0].housingType['3-6 Family']}`}  percent 
                  of all housing units in this state whereas 
                  {` ${(resp.data[0].proportions[0].housingType['20+ Family']).toFixed(2)} `} 
                  percent are 20 plus family housing units. 
                </p>
                </div> 
              )
    
              //===  Income + Age group 
              setAgeinc(
                <div className="p-2 text-justify">
                  <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/salary_1.svg' style={{width:"50px", marginRight:"12px"}}/>
                    <h2 className="customh2fontsize"> Monthly Household Income </h2>
                  </div>
                  <p className="mt-2 mb-3" >
                    Monthly household income generally is defined as the total gross income before taxes, 
                    received within a calendar month period by all members of a household above a specified age 
                    (age 15 and older). It includes — but is not limited to wage, salary, self-employment earnings, social security, 
                    pension, and income from other sources. 
                    In the selected area {` ${resp.data[0].proportions[0].income['below-1500']} `} percent have monthly household income below €1500 
                    and {` ${(resp.data[0].proportions[0].income['1500-2599'] + resp.data[0].proportions[0].income['2600-3999']).toFixed(2)} `} percent have 
                    household monthly income between €1500 and €4000. The proportion of high-income households above €7500 monthly which is usually low in most regions of the  
                    Germany account for {` ${resp.data[0].proportions[0].income['above-7500']} `} percent in this area. 
                  </p>
                  <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/age-group_1.svg' style={{width:"50px", marginRight:"12px"}}/>
                    <h2 className="customh2fontsize"> Age Distribution </h2>
                  </div>
                  <p className="mt-2 mb-2">
                  The age distribution, also called Age Composition,
                  in population studies, is the proportionate numbers of persons in successive 
                  age categories in a given population. Age distribution affects many macroeconomic equations 
                  such as consumer behaviour, consumer styles , housing-investment, money-demand, labor-force-participation etc.  
                  In this area, about {`${resp.data[0].proportions[0].age['10-24']}`} percent of the population 
                  is between 10 to 24 years category, 
                  {` ${resp.data[0].proportions[0].age['40-65']}`} percent into the 40 to 64 years age group and {` ${resp.data[0].proportions[0].age['above 65']}`} percent of 
                  the population are over 65 years of age.
                  </p>
                  
                </div>
            )
          } catch (error) {

            setLoading(false)
            if (error.response.status == 429) {
                setLimitExceeded(true)
                const returnUrl = window.location.protocol + '//' + window.location.host + '/lookup/browse/de/states'; 
                const res = await createCheckoutSession(returnUrl) ;
                const sessionUrl = res.data.url ; 
                window.open(sessionUrl, "_blank");
                history.goBack()
    
            } else {
              setException(true)
            }
            
          }          
        }

        if (location.state) getData() ;
    
    }, [])

    return (
        <div className='content-wrapper'>
          <NavBar isAdd={true} bgwhite={true} />
        { dts.length > 0 && location.state && Object.keys(attributes).length && 
            <>
                <DashboardInfo 
                title={`${location.state.place} (${location.state.radius} kms)`} 
                description={titletext} 
                // link1={`/browse/us/${attributes.state.toLowerCase().replace(/\s/g, '-')}/zipcodes`} 
                link1title={`Demographics by ZIP codes in ${attributes.state}`}
                // link2={`/browse/us/${attributes.state.toLowerCase().replace(/\s/g, '-')}/cities`} 
                link2title={`Demographics by Cities in ${attributes.state}`}
                />
                <Map dts={dts}  areas={areas} center={attributes.center} country={'de'} attributes={attributes} zoom={8}/>
                <Info description={living} />
                <LivingWith dts={dts} />
                <Info description={hhunit} />
                <ApartMent dts={dts} />
                <Info description={ageinc} />
                <Household dts={dts} />
            </>
      }
      { loading && dts.length == 0 && !limitExceeded && <LoadingPage /> }
      { dts.length == 0 && limitExceeded && !loading && <LoadingPage text={'Free limits exceeded'} /> }
      </div>
    )


}

export default PoiDashboard ; 