import React, { useState , useEffect } from 'react' ; 
import { useParams , Redirect , useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import '../main.css';
import Header from '../../Header';
import Map from '../../Map/many.js';
import LivingWith from '../../LivingWith';
import ApartMent from '../../Apartment';
import Education from '../../Education';
import Household from '../../Household';
import DashboardInfo from '../../DashboardInfo';
import Info from '../../Info';
import Races from '../../Races';
import Demowall from "../../Demowall/index.js"; 
import Authwall from "../../Authwall/index.js"; 
import Limitwall from "../../Authwall/indexlimit.js"; 
import OneTap from "../../../../../common/Onetap.jsx" ;
import LoadingPage from "../../../../../common/tools-ui/loadingPage" ;
import Occupations from '../../Occupation';
import allStates from "../../../../browse/us/allstates.json";
import { getState } from "../../../../../../services/lookupService" ; 
import { getCurrentUser } from "../../../../../../services/userService" ; 
import _ from "lodash" ; 
import Footer from "../../../../../landing-page/Footer" ;
import Sticky from "../../../../../common/StickyHeader" ;

const Main = () => {

  const [limitExceeded, setLimitExceeded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [exception, setException] = useState(false)
  const [dts, setDts] = useState([]) ; 
  const [areas, setAreas] = useState([]); 
  const [attributes, setAttributes] = useState({}) ;
  const [ loc, setLoc ] = useState({
    statekey : useParams().statekey
  }); 
  const [state, setState]= useState('') ; 
  const [titletext, setTitletext] = useState('') ;
  const [living, setLiving] = useState('') ; 
  const [hhunit, setHhunit] = useState('') ;
  const [ageinc, setAgeinc] = useState('') ;
  const [race, setRace] = useState('') ;
  const [education, setEducation] = useState('') ; 
  const [occupations, setOccupations ] = useState('') ; 
  const [textpopulatedzip, setTextpopulatedZip] = useState('')
  const [textpopulatedcities, setTextpopulatedCities] = useState('')
  const history = useHistory() ; 
  const nf = new Intl.NumberFormat() ;

  useEffect(() => {

    async function getData() {

        try {
          const statekey = loc.statekey ;
          const resp = await getState("us", encodeURIComponent(statekey) ) ;
          setLoading(false)
          setState(resp.data[0].state)
          const obj = resp.data[0].proportions[0] ; 
          const cur = allStates.find(s => s.name === resp.data[0].state);
          setDts([...resp.data[0].proportions])
          setAreas([...resp.data[0].zcta])
          setAttributes({
              state: resp.data[0].state , 
              statekey: resp.data[0].statekey , 
              population: resp.data[0].population ,
              households : resp.data[0].households , 
              old: resp.data[0].old , 
              young: resp.data[0].young , 
              center: [cur.lng, cur.lat ]
          })
          // setPopulated({...resp.data[0].populated})
          const popzipcodes = resp.data[0].populated.zipcodes ; 
          const popcities = resp.data[0].populated.cities ; 
            // === header text 
            setTitletext(
              <div className="text-justify">
                  <p className="mt-2 mb-2">
                  Within {`${resp.data[0].state}`}, ZIP Codes collectively shelter around {` ${nf.format(resp.data[0].population)} `} inhabitants and 
                  approximately {` ${nf.format(resp.data[0].households)} `} family units. Here, {` ${obj.gender.male.toFixed(2)}`} percent of 
                  individuals self-identify as male, 
                  predominantly falling {`${ resp.data[0].young > resp.data[0].old ? `under the 40-year age bracket.` : `into the category of 40 years and older.` } `} 
                  Unlock in-depth, real-time data for {`${resp.data[0].state}`} covering demographics, socio-economic profiles, and housing details in a mere 30 seconds with Zipscore.

                  </p>
      
              </div>
            )
      
            //=== living arrangement + Persons in Household  
            setLiving(
              <div className="p-2 text-justify">  
                <div className="d-flex align-items-center">
                  <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658682911/free-tools/family_1.svg' style={{width:"47px", marginRight:"12px"}} alt="living"/>
                  <h2 className="customh2fontsize"> Household Living Arrangement in {`${resp.data[0].state}`}, US </h2>
                </div>
                <p className="mt-2 mb-3" >
                As per the U.S. Census Bureau's definition, a household is composed of every individual who occupies a 
                singular residential unit as their primary residence, irrespective of their mutual connections. In {` ${resp.data[0].state}`}, household 
                configurations are chiefly segmented into four distinct types: individuals residing solo, 
                those cohabiting with minors below 18, households with all members being adults over 18, and those 
                living with at least one individual aged 60 or older. Delving into the specifics for {` ${resp.data[0].state}`}, {` ${resp.data[0].proportions[0].livingWith['Living Alone']}`} percent of households consist of solo dwellers, {` ${resp.data[0].proportions[0].livingWith['Living with Children under 18']}`} percent are homes with children younger than 18, and {`${resp.data[0].proportions[0].livingWith['Living with person 60 plus age']}`} percent accommodate an individual aged 60 or above.

                </p>

                <div className="d-flex align-items-center">
                  <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/house_1.svg' style={{width:"47px", marginRight:"12px"}} alt="household"/>
                  <h2 className="customh2fontsize"> Number of Individuals per Household in ZIP Codes of {`${resp.data[0].state}`}, US </h2>
                </div>
                <p className="mt-2 mb-3" >
                In the state of {`${resp.data[0].state}`}, {` ${resp.data[0].proportions[0].livingPerson["1 per"]}`} percent of households consist of just 
                one individual. Meanwhile, {`${(resp.data[0].proportions[0].livingPerson["2 per"] + resp.data[0].proportions[0].livingPerson["3 per"]).toFixed(2)}`} percent 
                represent households with two or three members. Additionally, {` ${resp.data[0].proportions[0].livingPerson["4 or more"]}`} percent of homes in the 
                state are inhabited by 4 or more individuals.

                </p>
              </div>
            )
            
            //=== HH unit structure type 
            setHhunit(
              <div className="p-2 text-justify">
                <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/building_1.svg' style={{width:"47px", marginRight:"12px"}} alt="housing unit"/>
                    <h2 className="customh2fontsize"> Housing Unit Structures of ZIP Codes in {`${resp.data[0].state}`}, US </h2>
                </div>
              <p className="mt-2 mb-2">

                A housing unit, also known as a dwelling unit, refers to a structure or the part of a structure that is used by an individual or household as a place of residence.
                In {`${resp.data[0].state}`}, detached single units account for {` ${resp.data[0].proportions[0].housingType['1 unit, detached']} `}
                 percent of housing units while  {` ${(resp.data[0].proportions[0].housingType['2 apartments']+resp.data[0].proportions[0].housingType['3 or 4 apartments']).toFixed(2)} `} percent 
                 have about 2 to 4 apartments, and 
                 {` ${(resp.data[0].proportions[0].housingType['5 or 9 apartments']+resp.data[0].proportions[0].housingType['10 or more apartments']).toFixed(2)} `} 
                 percent have a minimum of 5 apartments. 
              </p>
              </div> 
            )
            
            //===  Income + Age group 
            setAgeinc(
              <div className="p-2 text-justify">
                <div className="d-flex align-items-center">
                  <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/salary_1.svg' style={{width:"50px", marginRight:"12px"}} alt="age"/>
                  <h2 className="customh2fontsize"> Household Income for {`${resp.data[0].state}`} Postal Codes, US </h2>
                </div>
                <p className="mt-2 mb-3" >

                  The U.S. Census Bureau defines household income as the total gross income 
                  before taxes received in a 12-month period by all members of a household 
                  who are age 15 and above. Household income in the state of {`${resp.data[0].state}`} includes wage, salary, 
                  proceeds of self-employment, social security, pension, and income from any other source.
                  {` ${(resp.data[0].proportions[0].income['below-35,000'] + resp.data[0].proportions[0].income['35,000-49,999']).toFixed(2)} `}
                   percent of households in the state of {`${resp.data[0].state}`} earns a gross annual income below $50,000, 
                   {` ${(resp.data[0].proportions[0].income['50,000-99,999'] + resp.data[0].proportions[0].income['100,000-149,000']).toFixed(2)} `}
                    percent earns between $50,000 and $150,000, 
                  while the remaining {` ${resp.data[0].proportions[0].income['above-150,000']} `} percent accounts for high-income households earning above $150,000.

                </p>
                <div className="d-flex align-items-center">
                  <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/age-group_1.svg' style={{width:"50px", marginRight:"12px"}} alt="income"/>
                  <h2 className="customh2fontsize"> Age Distribution of {`${resp.data[0].state}`} ZIP Codes, US </h2>
                </div>
                <p className="mt-2 mb-2">
                Age distribution in population studies, which is also known as age composition, 
                refers to the frequency of persons in certain age groups in a given population. 
                It influences many macroeconomic equations in {`${resp.data[0].state}`} such as consumer behavior, consumer styles, 
                housing-investment, money-demand, labor-force-participation, and many more. 
                {` ${resp.data[0].proportions[0].age['10-24']}`} percent of {`${resp.data[0].state}`}’s population are 10 to 24 years of age, 
                {` ${resp.data[0].proportions[0].age['40-64']}`} percent are between 40 and 64 years, while {` ${resp.data[0].proportions[0].age['above 64']}`} percent are above 64 years
                </p>
                
              </div>
            )
      
            //=== race
            setRace(
              <div className="p-2 text-justify">
                <div className="d-flex align-items-center">
                  <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662210778/free-tools/ethnicity.png' style={{width:"49px", marginRight:"12px"}} alt="race"/>
                  <h2 className="customh2fontsize"> Ethnicity Groups in {`${resp.data[0].state}`}, US </h2>
                </div>
                <p className="mt-2 mb-2">

                  The U.S. Census Bureau used a self-identification method to collect racial data from {`${resp.data[0].state}`}'s residents. 
                  The census questionnaire allows people to choose their racial category from White, Black or African American, American Indian or Alaska Native, Asian, 
                  and Native Hawaiian or Other Pacific Islander. 
                  {` ${resp.data[0].proportions[0].races['White']}`} percent of {`${resp.data[0].state}`}’s population are white, 
                  {` ${resp.data[0].proportions[0].races['Black or African American alone']} `} percent accounts for Black or African American, 
                  while {` ${resp.data[0].proportions[0].races['Asian alone']}`} percent account for Asians. 


                </p>
              </div>
            )
          //===  Education
          setEducation(
            <div className="p-2 text-justify">
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1665248778/free-tools/education.svg' style={{width:"50px", marginRight:"14px"}} alt="education"/>
                <h2 className="customh2fontsize"> Education Attainment in {`${resp.data[0].state}`}, US </h2>
              </div>
              <p className="mt-2 mb-3" >
              Education attainment refers to the highest degree or level of education an individual has achieved. 
              The U.S. Census Bureau reports the educational attainment data on three different levels which are School level, Bachelor, and Graduate level education. 
              {` ${resp.data[0].proportions[0].education['School level']}`} percent of {`${resp.data[0].state}`}’s population have completed the school level education 
              while {` ${resp.data[0].proportions[0].education['Bachelor']}`} percent have earned a Bachelor’s degree. 
              {` ${resp.data[0].proportions[0].education['Graduate']}`} percent of the populace in the state of {`${resp.data[0].state} `}  
              have graduate study degrees.  
              </p>   
            </div>
          )
          
          //===  occupation 
          setOccupations(
            <div className="p-2 text-justify">
            <div className="d-flex align-items-center">
              <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1665249595/free-tools/occupation.png' style={{width:"49px", marginRight:"14px"}} alt="occupations"/>
              <h2 className="customh2fontsize"> Employment by Industry Sector in {`${resp.data[0].state}`}, US </h2>
            </div>
            <p className="mt-2 mb-2">
            In the state of {`${resp.data[0].state}`} , {`${resp.data[0].proportions[0].occupations['Educational services, and health care and social assistance']}`}  percent of the workforce 
            is employed in Educational services, and health care and social assistance
            sector, {`${resp.data[0].proportions[0].occupations['Construction']}`} percent in Construction and 
            {` ${resp.data[0].proportions[0].occupations['Manufacturing']} `} percent in Manufacturing. 
            The Industry sector "Professional, scientific, and management, and administrative and waste management services" 
            which includes some of the highest paid proffessions such as Software Developers, Other Engineering Managers account 
            for {`${resp.data[0].proportions[0].occupations['Professional, scientific, and management, and administrative and waste management services']} `} 
            percent in the state of {`${resp.data[0].state}`}.
            </p>
          </div>
        )

        setTextpopulatedZip (
          <div className="p-2 text-justify">
              <>
                <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1666108937/free-tools/populatedzips.svg' style={{width:"49px", marginRight:"12px"}} alt="population"/>
                    <h2 className="customh2fontsize"> Most Populated ZIP Code Areas in {`${resp.data[0].state}`}, US </h2>
                </div>
                <div className="text-justify">
                    <p className="mt-2 mb-2"> 
                          With {`${ nf.format(popzipcodes[0]?.population )} `} residents, {`${ popzipcodes[0]?.plz } `} is the most populated ZIP Code in the state of {`${resp.data[0].state}`}, 
                          while {`${ popzipcodes[1]?.plz } `} is the second most populous ZIP Code with a population of {`${ nf.format(popzipcodes[1]?.population )} `}. 
                          The top ten most populous ZIP Codes in {`${resp.data[0].state} `} are - { _.map(popzipcodes, 'plz').join(' , ')}.

                    </p>
                    <div className='state-city mt-1 mb-3 justify-content-left align-items-center'>    
                        {
                            popzipcodes.map((item) => { 
                                return <div key={item.key} >
                                            <a href={`/us/${item.plz}/zip-code/${item.lng}/${item.lat}`}> {item.plz} </a> 
                                  </div>
                                            
                            })  
                          }
                    </div>
                    <hr className=" mb-2"/>
                    <div className="mt-2 hover-underline-animation  dashboard-content-wrapper-link" style={{borderBottom: "1.2px solid #007bff"}}>
                      <a href={`/browse/us/${ encodeURIComponent(loc.statekey)}/zip-codes`}> List of all ZIP codes in {`${resp.data[0].state}`} </a>
                    </div>
                </div>     
              </>
          </div>
        )

        setTextpopulatedCities (
          <div className="p-2 text-justify">
              <>
                <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1666108945/free-tools/populatedcity.svg' style={{width:"49px", marginRight:"12px"}} alt="population"/>
                    <h2 className="customh2fontsize"> Most Populated City Areas in {`${resp.data[0].state}`}, US </h2>
                </div>
                <div className="text-justify">
                    <p className="mt-2 mb-2"> 
                          With {` ${ nf.format(popcities[0]?.population )} `} residents, {` ${ popcities[0]?.city } `} is the most populated city in the state of {` ${resp.data[0].state} `}. 
                          {` ${ popcities[1]?.city } `} has a population of {`${ nf.format(popcities[1]?.population )} `}, 
                          making it the second most populous city. 
                          The top ten most populous cities in {`${resp.data[0].state} `} are - { _.map(popcities, 'city').join(' , ')}.
                    </p>
                    <div className='state-city mt-1 mb-3 justify-content-left align-items-center'>    
                        {
                                    
                          popcities.map((item) => { 
                              return <div key={item.citykey}>
                                        <a href={`/us/${encodeURIComponent(loc.statekey)}/${encodeURIComponent(item.citykey)}/zip-code-map`}> {item.city} </a>  
                                  </div>
                                
                          })
                         
                        }
                    </div>
                </div>
                <hr className="mb-2"/> 
                <div className="mt-2 hover-underline-animation dashboard-content-wrapper-link" style={{borderBottom: "1.2px solid #007bff"}}>
                    <a href={`/browse/us/${ encodeURIComponent(loc.statekey)}/cities`} > List of all city areas in {`${resp.data[0].state}`}  </a>
                </div>
              </>
          </div>
        )


        } catch (error) {
              setLoading(false)
          if (error.response.status == 429) {
              setLimitExceeded(true)
          } else {
            setException(true)
          }
        }
      }

      getData() ;

  }, [])
  
  const limitwall= <React.Fragment>
      <div className="mt-3 mb-4 text-center " >
          <img style={{width:'100%' , maxWidth:'550px'}} src='https://res.cloudinary.com/zipscores-collection/image/upload/v1663332859/11132.jpg' alt="limits"/>
          <h2 className="mt-1 mb-5 text-center "> Please log in to view {`${state}`} data </h2>
      </div>
    </React.Fragment>

  const authwall= <React.Fragment>   
      <p className="mt-3 mb-4 text-center "> Register for a free account to accesss all the features of Zipscore.AI. </p>
    </React.Fragment>

const authwall2 = <React.Fragment>
    <div className="d-flex align-items-center">
        <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662816675/free-tools/highincome.svg' style={{width:"49px", marginRight:"12px"}} alt="authentication "/>
        <h2 className="customh2fontsize"> High Household Income Cities in {`${state}`} </h2>
    </div>
    <hr/>
    <p className="mt-2 mb-3 text-center "> Register for a free account to view the high income city areas in {`${state}`} and other US states. </p>
</React.Fragment>


  if(exception) return <Redirect to="/404"/> ;
  if (getCurrentUser()) history.push(`/lookup/us/${loc.statekey}`)
      
  return (
    <>
    <Sticky />
    <div className='content-wrapper'>
      <OneTap />
      <Header />
      {/* <Helmet><link rel="canonical" href= "https://www.zipscore.ai/zip-code-map"/></Helmet> */}
      {/* <Helmet link = {[{"rel" : "canonical", "href" : "https://www.zipscore.ai/zip-code-map" }]}></Helmet> */}
      { dts.length > 0 && Object.keys(attributes).length && !limitExceeded && !loading &&
        <>
          <Helmet> <title> {`${attributes.state}, US`} </title></Helmet>
          <DashboardInfo 
            title={`${attributes.state}, US`} 
            description={titletext} 
            link1={`/browse/us/${ encodeURIComponent(attributes.statekey)}/zip-codes`} 
            link1title={`Demographics by ZIP codes in ${attributes.state}`}
            link2={`/browse/us/${ encodeURIComponent(attributes.statekey) }/cities`} 
            link2title={`Demographics by Cities in ${attributes.state}`}
          />
          <Map dts={dts}  areas={areas} center={attributes.center} country={'us'} attributes={attributes} zoom={6} />
          <Info description={textpopulatedzip} />
          <Info description={living} />
          <LivingWith dts={dts} />
          <Authwall description={authwall}/>
          <Info description={hhunit} />
          <ApartMent dts={dts} />
          <Demowall title={`Predict success of your business in ${attributes.state}.`}/>
          <Info description={ageinc} />
          <Household dts={dts} />
          <Info description={education} />
          <Education dts={dts} />
          <Info description={occupations} />
          <Occupations dts={dts} />
          <Info description={race} />
          {/* <Education dts={dts} /> */}
          <Races dts={dts} />
          <Info description={textpopulatedcities} />
          <Authwall description={authwall2} />
        </>
      }
      { loading && dts.length == 0 && !limitExceeded && <LoadingPage /> }
      { dts.length == 0 && limitExceeded && !loading && <Limitwall description={limitwall}/> }
    </div>
    <Footer />
    </>
  )
}

export default Main ; 