import React, { useEffect, useState } from 'react';
import _ from "lodash" ; 
import { useParams , Redirect , useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import '../style.css';
import NavBar from "../../../../common/NavBar/navbar.jsx" ; 
import DashboardInfo from "../../../datas/us/DashboardInfo/index.js" ; 
import Info from "../../../datas/us/Info/index.js" ; 
import Authwall from "../../../datas/us/Authwall/index.js"
import Demowall from "../../../datas/us/Demowall/index.js"

import LoadingPage from '../../../../common/tools-ui/loadingPage';
import { getStateCitiesAuth } from "../../../../../services/browseService" ; 
import Tabular from "../nonauth/tables/city-table"

const Browsecity = () => {

    const [state, setState ] = useState('');
    const [statekey, setStatekey]= useState(useParams().statekey); 
    const [cities, setCities]= useState([]);
    const [populated, setPopulated]= useState([]) ; 
    const [youngest, setYoungest]= useState([]);
    const [oldest, setOldest]= useState([]);
    const [wealthiest, setWealthiest]= useState([]) ;

    const [loading, setLoading] = useState(true) ; 
    const [exception, setException] = useState(false) ; 
    const history = useHistory() ; 

    const nf = Intl.NumberFormat();

    useEffect(() => {
        getCities()
    }, [])

    const getCities = async() => {
        try {
            const resp = await getStateCitiesAuth("us", encodeURIComponent(statekey))
            setLoading(false)
            setState(resp.data[0].all[0].state)
            setCities([...resp.data[0].all])
            setPopulated([...resp.data[0].toppopulated])
            setYoungest([...resp.data[0].topyoungest])
            setOldest([...resp.data[0].topoldest])
            setWealthiest([...resp.data[0].topwealthiest])
        } catch (error) {
            setLoading(false)
            setException(true)
        }

    }

    const text1= <React.Fragment>     
        <div className="text-justify">
            <p className="mt-3 mb-2">
            The demographic data of the cities in {`${state}`} documents income, employment status, household income, 
            residents, housing units, total population, and other demographic data that Marketing Researchers, Data Analysts and Data Scientists can utilize. 
            Product development and marketing efforts can be targeted, precise, and generate more significant ROI when a company can segment 
            the city areas in the state of {`${state}`} by it demographics. While many other websites only allow a basic city demographics lookup feature, our powerful features provide insightful analytics into the location data. 
            To access these analytics, you can click on any of the cities from the lists below.
            </p>
        </div>
    </React.Fragment>

    const textpopulated= <React.Fragment>
            <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806287/free-tools/mostpopulated.svg' style={{width:"49px", marginRight:"12px"}}/>
                <h5>Most Populated Cities in {`${state}`} </h5>
            </div>
            <div className="text-justify">
                <p className="mt-3 mb-2"> 
                        With {` ${ nf.format(populated[0]?.population )} `} residents, 
                        {` ${ populated[0]?.city } `} is the most populated city in the state of {` ${state} `}. 
                        {` ${ populated[1]?.city } `} has a population of {`${ nf.format(populated[1]?.population )} `}, making it the second most populous city. 
                        The top ten most populous cities in {`${state} `} are - { _.map(populated, 'city').join(' , ')}.
                </p>
                <hr/>
                <div className='state-city mt-4 justify-content-left align-items-center'>             
                    {
                        populated?.map((item) => { 
                            return <div key={item.citykey}>
                                      <a href={`/lookup/us/${encodeURIComponent(statekey)}/${encodeURIComponent(item.citykey)}`}> {item.city} </a>  
                                </div>
                        })  
                    }
                </div>
            </div>
        </React.Fragment>

        const textyoungest= <React.Fragment>
                <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806442/free-tools/mostyoungest.svg' style={{width:"49px", marginRight:"12px"}}/>
                    <h5> Youngest Population cities by ZIP Codes in {`${state}`} </h5>
                </div>
                <div className="text-justify">
                    <p className="mt-3 mb-2"> 
                            Out of all the cities in {`${state}`} which have population of at least 18,674 (average population of city areas in the US) ,
                            the city {` ${ youngest[0]?.city } `} have highest proportion of population younger than 25 years of age.
                            The top 10 youngest cities in the state of {`${state}`} that have at least 18,674 residents 
                            are - { _.map(youngest, 'city').join(' , ')}.
                    </p>
                    <hr/>
                    <div className='state-city mt-4 justify-content-left align-items-center'>             
                        {
                            youngest?.map((item) => { 
                                return <div key={item.citykey}>
                                    <a href={`/lookup/us/${encodeURIComponent(statekey)}/${encodeURIComponent(item.citykey)}`}> {item.city} </a>  
                                </div>
                            })  
                        }
                    </div>
                </div>
            </React.Fragment>

    
        const textoldest= <React.Fragment>
                        <div className="d-flex align-items-center">
                            <img alt="" src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806778/free-tools/mostoldest.svg' style={{width:"49px", marginRight:"12px"}}/>
                            <h5> Oldest Population Cities in {`${state}`}  </h5>
                        </div>
                        <div className="text-justify">
                            <p className="mt-3 mb-2"> 
                                Out of all the cities in {`${state}`} which have population of at least 18,674 (average population of city areas in the US) ,
                                the city {` ${ oldest[0]?.city } `} have highest proportion of population 65 years of age and older.
                                The top 10 oldest city areas in the state of {`${state}`} that have at least 18,674 residents 
                                are - { _.map(oldest, 'city').join(' , ')}.
                            </p>
                            <hr/>
                            <div className='state-city mt-4 justify-content-left align-items-center'>             
                                {
                                    
                                    oldest?.map((item) => { 
                                        return <div key={item.citykey}>
                                            <a href={`/lookup/us/${encodeURIComponent(statekey)}/${encodeURIComponent(item.citykey)}`}> {item.city} </a>  
                                        </div>
                                    }) 
                                    
                                }
                            </div>
                        </div>
                </React.Fragment>

        
        const textwealthiest = <React.Fragment>
                         <div className="d-flex align-items-center">
                         <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662816675/free-tools/highincome.svg' style={{width:"49px", marginRight:"12px"}} alt="hh income"/>
                             <h5> High Household Income Cities in {`${state}`}  </h5>
                         </div>
                         <div className="text-justify">
                             <p className="mt-3 mb-2">
                                 Out of all the cities in {`${state}`} which have at least 6,946 households (average number of households in the city) ,
                                 the city {` ${ wealthiest[0]?.city } `} have highest proportion of households that have gross annual income of $150,000 and above.
                                 The {` ${ wealthiest[0]?.city } `} city have {`${(wealthiest[0]?.above_150k_pct*100).toFixed(2)}`} percent households with gross annual income more than $150,000
                                 {
                                    wealthiest.length > 1 ?
                                    <>
                                    {' '}whereas 
                                    the {` ${ wealthiest[1]?.city } `} city, which is second most wealthiest city in the state, have {`${(wealthiest[1]?.above_150k_pct*100).toFixed(2)}`} percent households
                                    in this income group.{' '}
                                    </>
                                    : <>{' . '}</>
                                 }

                                 The top 10 high household income city areas in the state of {`${state}`} that have at least 6,946 households 
                                 are - { _.map(wealthiest, 'city').join(' , ')}.
                             </p>
                             <hr/>
                             <div className='state-city mt-4 justify-content-left align-items-center'>             
                                 {
                                     wealthiest?.map((item) => { 
                                         return <div key={item.citykey}>
                                             <a href={`/lookup/us/${encodeURIComponent(statekey)}/${encodeURIComponent(item.citykey)}`}> {item.city} </a>  
                                         </div>
                                     })  
                                 }
                             </div>
                         </div>
        </React.Fragment>

    const textlist= <React.Fragment>
            <div className="d-flex align-items-center">
                <img alt="" src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662810349/free-tools/list.svg' style={{width:"47px", marginRight:"12px"}}/>
                <h5> Cities in {`${state}`} </h5> 
            </div>
            <div className="text-justify">
                <p className="mt-3 mb-2"> 
                    According to the US Census Bureau, the state of {`${state}`} has about {Object.keys(cities).length} city areas.  
                    {` ${state}`}'s population and households are shown in the following table, along with its ZIP Codes (ZIP Code Tabulation Areas).
                    You can download the {`${state}`} cities list in Excel or CSV format for free from the  link below.
                </p>
                <hr/>
                <div className=' mt-4 justify-content-left align-items-center'>             
                    {
                       <Tabular data={cities && cities}/>
                        /*
                        cities?.map((item) => { 
                            return <div key={item.citykey}>
                                <a href={`/lookup/us/${encodeURIComponent(statekey)}/${encodeURIComponent(item.citykey)}`}> {item.city} </a>  
                            </div>
                        })
                        */ 
                    }
                </div>
            </div>
        </React.Fragment>

    if(exception) return <Redirect to="/404"/> ; 

    return (
        <div className='content-wrapper'>
            <NavBar isAdd={true} bgwhite={true}/>
            <Helmet> 
               <title> {`Lookup | ${state} ZIP Codes by Cities, US `} </title> 
            </Helmet>
            <DashboardInfo 
                title={`ZIP Codes by Cities of ${state}`} 
                description={text1} 
                link1={`/lookup/us/${ encodeURIComponent(statekey) }`} 
                link1title={`Demographics of ${state}`}
                link2={`/lookup/browse/us/${ encodeURIComponent(statekey) }/zipcodes`} 
                link2title={`Demographics by ZIP codes in ${state}`}    
            />
            {
                !loading && 
                <>
                    <Info description={textpopulated}/>
                    {/* INCLUDE WEALTHIEST INCOME ZIP CODES <Authwall description={authwall}/> */}
                    <Info description={textwealthiest}/> 
                    <Demowall title={`Predict success of your business in ${state}.`}/>
                    <Info description={textyoungest}/>
                    <Info description={textoldest}/> 
                    <Info description={textlist}/>
                </>
            }
            { loading && <LoadingPage /> }
        </div>
    )
}

export default Browsecity ; 