import React , { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link , Redirect , useHistory} from 'react-router-dom';
import { Message , Loader } from 'rsuite';
import { resendConfirmationLink , getCurrentUser } from '../../services/userService';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import NavBar from '../common/NavBar/navbar-center';

const schema = yup.object({
    email: yup.string().email('Invalid email format').required('Required') 
}).required();

const ResendConfirmation = () => {

    const history = useHistory() ; 
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const [loading, setLoading ] = useState(false)
    const [message, setMessage]= useState("")
    const [success, setSuccess]= useState(true)
    
    const onSubmit = async(data) => {
    
        try {
            setLoading(true);
            const resp = await resendConfirmationLink(data.email) ; 
            if (resp.status == 200) {
              setMessage(`Email with instructions sent to ${data.email}. We've sent you confirmation email. If the e-mail hasn't arrived within few minutes, please check your spam, bulk or junk mail folder to locate it`);

              // redirect to 
              /* Didn't receive an email yet?
                Check your spam folder, resend, or contact us.
              */ 

            }
            setLoading(false);
          } catch (ex) {
            setSuccess(false) ; 
            setLoading(false);
            if (ex.response && ex.response.status === 400) {
              setMessage(ex.response.data);
            }
          }
    }

    if (getCurrentUser()) history.push("/home")

    return (
        <div id='Forgot-password' className="signIn-Page">
            <NavBar />
            <div className="Forgot-password-inner">
                <div className='Forgot-password-title-block'>
                    {/* <Message type="info" className='mb-5 text-center'> <h3> {message} </h3> </Message> */}
                    
                    {
                        message && 
                        <>
                            {
                                !success ? 
                                <div className="alert alert-danger mb-3" role="alert">
                                    {message}
                                </div>
                                :
                                <div className="alert alert-success" role="alert">
                                    {message}
                                </div>

                            }
                        </>
                    }
                    
                    <h3 className='mb-4'>
                        Resend confirmation instructions
                    </h3>
                    <p className="mb-1">Enter your email address below and we'll resend the instructions.</p>

                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-forgotpassword'>
                        <div className="input-icons">
                            <i className="fa fa-envelope icon">
                            </i>
                            
                            <input className="input-field"
                                type="text"
                                {...register("email", { required: true })}
                                placeholder='name@example.com' />
                            {errors.email && <span className='errorTextForm'> {errors.email.message} </span>}
                        </div>
                        <button type="submit" className='submitbtn'>
                            { !loading && <i className="fa fa-arrow-right"></i> }
                            { loading && <Loader /> }
                        </button>
                    </div>

                </form>
                <div className='sign-In-link-block mt-3 text-center'>
                    <Link to='/login'>
                        Sign In
                    </Link>
                    {/* <Link to='/signup'>Sign Up</Link> */}
                </div>


            </div>

        </div>
    )
}

export default ResendConfirmation ; 
