import React, { useEffect, useState } from 'react';
import _ from "lodash" ; 
import { useParams , Redirect , useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import '../style.css';
import Header from "../../../datas/us/Header"
import DashboardInfo from "../../../datas/us/DashboardInfo/index.js" ; 
import Info from "../../../datas/us/Info/index.js" ; 
import Authwall from "../../../datas/us/Authwall/index.js"
import Demowall from "../../../datas/us/Demowall/index.js"
import { getStateCities } from "../../../../../services/browseService" ; 
import LoadingPage from '../../../../common/tools-ui/loadingPage';
import { getCurrentUser } from '../../../../../services/userService';
import Tabular from "./tables/city-table"
import Footer from "../../../../landing-page/Footer";
import Sticky from '../../../../common/StickyHeader';

const Browsecity = () => {

    const [state, setState ] = useState('');
    const [statekey, setStatekey]= useState(useParams().statekey); 
    const [cities, setCities]= useState([]);
    const [populated, setPopulated]= useState([]) ; 
    const [youngest, setYoungest]= useState([]);
    const [oldest, setOldest]= useState([]);
    const [richest, setRichest]= useState([]) ;

    const [loading, setLoading] = useState(true) ; 
    const [exception, setException] = useState(false) ; 
    const history = useHistory() ; 

    const nf = Intl.NumberFormat();

    useEffect(() => {
        getCities()
    }, [])

    const getCities = async() => {

        try {
            const resp = await getStateCities("us", encodeURIComponent(statekey))
            setLoading(false)
            setState(resp.data[0].all[0].state)
            setCities([...resp.data[0].all])
            setPopulated([...resp.data[0].toppopulated])
            setYoungest([...resp.data[0].topyoungest])
            setOldest([...resp.data[0].topoldest])
        } catch (error) {
            setLoading(false)
            setException(true)
        }

    }

    const text1= <React.Fragment>     
        <div className="text-justify">
            <p className="mt-3 mb-2">

            Household income, employment status, 
            residents, housing units, total population, population distribution, 
            ethnicity distribution, amongst others, 
            are the demographic data of the cities in {`${state}`} reported by the U.S. Census Bureau. 
            These data are useful to Data Analysts, Marketing Researchers, Data Scientists, 
            and even companies planning to execute targeted marketing campaigns 
            in the State of {`${state}`} to improve sales efficiency. 
            Many websites allow you to check the basic demographics of the cities in {`${state}`} but our powerful feature gives 
            you access to insightful analytics on each city’s data.

            </p>
        </div>
    </React.Fragment>

    const textpopulated= <React.Fragment>
            <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806287/free-tools/mostpopulated.svg' style={{width:"49px", marginRight:"12px"}} alt="population"/>
                <h2 className="customh2fontsize">Most Populated Cities in {`${state}`} </h2>
            </div>
            <div className="text-justify">
                <p className="mt-3 mb-2"> 

                        {`${ populated[0]?.city } `}, with {` ${ nf.format(populated[0]?.population )} `} residents, has the 
                        highest population of all the cities in the state of {`${state}`}, 
                        while {` ${ populated[1]?.city } `} with a population of {`${ nf.format(populated[1]?.population )} `} is the second most populous. 
                        The top ten most populous cities in {`${state} `} are - { _.map(populated, 'city').join(' , ')}.
                </p>
                <hr/>
                <div className='state-city mt-4 justify-content-left align-items-center'>             
                    {
                        populated?.map((item) => { 
                            return <div key={item.citykey}>
                                      <a href={`/us/${encodeURIComponent(statekey)}/${encodeURIComponent(item.citykey)}/zip-code-map`}> {item.city} </a>  
                                </div>
                        })  
                    }
                </div>
            </div>
        </React.Fragment>

        const textyoungest= <React.Fragment>
                <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806442/free-tools/mostyoungest.svg' style={{width:"49px", marginRight:"12px"}} alt="young" />
                    <h2 className="customh2fontsize"> Youngest Population cities by ZIP Codes in {`${state}`} </h2>
                </div>
                <div className="text-justify">
                    <p className="mt-3 mb-2"> 

                            The average population of city areas in the United States is 18,674. 
                            Out of all the cities in {`${state}`} with at least the US average city population, 
                            {` ${ youngest[0]?.city } `} has the highest percentage of population aged 25 and below. 
                            The top 10 youngest cities in the state of {`${state}`} that have 
                            at least 18,674 residents are - { _.map(youngest, 'city').join(' , ')}.
                    </p>
                    <hr/>
                    <div className='state-city mt-4 justify-content-left align-items-center'>             
                        {
                            youngest?.map((item) => { 
                                return <div key={item.citykey}>
                                     <a href={`/us/${encodeURIComponent(statekey)}/${encodeURIComponent(item.citykey)}/zip-code-map`}> {item.city} </a>  
                                 </div>
                            })  
                        }
                    </div>
                </div>
            </React.Fragment>

    
        const textoldest= <React.Fragment>
                        <div className="d-flex align-items-center">
                            <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806778/free-tools/mostoldest.svg' style={{width:"49px", marginRight:"12px"}} alt="old"/>
                            <h2 className="customh2fontsize"> Oldest Population Cities in {`${state}`}  </h2>
                        </div>
                        <div className="text-justify">
                            <p className="mt-3 mb-2"> 

                                {` ${ oldest[0]?.city } `} has the highest percentage of residents aged 65 and 
                                above out of all {`${state}`} cities 
                                that has a minimum population of 18,674 (average population of city areas in the US). 
                                The top 10 oldest city areas in the state of {`${state} `} are - { _.map(oldest, 'city').join(' , ')}.
                            </p>
                            <hr/>
                            <div className='state-city mt-4 justify-content-left align-items-center'>             
                                {
                                    oldest?.map((item) => { 
                                         return <div key={item.citykey}>
                                                    <a href={`/us/${encodeURIComponent(statekey)}/${encodeURIComponent(item.citykey)}/zip-code-map`}> {item.city} </a>  
                                             </div>
                                    })  
                                }
                            </div>
                        </div>
                </React.Fragment>

    const textlist= <React.Fragment>
            <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662810349/free-tools/list.svg' style={{width:"47px", marginRight:"12px"}} alt="list of cities"/>
                <h2 className="customh2fontsize"> Cities in {`${state}`} </h2> 
            </div>
            <div className="text-justify">
                <p className="mt-3 mb-2"> 
                    According to the US Census Bureau, the state of {`${state}`} has about {Object.keys(cities).length} city areas.  
                    {` ${state}`}'s population and households are shown in the following table, along with its ZIP Codes (ZIP Code Tabulation Areas).
                    You can download the {`${state}`} cities list in Excel or CSV format for free from the  link below.
                </p>
                <hr/>
                <div className=' mt-4 justify-content-left align-items-center'>    
                    {
                        
                        <Tabular data={cities && cities}/>
                        /*
                        cities?.map((item) => { 
                             return <div key={item.citykey}>
                                     <a href={`/us/${encodeURIComponent(statekey)}/${encodeURIComponent(item.citykey)}/zip-code-map`}> {item.city} </a> 
                                </div>
                        })
                        */ 
                    }
                </div>
            </div>
        </React.Fragment>

    const authwall= <React.Fragment>
            <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662816675/free-tools/highincome.svg' style={{width:"49px", marginRight:"12px"}} alt="authentication "/>
                <h2 className="customh2fontsize"> High Household Income Cities in {`${state}`} </h2>
            </div>
            <hr/>
            <p className="mt-2 mb-3 text-center "> Register for a free account to view the high income cities in {`${state}`} and other US states. </p>
        </React.Fragment>
    
    if (exception) return <Redirect to="/404"/> ; 
    if (getCurrentUser()) history.push(`/lookup/browse/us/${statekey}/cities`)             

    return (
        <>
            <Sticky />
            <div className='content-wrapper'>
                <Header />
                {
                    !loading && 
                    <>
                        <Helmet> 
                            <title> {`${state} ZIP Codes by Cities, US`} </title> 
                        </Helmet>
                        <DashboardInfo 
                            title={`ZIP Codes by Cities of ${state}`} 
                            description={text1} 
                            link1={`/us/${ encodeURIComponent(statekey)}/zip-code-map`} 
                            link1title={`Demographics of ${state}`}
                            link2={`/browse/us/${ encodeURIComponent(statekey) }/zip-codes`} 
                            link2title={`Demographics by ZIP codes in ${state}`}    
                        />
                        <Info description={textpopulated}/>
                        <Authwall description={authwall}/>
                        <Info description={textyoungest}/>
                        <Demowall title={`Predict success of your business in ${state}.`}/>
                        <Info description={textoldest}/> 
                        <Info description={textlist}/>
                    </>
                }
                { loading && <LoadingPage /> }
            </div>
            <Footer />
        </>
    )
}

export default Browsecity ; 
