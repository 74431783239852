import L from "leaflet"

/*
export const IndicatorIcon = L.icon({
  // iconUrl: require("./indicator_icon.svg"),
  // iconRetinaUrl: require("../assets/venue_location_icon.svg"),
  iconUrl: require("./blueDot.png"),
  iconRetinaUrl: require("./blueDot.png"),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [35, 35],
  className: "leaflet-venue-icon",
})
*/
export const IndicatorIcon = L.icon({
  iconUrl: require("./livelocation.svg"),
  iconRetinaUrl: require("./livelocation.svg"),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [35, 35],
  className: "leaflet-venue-icon",
})
