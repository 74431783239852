import React, { useEffect, useState } from 'react';
import { useParams , useHistory, Redirect } from "react-router-dom";
import _ from "lodash"

import '../style.css';
import NavBar from "../../../../common/NavBar/navbar.jsx"
import DashboardInfo from "../../../datas/us/DashboardInfo/index.js" ; 
import Info from "../../../datas/us/Info/index.js" ; 
import Demowall from "../../../datas/us/Demowall/index.js"
import { Helmet } from "react-helmet";
import { getStateZipcodesAuth } from "../../../../../services/browseService"
import LoadingPage from '../../../../common/tools-ui/loadingPage';
import Tabular from "../nonauth/tables/zipscode-table" ; 

const Browsezipcode = () => {

    const [statekey, setStatekey ] = useState(useParams().statekey);
    const [state, setState ] = useState('');
    const [zipcodes, setZipcodes]= useState([])
    const [populated, setPopulated]= useState([])
    const [youngest, setYoungest]= useState([])
    const [oldest, setOldest]= useState([])
    const [wealthiest, setWealthiest]= useState([])

    const [loading, setLoading] = useState(true) ; 
    const [exception, setException] = useState(false) ; 
    const history = useHistory() ; 
    const nf = Intl.NumberFormat();

    useEffect(() => {
        getZipcodes()
    }, [])

    const getZipcodes = async() => {
        try {
            const resp = await getStateZipcodesAuth("us", encodeURIComponent(statekey))
            setLoading(false)
            setState(resp.data[0].all[0].state)
            setZipcodes([...resp.data[0].all])
            setPopulated([...resp.data[0].toppopulated])
            setYoungest([...resp.data[0].topyoungest])
            setOldest([...resp.data[0].topoldest])
            setWealthiest([...resp.data[0].topwealthiest])
        } catch (error) {
            setLoading(false)
            setException(true)
        }
    }

    const text1= <React.Fragment>     
        <div className="text-justify">
            <p className="mt-2 mb-2">
            Explore comprehensive demographic details and ZIP Code directories for {`${state}`}. 
            Delve into key metrics such as income levels, employment statistics, household earnings, resident counts, housing 
            unit numbers, total population figures, and more. This rich data is invaluable for Marketing Professionals, Data Analysts, and 
            Data Scientists seeking to refine product strategies and optimize marketing campaigns. By segmenting ZIP codes 
            in {`${state}`} based on these demographics, businesses can achieve sharper targeting and better ROI. 
            Unlike other platforms that offer only basic ZIP code lookups, our platform presents deep-dive analytics 
            into location-specific data. Access these insights by selecting any ZIP code from the list below.
            </p>
        </div>
    </React.Fragment>

    const textpopulated= <React.Fragment>
            <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806287/free-tools/mostpopulated.svg' style={{width:"49px", marginRight:"12px"}}/>
                <h5> {`${state}`} Most Populated ZIP Codes </h5>
            </div>
            <div className="text-justify">
                <p className="mt-2 mb-2"> 
                        With {`${ nf.format(populated[0]?.population )} `} residents, {`${ populated[0]?.plz } `} is the most populated ZIP Code in the state of {`${state} `}. 
                        {`${ populated[1]?.plz } `} has a population of {`${ nf.format(populated[1]?.population )} `}, making it the second most populous ZIP Code. 
                        The top ten most populous ZIP Codes in {`${state} `} are - { _.map(populated, 'plz').join(' , ')}. 
                        <br/>

                </p>
                <hr/>
                <div className='state-city mt-4 justify-content-left align-items-center'>             
                    {
                        populated?.map((item) => { 
                            return <div key={item.key}>
                                        <a href={`/lookup/us/${item.plz}/${item.lng}/${item.lat}`}> {item.plz} </a> 
                                </div>
                        })  
                    }
                </div>
            </div>
    </React.Fragment>

    const textyoungest= <React.Fragment>
    <div className="d-flex align-items-center">
        <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806442/free-tools/mostyoungest.svg' style={{width:"49px", marginRight:"12px"}}/>
        <h5> Youngest population ZIP Codes in {`${state}`} </h5>
    </div>
    <div className="text-justify">
        <p className="mt-2 mb-2"> 
                Out of all the ZIP Codes in {`${state}`} which have population of at least 9,842 (average population in US ZIP Code areas) ,
                the ZIP Code {` ${ youngest[0]?.plz } `} have highest proportion of population younger than 25 years of age.
                The top 10 youngest ZIP Codes in the state of {`${state}`} that have at least 9,842 residents 
                are - { _.map(youngest, 'plz').join(' , ')}. Many of these ZIP Codes are home to universities.
        </p>
        <hr/>
        <div className='state-city mt-4 justify-content-left align-items-center'>             
            {
                youngest?.map((item) => { 
                    return <div key={item.key}>
                                <a href={`/lookup/us/${item.plz}/${item.lng}/${item.lat}`}> {item.plz} </a> 
                        </div>
                })  
            }
        </div>
    </div>
    </React.Fragment>

    const textoldest= <React.Fragment>
    <div className="d-flex align-items-center">
        <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806778/free-tools/mostoldest.svg' style={{width:"49px", marginRight:"12px"}}/>
        <h5> {`${state}`} Oldest population ZIP Code List </h5>
    </div>
    <div className="text-justify">
        <p className="mt-2 mb-2"> 
                Out of all the ZIP Codes in {`${state}`} which have population of at least 9,842 (average population in US ZIP Code areas),
                the ZIP Code {` ${ oldest[0]?.plz } `} have highest proportion of population 65 years of age and older.
                The top 10 oldest ZIP Codes in the state of {`${state}`} that have at least 9,842 residents 
                are - { _.map(oldest, 'plz').join(' , ')}.
                You can check the oldest populated Arizona Zip Code List below - 
        </p>
        <hr/>
        <div className='state-city mt-4 justify-content-left align-items-center'>             
            {
                oldest?.map((item) => { 
                    return <div key={item.key}>
                                <a href={`/lookup/us/${item.plz}/${item.lng}/${item.lat}`}> {item.plz} </a> 
                        </div>
                })  
            }
        </div>
    </div>
    </React.Fragment>

const textwealthiest = <React.Fragment>
    <div className="d-flex align-items-center">
    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662816675/free-tools/highincome.svg' style={{width:"49px", marginRight:"12px"}} alt="hh income"/>
        <h5> High Household Income ZIP Codes in {`${state}`}  </h5>
    </div>
    <div className="text-justify">
        <p className="mt-3 mb-2">
            Out of all the ZIP Codes in {`${state}`} which have at least 3,661 households (average number of households in a ZIP Code) ,
            the ZIP Code {` ${ wealthiest[0]?.plz } `} have highest proportion of households that have annual gross income of $150,000 and above.
            The {` ${ wealthiest[0]?.plz } `} ZIP Code have {`${wealthiest[0]?.above_150k_pct}`} percent households with gross annual income more than $150,000 whereas 
            the ZIP Code {` ${ wealthiest[1]?.plz } `} , which is second most wealthiest ZIP Code in the state, have {`${wealthiest[1]?.above_150k_pct}`} percent households
            in this income group.
            The top 10 high household income ZIP Code areas in the state of {`${state}`} that have at least 3,661 households 
            are - { _.map(wealthiest, 'plz').join(' , ')}.
        </p>
        <hr/>
        <div className='state-city mt-4 justify-content-left align-items-center'>             
            {
                wealthiest?.map((item) => { 
                    return <div key={item.citykey}>
                        <a href={`/lookup/us/${item.plz}/${item.lng}/${item.lat}`}> {item.plz} </a> 
                    </div>
                })  
            }
        </div>
    </div>
</React.Fragment>

    const textlist = <React.Fragment>
        <div className="d-flex align-items-center">
            <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662810349/free-tools/list.svg' style={{width:"47px", marginRight:"12px"}}/>
            <h5> Total ZIP Codes in {`${state}`} </h5>
        </div>
        <div className="text-justify">
            <p className="mt-2 mb-2"> 
            According to the US Census Bureau, the state of {`${state}`} has {Object.keys(zipcodes).length} ZIP code tabulation areas (ZCTA's).  
            The following list contains a list of all the ZIP Codes in {`${state}`} as well as their city, state, population, and household information. 
            {`${state}`} ZIP Codes can also be downloaded for free from below in excel or csv format.
            </p>
            {/* <hr/> */}
            <div className='mt-4 justify-content-left align-items-center'>             
                {
                    <Tabular data={zipcodes && zipcodes}/>
                    /*
                    zipcodes?.map((item) => { 
                        return <div key={item.key}>
                                    <a href={`/lookup/us/${item.plz}/${item.lng}/${item.lat}`}> {item.plz} </a> 
                            </div>
                    })
                    */   
                }
            </div>
        </div>
    </React.Fragment>

    const authwall= <React.Fragment>    
        <div className="d-flex align-items-center">
            <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662816675/free-tools/highincome.svg' style={{width:"49px", marginRight:"12px"}}/>
            <h5> High household income ZIP Codes in {`${state}`} </h5>
        </div>
        <hr/>
        <p className="mt-2 mb-3 text-center "> Register for a free account to view the high income ZIP Codes in {`${state}`} and other US states. </p>
    </React.Fragment>

    if(exception) return <Redirect to="/404"/> ; 

    return (
        <div className='content-wrapper'>
            <Helmet> <title> {`Lookup | ${state} ZIP Codes List, US`} </title> </Helmet>
            <NavBar isAdd={true} bgwhite={true}/>
            <DashboardInfo 
                title={`${state} ZIP Codes List `} 
                description={text1} 
                link1={`/lookup/us/${ encodeURIComponent(statekey) }`} 
                link1title={`Demographics of ${state}`}
                link2={`/lookup/browse/us/${ encodeURIComponent(statekey) }/cities`} 
                link2title={`Demographics by Cities in ${state}`}
            />

            { !loading && 
                <>
                    <Info description={textpopulated}/>
                    <Info description={textwealthiest}/>
                    <Info description={textyoungest}/>
                    <Demowall title={`Predict success of your business in ${state}.`}/>
                    <Info description={textoldest}/>
                    <Info description={textlist}/>
                </>
            }
            { loading && <LoadingPage /> }
        </div>

    )
}

export default Browsezipcode ;