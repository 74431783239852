import React from 'react';
//import { getPercentile } from '../../services/exploreService';
import {ExploreContext} from "./filterTabs/exploreContext";
import _ from "lodash" ; 


class ExploreContextProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            percentiles : [], 
            countryPopulation: 0, 
            countryHouseholds: 0
        };
        //this.updateValue = this.updateValue.bind(this);
    }
    
    convertFirendlyFormat = (value) => {
        var pow = Math.pow, floor = Math.floor, abs = Math.abs, log = Math.log;
        var abbrev = 'kmb'; // could be an array of strings: [' m', ' Mo', ' Md']

        function round(n, precision) {
            var prec = Math.pow(10, precision);
            return Math.round(n*prec)/prec;
        }

        function format(n) {
            var base = floor(log(abs(n))/log(1000));
            var suffix = abbrev[Math.min(2, base - 1)];
            base = abbrev.indexOf(suffix) + 1;
            return suffix ? round(n/pow(1000,base),2)+suffix : ''+n;
        }

        return format(value)
    }

    
    updateValue = async (value) => {        

        /*
        const { data } = await getPercentile(value) ;

        const countryZipcodes = data.length ; 
        const countryPopulation = _.sum(_.map( data , 'population')) ;
        const countryHouseholds = _.sum(_.map( data , 'households')) ;


        this.setState({
            percentiles : [...data], 
            countryZipcodes: countryZipcodes, 
            countryHouseholds: this.convertFirendlyFormat(countryHouseholds), 
            countryPopulation: this.convertFirendlyFormat(countryPopulation) , 
            
        })
        */
        
    };
    

    render() {
        return(
            <ExploreContext.Provider
                value={{
                    state: this.state,
                    updateValue: this.updateValue 
                }}
            >
                {this.props.children}
            </ExploreContext.Provider>
        )
    }
}

export default ExploreContextProvider;