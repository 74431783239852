import React from 'react';
import './promptLibrary.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../containers/logoWhite.svg" ; 

function App() {
  const prompts = [
    {
        "title": "Market Potential Analysis for Sustainable Yoga Mats",
        "subtitle": "Estimate market potential for sustainable yoga mats specifically within the city of Austin, Texas, with a particular emphasis on the population size of individuals aged 25 to 34"
      },
      {
        "title": "Geographical Performance Discrepancy for Coffee Shops",
        "subtitle": "Compare socio-economic factors affecting our coffee shop's success in Seattle, Washington vs. Denver, Colorado."
      },
      {
        "title": "SWOT Analysis for a Bookstore Location",
        "subtitle": "Assess SWOT for opening a new bookstore in Miami, Florida."
      },
      {
        "title": "Cultural Influence on Cuisine Preference",
        "subtitle": "Assess the potential success of an authentic Asian restaurant in Albuquerque, New Mexico based on its ethnic demographic composition."
      },
      {
        "title": "Product Performance Deep Dive for Home Furnishings",
        "subtitle": "Our handmade wooden tables sell well in ZIP Code 90210 but underperform in ZIP Code 10001. Evaluate demographic differences between these areas, use tabular data."
      },
      {
        "title": "Advertising Location Analysis for Product Promotion",
        "subtitle": "Artisanal coffee advertising: Beverly Hills (90210) vs. Manhattan (10001). Which is more suitable?"
      },
      {
        "title": "Franchise Location Analysis for Gyms",
        "subtitle": "Opening a new gym: Los Angeles (90001) vs. Phoenix (85001). Which city offers better prospects?"
      },
      {
        "title": "Performance Assessment for Organic Groceries",
        "subtitle": "Our organic tea collection sells briskly in San Francisco (94101) but not in San Jose (95101). Why might that be?"
      },
      {
        "title": "Bakery Location Suitability Analysis",
        "subtitle": "Considering launching a bakery in Brooklyn (11201) vs. Queens (11351). Which borough is more favorable?"
      },
      {
        "title": "Toy Store Market Penetration",
        "subtitle": "Estimate the potential for a toy store in Orlando, Florida given its family-friendly demographic."
      },
      {
        "title": "Local Craft Beer Popularity",
        "subtitle": "Evaluate the market reception for our craft beer in Portland, Oregon vs. Boise, Idaho."
      },
      {
        "title": "Pet Store Expansion Evaluation",
        "subtitle": "Assess the demographic favorability for our pet store in Dallas (75201) vs. Austin (73301)."
      },
      {
        "title": "Digital Marketing for Handmade Jewelry",
        "subtitle": "Our online ads perform well in Seattle (98101) but see less engagement in Spokane (99201). What could be the reason?"
      },
      {
        "title": "Footwear Store Location Analysis",
        "subtitle": "Considering opening a luxury footwear store: Chicago (60601) vs. Boston (02108). Which location might be better?"
      },
      {
        "title": "Children's Bookstore Popularity Assessment",
        "subtitle": "How might a children's bookstore fare in Madison, Wisconsin vs. Lincoln, Nebraska given their demographics?"
      },
      {
        "title": "Local Art Gallery Market Reach",
        "subtitle": "Evaluate the potential for an art gallery in Santa Fe, New Mexico vs. Tucson, Arizona."
      },
      {
        "title": "Fashion Boutique Success Factors",
        "subtitle": "Our women's boutique thrives in Miami (33101) but struggles in Atlanta (30301). Delve into demographic insights."
      },
      {
        "title": "Organic Cafe Popularity Projection",
        "subtitle": "Assess the potential success of an organic cafe in Boulder, Colorado vs. Salt Lake City, Utah."
      },
      {
        "title": "Sports Equipment Store Market Analysis",
        "subtitle": "Estimate the potential for a sports store focusing on winter sports in Minneapolis, Minnesota vs. Detroit, Michigan."
      },
      {
        "title": "Electronics Store Competitive Analysis",
        "subtitle": "Compare the market landscape for our electronics store in Silicon Valley (94301) vs. Raleigh (27601)."
      },
      {
        "title": "Outdoor Gear Store Location Potential",
        "subtitle": "Evaluate location potential for an outdoor gear store: Denver, Colorado (80201) vs. Anchorage, Alaska (99501)."
      },
      {
        "title": "Handmade Soap Store Demographics Analysis",
        "subtitle": "Analyze the potential for our handmade soap store in Portland, Maine (04101) vs. Burlington, Vermont (05401)."
      },
      {
        "title": "Local Music Store Performance",
        "subtitle": "Assess why our music store does well in Nashville, Tennessee but has less footfall in Memphis, Tennessee."
      },
      {
        "title": "Skincare Product Sales Analysis",
        "subtitle": "Evaluate sales differences for our skincare line: Honolulu, Hawaii (96801) vs. Las Vegas, Nevada (89101)."
      },
      {
        "title": "Dessert Parlor Location Insights",
        "subtitle": "Considering opening a dessert parlor: Charleston, South Carolina (29401) vs. Savannah, Georgia (31401). Where might it flourish?"
      },
      {
        "title": "Plant Nursery Market Demand",
        "subtitle": "Estimate the market demand for a plant nursery in Boise, Idaho (83701) vs. Billings, Montana (59101)."
      },
      {
        "title": "Wedding Boutique Popularity Projection",
        "subtitle": "Assess potential success for our wedding boutique in New Orleans, Louisiana vs. Lexington, Kentucky."
      },
      {
        "title": "Local Newsstand Location Assessment",
        "subtitle": "Considering a newsstand: Manhattan, New York (10001) vs. Newark, New Jersey (07101). Which is more promising?"
      },
      {
        "title": "Ceramic Workshop Expansion Insights",
        "subtitle": "Evaluate the market for expanding our ceramic workshop to Santa Barbara, California vs. San Diego, California."
      },
      {
        "title": "Home Decor Popularity Analysis",
        "subtitle": "Analyze sales for our home decor items in Providence, Rhode Island (02901) vs. Hartford, Connecticut (06101)."
      },
      {
        "title": "Craft Beer Advertising Effectiveness",
        "subtitle": "Craft beer billboard advertising: Philadelphia, Pennsylvania (19101) vs. Pittsburgh, Pennsylvania (15201). Where to place?"
      },
      {
        "title": "Artisanal Chocolate Shop Market Penetration",
        "subtitle": "Estimate market penetration for our chocolates in Richmond, Virginia (23201) vs. Wilmington, Delaware (19801)."
      },
      {
        "title": "Local Theater Popularity Projection",
        "subtitle": "Assess the potential for a local theater in Fargo, North Dakota vs. Sioux Falls, South Dakota."
      },
      {
        "title": "Antique Shop Market Assessment",
        "subtitle": "Evaluate market potential for our antique shop in Jackson, Mississippi vs. Little Rock, Arkansas."
      },
      {
        "title": "Tattoo Parlor Location Insights",
        "subtitle": "Considering a tattoo parlor: Austin, Texas (73301) vs. Oklahoma City, Oklahoma (73101). Which offers a better opportunity?"
      },
      {
        "title": "Fitness Gear Store Demand Analysis",
        "subtitle": "Estimate demand for our fitness gear in Indianapolis, Indiana vs. Columbus, Ohio."
      },
      {
        "title": "Spa and Wellness Center Popularity Projection",
        "subtitle": "Assess the market for our spa center in Asheville, North Carolina vs. Charleston, West Virginia."
      },
      {
        "title": "Home Appliance Store Competitive Landscape",
        "subtitle": "Compare market dynamics for our appliance store in Milwaukee, Wisconsin vs. Des Moines, Iowa."
      },
      {
        "title": "Adventure Gear Market Demand",
        "subtitle": "Analyze potential demand for our adventure gear in Cheyenne, Wyoming vs. Rapid City, South Dakota."
      },
      {
        "title": "Local Dairy Product Sales Insights",
        "subtitle": "Evaluate dairy product sales: Madison, Wisconsin vs. Springfield, Illinois."
      },
      {
        "title": "Natural Cosmetics Store Performance",
        "subtitle": "Why does our store see higher sales in Portland, Oregon compared to Reno, Nevada?"
      },
      {
        "title": "Craft Supplies Shop Location Analysis",
        "subtitle": "Considering a crafts shop: Eugene, Oregon (97401) vs. Olympia, Washington (98501). Where might it thrive?"
      },
      {
        "title": "Handmade Pottery Shop Market Insights",
        "subtitle": "Assess market dynamics for our pottery shop in Taos, New Mexico vs. Flagstaff, Arizona."
      },
      {
        "title": "Local Bookstore Advertising Analysis",
        "subtitle": "Promote our bookstore: Salem, Oregon (97301) vs. Boise, Idaho (83701). Where to focus our ads?"
      },
      {
        "title": "Herbal Tea Shop Market Potential",
        "subtitle": "Analyze potential for our herbal tea shop in Boulder, Colorado vs. Missoula, Montana."
      },
      {
        "title": "Board Games Store Location Insights",
        "subtitle": "Considering a board games store: Ann Arbor, Michigan vs. Toledo, Ohio. Which city is more favorable?"
      },
      {
        "title": "Eco-friendly Bags Market Analysis",
        "subtitle": "Evaluate sales potential for our eco-friendly bags in Green Bay, Wisconsin vs. Grand Rapids, Michigan."
      },
      {
        "title": "Local Music Instrument Shop Performance Insights",
        "subtitle": "Assess why our shop thrives in Austin, Texas but not in San Antonio, Texas."
      },
      {
        "title": "Organic Farm Produce Sales Analysis",
        "subtitle": "Analyze sales for our organic produce in Fresno, California vs. Bakersfield, California."
      },
      {
        "title": "Adventure Travel Agency Market Reach",
        "subtitle": "Evaluate market reach for our travel agency focusing on Albuquerque, New Mexico vs. El Paso, Texas."
      },
      {
        "title": "Organic Juice Bar Location Potential",
        "subtitle": "Considering a juice bar: Jacksonville, Florida vs. Tampa, Florida. Which offers better prospects?"
      }
  ];

  return (
    <div className="prompt-library-app">
        <div className="prompt-library-navbar">
        <img className="prompt-library-imgCenterNav" src={logo} alt="logo" />
        <span className="prompt-library-navbar-title">Prompts Library</span>
      </div>
      <PromptLibrary prompts={prompts} />
    </div>
  );
}

const PromptLibrary = (props) => {

const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text).then(() => {
        alert('Prompt copied to clipboard!');
    }).catch(err => {
        console.error('Could not copy text: ', err);
    });
}

  return (
    <>
        <div className="prompt-library-container mt-2">
        {props.prompts.map((prompt, index) => (
            <div key={index} className="prompt-library-card">
            <h2 className="prompt-library-title">{prompt.title}</h2>
            <p className="prompt-library-subtitle">{prompt.subtitle}</p>
            <FontAwesomeIcon size="1x" onClick={() => handleCopyClick(prompt.subtitle)} icon={['fa', 'copy']}/>                 
            </div>
        ))}
        </div>
    
    </>
  );
};

export default App;
