import React from "react"
import Lightbulb from "../../../containers/lightbulb.png"
import Offbulb from "../../../containers/offbulb.png"
import styles from "./bulb.module.css" ;


const Bulb = (props) => {

    const { on, bottom } = props ; 

    return (
        <div className={`${styles.tooltipSwitch}`} style={{position: "absolute", bottom: bottom , right: 1, zIndex:10 }}>
            {
             on &&  
                <>
                <span className={styles.tooltiptext}>Hide Scribbles</span>
                <img className={styles.Bulb} width="52px" height="auto" src={Lightbulb} alt="" />
                </>
            }
            {
             !on &&  <img width="52px" height="auto" src={Offbulb} alt="" />
            }
        </div>
    )

}

export default Bulb ; 