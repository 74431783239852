import React, { Component } from 'react';
// import './svgs.css'

class SendIcon extends React.Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.7398 2.8311C21.6401 2.41335 21.1811 2.1291 20.7648 2.27685C20.7648 2.27685 2.73407 8.6406 2.73107 8.64285C2.45432 8.7456 2.25107 9.0096 2.23607 9.31185C2.22257 9.6021 2.40182 9.90435 2.66657 10.0273L9.65056 13.2876C9.65056 13.2876 13.0533 9.88485 13.0593 9.87885C13.7343 9.20385 14.8121 10.2478 14.1198 10.9393L10.7111 14.3481C10.7111 14.3481 13.9668 21.3246 13.9728 21.3358C14.1056 21.5908 14.3651 21.7528 14.6501 21.7656C14.9598 21.7798 15.2463 21.5428 15.3573 21.2653C15.3573 21.2653 21.7173 3.2451 21.7211 3.23385C21.7683 3.1041 21.7713 2.96385 21.7398 2.8311Z" fill="white" />
            </svg>
        )
    }
}

export default SendIcon;