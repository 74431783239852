import React , { useState , useEffect } from 'react'
import { useForm } from 'react-hook-form'
import GoogleLogin, { GoogleLogout } from 'react-google-login';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import GoogleIcon from './icons/gicon.png'
// import thumb from './icons/thumb.png'
import { getCurrentUser, googleAuth, loginWithJwt, register as registerUser } from '../../services/userService';
import { Loader, Message } from 'rsuite';
import toast from 'react-hot-toast';

const schema = yup.object({
    email: yup.string().email('Invalid email format').required('Required') 
}).required();

const SignUp = () => {

    const [loading, setLoading ] = useState(false)
    const [message, setMessage]= useState("")
    const [success, setSuccess]= useState(true)
    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });


    const responseGoogleSuccess = async (response) => {
        setLoading(true);

        const args = { profile: response.profileObj , oneTap : false }
        const resp = await googleAuth(args)
        loginWithJwt(resp.headers['x-auth-zips'])
        setTimeout(() => {
            if (resp.data === "new") {
                //window.location.href = `/collaborate/${getCurrentUser().clientName}`
                window.location.href = `/user/form`
            } else {
                window.location.href = `/home`
            }
        }, 1000);
    }

    const responseGoogleFail = (response) => {
        setSuccess(false)
        setMessage("Trouble authenticating your google account");
        toast.error("We could'nt authenticate your google account!")
    }

    return (

            <>
                <div id="Sign-up-reuse" className='d-flex-row'>
                    <Link className="mr-3" to="/register"> <button className="global-btn-primary">Sign up with email</button></Link>
                        <GoogleLogin
                            clientId={process.env.REACT_APP_GAUTH }
                            buttonText="Enable"
                            render={renderProps => (
                                <>  
                                    <button onClick={renderProps.onClick} className='googlebtn'>
                                        { !loading && <img style={{ width: "18px"}} src={GoogleIcon} alt="google icon"/> }
                                        { loading && <Loader /> }
                                    </button>
                                </>
                            )}
                            onSuccess={responseGoogleSuccess}
                            onFailure={responseGoogleFail}
                            cookiePolicy={'single_host_origin'}
                            //scope="https://www.googleapis.com/auth/spreadsheets.readonly"
                        /> 
                </div>
                
                <div className="mt-4" style={{fontSize:'0.8rem', color: '#808080ad'}}>
                    Already have an account? 
                    <Link to="/login" >
                        <span>{' '}Login </span>
                    </Link>
                </div>
                
            </>

    )
}

export default SignUp
