import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import './appsumo.css';
import { createAppsumoCheckout } from "../../services/checkoutService"
import NavBar from "./NavBar/navbar";



const schema = yup.object({
  couponcode: yup.string().required('Coupon code is required'),
});

function AppSumo() {
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const [redeemStatus, setRedeemStatus] = useState({ success: false, error: null });

  const onSubmit = async (data) => {
    try {
      const resp = await createAppsumoCheckout(data.couponcode);
      if (resp.status === 200) {
        // If the redemption is successful, set the success state
        setRedeemStatus({ success: true, error: null });
      }
    } catch (error) {
      setRedeemStatus({ success: false, error: 'Coupon code is not valid. Contact support if you need assistance.' });
    }
  };

  return (
    <>
    <NavBar />
    <div className="appsumo-coupon-container">
      <div className="appsumo-coupon-content">
        
        {
            !redeemStatus.success  && 
            <>
            
            <h1 className="appsumo-coupon-title mt-2 mb-2">Redeem Your AppSumo Coupon</h1>
            <p className="appsumo-coupon-subtitle">Thank you for your purchase!</p>

            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="appsumo-coupon-form-group mt-5">
                <label htmlFor="couponcode">Coupon Code:</label>
                <Controller
                name="couponcode"
                control={control}
                render={({ field }) => (
                    <input
                    type="text"
                    id="couponcode"
                    {...field}
                    className="appsumo-coupon-form-control"
                    />
                )}
                />
                {errors.couponcode && (
                <p className="appsumo-coupon-error" style={{ color: 'red', marginTop: '5px' }}>
                    {errors.couponcode.message}
                </p>
                )}
            </div>
            <button type="submit" className="appsumo-coupon-submit-button">
                Redeem
            </button>
            </form>

            </>
        }

        {!redeemStatus.success && redeemStatus.error && (
          <p className="appsumo-coupon-error" style={{ color: 'red', marginTop: '5px' }}>
            {redeemStatus.error}
          </p>
        )}
        
        {redeemStatus.success && !redeemStatus.error && (
          <div className="appsumo-coupon-success">
             <span style={{color: 'green'}}> Coupon code successfully redeemed! </span>
            <p style={{color: '#333'}} className="app-sumo-paragraph mt-3">
            You now have lifetime access to the premium features of Zipscore, which include - <br /> 
            unlimited use of Co-Pilot, 
            location searches, POI analysis, scribbles, and real-time collaboration.
              </p>

            <p style={{color: 'grey'}}>Click <a href="/home">here</a> to navigate to the home page.</p>
          </div>
        )}
      </div>
    </div>

    </>

  );
}

export default AppSumo;
