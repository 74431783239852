import React, { useEffect, useState } from 'react';
import { useParams ,  useHistory , Redirect } from "react-router-dom";
import _ from "lodash"

import '../style.css';
import DashboardInfo from "../../../datas/de/DashboardInfo/index.js" ; 
import Info from "../../../datas/us/Info/index.js" ; 
import Demowall from "../../../datas/de/Demowall/index.js"
import NavBar from '../../../../common/NavBar/navbar.jsx';

import { getStateZipcodes } from "../../../../../services/browseService"
import LoadingPage from '../../../../common/tools-ui/loadingPage';

const Browsezipcode = () => {

    const [statekey, setStatekey ] = useState(useParams().statekey);
    const [state, setState ] = useState('');
    const [zipcodes, setZipcodes]= useState([])
    const [populated, setPopulated]= useState([])
    const [youngest, setYoungest]= useState([])
    const [oldest, setOldest]= useState([])
    const [richest, setRichest]= useState([])

    const [loading, setLoading] = useState(true) ; 
    const [exception, setException] = useState(false) ; 
    const history = useHistory() ; 
    const nf = Intl.NumberFormat();

    useEffect(() => {
        getZipcodes()
    }, [])

    const getZipcodes = async() => {
        try {
            const resp = await getStateZipcodes("de", encodeURIComponent(statekey))
            setLoading(false)
            setState(resp.data[0].all[0].state)
            setZipcodes([...resp.data[0].all])
            setPopulated([...resp.data[0].toppopulated])
            setYoungest([...resp.data[0].topyoungest])
            setOldest([...resp.data[0].topoldest])
        } catch (error) {
            setLoading(false)
            setException(true)
        }
    }

    const text1= <React.Fragment>     
        <div className="text-justify">
            <p className="mt-2 mb-2">
            The demographic data along with the ZIP Codes List of {`${state}`} documents income, employment status, household income, 
            residents, housing units, total population, and other demographic data that Marketing Researchers, Data Analysts and Data Scientists can utilize. 
            Product development and marketing efforts can be targeted, precise, and generate more significant ROI when a company can segment 
            the ZIP codes in the state of {`${state}`} by it demographics. While many other websites only allow a basic zip code lookup feature, our powerful features provide insightful analytics into the location data. 
            To access these analytics, you can click on any of the zip codes below.
            </p>
        </div>
    </React.Fragment>

    const textpopulated= <React.Fragment>
            <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806287/free-tools/mostpopulated.svg' style={{width:"49px", marginRight:"12px"}}/>
                <h2 className="customh2fontsize"> {`${state}`} Most Populated ZIP Codes List</h2>
            </div>
            <div className="text-justify">
                <p className="mt-2 mb-2"> 
                        With {`${ nf.format(populated[0]?.population )} `} residents, {`${ populated[0]?.plz } `} is the most populated ZIP Code in the state of {`${state} `}. 
                        {` ${ populated[1]?.plz } `} has a population of {`${ nf.format(populated[1]?.population )} `}, making it the second most populous ZIP Code. 
                        The top ten most populous ZIP Codes in {`${state} `} are - { _.map(populated, 'plz').join(' , ')}. <br/>
                        You can check most populated Arizona Zip Code List below - 

                </p>
                <hr/>
                <div className='state-city mt-4 justify-content-left align-items-center'>             
                    {
                        populated?.map((item) => { 
                            return <div key={item.key}>
                                        <a href={`/lookup/de/${item.plz}/${item.lng}/${item.lat}`}> {item.plz} </a> 
                                </div>
                        })  
                    }
                </div>
            </div>
    </React.Fragment>

    const textyoungest= <React.Fragment>
    <div className="d-flex align-items-center">
        <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806442/free-tools/mostyoungest.svg' style={{width:"49px", marginRight:"12px"}}/>
        <h2 className="customh2fontsize"> Youngest population ZIP Codes in {`${state}`} </h2>
    </div>
    <div className="text-justify">
        <p className="mt-2 mb-2"> 
                Out of all the ZIP Codes in {`${state}`} which have population of at least 10,099 (average population of postal code areas in Germany),
                the ZIP Code {` ${ youngest[0]?.plz } `} have highest proportion of population younger than 25 years of age.
                The top 10 youngest ZIP Codes in the state of {`${state}`} that have at least 10,099 residents 
                are - { _.map(youngest, 'plz').join(' , ')}. Many of these ZIP Codes are home to universities.
        </p>
        <hr/>
        <div className='state-city mt-4 justify-content-left align-items-center'>             
            {
                youngest?.map((item) => { 
                    return <div key={item.plz}>
                                <a href={`/lookup/de/${item.plz}/${item.lng}/${item.lat}`}> {item.plz} </a> 
                        </div>
                })  
            }
        </div>
    </div>
    </React.Fragment>

    const textoldest= <React.Fragment>
    <div className="d-flex align-items-center">
        <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662806778/free-tools/mostoldest.svg' style={{width:"49px", marginRight:"12px"}}/>
        <h2 className="customh2fontsize"> {`${state}`} Oldest population ZIP Code List </h2>
    </div>
    <div className="text-justify">
        <p className="mt-2 mb-2"> 
                Out of all the ZIP Codes in {`${state}`} which have population of at least 10,099 (average population of postal code areas in Germany) ,
                the ZIP Code {` ${ oldest[0]?.plz } `} have highest proportion of population 65 years of age and older.
                The top 10 oldest ZIP Codes in the state of {`${state}`} that have at least 10,099 residents 
                are - { _.map(oldest, 'plz').join(' , ')}.
                You can check the oldest populated Arizona Zip Code List below - 
        </p>
        <hr/>
        <div className='state-city mt-4 justify-content-left align-items-center'>             
            {
                oldest?.map((item) => { 
                    return <div key={item.key}>
                                <a href={`/lookup/de/${item.plz}/${item.lng}/${item.lat}`}> {item.plz} </a> 
                        </div>
                })  
            }
        </div>
    </div>
    </React.Fragment>

    const textlist = <React.Fragment>
        <div className="d-flex align-items-center">
            <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662810349/free-tools/list.svg' style={{width:"47px", marginRight:"12px"}}/>
            <h2 className="customh2fontsize"> Total ZIP Codes in {`${state}`} </h2>
        </div>
        <div className="text-justify">
            <p className="mt-2 mb-2"> 
                According to Federal Statistical Office of Germany (Statistisches Bundesamt), the state of {`${state}`} have {Object.keys(zipcodes).length} ZIP code tabulation areas (ZCTA's).  
                The list of all the ZIP Codes in {`${state}`} are as follows - 
            </p>
            {/* <hr/> */}
            <div className='state-city mt-4 justify-content-left align-items-center'>             
                {
                    zipcodes?.map((item) => { 
                        return <div key={item.key}>
                                    <a href={`/lookup/de/${item.plz}/${item.lng}/${item.lat}`}> {item.plz} </a> 
                            </div>
                    })  
                }
            </div>
        </div>
    </React.Fragment>



    if(exception) return <Redirect to="/404"/> ; 

    return (
        <div className='content-wrapper'>
            <NavBar isAdd={true} bgwhite={true}/>
            <DashboardInfo 
                title={`${state} ZIP Codes List `} 
                description={text1} 
                link1={`/lookup/de/${ encodeURIComponent(statekey) }`} 
                link1title={`Demographics of ${state}`}
                link2={`/lookup/browse/de/${ encodeURIComponent(statekey) }/cities`} 
                link2title={`Demographics by Cities in ${state}`}
            />
            {
                !loading && 
                <>
                    <Info description={textpopulated}/>
                    <Info description={textyoungest}/>
                    <Demowall title={`Predict success of your business in ${state}.`}/>
                    <Info description={textoldest}/>
                    <Info description={textlist}/>
                </>
            }
            { loading && <LoadingPage /> }
        </div>

    )
}

export default Browsezipcode ;