import http from "./httpService";

import config from "../config/api.js";
const { apiUrl_dev, apiUrl_prod } = config;

const apiUrl = process.env.REACT_APP_ENVIRON === "development" 
               ? apiUrl_dev
               : apiUrl_prod


export function getAllUsers() {
    return http.get(`${apiUrl}/client/users/all`);
}


export function getSubsDetails() { 
    return http.get(`${apiUrl}/client/subscription/details`)
  }