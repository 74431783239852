import React, { useState , useEffect } from 'react';
import './style.css';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import LivingWithChart from '../../../../common/shared-charts/LivingWithChart';

const COLORS = ["#EF8636", "#F5BE82", "#B3C6E5" , "#3B75AF"];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (data) => ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
            style={{fontStyle: 'Axiform' , fontSize: '12px'}}
        >
            {`${data[index].label}`}
        </text>
    );
};

const CustomTooltip = ({ active, payload }) => {
    if (active) {
        return (
            <div
                className="custom-tooltip"
                style={{
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    textAlign: "center" ,
                    padding: "8px" ,
                    borderRadius: "6px", 
                    fontFamily: "Axiform"
                }}
            >
                <label style={{color: "#FFFFFF"}}>{`${payload[0].value}%`}</label>
            </div>
        );
    }
    return null;
};

const transformLivingData = (obj) => {

    const transformed = [] ;
    Object.entries(obj).forEach(([key, value]) => {  
        let entry = {
         'name' : key, 
          'value' : value , 
          'label' : key
      }
      transformed.push(entry)
    })

    return transformed ; 
}


const LivingWith = ({dts}) => {
    
    const [newLiving , setnewLiving ] = useState([]) ; 
    
    useEffect(()=> {
        const dtLiving = transformLivingData(dts[0].livingPerson)
        setnewLiving([...dtLiving])

    }, [])
    
    // const newLiving = transformLivingData(dts[0].livingPerson) ; 

    return (
        <div className='Livingwith-wrapper'>
            <div className='container'>
                <div className='Livingwith-block'>
                    <div className='Livingwith-content'>
                        <div className='Livingwith-contents'>
                            <div className='Livingwith-title'> <h6 className="fontstyleaxiform"> Household Living Arrangement </h6></div>
                            
                            <LivingWithChart dts={ dts[0].livingWith } />
                        </div>
                    </div>
                    <div className='household-content'>
                        <div className='household-contents'>
                            <div className='Livingwith-title household'>  
                                <h6 className="fontstyleaxiform"> Persons in Household </h6>
                            </div>
                            <div className='household-description'>
                                {
                                    newLiving.length > 1 && 
                                    <PieChart width={260} height={200}>
                                        <Pie
                                            data={ newLiving }
                                            isAnimationActive={false}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={renderCustomizedLabel(newLiving)}
                                            outerRadius={100}
                                            stroke="none"
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {newLiving.map((entry, index) => (
                                                <Cell key={`cell-${index}`}  fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip content={<CustomTooltip />} />
                                    </PieChart>
                                }
                                <div className='hold-content'>
                                    {
                                        newLiving.map((k,i)=> {
                                            return <div key={k.name} className={`household-desc desc${i}`}> {k.name} </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default LivingWith