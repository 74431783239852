import React from "react";
import "./styles/header.css";
import { Link } from "react-router-dom" ;
// import Logo from "./assets/images/logo.png";
import Logo from "./assets/images/logo.svg";

class Header extends React.Component {
  state = {
    class: "",
    menuOpened: false,
    dtsurl: ""
  };

  listenScrollEvent = (e) => {
    if (window.scrollY > 20) {
      this.setState({ ...this.state, class: "scrolled" });
    } else {
      this.setState({ ...this.state, class: "" });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
    let url = `/browse/${this.props.country}/census-data` ;
    this.setState({dtsurl: url})

  }

  scrollToViewOffset = (id, offsetVal) => {
    const element = document.getElementById(id);
    const offset = offsetVal;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

  render() {
    
    return (
      <div className={`l-header ${this.state.class}`}>
        <img src={Logo} alt='logo' />
        <div className="hamburger-menu hamburger-menu-two">
          <input
            type="checkbox"
            className="input-check2"
            id="input-check2"
            hidden
            checked={this.state.menuOpened}
            onChange={() => {}}
          />
          <label
            className="bars-container bars-container2"
            htmlFor="input-check2"
            onClick={() => {
              this.setState((prevState) => ({
                ...prevState,
                menuOpened: !prevState.menuOpened,
              }));
            }}
          >
            <span className="bar bar1"></span>
            <span className="bar bar2"></span>
            <span className="bar bar3"></span>
          </label>
        </div>
        <nav>
          <ul>
            <li className="top-nav-1"
              onClick={() => {
                this.scrollToViewOffset("features", 0)
              }}
            >
              Features
            </li>

            <li 
              className="top-nav-1"
              onClick={() => {
                this.scrollToViewOffset("usecases", 170)
              }}
            >
              Usecases
            </li>
            {/* <li 
              className="top-nav-1"
              onClick={() => {
                this.scrollToViewOffset("pricing", 75)
              }}
            >
              Pricing
            </li> */}

            <li 
              className="top-nav-1"
            >
              <Link to="/login" style={{textDecoration: 'none!important'}}> Login</Link>
            </li>

          </ul>
          <div className="header-cta">

              <Link to="/register">
                <button className="btn primary">Try for free</button>
              </Link>
          </div>
        </nav>
        <div
          className={
            this.state.menuOpened ? "mobile-menu menu-opened" : "mobile-menu"
          }
        >
          <nav>
            <ul>
              <li
                onClick={() => {
                  this.setState((prevState) => ({
                    ...prevState,
                    menuOpened: false,
                  }));
                  this.scrollToViewOffset("features", 0)
                }}
              >
                Features
              </li>

              <li
                onClick={() => {
                  this.setState((prevState) => ({
                    ...prevState,
                    menuOpened: false,
                  }));
                  this.scrollToViewOffset("usecases", 70)
                }}
              >
                Usecases
              </li>

              <li
                onClick={() => {
                  this.setState((prevState) => ({
                    ...prevState,
                    menuOpened: false,
                  }));
                  this.scrollToViewOffset("pricing", 60)
                }}
              >
                Pricing
              </li>

              {/* <li
                onClick={() => {
                  this.setState((prevState) => ({
                    ...prevState,
                    menuOpened: false,
                  }));
                }}
              >
                <Link style={{color: "#0477F5"}} to="/story"> Story </Link>
              </li> */}

              <li
                onClick={() => {
                  this.setState((prevState) => ({
                    ...prevState,
                    menuOpened: false,
                  }));
                }}
              >
                <Link to="/login"> Login </Link>
              </li>

              <li
                onClick={() => {
                  this.setState((prevState) => ({
                    ...prevState,
                    menuOpened: false,
                  }));
                }}
              >
                <a href={this.state.dtsurl}> Data </a>
              </li>
              <li
                onClick={() => {
                  this.setState((prevState) => ({
                    ...prevState,
                    menuOpened: false,
                  }));
                }}
                className="btn primary"
              >
                <Link to="/register"> Signup </Link> 
              </li> 
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default Header;