import React, { Component } from 'react'
import Markdown from 'react-markdown' ; 
import styles from "./blog.module.css" ;
// import Sidebar from "./sidebar.jsx" 
import { Link } from "react-router-dom"
import { getEntryId } from '../../services/contentService';
import LoadingPage from '../common/tools-ui/loadingPage';
import { getCurrentUser } from "../../services/userService" ; 
import NavBar from '../common/NavBar/navbar';
import Header from "../look-up/datas/us/Header/index" ;
import Footer from "../landing-page/Footer"
import { Helmet } from "react-helmet";

class BlogEntry extends Component {
    state = { 
        post: null, 
        errorLog: null, 
        loading: true 
    }
    
    async componentDidMount() {
        const entryId = this.props.match.params.entryId ;

        try {

            const response = await getEntryId(entryId) ; 
            this.setState({
                loading: !this.state.loading , 
                post: response.data.fields
            })
            
        } catch (ex) {
            this.setState({ loading: !this.state.loading , errorLog : ex.message})
        }

    }

    
    render() { 

        const { post, errorLog , loading } = this.state ; 
    
        return ( 
                <>
                
                <div className="container-fluid">
                    { getCurrentUser() &&  <NavBar /> }
                    { !getCurrentUser() &&  <Header /> }

                    <div className="row mt-3">                   
                        <div className="col-md-2"></div>
                        <div className="col-md-6" >
                            <Link className="mt-0" style={{ marginLeft: '-1rem'}} to="/blog"> &#x2190; Back to Blog </Link>
                            {/* <hr style={{ width:"100%" , marginLeft: '-1rem'}}/> */}
                                {
                                
                                    post && !loading && !errorLog &&
                                    <>
                                        <Helmet> <title> {`${post.title}`} </title> </Helmet>
                                        <div className={styles.blogEntryTitleDiv}> <h1 style={{color:"#4d4d4d"}}> { post.title } </h1></div>
                                    </>    
                                }
                                <div className="row d-block mt-0">    

                                    { errorLog && !loading &&  <h2 style={{color:'crimson'}}>404 resource not found</h2> }
                                    {
                                    post &&
                                        <>
                                            <Markdown className={` mt-2 ${styles.contentText}`} children={ post.body } />
                                        </>
                                    }
                                    { loading && !errorLog && <LoadingPage />}
                                </div> 
                        </div>
                        <div className="col-md-3 text-center" >
                                {/* <Sidebar /> */}
                        </div>    
                    </div>
                </div>
                {!getCurrentUser() && <Footer />}
                </>
         );
    }
}
 
export default BlogEntry;