import { Table } from 'rsuite';
import React from 'react';
import {CSVLink } from 'react-csv';
import ReactExport from "react-export-excel";
import { Button } from 'rsuite';
import ReactGA from "react-ga4" ;

const { Column, HeaderCell, Cell } = Table;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const Tabular = ({data}) => {
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);

  
  const getData = () => {
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data;
  };
  

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const sendEvent=(file) => {

    if (process.env.REACT_APP_ENVIRON === "production" ) {
      
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID) ; 
      ReactGA.event({
        category: "download list" , 
        action: `download ${file} - ${window.location.pathname + window.location.search}`
      });

    } 

  }

  const nf = new Intl.NumberFormat() ;


  const headersCsv = [
    { label: "State", key: "state" },
    { label: "ZIP Code", key: "plz" },
    { label: "City", key: "city" },
    { label: "Population", key: "population" },
    { label: "Households", key: "households" },
  ]

  return (
    <>
      <div className="mt-0">
          <Table
            height={700}
            // width={1250}
            //autoHeight
            virtualized= {true}
            data={getData()}
            // data={data}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            loading={loading}
            style={{
              fontSize: "12px" ,
              color:"#6b6d6d", 
              cursor: "default" 
          }}
          >
            <Column width={200} align="left" >
              <HeaderCell>State</HeaderCell>
              <Cell>
                  {(rowData, rowIndex) => {
                      return <a className="hover-underline-animation" href={`/us/${rowData.state_key}/zip-code-map`}>{rowData.state}</a>;
                  }}
              </Cell>
            </Column>

            <Column width={200} align="left" >
              <HeaderCell>ZIP Code</HeaderCell>
              <Cell dataKey="plz" >
                  {(rowData, rowIndex) => {
                      return <a className="hover-underline-animation" href={`/us/${rowData.plz}/zip-code/${rowData.lng}/${rowData.lat}`}>{rowData.plz}</a>;
                  }}
              </Cell>
            </Column>

            <Column width={200} align="left">
              <HeaderCell>City</HeaderCell>
              <Cell dataKey="city">
                  {(rowData, rowIndex) => {
                      return <a className="hover-underline-animation" href={`/us/${rowData.state_key}/${rowData.city_key}/zip-code-map`}>{rowData.city}</a>;
                  }}
              </Cell>
            </Column>

            {/* <Column width={200} >
              <HeaderCell>County</HeaderCell>
              <Cell dataKey="county" />
            </Column> */}

            <Column width={200} sortable align="left">
              <HeaderCell>Population</HeaderCell>
              <Cell dataKey="population">
                  {(rowData, rowIndex) => {
                      return nf.format(rowData.population);
                  }}
              </Cell>
            </Column>

            <Column width={200} sortable align="left">
              <HeaderCell>Households</HeaderCell>
               <Cell dataKey="households">
                  {(rowData, rowIndex) => {
                      return nf.format(rowData.households);
                  }}
              </Cell>
            </Column>

          </Table>
      </div>
      <div className="customh2fontsize">
            <hr className="hrModal" />
            <div className="text-center align-items-center justify-content-center mt-2 mb-3">
                <span onClick={() => sendEvent('excel')} className="ml-3">
                    <ExcelFile element={<Button appearance="ghost" active> Download xlsx </Button>} filename= {"zipcodes-by-zipscore"}>
                        <ExcelSheet data={ data } name="selections">
                            <ExcelColumn label="State URL" value={(col) => `https://www.zipscore.ai/us/${col.state_key}/zip-code-map`} />
                            <ExcelColumn label="State" value="state"/>
                            <ExcelColumn label="ZIP Code URL" value={(col) => `https://www.zipscore.ai/us/${col.plz}/zip-code/${col.lng}/${col.lat}`} />
                            <ExcelColumn label="ZIP Code" value="plz"/>
                            <ExcelColumn label="City URL" value={(col) => `https://www.zipscore.ai/us/${col.state_key}/${col.city_key}/zip-code-map`} />
                            <ExcelColumn label="City" value="city"/>
                            <ExcelColumn label="Population" value="population"/>
                            <ExcelColumn label="Households" value="households"/>
                        </ExcelSheet>
                    </ExcelFile>
                </span>
                <span onClick={() => sendEvent('csv')} className="ml-4">
                    <CSVLink data={ data } headers={ headersCsv } separator={";"} filename={"zipcodes-by-zipscore.csv"} >
                          <Button appearance="ghost" active> Download csv </Button>
                    </CSVLink>
                </span>
                {/* <span className="ml-3">
                    Add to google drive
                </span> */}
            </div>
            <div className="text-center align-items-center justify-content-center mt-2" style={{fontSize: "12px"}}>
                Read here > <a href='/blog/how-to-target-zip-codes-in-google-ads' target="_blank"> How to Target Zip Codes in Google Ads?  </a>   
            </div>
      </div>
    </>
  );
};

export default Tabular