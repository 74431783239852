/* eslint-disable default-case */
import React, { Component } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { ExploreContext } from "./filterTabs/exploreContext";
import _ from "lodash" ; 
import { filterPercentiles } from "./filterTabs/utils"
import BottomPanel from "../common/bottomPanel/bottomPanel"
import NavBar from "../common/NavBar/navbar" ; 
import { colorsMap, dropzoneCountries, mapboxStyle , mapboxToken } from "../../global" ;
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import "./geocoder.css" ; 
import Switch from "../common/tools-ui/switch" ; 
import Bulb from "../common/tools-ui/Bulb" ; 
import { getAllInstances } from "../../services/scribbleService";
import { getPercentileFilter } from "../../services/exploreService" ; 


class Map extends Component {
    state = {
        lng: null ,
        lat: null ,
        zoom: null,
        plzSelections: [],
        plzTooltip : true, 
        selectedPopulation: 0 , 
        selectedHouseholds: 0 , 
        download: [], 
        threeD: false,        
        instanceLayers: [] , 
        instanceSources: [], 
        showInstances: true
    };

    addLayer = (tileset) => { 
        this.map.addSource('postleitzahlen', {
            type: 'vector',
            url: tileset
        });
        
        this.map.addLayer(
            {
                'id': 'zipcode',
                'type': 'fill',
                'source': 'postleitzahlen', 
                'source-layer': 'postleitzahlen',
                'paint': {
                    'fill-opacity': 0,
                    'fill-color': '#fff0e6',
                    
                },
            },
            'waterway-label'
        );

        this.map.addLayer(
            {
                'id': 'zipcode-boundary',
                'type': 'line',
                'source': 'postleitzahlen', 
                'source-layer': 'postleitzahlen',
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-opacity': 0.1,
                    'line-color': 'grey',
                    'line-width': 0.5,
                }
            },
            'waterway-label'
        )
    }

    componentDidMount() {
        this.context.updateValue(this.props.country)

        const mapAttri = dropzoneCountries.filter((o)=> {
            return o.value === this.props.country
        })

        mapboxgl.accessToken = mapboxToken ; 
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: mapboxStyle,
            center: [mapAttri[0].lng, mapAttri[0].lat],
            zoom: mapAttri[0].zoom,
            attributionControl: false
        });

        const thiss = this;

        this.map.on('load', () => {

            this.addLayer(mapAttri[0].tileset) ; 
            this.plotInstances() ; 
            
            const geocoder = new MapboxGeocoder({
                accessToken: mapboxToken,
                mapboxgl: mapboxgl, 
                zoom: 15
            });

            // thiss.map.addControl( geocoder , 'bottom-right');
            thiss.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
        });

        let popup = new mapboxgl.Popup({
            closeButton: false,
        });

        // display popup zip code and sales value
        thiss.map.on('mousemove', 'zipcode-boundary', function (e) {
            // this.map.getCanvas().style.cursor = 'pointer';
            if (thiss.state.plzTooltip === false) return; 
            popup.setLngLat(e.lngLat).setHTML(
                "<div style=color:grey;text-align:center;>" +
                "<div>" + e.features[0].properties.postcode + "</div>" +
                "<div>" + e.features[0].properties.locality + "</div>" +
                "</div>"
            ).addTo(thiss.map);
        });

        thiss.map.on('mouseleave', 'zipcode-boundary', function () {
            popup.remove();
        });
    }

    componentWillUnmount() {
        this.map.remove();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {   
        if( this.props.data && ( JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data))){
            //Dropped Data found
            this.handleDrop(this.props.data)
        } else if (this.state.plzSelections.length && !this.props.data) {
            this.map.setFilter('zipcode', null) ;
            this.map.setFilter('zipcode-boundary', null)
            this.map.setPaintProperty("zipcode-boundary", 'line-color', "grey");
            this.map.setPaintProperty("zipcode-boundary", 'line-width', 0.2);
            this.map.setPaintProperty("zipcode-boundary", 'line-opacity', 0.5);

            // remove fill for zipcode boundary 
            // this.map.setPaintProperty("zipcode", 'fill-color', "#8FB5F8");
            // this.map.setPaintProperty("zipcode", 'fill-opacity', 0.3);



        }   
    }


    handleDrop = async (data) => {
        const percentiles = this.context.state.percentiles ;
        const { country } = this.props ; 

        if (data) {
            if (Object.keys(data)[0] === "selection"){

                let selections = data.selection ; 
                let percentileValue = data.percentile ; 
                let filterOperator = data.percentileSelect ;

                let keysArray = [] ;
                
                if (Object.keys(selections).length === 0 ){
                    keysArray.push('population_pct')
                } else {
                        Object.keys(selections).forEach((item)=> {
                            let x = selections[`${item}`]['value'] ; 
                            keysArray.push(x)
                        }) ;  
                }

                const resp = await getPercentileFilter(country, keysArray, filterOperator, percentileValue)
                
                const results = resp.data ;
                if (results.length === 0 ) {
                    window.alert('Something went wrong, please try again!'); 
                    return ; 
                }
                let plzArray = _.map(results, "plz") ; 
                let downloadData = _.map(results, i => _.pick(i, 'state', 'plz' , 'city' , 'population' , 'households')) ;

                const textAttributes = {
                    selectedPlz: plzArray , 
                    countryZipcodes : this.context.state.countryZipcodes,
                    selectedPopulation: _.sum(_.map( results , 'population')), 
                    countryPopulation :  this.context.state.countryPopulation , 
                    selectedHouseholds: _.sum(_.map( results , 'households')),  
                    countryHouseholds : this.context.state.countryHouseholds, 
                }

                // this is passing plzLength to main component +++++++ 
                this.props.changePlz( textAttributes )

                this.setState({
                    ...this.state, 
                    plzSelections : [ ...plzArray ], 
                    selectedPopulation: _.sum(_.map( results , 'population')), 
                    selectedHouseholds: _.sum(_.map( results , 'households')) , 
                    download : [...downloadData]
                })


                // this.map.setPaintProperty("zipcode-boundary", 'line-color', "red");
                this.map.setFilter('zipcode', ['in', 'postcode', ...plzArray]);
                this.map.setFilter('zipcode-boundary', ['in', 'postcode', ...plzArray]) ;

                this.map.setPaintProperty("zipcode", 'fill-color', "#8FB5F8");
                this.map.setPaintProperty("zipcode", 'fill-opacity', 0.3);

                this.map.setPaintProperty("zipcode-boundary", 'line-color', "#F75E04");
                this.map.setPaintProperty("zipcode-boundary", 'line-width', 1);
                this.map.setPaintProperty("zipcode-boundary", 'line-opacity', 0.8);


   
            } else {


                let textSelections = data.filters ;
                let percentileValue = data.percentile ;  
                let filterOperator = data.percentileSelect ; 

                let keysArray = [] ; 
                Object.keys(textSelections).forEach((item)=> {
                    let x = textSelections[`${item}`]['value'] ; 
                    keysArray.push(x)
                })
                const resp = await getPercentileFilter(country, keysArray, filterOperator, percentileValue) ; 
                const results = resp.data ; 

                if (results.length === 0 ) {
                    window.alert('Something went wrong, please try again!'); 
                    return ; 
                }

                let plzArray = _.map(results, "plz")
                let downloadData = _.map(results, i => _.pick(i, 'state', 'plz' , 'city' , 'population' , 'households')) ;
                
                const textAttributes = {
                    selectedPlz: plzArray , 
                    countryZipcodes : this.context.state.countryZipcodes,   
                    selectedPopulation: _.sum(_.map( results , 'population')), 
                    countryPopulation :  this.context.state.countryPopulation , 
                    selectedHouseholds: _.sum(_.map( results , 'households')) , 
                    countryHouseholds : this.context.state.countryHouseholds, 
                }
                
                this.props.changePlz( textAttributes )

                this.setState({
                    ...this.state, 
                    selectedPopulation: _.sum(_.map( results , 'population')), 
                    selectedHouseholds: _.sum(_.map( results , 'households')) , 
                    download : [...downloadData], 
                    plzSelections : [ ...plzArray ]
                })

                this.map.setFilter('zipcode', ['in', 'postcode', ...plzArray]);
                this.map.setFilter('zipcode-boundary', ['in', 'postcode', ...plzArray]) ;
                
                this.map.setPaintProperty("zipcode-boundary", 'line-color', "#F75E04");
                this.map.setPaintProperty("zipcode-boundary", 'line-width', 1.5);
                this.map.setPaintProperty("zipcode-boundary", 'line-opacity', 0.6);
            }
        }
    }

    setThreeD = (val) => {
        setTimeout(()=>{ 
            const pitch = !val ? 90 : 0
            const bearing = !val ? 0 : 0
            
            this.map.setPitch(pitch)
            this.map.setBearing(bearing)
            this.setState({threeD : !val})
        } , 500)       
    }

    handleInstances = () => {     
        const { instanceLayers, showInstances } = this.state ; 
        if( instanceLayers.length >= 1 ){      
            for (const layer of instanceLayers){
                this.map.removeLayer(layer) 
                this.map.removeSource(layer)
            }
            // console.log(this.map.getStyle().layers)
            this.setState({ showInstances : !showInstances , instanceLayers:[] , instanceSources: [] })
        } else {
            this.plotInstances(); 
            this.setState({ showInstances : !showInstances })
        } 
    }

    plotInstances = async() => {

        // console.log(this.map.listImages())

        const { instanceLayers , instanceSources } = this.state ; 
        const resp = await getAllInstances() ; 
        const instances = resp.data.inst ; 
        if (instances.length<1) return console.log('no object')
        
        for(let i = 0; i<instances.length; i++){
            let layer = `layer${i}` ; 
            switch (instances[i].type) {

                case 'path' :
                case 'pencil': {

                    let coordinates = JSON.parse(instances[i].info)
                    let geoJson = coordinates.map(((v) => { return [v.lng , v.lat] })) ; 
                    const color = instances[i].type === 'path' ? colorsMap[0].pathColor  : colorsMap[0].pencilColor 

                    this.map.addSource(layer, {
                        'type': 'geojson',
                            'data': {
                            'type': 'FeatureCollection',
                            'features': [
                                    {
                                    'type': 'Feature',
                                    'properties': {
                                        'color': color
                                    },
                                    'geometry': {
                                        'type': 'LineString',
                                        'coordinates': geoJson
                                    }
                                }
                            ]
                        }
                    })
                    this.map.addLayer({
                            'id': layer,
                            'type': 'line',
                            'source': layer,
                            'paint': {
                                'line-width': 2,
                                'line-color': ['get', 'color']
                            }
                    });

                    instanceSources.push(layer)
                    instanceLayers.push(layer)
                    
                    break; 
                }
                case 'area' : {

                    let coordinates = JSON.parse(instances[i].info)
                    let geoJson = coordinates.map(((v) => { return [v.lng , v.lat] }))
                    
                    this.map.addSource(layer, {
                            'type': 'geojson',
                            'data': {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Polygon',
                                'coordinates': [ geoJson ]
                                }
                            }
                    });
                    this.map.addLayer({
                            'id': layer,
                            'type': 'line',
                            'source': layer,
                            'layout': {},
                            'paint': {
                                'line-color': colorsMap[0].areaColor,
                                'line-width': 2
                            }
                    });
                    instanceSources.push(layer)
                    instanceLayers.push(layer)
                    
                    break; 
                }
                case 'marker' : {
                    let coordinates = JSON.parse(instances[i].info).reverse()
                    this.map.addSource(layer, {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            'features': [
                                {
                                    'type': 'Feature',
                                    'geometry': {
                                        'type': 'Point',
                                        'coordinates': coordinates
                                    },
                                    'properties': {
                                        icon: 'mapbox-marker-icon-blue',
                                        // icon: 'marker-solid-blue',
                                        'vicinity': instances[i].vicinity, 
                                        'title': instances[i].name, 
                                        'desc': instances[i].desc, 
                                        'by': instances[i].by, 
                                    }
                                }
                            ]
                        }
                    });

                    this.map.addLayer({
                            'id': layer,
                            'type': 'symbol',
                            'source': layer,
                            layout: {
                                'icon-image': ['get', 'icon']
                            }
                    });

                    let popup = new mapboxgl.Popup({
                        closeButton: false,
                    });
                    
                    const _this = this;
                    this.map.on('mousemove', layer, function (e) { 
                        if (_this.state.plzTooltip === false) return ;
                        popup.setLngLat(e.lngLat).setHTML(
                            
                            "<div style=color:#555555;text-align:center;font-family: Axiform;>" +
                            '<span style="text-transform:capitalize ; color: #555555 ; border-bottom:2px solid #1B72F5; font-weight:bolder">'+ e.features[0].properties.title + "</span>" +
                            '<div class="mt-1 p-1">' + e.features[0].properties.desc + "</div>" +
                            '<div class="mt-2"><img src="' + process.env.PUBLIC_URL + '/collab/marker-small-icon.svg">' + 
                            '<span class="ml-1" ">'+ e.features[0].properties.vicinity + '</span>' +   
                            '</div>' +
                            '<div class="mt-2"><img width="14px" src="' + process.env.PUBLIC_URL + '/collab/user-marker.png">' + 
                            '<span class="ml-1" ">'+ e.features[0].properties.by + '</span>' +   
                            '</div>' 
                            
                        ).addTo(_this.map);
                    });

                    this.map.on('mouseleave', layer, function () {
                        popup.remove();
                    });

                    instanceSources.push(layer)
                    instanceLayers.push(layer)

                    break ; 
                }
            }
        }            
    }



    render() {

        const { plzSelections , download , threeD } = this.state ; 

        return (
            <>
                <div>
                    <div
                        style={{width: '100%', height: '100vh'}}
                        ref={el =>  this.mapContainer = el}
                    >
                    <div style={{position: 'absolute' , zIndex: 77 , width:'100%'}}>
                        <NavBar  isAdd={true} />
                    </div>

                    <div onClick={ ()=>{ this.setThreeD(threeD)} }>
                        <Switch on={'2D'} off={'3D'} bottom={'26vh'} />
                    </div>
                    <div onClick={ this.handleInstances }>
                        <Bulb on={this.state.showInstances} bottom={'39vh'} />
                    </div>
                    {   plzSelections.length>0 &&  download.length>0 && this.props.data && 
                        <BottomPanel
                            data={ plzSelections }
                            download = { download }
                        />
                    }
                    </div>
                </div>
            </>
         );
    }
}

Map.contextType = ExploreContext ;
export default Map;





