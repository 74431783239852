import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Chart from "react-apexcharts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


// const bg = ["#FBF8F1" , "#F7ECDE" , "#DFDFDE" , "#F7F5F2" , "#E9DAC1" , "#DFDFDE" , "#DFDFDE" ]
const bg = [ "#F7F5F2" , "#F7F5F2" , "#F7F5F2" , "#F7F5F2", "#F7F5F2", "#F7F5F2", "#F7F5F2" ]
const textcol = ["#008ffbD9" , "#00e396D9" , "#feb019D9" , "#ff4560D9" , "#775dd0D9" , "#008ffbD9" , "#00e396D9" ]

const RacesChart = ({dts}) => {
    const series = Object.values(dts);
    const options = {
            chart: {
                height: 280,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    startAngle: 0,
                    hollow: {
                        size: "30%",
                    },
                    dataLabels: {
                        name: {
                            fontSize: '16px',
                        },
                        value: {
                            fontSize: '16px',
                        },
                        total: {
                            show: true,
                            label: "White",
                            formatter: function (w) {
                                return dts["White"] + "%"
                            }
                        }
                    }
                }
            },
            labels: Object.keys(dts),
        }

    return (
        <div className='Races-description'>
            <div className='Races-map'>
                <Chart options={options} series={series} type="radialBar" height={268} />
            </div>
            <div className='Races-details'>
                {Object.keys(dts).map(function(key, index) {
                    /*
                    let bgColorCode = '#' + (Math.floor(dts[key] * 16777215).toString()).substring(0,6)+'1f';
                    let textColorCode = '#' + (Math.floor(dts[key] * 16777215).toString()).substring(0, 6);
                    */ 
                    let bgColorCode = bg[index];
                    let textColorCode = textcol[index]
                    
                    return <div className='Races-data' key={index} style={{backgroundColor: `${bgColorCode}` }}>
                        <div className='Races-data-text' style={{color: `${textColorCode}`}}>{key}</div>
                        <div className='Races-data-count' style={{color: `${textColorCode}`}}>{dts[key]}</div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default RacesChart ; 