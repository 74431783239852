import React from 'react'
import { Link } from "react-router-dom"

function index({ title }) {
    return (
        <div className='dashboard-wrapper-info'>
            <div className='container' >
                <div className='dashboard-content-wrapper-main-info'>
                    <div className='dashboard-content-wrapper-info' style={{backgroundColor: "#FFF6ED"}}>
                        <div className="mt-3 text-center align-items-center justify-content-center">
                            <div className='dashboard-info-block-info'>
                                <h4 style={{ color: "#4F4F4F" }}> { title } </h4>
                                <hr className="mr-5 ml-5"/>
                                <p className="mr-3 ml-3"> 
                                    Based on your company data and our high-quality location data such as demographics, consumer styles, footfall traffic, etc., we can together 
                                    predict the success of your company by measuring business and marketing relevant KPI's across any chosen point of interests.
                                    Our enterprise version costs less than hiring a team of Data Scientists to analyze location data.
                                </p>
                            </div>
                            <div id="Sign-up-reuse" className='d-flex-row mt-3 mb-2'>
                                <a className="mr-3" href="https://calendly.com/zipscore/30min"> <button className="global-btn-primary"> Book a Demo </button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default index


// it costs less than how much it will take for you hire a good data analyst or scientist. 
// If you would like to compare your KPI's against the ZIP Codes