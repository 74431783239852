import http from "../services/httpService";
import { addEntry } from "./lookupService"; 
import config from "../config/api.js";
const { apiUrl_dev, apiUrl_prod } = config;


const apiUrl = process.env.REACT_APP_ENVIRON === "development" 
               ? apiUrl_dev
               : apiUrl_prod


function addEntryBillboards() {

    const date= new Date(); 
    const today = date.setHours(0,0,0,0) ; 
    var entry = JSON.parse(localStorage.getItem("outdoor-entry"));
    var newentry = { "ts" : today , val: 1 } ;
  
    if (entry == null || entry["ts"] !== today  ) {
        localStorage.setItem("outdoor-entry", JSON.stringify(newentry));
        return 1; 
    } else {
        var append = { "ts" : entry["ts"] , val: entry["val"]+1 } ; 
        localStorage.setItem("outdoor-entry", JSON.stringify(append));
        return entry["val"]+1 ; 
    }
};

// change to getZipcodeResultFree 
export function getBillboard (country , geolevel, location) {
    return http.post(`${apiUrl}/billboard/${country}/${geolevel}`, {
        country: country , 
        location: location ,
        entry : addEntryBillboards() 
    });

}
export function getBillboardByRef (country , ref) {
    return http.post(`${apiUrl}/billboard/${country}/ref`, {
        country: country , 
        ref: ref ,
        entry : addEntryBillboards() 
    });   
}

