import React, { Component } from "react";
import './styles/TabStyle.css'
import PaywallUpgradeLight from "../../common/subscription/paywallUpgradeLight"


class Tab3 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: this.props.filterList
        }
    }

    onSelectOption = (item) => {
        const { list } = this.state
        if(!list[item].required) {
            this.props.onSelectionChange(item, !list[item].visible)
        } else {
            console.log("Not Allowed")
        }
    }

    render() {
        const { list } = this.state ;
        const { freeVersion } = this.props ; 

        return (
            <div /* className={"container"}*/ >

                {
                    freeVersion && 
                    <>
                        <div className="paywall-div-image-tab1"></div>
                        {/* <PaywallUpgrade /> */}
                        <div className="tab1PremiumFeatureText">
                            <PaywallUpgradeLight text={"to select or deselect demographic attributes"}/>
                        </div>

                    </>

                }

                {
                   !freeVersion && 
                    <>
                        {   
                                Object.keys(list).map((item, index) => {
                                return <div className={"mt-3 item"} key={index} onClick={() => this.onSelectOption(item)}>
                                    {/* <p>{capitalize(item)}</p> */}
                                    <p className={'settingsTabText font-weight-bold'}> {list[item].settingsName} </p>
                                    
                                    <div className={"selection"}>
                                        {list[item].required && <span className={"defaultLabel"}>Default</span>}
                                        <div className={"selectionContainer"}>
                                            <div className={`selectionInner ${list[item].required || list[item].visible ? 'activatedList' : '' }`} />
                                        </div>
                                    </div>
                                </div>
                        })}
                    </>
                }
            </div>
        );
    }
}

export default Tab3;
