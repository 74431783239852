import { de_placeholdersContent, us_placeholdersContent } from "./placeholders.js" ;


export const DeSelections = [
    
    {
        age: {
            data : [
                    { value: "Age_0to9_pct" , label: "0 to 9", selected: false},
                    { value: "Age_10to24_pct" , label: "10 to 24", selected: false} ,
                    { value: "Age_25to39_pct" , label: "25 to 39", selected: false} ,
                    { value: "Age_40to65_pct" , label: "40 to 65", selected: false} , 
                    { value: "Age_65_plus_pct" , label: "> 65", selected: false}
            ],
            required: false, 
            visible: true, 
            placeholder: de_placeholdersContent[0],
            settingsName : "Age Group"
        },
        
        income: {
            data : [
                { value: "income_below_1500_pct" , label: "< 1500 €", selected: false},
                { value: "income_1500_to_2600_pct" , label: "1500 to 2600 €", selected: false} , 
                { value: "income_2600_to_4000_pct" , label: "2600 to 4000 €", selected: false} , 
                { value: "income_4000_to_7500_pct" , label: "4000 to 7500 €", selected: false} ,
                { value: "income_7500_and_more_pct" , label: "> 7500 €", selected: false}
            ],
            required: false, 
            visible: true,
            placeholder : de_placeholdersContent[2],
            settingsName : "Household Income (Monthly)"
        },
        gender: {
            data : [
                { value: "prop_male_pct" , label: "male", selected: false},
                { value: "prop_female_pct" , label: "female", selected: false}
            ],
            required: false, 
            visible: true,
            placeholder: de_placeholdersContent[1],
            settingsName: "Gender"
        },

    }
]

export const DeFreeSelections = [
    {

        age: {
            data : [
                    { value: "Age_0to9_pct" , label: "0 to 9", selected: false},
                    { value: "Age_10to24_pct" , label: "10 to 24", selected: false} ,
                    { value: "Age_25to39_pct" , label: "25 to 39", selected: false} ,
                    { value: "Age_40to65_pct" , label: "40 to 65", selected: false} , 
                    { value: "Age_65_plus_pct" , label: "> 65", selected: false}
            ],
            required: false, 
            visible: true, 
            placeholder: de_placeholdersContent[0],
            settingsName : "Age Group"
        }, 
        income: {
            data : [],
            required: false, 
            visible: false,
            placeholder : de_placeholdersContent[2],
            settingsName : "Household Income (Monthly)"
        },
        gender: {
            data : [],
            required: false, 
            visible: false,
            placeholder: de_placeholdersContent[1],
            settingsName: "Gender"
        },
    }
]


export const DeFavorites = [
    {
        text: "Young Consumers" ,
        filters: [
            { value: "Age_10to24_pct"  , label: "10 to 24" },
        ] ,
        label: "Age group 10 to 24 years >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo"
    },
    {
        text: "Young Adults" ,
        filters: [
            { value: "Age_25to39_pct"  , label: "25 to 39" },
        ] ,
        label: "Age group 25 to 39 years >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo"
    },
    {
        text: "Middle-Aged Adults" ,
        filters: [
            { value: "Age_40to65_pct"  , label: "40 to 65" },
        ] ,
        label: "Age group 40 to 65 years >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo"
    },
    {
        text: "Old Consumers" ,
        filters: [
            { value: "Age_65_plus_pct"  , label: "> 65" },
        ] ,
        label: "Age group 65 years & above >= 90 percentile" , 
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo"
    },
    {
        text: "High Income Households" ,
        filters: [
            { value: "income_7500_and_more_pct"  , label: "> 7500" },
        ],
        label: "Monthly household income 7500 & above >= 90 Percentile " ,
        percentile : 90, 
        percentileSelect : "greaterThanEqualTo"
    },
    {
        text: "Low Income Households" ,
        filters: [
            { value: "income_below_1500_pct"  , label: "< 1500 €" },
        ],
        label: "Monthly household income below 1500€ >= 90 Percentile " ,
        percentile : 90, 
        percentileSelect : "greaterThanEqualTo"
    },
    {
        text: "Households With Children" ,
        filters: [
            { value: "Age_0to9_pct"  , label: "0 to 9"},
        ] ,
        label: "Age group 0 to 9 years >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo"
    },
    {
        text: "High Female Population" ,
        filters: [
            { value: "prop_female_pct"  , label: "female" },
        ] ,
        label: "Female population >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo"
    },
    {
        text: "High Male Population" ,
        filters: [
            { value: "prop_male_pct"  , label: "male" },
        ] ,
        label: "Male population >= 90 percentile" ,
        percentile: 90 , 
        percentileSelect : "greaterThanEqualTo"
    },
    {
        text: "Young Male Adults" ,
        filters: [
            { value: "Age_25to39_pct"  , label: "25 to 39" },
            { value: "prop_male_pct"  , label: "male" },
        ] ,
        label: "Age group 25 to 39 years & Male population >= 75 percentile" ,
        percentile: 75 , 
        percentileSelect : "greaterThanEqualTo"
    },
    {
        text: "Young Female Adults" ,
        filters: [
            { value: "Age_25to39_pct"  , label: "25 to 39" },
            { value: "prop_female_pct"  , label: "female" },
        ] ,
        label: "Age group 25 to 39 years & female population >= 75 percentile" ,
        percentile: 75 , 
        percentileSelect : "greaterThanEqualTo"
    },
    {
        text: "Middle-Aged Male Adults" ,
        filters: [
            { value: "Age_40to65_pct"  , label: "40 to 65" },
            { value: "prop_male_pct"  , label: "male" },
        ] ,
        label: "Age group 40 to 65 years & male population >= 75 percentile" ,
        percentile: 75 , 
        percentileSelect : "greaterThanEqualTo"
    },
    {
        text: "Middle-Aged Female Adults" ,
        filters: [
            { value: "Age_40to65_pct"  , label: "40 to 65" },
            { value: "prop_female_pct"  , label: "female" },
        ] ,
        label: "Age group 40 to 65 years & female population >= 75 percentile" ,
        percentile: 75 , 
        percentileSelect : "greaterThanEqualTo"
    },


]

// https://www.beresfordresearch.com/age-range-by-generation/


export const UsaSelections = [
    {
        age: {
            data : [
                    { value: "pct_Age_0to9" , label: "0 to 9", selected: false},
                    { value: "pct_Age_10to24" , label: "10 to 24", selected: false} ,
                    { value: "pct_Age_25to39" , label: "25 to 39", selected: false} ,
                    { value: "pct_Age_40to64" , label: "40 to 64", selected: false} , 
                    { value: "pct_Age_64_plus" , label: "> 64", selected: false}
            ],
            required: false, 
            visible: true, 
            placeholder: us_placeholdersContent[0] ,
            settingsName : "Age Group"
        },
        
        income: {
            data : [
                { value: "pct_below_35K", label: "below $35,000", selected: false},
                { value: "pct_35K_to_50K", label: "$35000 to $49,999", selected: false} , 
                { value: "pct_50K_to_100K", label: "$50,000 to $99,999", selected: false} , 
                { value: "pct_100K_to_150K", label: "$100,000 to $149,999", selected: false} ,
                { value: "pct_above_150K", label: "above $150,000", selected: false}
            ],
            required: false, 
            visible: true,
            placeholder : us_placeholdersContent[2],
            settingsName : "Household Income (Annual)"
        },
        gender: {
            data : [
                { value: "pct_male" , label: "male", selected: false},
                { value: "pct_female" , label: "female", selected: false}
            ],
            required: false, 
            visible: true,
            placeholder: us_placeholdersContent[1],
            settingsName: "Gender"
        },

    }
]

export const UsFreeSelections = [
    {

        age: {
            data : [
                { value: "pct_Age_0to9" , label: "0 to 9", selected: false},
                { value: "pct_Age_10to24" , label: "10 to 24", selected: false} ,
                { value: "pct_Age_25to39" , label: "25 to 39", selected: false} ,
                { value: "pct_Age_40to64" , label: "40 to 64", selected: false} , 
                { value: "pct_Age_64_plus" , label: "> 64", selected: false}
            ],
            required: false, 
            visible: true, 
            placeholder: de_placeholdersContent[0],
            settingsName : "Age Group"
        }, 
        income: {
            data : [],
            required: false, 
            visible: false,
            placeholder : de_placeholdersContent[2],
            settingsName : "Household Income (Monthly)"
        },
        gender: {
            data : [],
            required: false, 
            visible: false,
            placeholder: de_placeholdersContent[1],
            settingsName: "Gender"
        },
    }
]
