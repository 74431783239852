import React , { useEffect }  from 'react'; 
import Logo from "../../../../../containers/logo.svg" ; 
import { Link } from 'react-router-dom';
import './style.css';
import mapboxgl from "mapbox-gl"
import _ from 'lodash';
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
// import usericon from '../../images/user.png'

const Header = () => {

    useEffect(()=> {

        const geocoder = new MapboxGeocoder({
            accessToken:
              "pk.eyJ1IjoibWotZXBzaWxvbiIsImEiOiJja24wcWV5eTcwcTY5MnZwazA0cTRxMTlwIn0.powZMmJIS2FoR4JY1DFSGg",
            mapboxgl: mapboxgl,
            marker: false,
            placeholder: 'Search any location in Germany', 
            types: "address, postcode" , 
            flyTo: false,
            countries: "DE"
          })
          geocoder.addTo('#geocoder-header');
          
          geocoder.on("result", (e) => {
            setTimeout(() => {
              geocoder.clear()
            }, 10000);
            
            const lng = e.result.center[0] ; 
            const lat=  e.result.center[1] ;
      
            const countryObj = _.filter(e.result.context , (obj) => {
              return obj.id.indexOf("country") !== -1;
            });
            const country = countryObj[0].short_code ; 
      
            if (e.result.place_type[0] && e.result.place_type[0] == "postcode") {
        
                const zipcode = e.result.text ; 
                const url = `/${country}/${zipcode}/${lng}/${lat}?ht`
                return window.open( url , "_self");
        
              } else {
        
                const zipObj = _.filter(e.result.context , (obj) => {
                  return obj.id.indexOf("postcode") !== -1;
                });
        
                const zipcode = zipObj[0].text
                const url = `/${country}/${zipcode}/${lng}/${lat}?ht`
                return window.open(url , ",_self" );
            }
      
          })
          
    }, [])





  return (
    <div className='header-wrapper'>
        <div className='container'>
            <div className='header-nav'>
                <div className='row'>
                    <div className='header-right-block'>
                        <div className='header-logo'>
                            {/* <img src={logoimg}/> */}
                            <Link to={`/`}> <img src={Logo} alt="" /> </Link>
                        </div>
                    </div>
                    <div className='header-left-block'>
                        <div className='serch-block'>
                            <div className="serch-wrapper" id="geocoder-header"></div>
                        </div>
                        {/* <div className='account-block-wrapper'>
                            <div className='account-block'>
                                <div className='myaccount-icon'><img src={usericon} /></div> 
                                <div className='myaccount-text'>Add User</div>
                            </div>
                        </div>
                        <div className='menu-block'>
                            <div className='menu-dropdown'>
                                <select className='menu-title'>
                                    <option>M</option>
                                    <option>F</option>
                                </select>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header