import React, { useEffect, useState } from 'react';
import { useHistory , Redirect } from "react-router-dom";
import { geoCentroid } from "d3-geo";
import { Helmet } from "react-helmet";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    Annotation
} from "react-simple-maps";

import '../style.css';
import NavBar from "../../../../common/NavBar/navbar.jsx"
import DashboardInfo from "../../../datas/us/DashboardInfo/index.js" ; 
import Info from "../../../datas/us/Info/index.js" ; 
import Authwall from "../../../datas/us/Authwall/index.js"
import Demowall from "../../../datas/us/Demowall/index.js"
import allStates from "../allstates.json";
import { getStatesList } from "../../../../../services/browseService"
import LoadingPage from '../../../../common/tools-ui/loadingPage';
import Tabular from "../nonauth/tables/state-table"

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets = {
    VT: [50, -8],
    NH: [34, 2],
    MA: [30, -1],
    RI: [28, 2],
    CT: [35, 10],
    NJ: [34, 1],
    DE: [33, 0],
    MD: [47, 10],
    DC: [49, 21]
};

const States = () => {

    const [stateData, setStateData]= useState([]); 

    const [loading, setLoading] = useState(true) ; 
    const [exception, setException] = useState(false) ; 
    const history = useHistory() ; 

    useEffect(() => {
        getStates()
    }, [])

    const handler = (event) => {
        // console.log('event in handler', event)
    };

    const getStates = async() => {
        try {
            const resp = await getStatesList("us")
            setLoading(false)
            setStateData([...resp.data])
            
        } catch (error) {
            setLoading(false)
            setException(true)
            
        }
    }

    const onClickHandler = async (data) => {
        
        const cur = allStates.find(s => s.val === data.id);
        const searchparam = stateData.find(s => s.state === cur.name) ; 
        history.push(`/lookup/us/${ encodeURIComponent(searchparam.state_key) }`) 
    }

    const text=  <React.Fragment>
        <div className="text-justify">
            <p className="mt-3 mb-2">
            Businesses not only need the demographic data of the states in the US including age, gender, 
            education, ethnicity and employment statistics but also need other location data such as footfall traffic 
            and consumer styles. Also, easy access to the location data and demographic data allows businesses to
             make informed decisions on market trends and consumer behaviour. The US is the third-largest country in the world.
              California, Texas, Florida, New York, and Pennsylvania are the five most populous US states. 
              North Dakota, Alaska, Vermont and Wyoming are the least populous US states. 
              The United States postal code is a five-digit number that identifies a particular 
              delivery route within the United States. To access location and demographic data, 
              click on the map below, and you will have access to 
              demographic and location data which can be drilled down to the ZIP codes and cities in the selected state. 
            </p>
        </div>
    </React.Fragment>  

    const textlist = <React.Fragment>
        <div className="d-flex align-items-center mt-2 mb-2">
            <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662810349/free-tools/list.svg' style={{width:"47px", marginRight:"12px"}}/>
            <h4> Demographics by US ZIP Codes, Cities & States </h4>
        </div>
        <div className="text-justify">
            <p className="mt-3 mb-2"> 
                Government departments like the Census Bureau, US Data and Statistics, and US Bureau of Labor Statistics release data that are important to the individual department. 
                But Marketing researchers, Data Analysts or Data Scientists often have to comb through tons of websites, use complicated data analytics tools to input and interpret data 
                and use visualization tools to utilize this data for geo marketing, market segmentation or finding the best suited audience for their businesses. 
                <br/>
                To access demographic data by  select state from the list of statesus zip code list below - 

            </p>
            <hr/>
            <div className='mt-4 justify-content-left align-items-center'>             
            {              
                <Tabular data={stateData && stateData}/>     
                // stateData?.map((item) => { 
                //     return <div key={item.state_key}>
                //                 <a href={`/lookup/us/${encodeURIComponent(item.state_key)}`}> {item.state} </a> 
                //         </div>
                // })             
            }
            </div>
        </div>
    </React.Fragment>

    if(exception) return <Redirect to="/404"/> ; 

    return (
        <div className='content-wrapper'>
            <Helmet> <title> {`Lookup | US ZIP Codes, Cities & States`} </title> </Helmet>
            <NavBar isAdd={true} bgwhite={true}/>
            <DashboardInfo title={'US ZIP Codes, Cities & States '} description={text} />
            {
                !loading && 
                <>
                    <div className='container mb-3'>
                        <div className='state-wrapper'>
                            <div className='state-map'>
                                <ComposableMap projection="geoAlbersUsa">
                                    <Geographies geography={geoUrl}>
                                        {({ geographies }) => (
                                            <>
                                                {geographies.map(geo => (
                                                    <Geography
                                                        key={geo.rsmKey}
                                                        stroke="#FFF"
                                                        geography={geo}
                                                        fill="#d3d6be"
                                                        onClick={() => onClickHandler(geo)}
                                                    />
                                                ))}
                                                {geographies.map(geo => {
                                                    const centroid = geoCentroid(geo);
                                                    const cur = allStates.find(s => s.val === geo.id);
                                                    return (
                                                        <g  key={geo.rsmKey} /*onClick={() => handler(cur?.id)} key={geo.rsmKey + "-name"}*/>
                                                            {cur &&
                                                                centroid[0] > -160 &&
                                                                centroid[0] < -67 &&
                                                                (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                                                                    <Marker coordinates={centroid}>
                                                                        <text y="2" fontSize={14} textAnchor="middle">
                                                                            {cur.id}
                                                                        </text>
                                                                    </Marker>
                                                                ) : (
                                                                    <Annotation
                                                                        subject={centroid}
                                                                        dx={offsets[cur.id][0]}
                                                                        dy={offsets[cur.id][1]}
                                                                    >
                                                                        <text x={4} fontSize={14} alignmentBaseline="middle">
                                                                            {cur.id}
                                                                        </text>
                                                                    </Annotation>
                                                                ))
                                                            }
                                                        </g>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </Geographies>
                                </ComposableMap>
                            </div>
                        </div>
                    </div>
                    <Demowall title={`Predict success of your business.`}/>
                    <Info description={textlist} />
                </>                                    
            }
            { loading && <LoadingPage /> }
        </div>

    )
}

export default States ;  

