import http from "../services/httpService";

import config from "../config/api.js";
const { apiUrl_dev, apiUrl_prod } = config;

const apiUrl = process.env.REACT_APP_ENVIRON === "development" 
               ? apiUrl_dev
               : apiUrl_prod

/*
export function getPercentile( value ) {
    // console.log( 'called service +++++ ' ,  http.get(`${apiUrl}/explore/percentile/${ country }`))
    return http.get(`${apiUrl}/explore/${value}/percentile/` );
}
*/

export function getPercentileFilter( country , filters, cond, value ) {
    return http.post(`${apiUrl}/explore/${country}/percentile/filter` , {
        filters: filters, 
        cond: cond, 
        value: value
    });
}

export function getUsRule( country , statekey , label , zip) {
    return http.post(`${apiUrl}/explore/${country}/rule/${label}` , {
        statekey: statekey, 
        zip: zip
    });
}


export function getGptCompletion( country , prompt ) {
    return http.post(`${apiUrl}/explore/${country}/completion` , {
        prompt: prompt
    });
}


export function getCopilotCompletion( country , prompt ) {
    return http.post(`${apiUrl}/explore/${country}/copilot/completion` , {
        prompt: prompt
    });
}

export async function getCopilotCompletionStream( country , prompt ){
    const headers = {
        "Accept": "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "x-auth-zips": localStorage.getItem('x-auth-zips'),
        "access-control-expose-headers": "x-auth-zips"
    };

    const response = await fetch(`${apiUrl}/explore/${country}/copilot/completion`, {
        method: "post",
        headers: headers,
        body: JSON.stringify({ prompt: prompt }),
    });

    return response;
}

export function getCopilotConversationHistory(){
    return http.post(`${apiUrl}/explore/copilot/conversation/history`)
}

export function archiveConversationHistory(){
    return http.post(`${apiUrl}/explore/copilot/conversation/history/archive`)
}