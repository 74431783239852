import React, { Component } from 'react';
import mapboxgl from "mapbox-gl";
import './style.css';
import "mapbox-gl/dist/mapbox-gl.css";
import resident from '../../../../common/shared-charts/images/resident.png';
import GenderChart from '../../../../common/shared-charts/GenderChart';
import Switch from "../../../../common/tools-ui/switch" ; 
import { getAllInstances } from "../../../../../services/scribbleService";
import Bulb from "../../../../common/tools-ui/Bulb";
import { colorsMap, dropzoneCountries, mapboxStyle , mapboxToken } from "../../../../../global" ;

class Map extends Component {
    state = {
        //lng: 10.492188 ,
        //lat: 50.690426 ,
        zoom: 5.3,
        plzSelections: [],
        plzTooltip : true, 
        selectedPopulation: 0 , 
        selectedHouseholds: 0 , 
        download: [], 
        threeD: false,        
        instanceLayers: [] , 
        instanceSources: [], 
        showInstances: true
    };

    addLayer = (tileset) => { 
        this.map.addSource('postleitzahlen', {
            type: 'vector',
            url: tileset
        });
        
        this.map.addLayer(
            {
                'id': 'zipcode',
                'type': 'fill',
                'source': 'postleitzahlen', 
                'source-layer': 'postleitzahlen',
                'paint': {
                    'fill-opacity': 0.3,
                    'fill-color': '#8FB5F8',
                },
            },
            'waterway-label'
        );

        this.map.addLayer(
            {
                'id': 'zipcode-boundary',
                'type': 'line',
                'source': 'postleitzahlen', 
                'source-layer': 'postleitzahlen',
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-opacity': 0.2,
                    'line-color': 'grey',
                    'line-width': 0.5,
                }
            },
            'waterway-label'
        )
    }

    componentDidMount() {

        const mapAttri = dropzoneCountries.filter((o)=> {
            return o.value === this.props.country
        })

        mapboxgl.accessToken = mapboxToken ; 
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: mapboxStyle,
            center: [mapAttri[0].lng, mapAttri[0].lat],
            zoom: this.state.zoom,
            attributionControl: false, 
            // pitch: 90
        });

        const thiss = this;

        this.map.on('load', () => {

            this.addLayer(mapAttri[0].tileset) ;
            // this.plotBillboards() ;

            this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
            this.map.addControl(new mapboxgl.FullscreenControl(), 'bottom-right');
            this.map.setFilter('zipcode', ['in', 'postcode', ...this.props.areas ]) ;
            // this.map.setFilter('zipcode-boundary', ['in', 'postcode', ..."10001", "10002", "10003" ]) ;
            this.map.setPaintProperty("zipcode", 'fill-color', "#8FB5F8");


            this.map.setFilter('zipcode-boundary', ['in', 'postcode', ...this.props.areas ]) ;
            //this.map.setFilter('zipcode-boundary', ['in', 'postcode', ..."10001", "10002", "10003" ]) ;
            this.map.setPaintProperty("zipcode-boundary", 'line-color', "#F75E04");
            this.map.setPaintProperty("zipcode-boundary", 'line-width', 0.5);
            this.map.setPaintProperty("zipcode-boundary", 'line-opacity', 0.8);
        });

        let popup = new mapboxgl.Popup({
            closeButton: false,
        });

        // display popup zip code and sales value
        thiss.map.on('click', 'zipcode', function (e) {
            // this.map.getCanvas().style.cursor = 'pointer';
            if (thiss.state.plzTooltip === false) return;
            popup.setLngLat(e.lngLat).setHTML(
                "<div style=color:grey;text-align:center;>" +
                "<div>" + e.features[0].properties.postcode + "</div>" +
                "<div>" + e.features[0].properties.locality + "</div>" +
                "</div>"
            ).addTo(thiss.map);

        });

        thiss.map.on('mouseleave', 'zipcode-boundary', function () {
            popup.remove();
        });

        this.map.flyTo({
            // center: [mapAttri[0].lng, mapAttri[0].lat],
            center: this.props.center ,
            // zoom: this.props.country === "us" ? 4 : 6,
            // zoom: 6,
            zoom: this.props.zoom,
            bearing: 0,
            speed: 0.9, // make the flying slow
            curve: 1, // change the speed at which it zooms out
            easing: (t) => t,
            essential: true
        });
    }

    componentWillUnmount() {
        this.map.remove();
    }

    setThreeD = (val) => {
        setTimeout(()=>{ 
            const pitch = !val ? 0 : 90
            const bearing = !val ? 0 : 0
            
            this.map.setPitch(pitch)
            this.map.setBearing(bearing)
            this.setState({threeD : !val})
        } , 500)       
    }


    plotBillboards = async() => {

        const { instanceLayers , instanceSources } = this.state ; 

        // console.log(this.props.dts[0].billboard) ; 
        // const resp = await getAllInstances() ; 
        // const instances = resp.data.inst ; 
        
        const instances = this.props.dts[0].billboard ; 

        console.log('instances ++ ++ ', instances)
        console.log(this.props)

        if (instances.length<1) return console.log('no object')
        
        for(let i = 0; i<instances.length; i++){
            let layer = `layer${i}` ; 

            const sameInstance = instances.filter(m => m.lat === instances[i].lat && m.lng === instances[i].lng);
            

                    this.map.addSource(layer, {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            'features': [
                                {
                                    'type': 'Feature',
                                    'geometry': {
                                        'type': 'Point',
                                        'coordinates': [instances[i].lng, instances[i].lat],
                                         
                                    },
                                    'properties': {
                                        icon: 'livelocation',
                                        // icon: sameInstance.length>1 ? 'billboard-multiple': 'billboard-iconduck',
                                        // icon: 'billboard-iconduck',
                                        'size': instances[i].size, 
                                        'type': instances[i].type, 
                                        'face': instances[i].face, 
                                        'address' : instances[i].address
                                        // 'by': instances[i].by, 
                                    }
                                }
                            ]
                        }
                    });

                    this.map.addLayer({
                            'id': layer,
                            'type': 'symbol',
                            'source': layer,
                            layout: {
                                'icon-image': ['get', 'icon'],
                                'icon-allow-overlap': true
                            }
                    });

                    let popup = new mapboxgl.Popup({
                        closeButton: !false,
                    });
                    
                    const _this = this;
                    this.map.on('mousemove', layer, function (e) { 

                        let {size, type, face , address} = e.features[0].properties; 

                        let text= `${size}" ${type && type.toLowerCase()} facing ${face && face.toLowerCase()} for outdoor advertising near ${address}`;  

                        if (_this.state.plzTooltip === false) return ;
                        popup.setLngLat(e.lngLat).setHTML(
                            
                            "<div style=color:#555555;text-align:left;font-family: Axiform;>" +
                            '<div class="mt-1" ">'+ text + '<br /> <a class="hover-underline-animation font-weight-bold" href="/us/ohio/zip-code-map"> More Info </a></div>' +   
                            //'<div class="mt-1 hover-underline-animation"> <a href="/us/ohio/zip-code-map">' + instances.length + ' advertising billboards in '+ _this.props.zip + '</a> </div>' +
                            '</div>' 
                            
                        ).addTo(_this.map);
                    });

                    instanceSources.push(layer)
                    instanceLayers.push(layer)
            
            }                    
    }

    render() {

        const { threeD } = this.state ; 
        const { dts , attributes , zip , lat , lng , country } = this.props ;
        const { city, state , population , households , old, young } = attributes ;
        const nf = new Intl.NumberFormat() ;

        return (
            <>          
                
                <div className='map-wrapper'>
                    <div className='container'>
                        <div className='map-content-wrapper'>
                            {/* <div className='map-title'>{ zip }, { city }, { state } </div> */}
                            <div className='map-contentblock'>
                                <div className='map-content'>
                                    <div className='map-detail residents'>
                                        <div className='map-details'>
                                            <div className='resident-count d-flex'>
                                                <div>
                                                    <img 
                                                        src="https://res.cloudinary.com/zipscores-collection/image/upload/v1633804937/free-tools/family_q40u3g.svg"
                                                        style={{ width:'47px' , marginRight: '20px' , marginTop: '10px'}} 
                                                        alt="family"
                                                    />  
                                                </div>
                                                <div>
                                                   { nf.format(population) } 
                                                    <div className='resident-title mt-1'> residents live here </div>
                                                </div>
                                            </div>

                                            <div className='resident-count d-flex mt-4'>
                                                <div>
                                                    <img 
                                                        src="https://res.cloudinary.com/zipscores-collection/image/upload/v1633776483/free-tools/house_1_a9sesn.svg"
                                                        style={{ width:'47px' , marginRight: '20px' , marginTop: '10px'}} 
                                                        alt="house"
                                                    />  
                                                </div>
                                                <div>
                                                    { nf.format(households) } 
                                                    <div className='resident-title mt-1'> households </div>
                                                </div>
                                            </div>

                                            <div className='resident-count d-flex mt-4'>
                                                <div>
                                                    <img 
                                                        src="https://res.cloudinary.com/zipscores-collection/image/upload/v1633778015/free-tools/youth_yyf4oo.svg"
                                                        style={{ width:'48px' , marginRight: '20px' , marginTop: '14px'}} 
                                                        alt="youth"
                                                    />  
                                                </div>
                                                <div>
                                                    {
                                                        young.toFixed(2) > old.toFixed(2) ? 
                                                        <>
                                                            {young.toFixed(2)} %
                                                            <div className='resident-title mt-1'> of the population is younger than 40 years of age </div>
                                                        </>
                                                        : <>
                                                            {old.toFixed(2)} % 
                                                            <div className='resident-title'> of the population is older than 40 years of age </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className='map-detail gender'>
                                        <div className='map-details'>
                                            {/* <div className='gender-title'>Gender</div> */}
                                            <h4 className="fontstyleaxiform">Gender</h4>
                                            <GenderChart dts={dts[0].gender} />
                                        </div>
                                    </div>
                                </div>
                                <div className='map-block'>
                                    <div className="mapouter">
                                    <div className="gmap_canvas" ref={el =>  this.mapContainer = el}></div>
                                    <Switch on={'3D'} off={'2D'} bottom={'29.5vh'} /> 
                                        {/* <div className="gmap_canvas" >
                                                <div ref={el =>  this.mapContainer = el} > <div onClick={ ()=>{ this.setThreeD(threeD)} }>            
                                                        </div>
                                                            <div onClick={ this.handleInstances }>
                                                                <Bulb on={this.state.showInstances} bottom={'43vh'} />
                                                            </div>
                                                 </div> 
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                
                
                
                
                
                
                

            </>
         );
    }
}

export default Map;

