import _ from 'lodash';
import React, { Component } from 'react';
import { getPercentileFilter , getUsRule } from '../../../../services/exploreService';
import LoadingPage from "../../../common/tools-ui/loadingPage"  ;
import Desc from "./desc" ; 
import { AreaLabels } from "../questions" ; 
import { dropzoneCountries } from "../../../../global" ;
import { Helmet } from "react-helmet";

class Areas extends React.Component {

    state = {
        loading: true ,  
        areas: [] , 
        country: null , 
        label: null , 
        lat: null, 
        lng : null , 
        desc: null , 
        imgUrl: null , 
        filters: null ,
        downloadData: [] , 
        url: null 
    }

    async componentDidMount() {
        const country = this.props.match.params.country ; 
        // r56sx
        const id = parseInt( this.props.match.params.id ) ; 
        const data = AreaLabels.filter( k => { return k.country === country && k.id === id}) 

        try {

          if ( data[0]?.id >= 1000 ) {
              const resp = await getUsRule(country, data[0].statekey , data[0].label , data[0].zip ) ; 
              const results = resp.data ;

              let plzArray = _.map(results, "plz")
              let downloadData = _.map(results, i => _.pick(i, 'state', 'plz' , 'city' , 'population' , 'households')) ;
              let url = data[0].zip ? `lookup/browse/us/${data[0].statekey}/zipcodes` : `lookup/browse/us/${data[0].statekey}/cities` ; 
              let urldesc= data[0].zip ? `Demographics by ZIP Codes in ${data[0].state} ` : `Demographics by cities in ${data[0].statekey}`

              this.setState({
                areas: [...plzArray],
                country: country,
                loading: !true,
                desc: data[0].desc,
                imgUrl: data[0].imgSource,
                lat: results[5].lat , 
                lng: results[5].lng , 
                url: url , 
                urldesc : urldesc,
                zoom: 6.8 ,
                downloadData: [...downloadData] ,
              });

          } else {
              let textSelections = data[0].filters ;
              let percentileValue = data[0].percentile ;  
              let filterOperator = data[0].percentileSelect ; 

              let keysArray = [] ; 
              Object.keys(textSelections).forEach((item)=> {
                  let x = textSelections[`${item}`]['value'] ; 
                  keysArray.push(x)
              })
              const resp = await getPercentileFilter(country, keysArray, filterOperator, percentileValue) ; 
              const results = resp.data ; 

              if (results.length === 0 ) {
                  window.alert('Something went wrong, please try again!'); 
                  return ; 
              }

              let plzArray = _.map(results, "plz")
              let downloadData = _.map(results, i => _.pick(i, 'state', 'plz' , 'city' , 'population' , 'households')) ;   
              
              const mapAttri = dropzoneCountries.filter((o)=> {
                return o.value === country
              })

              this.setState({
                areas: [...plzArray],
                country: country,
                loading: !true,
                desc: data[0].desc,
                imgUrl: data[0].imgSource,
                filters: data[0]?.label,
                lat: mapAttri[0].lat , 
                lng: mapAttri[0].lng , 
                zoom: mapAttri[0].zoom,
                downloadData: [...downloadData]
            });

          }
        } catch (error) {
          this.props.history.push("/404"); 
        }

    }

    render() {
        const { loading, favorite, areas , desc , imgUrl , downloadData , filters , lat , lng , zoom, url , urldesc } = this.state ; 
        
        return (
          <>
            {loading && <LoadingPage />}
            {!loading && (
              <>
                {
                  this.props.match.params.country === "us" && 
                  // <Helmet> <title> {`Zipscore | ${desc && desc.charAt(0).toUpperCase() + desc.slice(1)}`} </title> </Helmet>
                  <Helmet> <title> {`Zipscore | Audience Segment`} </title> </Helmet>
                }
                {
                  this.props.match.params.country === "de" && 
                  <Helmet> <title> {`Zipscore | Explore Audience `} </title> </Helmet>
                }
                <Desc
                  favorite={favorite}
                  areas={areas}
                  desc={desc}
                  imgUrl={imgUrl}
                  country={this.props.match.params.country}
                  filters={filters}
                  downloadData={ downloadData }
                  lat= {lat}
                  lng= {lng}
                  zoom= {zoom}
                  url= {url}
                  urldesc= {urldesc}
                />

                {/* <div className="mt-5"> { JSON.stringify(attributes) } </div> */}
              </>
            )}
          </>
        );
        
    }
}
 
export default Areas ;