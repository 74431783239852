import React, { Component } from 'react'; 
import BlogItem from "./blogItem" ; 
import styles from "./blog.module.css" ; 
// import Sidebar from "./sidebar" ; 
import LoadingPage from '../common/tools-ui/loadingPage';
import { getEntries } from '../../services/contentService';
import { getCurrentUser } from "../../services/userService" ; 
import NavBar from '../common/NavBar/navbar';
import { Helmet } from "react-helmet";
import Footer from "../landing-page/Footer" ; 

class BlogItems extends Component {
    state = {
        posts: [], 
        postsFiltered: [], 
        tags: [], 
        selectedTag: null, 
        error : false, 
        loading : true
    }

    async componentDidMount() {

        try {
            const response = await getEntries() ; 
            const result = []
            response.data.items.map((p)=> { p.metadata.tags.map((t)=> { result.push(t.sys.id) }) }); 
            const tags = result.filter((e, i, a) => a.indexOf(e) === i)
            
            this.setState({
                loading: !this.state.loading , 
                posts: response.data.items,
                postsFiltered: response.data.items, 
                tags: [...tags]
            })

        } catch (ex) {
            this.setState({loading: !this.state.loading , error : !this.state.error})
        }
    }

    handleTags = (t) => {
        const { posts , selectedTag } = this.state ;
        if (t===selectedTag) {
           return this.setState({postsFiltered: [...posts], selectedTag: null })
        }
        const result = posts.filter(post => post.metadata.tags.filter( tag=> tag.sys.id.includes(t)).length > 0)
        this.setState({ postsFiltered: [...result] , selectedTag: t })
    }

    render() { 
        const { tags, postsFiltered , selectedTag , error , loading } = this.state ; 

        return ( 
            <>
            <Helmet> <title>Blog | Zipscore.ai</title> </Helmet>
            <div className="container-fluid">
            { getCurrentUser() &&  <NavBar user = { getCurrentUser().firstname }/> }
                <div className="row mt-5">                   
                    <div className="col-md-1"></div>
                    <div className="col-md-7">
                        <div className="mr-2"> <span className={styles.blogHeader}>Our Blog &#128075; </span></div>
                        <hr className="mt-3" style={{ width:"100%" }}/>
                        <div className="" style={{marginTop:"-1rem" , marginRight:"-2rem"}}>
                            <span className={styles.filterBy}> filter by:</span>
                            { tags.map((t,i) => 
                                <span 
                                 key={i} 
                                 onClick={(()=>{this.handleTags(t)})} 
                                 className={t === selectedTag? styles.tagsSelected: styles.tags} 
                                > 
                                  #{t}{t===selectedTag && <sup style={{color:'black'}}> X </sup>}
                                </span> 
                                
                            )}
                        </div>
                        {
                            !error && !loading &&
                            <div className="row d-block min-vh-100">   
                                { postsFiltered.map(({fields, sys }, i) =>
                                    <BlogItem key={i} {...fields } {...sys} />
                                )}
                            </div> 
                        }

                        {
                            loading && !error && <LoadingPage />
                        }

                        {
                            error && !loading && 
                            <h3 className="mt-5 ml-5" style={{color:"crimson"}}> Something went wrong. <br/> Please try again later. </h3>
                        }
                    </div>
                    <div className="col-md-3 text-center" >
                            {/* <Sidebar /> */}
                    </div>    
                </div>
            </div>
            {!getCurrentUser() && <Footer />}
            </>
         );
    }
}
 
export default BlogItems;
