import React, { Component } from 'react';
import MapFavorite from "./mapFavorite" ; 
import styles from "./lookup.module.css" ; 
import { Link } from 'react-router-dom';
import NavBar from '../../../common/NavBar/navbar';
import BottomPanel from '../../../common/bottomPanel/bottomPanel';


class Desc extends React.Component {
    render() { 

        const { areas, desc , imgUrl, country , filters , downloadData , lat, lng, zoom , url , urldesc } = this.props ;

        return (
            <>
                <NavBar isAdd={true}/>
                <div className="container-fluid">
                    <div className={`row ${styles.textsDesc}`} style={{ marginTop : '-4.5rem'}}>
                        <div className="col-md-4 text-center align-items-center" style={{marginTop: '0%'}}>
                            <div>
                                <img height='250rem' src={ imgUrl } alt="" />
                            </div>
                            <div className="mt-4">
                                <span className={styles.descNum}>
                                    { areas.length } <br/>
                                </span>
                            </div>
                            <div className="mt-3 pl-4 pr-4" style={{color: '#2A468B' }}>
                                { desc }
                            </div>
                            {
                                filters && 
                                <div className="mt-5">
                                    <hr />
                                    <span style={{fontSize:'0.7rem'}}>
                                    ZIP codes that have <br/> 
                                    { filters } <br/>
                                    are shown. For more granularilty<br/>
                                    use <Link to={ `/explore/filter/${country}` }> filters </Link> functionality
                                    </span>
                                </div> 
                            }
                            {
                                url && 
                                <div className="mt-4">
                                    <hr />
                                    <div className="hover-underline-animation"> <a href={`/${url}`}> { urldesc } </a> </div>
                                </div> 
                            }

                        </div>
                        <div className="col-md-8">
                            <MapFavorite areas={ this.props.areas } country={ country } lat={lat} lng={lng} zoom={zoom} />
                        </div>
                    </div>
                </div>
                { 
                  areas.length>0 && <BottomPanel data = { areas } download = { downloadData } />
                }
            </>
        )
    }
}
 
export default Desc;