import React, { Component } from 'react' ; 
import styles from "./upgrade.module.css" ;
import { createCheckoutSession } from "../../../services/checkoutService"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingPage from "../tools-ui/loadingPage"

class PaywallUpgrade extends Component {

    state = {
        loading : false
    }

    handleCheckoutSession = async() => {
        
        //this.setState({ loading: !this.state.loading })
        const returnUrl = window.location.href ; 
        const res = await createCheckoutSession(returnUrl) ;
        const sessionUrl = res.data.url ; 

        window.location.href= sessionUrl ;
    }
    
    render() { 

        const { loading } = this.state ; 

        return ( 

            <>

            {
                !loading && 
                <div onClick={this.handleCheckoutSession} className={styles.overImageUpgradeButton}>
                    <FontAwesomeIcon style={{ fontSize: "18px" , marginRight: "12px" }}icon={['fa', 'lock']}/>
                    Upgrade
                </div>

            }
            {
                loading && 
                <>
                    <LoadingPage />
                    <div onClick={this.handleCheckoutSession} className={styles.overImageUpgradeButton}>
                        <FontAwesomeIcon style={{ fontSize: "18px" , marginRight: "12px" }}icon={['fa', 'lock']}/>
                        Upgrade
                    </div>
                </>
            }
            </>

         );
    }
}
 
export default PaywallUpgrade;