import React from 'react'
import '../../styles/info.css';

function index({ attributes , zip , description }) {
    return (
        <div className='dashboard-wrapper-info'>
            <div className='container'>
                <div className='dashboard-content-wrapper-main-info'>
                    <div className='dashboard-content-wrapper-info'>
                        <div className='dashboard-info-block-info d-flex'>
                            {/* <img 
                            //  style={{width:"2rem"}}
                             src="https://res.cloudinary.com/zipscores-collection/image/upload/v1658771240/free-tools/light.png"
                             /> */}
                            <div className="mt-1 ml-3"> { description } </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default index