import http from "../services/httpService";

import config from "../config/api.js";
const { apiUrl_dev, apiUrl_prod } = config;

const apiUrl = process.env.REACT_APP_ENVIRON === "development" 
               ? apiUrl_dev
               : apiUrl_prod


// change to getZipcodeResultFree 
export function getZipcodeResult (country , zipcode) {
    return http.post(`${apiUrl}/list/${country}/zipcode/search`, {
        country: country , 
        zipcode: zipcode 
    });
      
}

export function getStatesList(country) {
    return http.get(`${apiUrl}/list/${country}/states`); 
}

// getStateCities
export function getStateCities(country, statekey){
    return http.post(`${apiUrl}/list/${country}/state/cities`, {
        state_key: statekey 
    });
}

// getStateZipcodes
export function getStateZipcodes(country, statekey){
    return http.post(`${apiUrl}/list/${country}/state/zipcodes`, {
        state_key: statekey
    });
}


// getStateCityZipcodes 
export function getStateCityZipcodes(country, statekey, citykey){
    return http.post(`${apiUrl}/list/${country}/state/city/zipcodes`, {
        state_key: statekey, 
        city_key: citykey
    });
}


// auth service same as above ======  // 
export function getStatesListAuth(country) {
    return http.get(`${apiUrl}/list/auth/${country}/states`); 
}

// getStateCities
export function getStateCitiesAuth(country, statekey){
    return http.post(`${apiUrl}/list/auth/${country}/state/cities`, {
        state_key: statekey 
    });
}

// getStateZipcodes
export function getStateZipcodesAuth(country, statekey){
    return http.post(`${apiUrl}/list/auth/${country}/state/zipcodes`, {
        state_key: statekey
    });
}