import React , { useState, useEffect } from 'react'
import { useGoogleOneTapLogin } from "react-google-one-tap-login"
import { googleAuth, loginWithJwt } from '../../services/userService'
import LoadingPage from './tools-ui/loadingPage'
import toast from "react-hot-toast"

const OneTap = () => {

    const [loading, setLoading] = useState(false)

    const responseGoogleSuccess = async (response) => {
        const args = { profile: response , oneTap: true}
        const resp = await googleAuth(args)   
        loginWithJwt(resp.headers['x-auth-zips'])
        setLoading(true); 
        
        setTimeout(() => {
            if (resp.data === "new") {
                window.location.href = `/user/form`
            } else {
                window.location.href = `/home`
            }
        }, 500);
    }

    const responseGoogleFail = (response) => {
        setLoading(false); 
        console.log('error', response)
        toast.error("We could'nt authenticate your google account!")
    }
    
    useGoogleOneTapLogin({
        // onSuccess: (response) => responseGoogleSuccess(response), 
        onSuccess: (resp) => {return responseGoogleSuccess(resp)}, 
        onError: (error) => {return responseGoogleFail(error) }, 
        googleAccountConfigs: {
          client_id : process.env.REACT_APP_GAUTH
        },
        referrerPolicy: {
            policy: 'strict-origin-when-cross-origin'
        }
    })

    return (
        <> 
        { loading && <LoadingPage/> }
        </>
    )
}

export default OneTap ; 