import React from 'react';
import MapFavorite from "./mapFavorite" ; 
import styles from "./lookup.module.css" ; 
import { Link } from 'react-router-dom';
import Logo from "../../../containers/logo.svg";
import SignUp from '../../auth/Signup.reuse';

class Desc extends React.Component {
    render() { 
        const { areas, desc , imgUrl , country  } = this.props ; 

        return (
            <>
                <div className="ml-2" id="favorite-logo">
                    <Link to="/"> <img src={Logo} className={`top-right-stick mt-2`} alt="logo"/> </Link>
                </div>
                <div className="container-fluid">
                    <div className={`row ${styles.textsDesc}`}>
                                
                        <div className="col-md-4 my-auto text-center align-items-center">
                            <div className='mt-5'>
                                <img height='250rem' src={ imgUrl } alt="area description" />
                            </div>
                            <div className="mt-4">
                                <span className={styles.descNum}>
                                    { areas.length } <br/>
                                </span>
                            </div>
                            <div className="mt-3" style={{color: '#2A468B'}}>
                                { desc }
                            </div>
                            
                            <div className="mt-5">
                                <hr />
                                <span style={{fontSize:'0.8rem'}}>
                                    Locate your target market in less than 30 seconds.<br/>
                                    Start for free today!
                                </span>
                                <div className="mt-4">
                                    <SignUp />
                                </div>
                            </div>
                        </div>
                            <div className="col-md-8">
                                <MapFavorite areas={ this.props.areas } country={country } />
                            </div>
                        </div>
                </div>
            </>
        )
    }
}
 
export default Desc;