import React, { useState , useEffect } from 'react' ; 
import { useParams , Redirect , useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import '../main.css';
import Header from '../../Header';
import Map from '../../Map/many.js';
import LivingWith from '../../LivingWith';
import ApartMent from '../../Apartment';
import Education from '../../Education';
import Household from '../../Household';
import DashboardInfo from '../../DashboardInfo';
import Info from '../../Info';
import Races from '../../Races';
import Demowall from "../../Demowall/index.js"; 
import Occupations from '../../Occupation';
import Authwall from "../../Authwall/index.js"; 
import Limitwall from "../../Authwall/indexlimit.js"; 
import OneTap from "../../../../../common/Onetap.jsx" ;
import LoadingPage from "../../../../../common/tools-ui/loadingPage" ;
import _ from "lodash" ; 
import { getStateCity } from "../../../../../../services/lookupService" ; 
import { getCurrentUser } from "../../../../../../services/userService" ;
import Footer from "../../../../../landing-page/Footer" ; 
import Sticky from "../../../../../common/StickyHeader" ;

const Main = () => {

  const [limitExceeded, setLimitExceeded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [exception, setException] = useState(false)
  const [dts, setDts] = useState([]) ; 
  const [areas, setAreas] = useState([]); 
  const [attributes, setAttributes] = useState({}) ;
  const [ loc, setLoc ] = useState({
    statekey : useParams().statekey, 
    citykey: useParams().citykey
  }); 
  const [titletext, setTitletext] = useState('') ;
  const [living, setLiving] = useState('') ; 
  const [hhunit, setHhunit] = useState('') ;
  const [ageinc, setAgeinc] = useState('') ;
  const [race, setRace] = useState('') ;
  const [education, setEducation] = useState('') ; 
  const [occupations, setOccupations ] = useState('') ; 
  const [textpopulated, setTextpopulated] = useState('')
  const history = useHistory() ; 
  const nf = new Intl.NumberFormat() ;

  useEffect(() => {

    async function getData() {
      
      try {   
          
          const resp = await getStateCity("us", encodeURIComponent(loc.statekey) , encodeURIComponent(loc.citykey) )
          const obj = resp.data[0].proportions[0] ; 
          setDts([...resp.data[0].proportions])
          setLoading(false)
          const plzs = _.map(resp.data[0].zcta, 'plz')
          setAreas([...plzs])
          setAttributes({
            state: resp.data[0].state , 
            city: resp.data[0].city , 
            population: resp.data[0].population ,
            households : resp.data[0].households , 
            old: resp.data[0].old , 
            young: resp.data[0].young , 
            center: [resp.data[0].lng, resp.data[0].lat ]
          })
    
          setTitletext(
            <div className="text-justify">
                <p className="mt-2 mb-2">
                Located in the state of {`${resp.data[0].state}`}, {`${resp.data[0].city}`} is a vibrant city 
                encompassing {`${resp.data[0].zipcounts > 1 ? `${resp.data[0].zipcounts} ZIP Codes`: `${resp.data[0].zipcounts} ZIP Code` }`}. It's home to 
                an estimated {` ${nf.format(resp.data[0].population)} `} individuals and 
                approximately {` ${nf.format(resp.data[0].households)} `} family units. 
                Of its population, {` ${obj.gender.male.toFixed(2)}`} percent identify as male, with a 
                significant portion being {`${ resp.data[0].young > resp.data[0].old ? `below 40 years in age` : `40 years and older` } `}. Dive deeper into the 
                demographic data of {`${resp.data[0].city} `} in {`${resp.data[0].state}`} with 
                Zipscore's complimentary resources.

                </p>
            </div>
          )
    
          //=== living arrangement + Persons in Household  
          setLiving(
            <div className="p-2 text-justify">  
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658682911/free-tools/family_1.svg' style={{width:"47px", marginRight:"12px"}} alt="living"/>
                <h2 className="customh2fontsize"> Household Living Arrangement for ZIP Code {`${resp.data[0].city}, ${resp.data[0].state} `}, US </h2>
              </div>
              <p className="mt-2 mb-3" >
                Household living arrangements in {` ${resp.data[0].city}`} can be categorized into four main categories 
                - living alone, living with children under 18, living without children all 18 plus and living with a person 60 plus age.
                U.S. Census Bureau statistics shows that {` ${resp.data[0].proportions[0].livingWith['Living Alone']} `}
                 percent of {` ${resp.data[0].city}`} households live alone, 
                 {` ${resp.data[0].proportions[0].livingWith['Living with Children under 18']}`}  percent live with children under the age of 18, 
                and {`${resp.data[0].proportions[0].livingWith['Living with person 60 plus age']}`} percent have a senior of at least 60 years old.  

              </p>
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/house_1.svg' style={{width:"47px", marginRight:"12px"}} alt="persons"/>
                <h2 className="customh2fontsize"> Persons in Household for ZIP Code {`${resp.data[0].city}, ${resp.data[0].state} `}, US </h2>
              </div>
              <p className="mt-2 mb-3" >
             According to the U.S. Census Bureau, the city of {`${resp.data[0].city} `}  
             has about {` ${ resp.data[0].proportions[0].livingPerson["1 per"]}`} percent of one-person households, 
             {` ${(resp.data[0].proportions[0].livingPerson["2 per"] + resp.data[0].proportions[0].livingPerson["3 per"]).toFixed(2)} `} percent of two and three-person households, 
             while a minimal percentage of the households with {` ${resp.data[0].proportions[0].livingPerson["4 or more"]} `} percent have 4 or more persons living in them.
              </p>
            </div>
          )
          
          //=== HH unit structure type 
          setHhunit(
            <div className="p-2 text-justify">
                <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/building_1.svg' style={{width:"47px", marginRight:"12px"}} alt="housing unit"/>
                    <h2 className="customh2fontsize"> Housing Unit Structures in {`${resp.data[0].city}, ${resp.data[0].state} `} US </h2>
                </div>
              <p className="mt-2 mb-2">

                While a housing unit can be defined as a place where a person or a group of people lives, 
                it could either be a larger structure or a single unit within a larger structure. 
                The city of {`${resp.data[0].city}`} has {` ${resp.data[0].proportions[0].housingType['1 unit, detached']}`} percent of detached single housing units, 
                {` ${(resp.data[0].proportions[0].housingType['2 apartments']+resp.data[0].proportions[0].housingType['3 or 4 apartments']).toFixed(2)} `} percent 2 - 4 apartments housing units, 
                and {` ${(resp.data[0].proportions[0].housingType['5 or 9 apartments']+resp.data[0].proportions[0].housingType['10 or more apartments']
                ).toFixed(2)} `}  percent 5 or more apartments housing units.


              </p>
            </div> 
          )
          
          //===  Income + Age group 
          setAgeinc(
            <div className="p-2 text-justify">
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/salary_1.svg' style={{width:"50px", marginRight:"12px"}} alt="age"/>
                <h2 className="customh2fontsize"> Household Income in {`${resp.data[0].city} ZIP Code, ${resp.data[0].state} `}, US </h2>
              </div>
              <p className="mt-2 mb-3" >
                Household income is the total amount of money earned by all the members of a household before tax payment. 
                {` ${(resp.data[0].proportions[0].income['below-35,000'] + resp.data[0].proportions[0].income['35,000-49,999']).toFixed(2)} `}
                 percent of {`${resp.data[0].city}`} households earn below $50,000 as gross annual income, 
                 {` ${(resp.data[0].proportions[0].income['50,000-99,999'] + resp.data[0].proportions[0].income['100,000-149,000']).toFixed(2)} `} 
                  earn between $50,000 to $150,000, 
                while {` ${resp.data[0].proportions[0].income['above-150,000']} `}  earn more than $150,000. 

              </p>
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/age-group_1.svg' style={{width:"50px", marginRight:"12px"}} alt="income"/>
                <h2 className="customh2fontsize"> Age Distribution in {`${resp.data[0].city} ZIP Code, ${resp.data[0].state} `}, US </h2>
              </div>
              <p className="mt-2 mb-2">
              Age distribution describes how the population of a given area like {`${resp.data[0].city}`} is grouped 
              according to the age of residents. 
              The U.S. Census Bureau shows that 
              {` ${resp.data[0].proportions[0].age['10-24']}`} percent of {`${resp.data[0].city}`}’s population are aged 10 to 24, 
              and {` ${resp.data[0].proportions[0].age['40-64']}`} percent are aged 40 to 64 while 
              {` ${resp.data[0].proportions[0].age['above 64']}`} percent are above 64 years. 
              </p>
              
            </div>
          )
    
          //=== race
          setRace(
            <div className="p-2 text-justify">
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662210778/free-tools/ethnicity.png' style={{width:"49px", marginRight:"12px"}} alt="race"/>
                <h2 className="customh2fontsize"> Ethnicity Groups for {`${resp.data[0].city} Postal Code, ${resp.data[0].state} `}, US </h2>
              </div>
              <p className="mt-2 mb-2">
                {`${resp.data[0].city}`} residents were made to fill a census questionnaire to collect racial data based on their self-identification.
                The census shows {` ${resp.data[0].proportions[0].races['White']}`} percent of {`${resp.data[0].city}`} residents identify as white, 
                {` ${resp.data[0].proportions[0].races['Black or African American alone']} `}  percent identify as Black or African American, 
                while {` ${resp.data[0].proportions[0].races['Asian alone']}`} percent identify as Asian. 

              </p>
            </div>
          )

          //===  Education
          setEducation(
            <div className="p-2 text-justify">
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1665248778/free-tools/education.svg' style={{width:"50px", marginRight:"14px"}} alt="education"/>
                <h2 className="customh2fontsize"> Education Attainment in {`${resp.data[0].city} , ${resp.data[0].state} `}, US </h2>
              </div>
              <p className="mt-2 mb-3" >
                Education attainment data in the United States is reported by the U.S. Census Bureau 
                for all ZIP Codes inlcuding the ZIP Codes in {`${resp.data[0].city}`} using three distinct levels - School level, Bachelor and Graduate level education. 
                In the {`${resp.data[0].city}`} of {`${resp.data[0].state}`}, it was reported that 
                {` ${resp.data[0].proportions[0].education['School level']}`} percent of the population have completed the school level education, 
                {` ${resp.data[0].proportions[0].education['Bachelor']}`} have completed their Bachelor studies, 
                while {` ${resp.data[0].proportions[0].education['Graduate']}`} percent have earned a graduate degree.
              </p>   
            </div>
          )
          
          //===  occupation 
          setOccupations(
            <div className="p-2 text-justify">
            <div className="d-flex align-items-center">
              <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1665249595/free-tools/occupation.png' style={{width:"49px", marginRight:"14px"}} alt="occupation"/>
              <h2 className="customh2fontsize"> Employment by Industry Sector in {`${resp.data[0].city} , ${resp.data[0].state} `}, US </h2>
            </div>
            <p className="mt-2 mb-2">
            Employment data for the city of {`${resp.data[0].city}`} are classified based on industrial sectors. 
            The U.S. Bureau of Labor Statistics shows that {`${resp.data[0].proportions[0].occupations['Educational services, and health care and social assistance']}`} percent
             of {`${resp.data[0].city}`}’s entire workforce are in the educational, 
            healthcare, and social assistance sector. 
            {` ${resp.data[0].proportions[0].occupations['Construction']} `} and {` ${resp.data[0].proportions[0].occupations['Manufacturing']} `}
             percent accounts for the construction and manufacturing sector respectively. 
            Professional, scientific, management, administrative, and 
            waste management services takes up to {` ${resp.data[0].proportions[0].occupations['Professional, scientific, and management, and administrative and waste management services']} `} 
            percent of {` ${resp.data[0].city}`}’s workforce.  
            </p>
          </div>
          )
        
          setTextpopulated (
            <div className="p-2 text-justify">
                <>
                  <div className="d-flex align-items-center">
                      <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1666108937/free-tools/populatedzips.svg' style={{width:"49px", marginRight:"12px"}} alt="population"/>
                      <h2 className="customh2fontsize"> ZIP Codes in {`${resp.data[0].city} , ${resp.data[0].state} `}, US </h2>
                  </div>
                  <div className="text-justify">
                      <p className="mt-2 mb-2"> 
                          According to the US Census Bereau, the city of {`${resp.data[0].city}`} have {`${resp.data[0].zipcounts}`} ZIP code tabulation areas (ZCTA's). 
                          The list of the ZIP Codes in {`${resp.data[0].city}`} are as follows -    
                      </p>
                      <div className='state-city mt-1 mb-3 justify-content-left align-items-center'>    
                           {
                              resp.data[0].zcta.map((item) => { 
                                  return <div key={item.key} >
                                              <a href={`/us/${item.plz}/zip-code/${item.lng}/${item.lat}`}> {item.plz} </a> 
                                    </div>
                              })  
                            }
                      </div>
                      <hr className=" mb-2"/>
                      <div className="mt-2 hover-underline-animation  dashboard-content-wrapper-link" style={{borderBottom: "1.2px solid #007bff"}}>
                        <a href={`/browse/us/${ encodeURIComponent(loc.statekey)}/zip-codes`}> List of all ZIP codes in {`${resp.data[0].state}`} </a>
                        {/* <a className="mr-5" href={`/browse/us/${ encodeURIComponent(loc.statekey) }/cities`} > List of all cities in {`${resp.data[0].state}`} </a> */}
                      </div>
                  </div>     
                </>
            </div>
          )

      } catch (error) {
        setLoading(false)
        if (error.response.status == 429) {
            setLimitExceeded(true)
        } else {
          setException(true)
        }
      }

    }
    getData() ;

  }, [])

const limitwall= <React.Fragment>   
    <div className="mt-3 mb-4 text-center " >
        <img style={{width:'100%' , maxWidth:'550px'}} src='https://res.cloudinary.com/zipscores-collection/image/upload/v1663332859/11132.jpg' alt="limit"/>
        <h2 className="mt-1 mb-5 text-center "> Please log in to view {`${_.capitalize(loc.statekey)}`} data </h2>
    </div>
  </React.Fragment>

const authwall= <React.Fragment>   
  <p className="mt-3 mb-4 text-center "> Register for a free account to accesss all the features of Zipscore.AI. </p>
  </React.Fragment>

const authwall2 = <React.Fragment>
    <div className="d-flex align-items-center">
        <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662816675/free-tools/highincome.svg' style={{width:"49px", marginRight:"12px"}} alt="authentication "/>
        <h2 className="customh2fontsize"> High Household Income ZIP Code Areas in {`${attributes.state}`} </h2>
    </div>
    <hr/>
    <p className="mt-2 mb-3 text-center "> Register for a free account to view the high income ZIP Code areas in {`${attributes.state}`} and other US states. </p>
</React.Fragment>

if(exception) return <Redirect to="/404"/> ; 
if (getCurrentUser()) history.push(`/lookup/us/${loc.statekey}/${loc.citykey}`)

  return (
    <>
    <Sticky />
    <div className='content-wrapper'>
      <OneTap />
      <Header />
      {  dts.length > 0 && loc.statekey && Object.keys(attributes).length && !limitExceeded && !loading && 
        <>
          <Helmet> <title> {`${attributes.city}, ${attributes.state}, US`} </title> </Helmet>
          <DashboardInfo 
            title={`${attributes.city}, ${attributes.state}, US`} 
            description={titletext} 
            /*
            link1={`/browse/us/${ encodeURIComponent(loc.statekey) }/zip-codes`} 
            link1title={`Demographics by ZIP codes in ${attributes.state}`}
            */
            link1={`/us/${ encodeURIComponent(loc.statekey)}/zip-code-map`}
            link1title={`Demographics of ${attributes.state}`}
            link2={`/browse/us/${ encodeURIComponent(loc.statekey)}/cities`} 
            link2title={`Demographics by Cities in ${attributes.state}`}
          />
          <Map dts={dts}  areas={areas} center={attributes.center} country={'us'} attributes={attributes} zoom={12}/>
          <Info description={living} />
          <LivingWith dts={dts} />
          <Demowall title={`Predict success of your business in ${attributes.city}.`}/>
          <Info description={hhunit} />
          <ApartMent dts={dts} />
          {/* <Authwall description={authwall}/> */}
          <Info description={ageinc} />
          <Household dts={dts} />
          <Info description={education} />
          <Education dts={dts} />
          <Info description={occupations} />
          <Occupations dts={dts} />
          <Authwall description={authwall2} />
          <Info description={race} />
          <Races dts={dts} />
          <Info description={textpopulated} />
         </>
      }
      { loading && dts.length == 0 && !limitExceeded && <LoadingPage /> }
      { dts.length == 0 && limitExceeded && !loading && <Limitwall description={limitwall}/> }
    </div>
    <Footer />
    </>
  )
}

export default Main ; 