import React, { Component } from 'react' ; 
import styles from "./upgrade.module.css" ;
import { createCheckoutSession } from "../../../services/checkoutService"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSubsDetails } from '../../../services/clientService';

class PaywallUpgradeLight extends Component {


    // handleBilling = async () => {

    //     const returnUrl = window.location.href ; 
    //     const res = await createCustomerSession(returnUrl) ;
    //     const sessionUrl = res.data.url ; 
    //     window.location.replace(sessionUrl) || window.open(sessionUrl, "_blank") ;
    // }

    handleCheckoutSession = async() => {
        
        //this.setState({ loading: !this.state.loading })
        const returnUrl = window.location.href ; 
        const res = await createCheckoutSession(returnUrl) ;
        const sessionUrl = res.data.url ; 

        window.location.href= sessionUrl ;
    }
    
    render() { 

        const { text , usersType , lookup } = this.props ; 
    
        return ( 
                <>

                    {
                        !usersType && !lookup &&
                        <div className={styles.textUpgradeVersion} onClick={ this.handleCheckoutSession }>
                            <FontAwesomeIcon style={{ fontSize: "18px" , marginRight: "10px" }}icon={['fa', 'lock']}/> <br />
                            Upgrade to <span className={ styles.upgradePaywalunderline }>premium</span> { text } .
                        </div>
                    }

                    {
                        lookup && 
                        <div className={styles.textUpgradeLookup} onClick={ this.handleCheckoutSession }>
                            <div className="mb-4">
                                <FontAwesomeIcon style={{ fontSize: "22px" , marginRight: "10px" }}icon={['fa', 'lock']}/>
                            </div>
                            Upgrade to <span className={ styles.upgradePaywalunderline }> premium </span> { text } .
                        </div>
                    }
        
                    {
                        usersType && 
                        <div className={styles.textUpgradeUsers} onClick={ this.handleCheckoutSession }>
                            <div className="mb-2">
                                <FontAwesomeIcon style={{ fontSize: "22px" , marginRight: "10px" }}icon={['fa', 'lock']}/>
                            </div>
                            Upgrade to plan <span className={ styles.upgradePaywalunderline }>Team S or Team L</span> { text } .
                        </div>
                    }
                </>

         );
    }
}
 
export default PaywallUpgradeLight;