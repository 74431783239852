import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const EducationChart = ({dts}) => {

    const options = {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return Number(context.formattedValue) + "%";
              },
              title:function(){
                return "";
              }
            }, 
            displayColors: false 
          },
        },
        scales: {
          x: {
            grid: {
              display: !false
            },
            ticks: {
              font: {
                size: 12,
                family: 'Axiform'
              }
            }
          },
          y: {
            grid: {
              display: !false
            },
            ticks: {
              font: {
                size: 12,
                family: 'Axiform'
              }
            }
          }
        },
      };


    const data = {
        labels: Object.keys(dts),
        datasets: [
          {
            label: '',
            data: Object.values(dts),
            backgroundColor: ["#FB9A55" , "#BFD0E9",  "#1E77B4" ], 
            barThickness: 100,
            maxBarThickness: 100,
          }
        ],
      };


    return (
        <div className='Education-detail'>
            <Bar options={options} data={data} />
        </div>
    )
}

export default EducationChart
