import React from "react";
import { useDrop } from "react-dnd";
import Map from "./map";
import ExploreContextProvider from "./exploreContext";

const style = {
    height: "100vh",
    color: "white",
    textAlign: "center",
    fontSize: "1rem",
    lineHeight: "normal",
    position: "relative",
    // zIndex: 1
};

export const Container = ({data , country , changePlz }) => {

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'box',
        drop: () => ({ name: "Dustbin" }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const isActive = canDrop && isOver;
    let backgroundColor = "#ffffff00";
    if (isActive) {
        backgroundColor = "#bdc3c7";
    } else if (canDrop) {
        backgroundColor = "#ecf0f1";
    }

    return (
        <div ref={drop} style={{ ...style, backgroundColor }} >
            <ExploreContextProvider>
                <Map data={data && data} country = {country} changePlz={ changePlz }/>
            </ExploreContextProvider>
        </div>
    );
};
