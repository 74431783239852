import React, { Component } from "react";
import styles from "./styles/story.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin  , faTwitter } from "@fortawesome/free-brands-svg-icons"
import gmailIcon from "./assets/images/gmail.png"
// import AuthHeader from "../auth/authHeader";
// import AuthHeader from "../auth/styles/authHeader.module.css";


class Company extends Component {
  render() {
    return (
      <>

       
          <div className={styles.body}>
            <div className="row mb-5">
                {/* <div className="col-md-12 mb-5">
                  <AuthHeader page='login'/>
                </div> */}
            </div> 
              <div className="row mt-5">

                    <div className="col-md-2"></div>

                    <div className={`col-md-4 mt-5 ${styles.bodyText}`}>
                        
                          {/* <h2>Hey everyone - </h2>  */}

                          <div className="mt-5">
                            <h3> Gigaparsec DataLab GmbH </h3> 
                        </div>
                        <div className="mt-3">
                            Mühlenstraße 8 a, <br/>
                            14167 Berlin, Germany
                        </div>
                        <div></div>
                        <div className="mt-3 mb-4">
                            Commercial Register: HRB 233085 B <br/>
                            Registration court: Amtsgericht Charlottenburg
                        </div>
                        <div className="mt-3 mb-4">
                           VAT (Sales tax identification number according to Sect. 27 a of the Sales Tax Law) : DE 349 336 327 <br/>

                           
                        </div>
                        <div className="mt-3 mb-3">
                            Represented by - Mukund Jha <br/>
                        </div>
                        <hr />
                        
                          {/* <img src={leftQuote} className="mb-0" style={{height: 'auto' , width: "20px"}}  alt=""/> */}
                          <div className="mt-5"> 
                            <div className="">
                            I'm Mukund, a data scientist and full-stack developer based in Berlin. I love building data-intensive SaaS products that solve interesting business problems, and Zipscore is one of them :) 
                            </div>
                            <div className="mt-3">
                                    <div className="mt-1">
                                        +  Partnerships ?<br/>
                                    </div>
                                    <div className="mt-1">
                                        +  Feedback? <br/> 
                                    </div>
                                   
                            </div>
                          <div className="mt-4">
                             say HI to me here - <p />
                          </div>

                          <div className="mt-4">
                            <a href="https://www.linkedin.com/in/mukund-jha-39449b52" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className={styles.iconLi} size="2x" icon={faLinkedin} />
                            </a>
                            <a href="https://twitter.com/__mukund" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className={`ml-3 ${styles.iconTweet}`} size="2x" icon={faTwitter} />
                            </a>

                            <a href="mailto:mukund@zipscore.ai" target="_blank" rel="noopener noreferrer" >
                              <img src={gmailIcon} className="ml-3 mb-3" style={{height: '26px' , width: "26px"}}  alt=""/>
                            </a> 

                          </div>
                          </div>
                      </div>

                      <div className="col-md-6"></div>
                </div>

            </div>
      
      </>
         
    );
  }
}

export default Company ;
