import React from 'react'
import styles from "./switch.module.css"

const Switch = (props) =>  {

    const { on , off , bottom } = props ; 

    return (

        <div style={{position: "absolute", bottom: bottom , right: 8,  zIndex:10}}>
               <label className= {`${styles.switch}`} >
                    <input type="checkbox" id="togBtn" />
                    <div className={`${styles.slider} ${styles.round}`}>
                        <span className={styles.on}> {on} </span>
                        <span className={styles.off}> {off} </span>
                    </div>
                </label> 
        </div> 
    
    )

}


export default Switch ; 