import React , { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import GoogleLogin, { GoogleLogout } from 'react-google-login';
import { Link , useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
// import GoogleIcon from './icons/icons8-google.svg'
import GoogleIcon from './icons/gicon.png'
// import thumb from './icons/thumb.png'
import { getCurrentUser, googleAuth, loginWithJwt, register as registerUser } from '../../services/userService';
import { Loader, Message } from 'rsuite';
import toast from 'react-hot-toast';
import NavBar from '../common/NavBar/navbar-center';
import logo from "../../containers/logo.svg"
// import { gapi } from 'gapi-script';

  
const schema = yup.object({
    email: yup.string().email('Invalid email format').required('Required') 
}).required();

const SignUp = () => {
    const history = useHistory() ; 
    const [loading, setLoading ] = useState(false)
    const [message, setMessage]= useState("")
    const [success, setSuccess]= useState(true)
    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });        

    const onSubmit = async (data) => {
        try {
          setLoading(true);
          const resp = await registerUser(data.email);
          if (resp.status == 200) {
            setMessage("We've sent you confirmation email. If the e-mail hasn't arrived within few minutes, please check your spam, bulk or junk mail folder to locate it");
          }

          setLoading(false);
        } catch (ex) {
          setSuccess(false)
          setLoading(false);
          if (ex.response && ex.response.status === 400) {
            setMessage(ex.response.data);
          }
        }
    };

    const responseGoogleSuccess = async (response) => {
        setLoading(true);
        const args = { profile: response.profileObj , oneTap : false}
        const resp = await googleAuth(args)
        loginWithJwt(resp.headers['x-auth-zips'])
        if (resp.data === "new") {
            window.location.href = `/user/form`
            // window.location.href = `/collaborate/${getCurrentUser().clientName}`
        } else {
            window.location.href = `/home`
        }
        
        /*
        setTimeout(() => {
            loginWithJwt(resp.headers['x-auth-zips'])
            if (resp.data === "new") {
                // window.location.href = `/user/form`
                window.location.href = `/collaborate/${getCurrentUser().clientName}`
            } else {
                window.location.href = `/home`
            }
        }, 1000);
        */
    }

    const responseGoogleFail = (response) => {
        setSuccess(false)
        setMessage("Trouble authenticating your google account");
        toast.error("We could'nt authenticate your google account!")
    }

    if (getCurrentUser()) history.push("/home")

    return (
        <div id='Sign-up' className="">
            <div className='sign-up-block'>
                <div className="Sign-up-inner">
                    <div className="Sign-up-left">
                        <div className='Sign-up-title-block mt-5'>
                    <div className="text-center"> 
                        <Link to="/"> <img className="signUpCenterLogo" src={logo} alt="sign up logo " /> </Link> 
                    </div>

                            <div className="mb-2">
                                {
                                    message && 
                                    <>
                                       {
                                           !success ? 
                                            <div className="alert alert-danger mb-1" role="alert">
                                                {message}
                                            </div>
                                            :
                                            <div className="alert alert-success" role="alert">
                                                {message}
                                            </div>

                                       }
                                    </>
                                }

                            </div>

                            {/* <h3>
                                Welcome! Let's set up your account.
                            </h3> */}
                            <div className='googlebtn-block mt-4'>
                                <GoogleLogin
                                    //clientId={ gclientId }
                                    clientId={process.env.REACT_APP_GAUTH }
                                    buttonText="Enable"
                                    render={renderProps => (
                                        // <button onClick={renderProps.onClick} style={customStyle}>Enable</button>
                                        <button onClick={renderProps.onClick} className='googlebtn'><img src={GoogleIcon} alt="google icon"/> Sign up for free with Google</button>
                                    )}
                                    onSuccess={responseGoogleSuccess}
                                    onFailure={responseGoogleFail}
                                    cookiePolicy={'single_host_origin'}
                                    //scope="https://www.googleapis.com/auth/spreadsheets.readonly"
                                /> 
                            </div>

                            <div className='or-contain mt-4 mb-4'>
                                <p>or</p>
                                {/* <hr /> */}
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className='mt-3'>
                            <div className='form-signup'>
                                <div className="input-icons">
                                    <i className="fa fa-envelope icon"></i>
                                    <input className="input-field"
                                        type="text"
                                        {...register("email")}
                                        placeholder='name@example.com' 
                                    />
                                    {errors.email && <span className='errorTextForm'> {errors.email.message} </span>}
                                </div>
                                <button type="submit" className='signupbtn mt-3 '>
                                    { !loading && 'Sign up for free'}
                                    { loading && <Loader/>}
                                </button>
                                {/* <button type="submit" className='signupbtn mt-3'>
                                    { !loading && 'Sign up for free'}
                                    { loading && <Loader/>}
                                </button> */}

                            </div>
                        </form>
                        <div className='sign-up-content'>
                            <p>No Credit Card Required</p>
                            <p>
                                By creating an account, you agree to our <a className='link-content' href='/privacy'> privacy policy </a> and <a href='/terms' className='link-content'> terms of service </a>
                            </p>
                        </div>
                        {/* <div className='commany-ads'>
                            <img src={logo1} />
                            <img src={logo2} />
                            <img src={logo3} />
                            <img src={logo4} />
                            <img src={logo5} />

                        </div> */}
                        <div className='sign-In-link-block'>
                            <Link to='/login'>
                                Sign In
                            </Link>
                            <Link to='/confirm/resend'>Didn't receive an email confirmation?</Link>
                        </div>
                    </div>

                </div>
                <div className='sign-up-inner2'>
                    <div className="sign-up-right">
                        <div className='thumb-block'>
                            {/* <img src={thumb} /> */}
                            <iframe 
                                src="https://player.vimeo.com/video/761969461?h=b35273fe32&api=1&background=1&mute=0&quality=1080p" 
                                style={{width : '78vh', height : '42vh', borderRadius: '5px', left:'50%'}}
                                frameBorder="0" 
                                allow="autoplay; fullscreen; picture-in-picture" 
                                title="audienceScoreDes"
                                className="iframeVideoVimeo"
                            /> 

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp
