import React from 'react'
import * as Markdown from 'react-markdown'
import { Link } from "react-router-dom"
import styles from "./blog.module.css"


const BlogItem = (props) => {

    //const entryId = props.id; 
    const title = props.title.replace(/\s+/g, '-').toLowerCase(); 

    return   <>
                    <div className={styles.blogItemsText}>
                    <div className="col-md-5 ml-5 pb-2 mt-2 float-left " style={{borderBottom: '0.05rem solid #EBEBEB' }}>
                            <div style={{ minHeight:'90px'}} className="align-text-centre">
                            {/* <Link className="hover-underline-animation" to={{ pathname: `/blog/entry/${entryId}`}}><h6 className="mt-4" style={{color:"#424B4F"}}> {props.title} </h6></Link> */}
                                <Link className="hover-underline-animation" to={{ pathname: `/blog/${title}`}}><h6 className="mt-4" style={{color:"#424B4F"}}> {props.title} </h6></Link>
                            </div>
                        <div style={{ minHeight:'120px'}}>
                            <div className="text-justify" > 
                            { props.description } 
                            </div>
                            <Link to={{ pathname: `/blog/${title}`}}>
                                [...]
                            </Link>

                        </div>
                    </div>
                    </div>
                 </>
    

}
    

export default BlogItem ; 