import React from "react";
import styles from "./navbar.module.css";
import { Navbar } from "react-bootstrap";
import logo from "../../../containers/logo.svg";
import { Link } from "react-router-dom";
// import logo from "../../../containers/homepage-icon.svg";


const NavBar = () => {

  return (
    <div className={styles.centerNav} >
      <Link to="/">
        <img className={styles.imgCenterNav} src={logo} alt="logo" />
      </Link>
    </div>
  );
};

export default NavBar;
