import { library } from '@fortawesome/fontawesome-svg-core';

import { faLinkedin, 
         faMedium, 
         faTwitter
} from '@fortawesome/free-brands-svg-icons' 

import { faUsers 
    , faSquareRootAlt 
    , faShoppingCart
    , faVenusMars
    , faUserPlus
    , faGlobeEurope
    , faGlobeAmericas
    , faHeart
    , faCoffee
    , faArrowCircleRight
    , faArrowCircleLeft
    , faHome
    , faArrowAltCircleLeft
    , faQuoteLeft
    , faGlobe
    , faPassport
    , faMapMarkerAlt
    , faVideo
    , faComments
    , faTimesCircle
    , faGripLines
    , faSlidersH
    , faBars
    , faWindowMaximize
    , faWindowMinimize
    , faCopy
    , faInfoCircle
    , faCheck
    , faEnvelope
    , faFileExport
    , faLock 
    , faTimes
} from '@fortawesome/free-solid-svg-icons';



library.add(
  faSquareRootAlt,
  faUsers,
  faShoppingCart,
  faVenusMars,
  faUserPlus,
  faGlobeEurope,
  faHeart,
  faCoffee,
  faArrowCircleRight,
  faArrowCircleLeft,
  faHome,
  faArrowAltCircleLeft,
  faQuoteLeft,
  faGlobe,
  faPassport,
  faMapMarkerAlt,
  faVideo,
  faComments,
  faTimesCircle,
  faGripLines,
  faGlobeAmericas,
  faSlidersH,
  faBars,
  faWindowMaximize,
  faWindowMinimize,
  faCopy,
  faInfoCircle,
  faCheck,
  faEnvelope,
  faFileExport , 
  faLock ,
  faLinkedin, 
  faMedium, 
  faTwitter, 
  faTimes
);


/* 

--- fontawesome integration ---- 
https://www.digitalocean.com/community/tutorials/how-to-use-font-awesome-5-with-react#step-1-%E2%80%94-using-font-awesome

--- choose fonts ---- 
https://fontawesome.com/icons?d=gallery&m=free


*/ 