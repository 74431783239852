import React from 'react'
import Chart from "react-apexcharts";


class ApexChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [57.15, 33.54, 47.16, 65.58, 80.42, 81.23, 49.31],
            options: {
                chart: {
                    height: 280,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        startAngle: 0,
                        hollow: {
                            size: "30%",
                        },
                        dataLabels: {
                            name: {
                                fontSize: '22px',
                            },
                            value: {
                                fontSize: '16px',
                            },
                            total: {
                                show: true,
                                label: 'Total',
                                formatter: function (w) {
                                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                    return 98 + "%"
                                }
                            }
                        }
                    }
                },
                labels: ['White', 'Black', 'African', 'Asian', 'Nativbe', 'Some', 'Two'],
            },


        };
    }



    render() {
        return (


            <div id="chart">
                <Chart options={this.state.options} series={this.state.series} type="radialBar" height={268} />
            </div>

        );
    }
}

export default ApexChart