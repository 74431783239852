import React from "react";
import "./styles/heronew.css";
import "./styles/index.css";
import Header from "./Header";
import Germany from "./assets/images/germany.png";
import USA from "./assets/images/usa.png";
// import Mail from "./assets/images/mail.png";
// import Denmark from "./assets/images/denmark.png";
import Bg from "./assets/images/bg.png";
import PlayBtn from "./assets/images/play_btn.png";
import PlayBtnHover from "./assets/images/play_btn-1.png";
import { Link } from "react-router-dom"; 
import { dropdownOptions } from "../../global";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder" ; 
import mapboxgl from "mapbox-gl" ; 
import _ from "lodash";
import { withRouter } from "react-router-dom" ; 
// https://docs.mapbox.com/playground/geocoding/

class Hero extends React.Component {

  constructor () {
    super()
    this.state = {
      isOpen: false,
      btnImg: PlayBtn, 
      options: dropdownOptions,
      selectedCountry: 'DE',
      zipcode: null, 
      invalidZipcode: false
    }
    this.el = null;
    this.openModal = this.openModal.bind(this)
    this.playOver = this.playOver.bind(this)
    this.playOut = this.playOut.bind(this)
  }

  componentDidMount() {

    const geocoder = new MapboxGeocoder({
      accessToken:
        "pk.eyJ1IjoibWotZXBzaWxvbiIsImEiOiJja24wcWV5eTcwcTY5MnZwazA0cTRxMTlwIn0.powZMmJIS2FoR4JY1DFSGg",
      mapboxgl: mapboxgl,
      marker: false,
      placeholder: 'Search any location in Germany', 
      types: "address, postcode" , 
      flyTo: false,
      countries: "DE"
    })
    geocoder.addTo('#geocoder');
    geocoder.on("result", (e) => {

      setTimeout(() => {
        geocoder.clear()
      }, 10000);
      
      const lng = e.result.center[0] ; 
      const lat=  e.result.center[1] ;

      const countryObj = _.filter(e.result.context , (obj) => {
        return obj.id.indexOf("country") !== -1;
      });
      const country = countryObj[0].short_code ; 

      if (e.result.place_type[0] && e.result.place_type[0] == "postcode") {
        
        const zipcode = e.result.text ; 
        const url = `/${country}/${zipcode}/${lng}/${lat}?hl`
        return window.open( url , "_blank");

      } else {

        const zipObj = _.filter(e.result.context , (obj) => {
          return obj.id.indexOf("postcode") !== -1;
        });

        const zipcode = zipObj[0].text
        const url = `/${country}/${zipcode}/${lng}/${lat}?hl`
        return window.open(url, "_blank");
      }

    })

  }


  openModal () {
    this.setState({isOpen: true})
  }

  playOver () {
    this.setState({btnImg: PlayBtnHover})
  }

  playOut () {
    this.setState({btnImg: PlayBtn})
  }

  regionChange = (field, value) => {
    this.setState({
        ...this.state,
        [field]: value 
    })
}

  enterZipcode = (val) => {
    this.validateZipcode(val)
  }

  validateZipcode = (val) => {

    if (val.length > 5 ){
        this.setState({
          invalidZipcode: !this.state.invalidZipcode, zipcode: null
        })
    } else {
      this.setState({ zipcode: val, invalidZipcode: false })
    }
  }

  render() {

    const { selectedCountry, options , zipcode , invalidZipcode } = this.state ; 

    return (        
                    <>
                     <Header country={"de"}/>
                     <div className="mt-5">
                        <div className='herocenteredlanding'>
                                        <div className="text-center textPlz">
                                            
                                                <div className="mb-4 titlehead">
                                                {/* target market demographics <br/> in less than 30 seconds */}
                                                 Locate your target market in <br/> less than 30 seconds
                                                </div>
                                               
                                        </div>


                                        <div className="mt-5 d-flex flex-sm-row flex-column browsebtncenteralign">
                                          <div className="p-2">
                                            <div className="mt-0" id="geocoder"></div>
                                            <div className="text-center">
                                                <span className="startFreeTextHero">Example: 10117, Berlin</span>
                                            </div>
                                          </div>

                                          <div className="p-2 setormobile " style={{color: "#AFB3BA"}}> - <span  style={{fontSize: "14px"}}> or </span> - </div>
                                          
                                          <div className="ml-1 text-center" style={{marginTop: "-1px"}}>
                                            <div className="browsecta"> <a href="/browse/de/states"> Browse </a>  </div>
                                          </div>    
                                      </div>


                                        <div  className="mt-3"><hr style={{width: '30vw'}}/> </div>
                                        <div className="text-center mt-2"> 
                                                <Link to='/areas/de/young-consumers' target='_blank'>
                                                <span className="faqsHero hover-underline-animation p-1"> 
                                                    Where do young consumers live in Germany ?
                                                </span>
                                                </Link>
                                                <br />
                                                <Link to='/areas/de/income' target='_blank'>
                                                <span className="faqsHero hover-underline-animation p-1"> 
                                                    Which areas in Germany have high household income? 
                                                </span>
                                                <br />
                                                </Link>
                                                <Link to='/areas/de/more-females-population' target='_blank'>
                                                <span className="faqsHero hover-underline-animation p-1"> 
                                                    {/* Which areas in <span className="ml-1 mr-1 faqsCountry" > Germany </span>  have high proportion of females ?  */}
                                                    In Germany, which areas have a high female population ?
                                                </span>
                                                <br />
                                                </Link>
                                        </div> 
                            <div className="float-right">
                                <img src={Bg} className="bgnew"  alt=" background " />
                            </div>  
                            <div className="countries mt-5">
                                <img className="deselectedCountryIcon" onClick={() => this.props.history.push("/")} src={USA} alt='usa'/>  
                                <img className="selectedCountryIcon" src={Germany} alt="germany" />
                            </div>
                    </div>
                    </div>
                  </>
    );
  }
}

export default withRouter(Hero) ;


