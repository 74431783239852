import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip
);

const HourseHoldGross = ({ dts }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return Number(context.formattedValue) + "%";
          },
          title:function(){
            return "";
          }
        }, 
        displayColors: false 
      },
    },
    scales: {
      x: {
        grid: {
          display: !false
        },
        ticks: {
          font: {
            size: 12,
            family: 'Axiform'
          }
        }
      },
      y: {
        grid: {
          display: !false
        },
        ticks: {
          font: {
            size: 12,
            family: 'Axiform'
          }
        }
      }
    },
  };

  const data = {
    labels: Object.keys(dts),
    datasets: [
      {
        label: '',
        data: Object.values(dts),
        backgroundColor: '#6995BF',
        // backgroundColor: '#3C76AE',
        barThickness: 50,
        maxBarThickness: 50
      }
    ],
  };

  return (
    <div className='Household-description'>
      <Bar data={data} width='200px' options={options} />
    </div>
  )
}

export default HourseHoldGross ; 
