import React, { useState , useEffect } from 'react' ; 
import { useParams , Redirect , useHistory } from "react-router-dom";

import '../main.css';
import Map from '../../Map/many.js';
import LivingWith from '../../LivingWith';
import ApartMent from '../../Apartment';
import Education from '../../Education';
import Household from '../../Household';
import DashboardInfo from '../../DashboardInfo';
import Info from '../../Info';
import Races from '../../Races';
import Demowall from "../../Demowall/index.js"; 

import LoadingPage from "../../../../../common/tools-ui/loadingPage" ;
import { getStateCityAuth } from "../../../../../../services/lookupService" ; 
import NavBar from '../../../../../common/NavBar/navbar';
import { createCheckoutSession } from "../../../../../../services/checkoutService";

const Main = () => {

  const [limitExceeded, setLimitExceeded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [exception, setException] = useState(false)
  const [dts, setDts] = useState([]) ; 
  const [areas, setAreas] = useState([]); 
  const [attributes, setAttributes] = useState({}) ;
  const [ loc, setLoc ] = useState({
    statekey : useParams().statekey, 
    citykey: useParams().citykey
  }); 
  const [titletext, setTitletext] = useState('') ;
  const [living, setLiving] = useState('') ; 
  const [hhunit, setHhunit] = useState('') ;
  const [ageinc, setAgeinc] = useState('') ;
  const [race, setRace] = useState('') ;
  const history = useHistory() ; 
  const nf = new Intl.NumberFormat() ;

  useEffect(() => {

    async function getData() {
      
      try {   

        const resp = await getStateCityAuth("de", encodeURIComponent(loc.statekey) , encodeURIComponent(loc.citykey) )
        const obj = resp.data[0].proportions[0] ; 
        setDts([...resp.data[0].proportions])
        setLoading(false)
        setAreas([...resp.data[0].zcta])
        setAttributes({
          state: resp.data[0].state , 
          city: resp.data[0].city , 
          population: resp.data[0].population ,
          households : resp.data[0].households , 
          old: resp.data[0].old , 
          young: resp.data[0].young , 
          center: [resp.data[0].lng, resp.data[0].lat ]
        })
    
      setTitletext(
        <div className="text-justify">
          <p className="mt-2 mb-2">
          {`${resp.data[0].city}`} is located in the state of {`${resp.data[0].state}`}. The city of {`${resp.data[0].city}`} has {`${resp.data[0].zipcounts > 1 ? `${resp.data[0].zipcounts} ZIP Codes`: `${resp.data[0].zipcounts} ZIP Code` }`}. 
          There are {` ${nf.format(resp.data[0].population)} `} residents and 
          {` ${nf.format(resp.data[0].households)} `} households in {`${resp.data[0].city}`}. In this city, 
          {` ${obj.gender.male.toFixed(2)}`} percent of the population identify their gender as male 
          and the majority are {`${ resp.data[0].young > resp.data[0].old ? `under 40 years of age.`: `over 40 years of age.` } `}
          Access demographics, socio-demographics and location data for the city of {` ${resp.data[0].city}`} such as age, gender, household income, 
          household living arrangement, total persons in household and type of housing units in less than 30 seconds on Zipscore.AI
          </p>
      </div>
      )
  
      //=== living arrangement + Persons in Household  
      setLiving(
        <div className="p-2 text-justify">  
          <div className="d-flex align-items-center">
            <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658682911/free-tools/family_1.svg' style={{width:"47px", marginRight:"12px"}}/>
            <h2 className="customh2fontsize"> Buildings in {`${resp.data[0].city}`}, {`${resp.data[0].state}`}, Germany </h2>
          </div>
          <p className="mt-2 mb-3" >
            According to the Federal Statistical Office of Germany (Statistisches Bundesamt) the buildings can be primarily classified into commercial or residential buildings. 
            Commercial buildings are buildings that are used for commercial purposes, and include office buildings, warehouses, retail buildings etc., 
            residential buildings are any building that is used primarily for residential purposes.
            In {` ${resp.data[0].city}`} city, {` ${resp.data[0].proportions[0].buildings['residential']}`} percent of the buildings are 
            residential whereas {` ${resp.data[0].proportions[0].buildings['commercial']}`} percent are commercial buildings. 
          </p>
          <div className="d-flex align-items-center">
            <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/house_1.svg' style={{width:"47px", marginRight:"12px"}}/>
            <h2 className="customh2fontsize"> Persons in Household in {`${resp.data[0].city}`}, {`${resp.data[0].state}`}, Germany </h2>
          </div>
          <p className="mt-2 mb-3" >
          The share of household types can be classified into three categories - single person households, multiple person households with children and multiple person households without children. 
          In the state of {`${resp.data[0].state}`}, {` ${ resp.data[0].proportions[0].livingPerson["single"]}`} percent 
          of the households are single-person households. The households with children account for 
          {` ${resp.data[0].proportions[0].livingPerson["Multi-person with children"]} `} percent. 
          </p>
        </div>
      )
        
      //=== HH unit structure type 
      setHhunit(
        <div className="p-2 text-justify">
          <div className="d-flex align-items-center">
              <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/building_1.svg' style={{width:"47px", marginRight:"12px"}}/>
              <h2 className="customh2fontsize"> Housing Unit Structures of {`${resp.data[0].city}`}, {`${resp.data[0].state}`}, Germany </h2>
          </div>
        <p className="mt-2 mb-2">
          A housing unit is a single unit within a larger structure that can be used by an individual or
          household to eat, sleep, and live. The unit can be in any type of residence, such as a house, apartment, 
          or mobile home, and may also be a single unit in a group of rooms. 
          In the state of {`${resp.data[0].state}`},  {` ${resp.data[0].proportions[0].housingType['1-2 Family']}`} percent 
          of 1-2 family household units. The housing units which have 3-6 family household structures account for 
          {` ${resp.data[0].proportions[0].housingType['3-6 Family']}`}  percent 
          of all housing units in this state whereas 
          {` ${(resp.data[0].proportions[0].housingType['20+ Family']).toFixed(2)} `} 
          percent are 20 plus family housing units. 
        </p>
        </div> 
      )
        
        //===  Income + Age group 
      setAgeinc(
          <div className="p-2 text-justify">
            <div className="d-flex align-items-center">
              <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/salary_1.svg' style={{width:"50px", marginRight:"12px"}}/>
              <h2 className="customh2fontsize"> Monthly Household Income in {`${resp.data[0].city}`}, {`${resp.data[0].state}`}, Germany </h2>
            </div>
            <p className="mt-2 mb-3" >
              Monthly household income generally is defined as the total gross income before taxes, 
              received within a calendar month period by all members of a household above a specified age 
              (age 15 and older). It includes — but is not limited to wage, salary, self-employment earnings, social security, 
              pension, and income from other sources. 
              In {`${resp.data[0].zipcode}`}, {` ${resp.data[0].proportions[0].income['below-1500']} `} percent have monthly household income below €1500 
              and {` ${(resp.data[0].proportions[0].income['1500-2599'] + resp.data[0].proportions[0].income['2600-3999']).toFixed(2)} `} percent have 
              household monthly income between €1500 and €4000. The proportion of high-income households above €7500 monthly which is usually low in most states of the  
              Germany account for {` ${resp.data[0].proportions[0].income['above-7500']} `} percent in the state of {`${resp.data[0].state}`}. 
            </p>
            <div className="d-flex align-items-center">
              <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/age-group_1.svg' style={{width:"50px", marginRight:"12px"}}/>
              <h2 className="customh2fontsize"> Age Distribution of {`${resp.data[0].city}`}, {`${resp.data[0].state}`}, Germany </h2>
            </div>
            <p className="mt-2 mb-2">
            The age distribution, also called Age Composition,
            in population studies, is the proportionate numbers of persons in successive 
            age categories in a given population. Age distribution affects many macroeconomic equations 
            such as consumer behaviour, consumer styles , housing-investment, money-demand, labor-force-participation etc.  
            In the postal code area {`${resp.data[0].zipcode}`}, about {`${resp.data[0].proportions[0].age['10-24']}`} percent of the population 
            is between 10 to 24 years category, 
            {` ${resp.data[0].proportions[0].age['40-65']}`} percent into the 40 to 64 years age group and {` ${resp.data[0].proportions[0].age['above 65']}`} percent of 
            the population are over 65 years of age.
            </p>
            
          </div>
      )
    } catch (error) {
        setLoading(false)
        if (error.response.status == 429) {
            setLimitExceeded(true)
            const returnUrl = window.location.protocol + '//' + window.location.host + '/lookup/browse/de/states'; 
            const res = await createCheckoutSession(returnUrl) ;
            const sessionUrl = res.data.url ; 
            window.open(sessionUrl, "_blank");
            history.goBack()

        } else {
          setException(true)
        }
    }
  }
    getData() ;

  }, [])


  if(exception) return <Redirect to="/404"/> ; 

  return (

    <div className='content-wrapper'>
      <NavBar isAdd={true} bgwhite={true}/>
      <DashboardInfo 
        title={`${attributes.city}, ${attributes.state} , Germany`} 
        description={titletext} 
        link1={`/lookup/de/${ encodeURIComponent(loc.statekey) }`} 
        link1title={`Demographics of ${attributes.state}`}
        link2={`/lookup/browse/de/${ encodeURIComponent(loc.statekey)  }/cities`} 
        link2title={`Demographics by Cities in ${attributes.state}`}
      />
      { dts.length > 0 && loc.statekey && Object.keys(attributes).length && !limitExceeded && !loading && 
        <>
          <Map dts={dts}  areas={areas} center={attributes.center} country={'de'} attributes={attributes} zoom={12}/>
          <Info description={living} />
          <LivingWith dts={dts} />
          <Info description={hhunit} />
          <ApartMent dts={dts} />
          <Demowall title={`Predict success of your business in ${attributes.state}.`}/>
          <Info description={ageinc} />
          <Household dts={dts} />
        </>
      }
      { loading && dts.length == 0 && !limitExceeded && <LoadingPage /> }
      { dts.length == 0 && limitExceeded && !loading && <LoadingPage text={'Free limits exceeded'} /> }
    </div>
  )
}

export default Main ; 