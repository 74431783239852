import http from "./httpService";
import jwtDecode from "jwt-decode";

import config from "../config/api.js";
const { apiUrl_dev, apiUrl_prod } = config;


http.setJwt(getJwt());

export function getJwt() {
    return localStorage.getItem('x-auth-zips');
}

const api = process.env.REACT_APP_ENVIRON === "development" 
               ? apiUrl_dev
               : apiUrl_prod


export function register(email){
    return http.post(`${api}/user/register/`, {
        email: email.toLowerCase() 
    }); 
}

export function googleAuth(dt){

    return http.post(`${api}/user/google/auth`, {
        profile: dt.profile, 
        oneTap: dt.oneTap
    });
}

export function getVerifyConfirmation( tokenStr ){
    const resp = http.post(`${api}/user/confirm/link/verify` , {
        confirmationCode: tokenStr
    });
    return resp ;
}

export function resendConfirmationLink(email){
    return http.post(`${api}/user/confirm/link/resend`, {
        email: email.toLowerCase() 
    }); 
}

export function getResetPwdLink( email ){
    const resp = http.post(`${api}/user/pwd/reset/link` , {
      email: email
    });
    
    return resp ;
}

export function ResetPwdLinkConfirmation(email, token ){
    const resp = http.post(`${api}/user/pwd/reset/link/verify` , {
      email: email.toLowerCase(), 
      token: token
    });

    return resp ;
}

export function updatePwd(id, email, pwd ){
    const resp = http.put(`${api}/user/pwd/update` , {
      userId: id, 
      email: email.toLowerCase() ,  
      pwd: pwd
    });
    return resp ;
}

export function updateDetails(details ){
    const { firstname , lastname, jobtitle, company } = details ; 
    const resp = http.put(`${api}/user/details` , {
        firstname: firstname , 
        lastname: lastname , 
        jobtitle: jobtitle , 
        companyname: company 
    });
    return resp ;
}

export function loginWithJwt (jwt) {
    return localStorage.setItem("x-auth-zips", jwt);
}

export function logout() {
    localStorage.removeItem('x-auth-zips');
}


export async function login(email, password) {
    const response = await http.post(`${api}/user/login`, { email, password });
    localStorage.setItem('x-auth-zips', response.headers["x-auth-zips"]);
    return response ; 
}

export function getCurrentUser() {
    try {
      const jwt = localStorage.getItem('x-auth-zips');
      return jwtDecode(jwt);
    } catch (ex) {
      return null;
    }
}

export function addUser(newUser, requestedBy){
   return http.post(`${api}/user/add`, { newUser: newUser, requestedBy: requestedBy });
}

export function allUsers(){
    return http.get(`${api}/user/all`)
}