import React, { useState , useEffect } from 'react' ; 
import { useLocation , Redirect , useHistory  } from "react-router-dom";
import Map from '../../Map/many.js';
import LivingWith from '../../LivingWith';
import ApartMent from '../../Apartment';
import Household from '../../Household';
import Education from '../../Education';
import Races from '../../Races';
import Occupations from '../../Occupation';
import DashboardInfo from '../../DashboardInfo';
import Info from '../../Info';
import LoadingPage from "../../../../../common/tools-ui/loadingPage" ;
import NavBar from "../../../../../common/NavBar/navbar.jsx" ;
import { createCheckoutSession } from "../../../../../../services/checkoutService";
import { getPoiPercentage } from "../../../../../../services/poiService" ;
import { Helmet } from "react-helmet";

const PoiDashboard = () => {

    const location = useLocation() ; 
    const history= useHistory(); 
    if (!location.state) history.push("/poi/us")

    const [limitExceeded, setLimitExceeded] = useState(false)
    const [loading, setLoading] = useState(true)
    const [exception, setException] = useState(false)
    const [dts, setDts] = useState([]) ; 
    const [areas, setAreas] = useState([]); 
    const [attributes, setAttributes] = useState({}) ;
    const [titletext, setTitletext] = useState('') ;
    const [living, setLiving] = useState('') ; 
    const [hhunit, setHhunit] = useState('') ;
    const [ageinc, setAgeinc] = useState('') ;
    const [race, setRace] = useState('') ;
    const [occupations, setOccupations ] = useState('') ; 
    const [education, setEducation] = useState('') ; 
    const nf = new Intl.NumberFormat() ;

    useEffect(() => {

        async function getData() {

          try {
              const { zcta , radius, place, lng , lat } = location.state ;  
              const resp = await getPoiPercentage("us", zcta )
            
              const obj = resp.data[0].proportions[0] ; 
              setDts([...resp.data[0].proportions]) ; 
              setAreas([...zcta]); 
        
              setAttributes({
                population: resp.data[0].population ,
                households : resp.data[0].households , 
                old: resp.data[0].old , 
                young: resp.data[0].young , 
                center: [lng, lat ]
              })
        
              // setTitletext(text)
    
              setTitletext(
                <div className="text-justify">
                    <p className="mt-2 mb-2">
                    You have selected {`${zcta.length}`} ZIP Codes in the radius of {`${radius}`} miles from the {`${place}`}, 
                    The population in this area is {`${nf.format(resp.data[0].population)}`}, 
                    of which {`${obj.gender.male.toFixed(2)}% `} identify their gender as male. 
                    The majority of the population living in this area {` ${ resp.data[0].young > resp.data[0].old ? `under the age of 40.`: `over the age of 40.` } `}. 
                    </p>
        
                </div>
              )
    
              setLiving(
                <div className="p-2 text-justify">  
                  <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658682911/free-tools/family_1.svg' style={{width:"47px", marginRight:"12px"}}/>
                    <h5> Household Living Arrangement </h5>
                  </div>
                  <p className="mt-2 mb-3" >
                    The U.S. Census Bureau defines a household as follows: “a household includes all the persons who occupy a housing unit as their usual place of residence. 
                    Household living arrangements refer to whether or not the person lives with another person or persons and, if so,
                    whether or not he or she is related to that person or persons. Household living arrangements can be categorized into four main categories 
                    - living alone, living with children under 18, living without children all 18 plus and living with a person 60 plus age.  
                    In the selected area , {` ${resp.data[0].proportions[0].livingWith['Living Alone']}`} percent 
                    of households live alone whereas {` ${resp.data[0].proportions[0].livingWith['Living with Children under 18']}`} percent of 
                    households live with children under 18. 
                    Also, {`${resp.data[0].proportions[0].livingWith['Living with person 60 plus age']}`} percent of households in 
                    in this area have at least one person above the age of 60.
                  </p>
                  <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/house_1.svg' style={{width:"47px", marginRight:"12px"}}/>
                    <h5> Persons in Household </h5>
                  </div>
                  <p className="mt-2 mb-3" >
                  According to the U.S. Census Bureau the number of people per household has decreased over the past decades in the U.S.
                  In the selected area, {` ${ resp.data[0].proportions[0].livingPerson["1 per"]}`} percent 
                  of the households are one-person households, the two and three-person households 
                  together account for {`${(resp.data[0].proportions[0].livingPerson["2 per"] + resp.data[0].proportions[0].livingPerson["3 per"]).toFixed(2)} `} percent  
                  whereas {` ${resp.data[0].proportions[0].livingPerson["3 per"]} `} percent of the households have at least 4 or more persons.  
                  </p>
                </div>
              )
    
              //=== HH unit structure type 
              setHhunit(
                <div className="p-2 text-justify">
                    <div className="d-flex align-items-center">
                        <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/building_1.svg' style={{width:"47px", marginRight:"12px"}}/>
                        <h5> Housing Unit Structures </h5>
                    </div>
                  <p className="mt-2 mb-2">
                    A housing unit is a single unit within a larger structure that can be used by an individual or
                    household to eat, sleep, and live. The unit can be in any type of residence, such as a house, apartment, 
                    or mobile home, and may also be a single unit in a group of rooms. 
                    In this area,  {` ${resp.data[0].proportions[0].housingType['1 unit, detached']}`} percent 
                    of housing units are detached single units. The housing units which have 2, 3, or 4 apartments account for 
                    {` ${(resp.data[0].proportions[0].housingType['2 apartments']+resp.data[0].proportions[0].housingType['3 or 4 apartments']).toFixed(2)} `} percent 
                    of all housing units in this region whereas 
                    {` ${(resp.data[0].proportions[0].housingType['5 or 9 apartments']+resp.data[0].proportions[0].housingType['10 or more apartments']).toFixed(2)} `} 
                    percent have at least 5 or more apartments. 
                  </p>
                </div> 
              )
              
              //===  Income + Age group 
              setAgeinc(
                <div className="p-2 text-justify">
                  <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/salary_1.svg' style={{width:"50px", marginRight:"12px"}}/>
                    <h5> Household Income </h5>
                  </div>
                  <p className="mt-2 mb-3" >
                    Household income generally is defined as the total gross income before taxes, 
                    received within a 12-month period by all members of a household above a specified age 
                    (the Census Bureau specifies age 15 and older). 
                    It includes — but is not limited to wage, salary, self-employment earnings, social security, 
                    pension, and income from other sources. 
                    In this region , 
                    {` ${(resp.data[0].proportions[0].income['below-35,000'] + resp.data[0].proportions[0].income['35,000-49,999']).toFixed(2)} `} percent 
                    of households have a gross annual income below $50,000 
                    and {` ${(resp.data[0].proportions[0].income['50,000-99,999'] + resp.data[0].proportions[0].income['100,000-149,000']).toFixed(2)} `} 
                    percent of households have a gross annual income between $50,000 and $150,000. 
                    The proportion of high-income households above $150,000 which is usually low in most of the regions of the 
                    US account for {` ${resp.data[0].proportions[0].income['above-150,000']} `} percent in this area. 
                  </p>
                  <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/age-group_1.svg' style={{width:"50px", marginRight:"12px"}}/>
                    <h5> Age Distribution </h5>
                  </div>
                  <p className="mt-2 mb-2">
                  The age distribution, also called Age Composition,
                  in population studies, is the proportionate numbers of persons in successive 
                  age categories in a given population. Age distribution affects many macroeconomic equations 
                  such as consumer behaviour, consumer styles , housing-investment, money-demand, labor-force-participation etc.  
                  In the selected area, about {`${resp.data[0].proportions[0].age['10-24']}`} percent of the population 
                  is between 10 to 24 years category, 
                  {` ${resp.data[0].proportions[0].age['40-64']}`} percent into the 40 to 64 years age group and {` ${resp.data[0].proportions[0].age['above 64']}`} percent of 
                  the population are over 65 years of age.
                  </p>
                  
                </div>
              )
        
              //=== race
              setRace(
                <div className="p-2 text-justify">
                  <div className="d-flex align-items-center">
                    <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1662210778/free-tools/ethnicity.png' style={{width:"49px", marginRight:"12px"}}/>
                    <h5> Ethnicity Groups </h5>
                  </div>
                  <p className="mt-2 mb-2">
                    The racial data collected by the US census bureau are based on self-identification. 
                    The racial categories included in the census questionnaire are - White, 
                    Black or African American, American Indian or Alaska Native, Asian, 
                    and Native Hawaiian or Other Pacific Islander. 
                    The white population alone in the selected area is 
                    {` ${resp.data[0].proportions[0].races['White']}`} percent and 
                    about {` ${resp.data[0].proportions[0].races['Black or African American alone']} `} 
                    percent of the population is Black or African American alone.
                    Moreover, the Asian alone ethnicity accounts for {` ${resp.data[0].proportions[0].races['Asian alone']}`} percent in this region.
                  </p>
                </div>
              )

            //===  Education
            setEducation(
              <div className="p-2 text-justify">
                <div className="d-flex align-items-center">
                  <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1665248778/free-tools/education.svg' style={{width:"50px", marginRight:"14px"}}/>
                  <h2 className="customh2fontsize"> Education Attainment </h2>
                </div>
                <p className="mt-2 mb-3" >

                Educational attainment refers to the highest level of education that an individual has completed. 
                This is distinct from the level of schooling that an individual is attending.
                  This data is reported by US Census Bereau on three levels - School level, Bachelor and Graduate level education. 
                
                In this area {` ${resp.data[0].proportions[0].education['School level']}`} percent
                have completed the school level education whereas {` ${resp.data[0].proportions[0].education['Bachelor']}`} have finished their Bachlor studies. 
                The porportion of graduates in {`${resp.data[0].state}`} is {` ${resp.data[0].proportions[0].education['Graduate']}`} percent. 
                </p>   
              </div>
            )
            
            //===  occupation 
            setOccupations(
              <div className="p-2 text-justify">
              <div className="d-flex align-items-center">
                <img src='https://res.cloudinary.com/zipscores-collection/image/upload/v1665249595/free-tools/occupation.png' style={{width:"49px", marginRight:"14px"}}/>
                <h2 className="customh2fontsize"> Employment by Industry Sector </h2>
              </div>
              <p className="mt-2 mb-2">
              The U.S. Bureau of Labor Statistics provides data on employment by industry sectors.
              Workplaces are classified into industries based on their principal product or activity. 
              This statistic shows the distribution of the workforce across different economic sectors.
              In the selected area, {`${resp.data[0].proportions[0].occupations['Educational services, and health care and social assistance']}`}  percent of the workforce 
              is employed in Educational services, and health care and social assistance
              sector, {`${resp.data[0].proportions[0].occupations['Construction']}`} percent in Construction and 
              {` ${resp.data[0].proportions[0].occupations['Manufacturing']} `} percent in Manufacturing. 
              The Industry sector "Professional, scientific, and management, and administrative and waste management services" 
              which includes some of the highest paid proffessions such as Software Developers, Other Engineering Managers account 
              for {`${resp.data[0].proportions[0].occupations['Professional, scientific, and management, and administrative and waste management services']} `} 
              percent in this area.
              </p>
            </div>
            )
            
          } catch (error) {
            setLoading(false)
            if (error.response.status == 429) {

                setLimitExceeded(true)
                const returnUrl = window.location.protocol + '//' + window.location.host + '/home'; 
                const res = await createCheckoutSession(returnUrl) ;
                const sessionUrl = res.data.url ; 
                window.open(sessionUrl, "_blank");
                history.goBack()

            } else {
              setException(true)
            }
          }
            
        }

        if (location.state) getData() ;
    
    }, [])

    if(exception) return <Redirect to="/404"/> ; 

    return (

        <div className='content-wrapper'>
            
            <NavBar isAdd={true} bgwhite={true}/>
            { dts.length > 0 && location.state && Object.keys(attributes).length && 
            <>
                <Helmet> <title>{`POI | ${location.state.place} (${location.state.radius} miles)`}</title> </Helmet>
                <DashboardInfo 
                title={`${location.state.place} (${location.state.radius} miles)`} 
                description={titletext} 
                // link1={`/browse/us/${attributes.state.toLowerCase().replace(/\s/g, '-')}/zipcodes`} 
                link1title={`Demographics by ZIP codes in ${attributes.state}`}
                // link2={`/browse/us/${attributes.state.toLowerCase().replace(/\s/g, '-')}/cities`} 
                link2title={`Demographics by Cities in ${attributes.state}`}
                />
                <Map dts={dts}  areas={areas} center={attributes.center} country={'us'} attributes={attributes} zoom={8} />
                <Info description={living} />
                <LivingWith dts={dts} />
                <Info description={hhunit} />
                <ApartMent dts={dts} />
                <Info description={ageinc} />
                <Household dts={dts} />
                <Info description={education} />
                <Education dts={dts} />
                <Info description={occupations} />
                <Occupations dts={dts} />
                <Info description={race} />
                <Races dts={dts} />
            </>
            }
            { loading && dts.length == 0 && !limitExceeded && <LoadingPage /> }
            { dts.length == 0 && limitExceeded && !loading && <LoadingPage text={'Free limits exceeded'} /> }
      </div>
    )


}

export default PoiDashboard ; 