import React, { useState , useEffect } from 'react'
import NavBar from "../common/NavBar/navbar"
import { getCurrentUser } from "../../services/userService";
import { Avatar } from 'rsuite';
import { getAllUsers } from '../../services/clientService';
import styles from "./account.module.css"
import AddUserForm from './userForm';
import { createCheckoutSession } from '../../services/checkoutService';
import { Helmet } from "react-helmet";


const User = () => {

const [user, setUser] = useState({
    username: "", email: "", isAdmin: true
})
const [team, setTeam] = useState([])

useEffect( () => {
    async function getUser() {
        const dt = await getCurrentUser()
        const resp = await getAllUsers(); 
        setUser({ ...dt})
        setTeam([...resp.data])
    }

    getUser();
}, [] );

const handleCheckoutSession = async() => {
        
    // this.setState({ loading: !this.state.loading })
    const returnUrl = window.location.href ; 
    const res = await createCheckoutSession(returnUrl) ;
    const sessionUrl = res.data.url ; 

    window.location.href= sessionUrl ;
}

return (
    <>
        <Helmet> <title> {`Zipscore | Account`} </title> </Helmet>
        <NavBar />
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3 mt-5"> 
                    <div className="text-center align-items-center">
                        <div className="">
                            <Avatar circle style={{ background: '#004299' }}  size="lg">
                                { user.email.slice(0,2).toUpperCase() }
                            </Avatar>
                        </div>
                        <div className="mt-4">
                            { user.firstname } {' '} { user?.lastname } <br />
                            { user.email }
                        </div>
                        <div className="text-center align-items-center mt-4" onClick={handleCheckoutSession}>
                            <div> <span style={{ paddingBottom: '5px' , borderBottom: "2px solid #0477F5" , fontSize:"16px" , cursor: "pointer" }}> Billing </span> </div>
                            {/* <div className={`${styles.upgradePremium}`} >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.12776 15.7328C1.67176 12.7673 1.21551 9.8018 0.759006 6.8363C0.736742 6.69057 0.75836 6.54152 0.821105 6.40812C0.88385 6.27473 0.984883 6.16303 1.11133 6.08725C1.23778 6.01148 1.38393 5.97506 1.53115 5.98264C1.67837 5.99022 1.82 6.04144 1.93801 6.1298L6.20251 9.3278C6.31331 9.41094 6.43992 9.4706 6.57457 9.50314C6.70923 9.53568 6.84911 9.5404 6.98566 9.51702C7.1222 9.49364 7.25254 9.44266 7.36871 9.36718C7.48487 9.2917 7.58442 9.19332 7.66126 9.07805L11.2118 3.75005C11.2983 3.62057 11.4155 3.51443 11.5528 3.44103C11.6902 3.36763 11.8435 3.32922 11.9993 3.32922C12.155 3.32922 12.3083 3.36763 12.4457 3.44103C12.5831 3.51443 12.7002 3.62057 12.7868 3.75005L16.3373 9.07505C16.4141 9.19032 16.5136 9.2887 16.6298 9.36418C16.746 9.43966 16.8763 9.49064 17.0129 9.51402C17.1494 9.5374 17.2893 9.53268 17.4239 9.50014C17.5586 9.4676 17.6852 9.40794 17.796 9.3248L22.0605 6.1268C22.1785 6.03844 22.3201 5.98722 22.4674 5.97964C22.6146 5.97206 22.7607 6.00848 22.8872 6.08425C23.0136 6.16003 23.1147 6.27173 23.1774 6.40512C23.2402 6.53852 23.2618 6.68757 23.2395 6.8333C22.783 9.7988 22.3268 12.7643 21.8708 15.7298L2.12776 15.7328Z" fill="white"/>
                                    <path d="M2.12769 17.3783H21.8722V19.6395C21.8722 19.7748 21.8456 19.9087 21.7938 20.0336C21.7421 20.1585 21.6662 20.2721 21.5706 20.3677C21.475 20.4633 21.3614 20.5392 21.2365 20.5909C21.1116 20.6427 20.9777 20.6693 20.8424 20.6693H3.15744C2.88433 20.6693 2.62241 20.5608 2.42929 20.3677C2.23618 20.1746 2.12769 19.9127 2.12769 19.6395V17.3783Z" fill="white"/>
                                </svg>
                                <span className={styles.premiumText}> Premium </span> 
                            </div> */}
                        </div>
                        {/* <div className="mt-5">
                            <span className={styles.upgrade} onClick={handleCheckoutSession}> UPGRADE </span>
                        </div> */}
                    </div>           
                </div>
                <div className="col-md-9 mt-5">
                        <h5 className="ml-5"> Add User </h5>
                        <AddUserForm />
                        {/* <hr /> */}
                        {/* <div className={styles.userText}> Your Team </div>                     */}

                        <div className="text-left align-items-center mt-4">
                            <h5 className="ml-5">Members</h5>
                            {
                                team && team.map((u)=> {
                                    return <>
                                          
                                        <div className="d-flex justify-content-left ml-5 mt-4">
                                            {/* <div className="p-2 ml-5"> { u.firstname } {' '} { u.lastname } </div> */}
                                            <div className="p-2 ml-3"> 
                                                <Avatar circle style={{ background: 'grey' }}  size="sm"> {user.email.slice(0,2).toUpperCase()}  </Avatar>
                                            </div>
                                            <div className="p-2"> { u.email} </div>
                                            { user.status === "Pending" && 
                                              <>
                                                <div className="p-2"> Invite Pending </div>
                                              </>
                                            }
                                        </div>
                                        {/* <hr /> */}
                                        
                                    
                                    </>
                                })
                            }

                        </div>
                </div>
            </div>

        </div>
        
    </>
)

}

export default User ;