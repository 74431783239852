import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ApartmentChart = ({dts}) => {

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return Number(context.formattedValue) + "%";
          },
          title:function(){
            return "";
          }
        }, 
        displayColors: false 
      },
    },
  };

  const getChartData = (passVal) => {
    return {
      labels: [],
      datasets: [
          {
              data: [passVal, 100-passVal],
              backgroundColor: [ '#CF6762' , "#EEEEEE"], // here Color is automatically set by value
              // backgroundColor: ["#" + Math.floor(passVal * 16777215).toString(16), "#EEEEEE"], // here Color is automatically set by value
              borderWidth: 0,
              padAngle: '10',
              // outerRadius: '50px',
              outerRadius: '40px',
              innerRadius: "40px",
              // innerRadius: "50px",
              cornerRadius: '15%',
              padAngle: '10',
              // innerHeight: '15px',
              innerHeight: '10px',
              innerWidth: '10px' , 
              // innerWidth: '15px'
          }
      ],
      /*
        plugins: {
          labels: {
              render: "percentage",
              fontColor: ["green", "white", "red"],
              precision: 1
          },
        },
      */
  }
  }

  return (
    <div className='Apartment-description'>
        {Object.keys(dts).map(function(key, index) {
          return <div className='Apartment-detail' key={index}>
            <div className='Apartment-map'>
                <Doughnut
                    data={getChartData(dts[key])}
                    type='doughnut'
                    options={options} 
                />
            </div>
            <div style={{font: 'Normal 14px/28px Axiform' }}>{key}</div>
        </div>
        })}

    </div>
  )
}

export default ApartmentChart