import React, {Component} from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {bbox, point, intersect, lineString, pointToLineDistance} from "@turf/turf";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { CircleMode, DragCircleMode,DirectMode, SimpleSelectMode } from 'mapbox-gl-draw-circle';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css' ; 
import { radiusStyle } from "./radius-style" ; 
import './poiMap.css' ; 
import { toast } from "react-toastify"; 
import { live , mapboxStyle , mapboxToken, colorsMap, dropzoneCountries } from "../../global.js";
import NavBar from "../common/NavBar/navbar" ;
import { reverseGeocodeApi } from "../../services/externalServices" ;
import Switch from "../common/tools-ui/switch" ; 
import "../explore/geocoder.css" ; 
import infoImg from "../../containers/poi-information.png" 
import { getAllInstances } from "../../services/scribbleService";
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

import Bulb from "../common/tools-ui/Bulb" ; 

class PoiMap extends Component {

    state = {
        lng: null ,
        lat: null ,
        zoom: null,
        map: null,
        radius: 0,
        radiusProp : 0 ,
        feature: null,
        country : null,  
        plzSelected : [],
        circle_postcode : [],
        // percentiles : [] , 
        percentages : [] , 
        download:[] , 
        markerPoint : [] ,
        plzTooltip : true , 
        pointOnMapLat: null,
        pointOnMapLong: null, 
        placeLabel: null,
        showInstances: true , 
        threeD: false , 
        instanceLayers: [] , 
        instanceSources: [] , 
        young: null , 
        old: null  , 
    }

    addLayer = (tileset) => {

        this.map.addSource('postleitzahlen', {
            type: 'vector',
            url: tileset
        });

         
        this.map.addLayer(
            {
                'id': 'zipcode',
                'type': 'fill',
                'source': 'postleitzahlen' ,
                'source-layer': 'postleitzahlen',
                'paint': {
                    'fill-opacity': 0,
                    // 'fill-color': '#fff0e6',
                    'fill-color': 'black',
                },
            },
            'waterway-label'
        );

        this.map.addLayer(
            {
                'id': 'zipcode-boundary',
                'type': 'line',
                'source': 'postleitzahlen' ,
                'source-layer': 'postleitzahlen',
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-opacity': 0.2,
                    'line-color': 'grey',
                    'line-width': 0.5
                }
            },
            'waterway-label'
        )
    }

    async componentDidMount() {

        let routeParam = this.props.match.params.country ;

        if (!live.includes(routeParam)) {
            this.props.history.push("/home"); 
            toast.error("You have selected wrong country", {
                autoClose: 5000,
                closeOnClick: true
            })
        }
        this.setState({...this.state.country , country : routeParam })

        const mapAttri = dropzoneCountries.filter((o)=> {
            return o.value === this.props.match.params.country
        })

        mapboxgl.accessToken = mapboxToken ; 

        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: mapboxStyle,
            center: [mapAttri[0].lng, mapAttri[0].lat],
            zoom:  mapAttri[0].zoom, 
            attributionControl: false
        });
        
        var marker = new mapboxgl.Marker({
            color: "#F75E02"
        })
        .setLngLat([36.686877,-82.973296])
        .addTo(this.map);
        const _this = this;

        
        let popup = new mapboxgl.Popup({
            closeButton: false,
        });

        
        this.map.on('mousemove', 'zipcode-boundary', function (e) {
            if (_this.state.plzTooltip === false) return ;
            popup.setLngLat(e.lngLat).setHTML(
                "<div style=color:grey;text-align:center;>" +
                "<div>" + e.features[0].properties.postcode + "</div>" +
                "<div>" + e.features[0].properties.locality + "</div>" +
                "</div>"
            ).addTo(_this.map);

        });

        this.map.on('mouseleave', 'zipcode-boundary', function () {
            popup.remove();
        });

        this.map.on('load', () => {
            this.addLayer(mapAttri[0].tileset) ; 
            this.plotInstances() ;  
            /*
            const geocoder = new MapboxGeocoder({
                accessToken: mapboxToken,
                mapboxgl: mapboxgl, 
                zoom: 9
            });
            this.map.addControl( geocoder , 'bottom-right');
            */ 
            this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

            const draw = new MapboxDraw({
                defaultMode: "draw_circle",
                userProperties: true,
                styles: radiusStyle,
                //units: "miles",
                displayControlsDefault: false,
                controls: {
                    polygon: false,
                    trash: false
                },
                modes: {
                    ...MapboxDraw.modes,
                    draw_circle: CircleMode,
                    drag_circle: DragCircleMode,
                    direct_select: DirectMode,
                    simple_select: SimpleSelectMode
                }
            });
            // Add this draw object to the map when map loads
            _this.map.addControl(draw);
            
            
            draw.changeMode('draw_circle', {initialRadiusInKm: 100}); 
            // draw.changeMode('draw_circle', {initi: 200}); 
        

        });

        async function updateArea(f) {
            // console.log("draw", f)
            const circle_centroid = point(f.features[0].properties.center);
            const outer_lineString = lineString(f.features[0].geometry.coordinates[0]);
           
            const radius =
              _this.state.country === "de"
                ? Math.round(
                    pointToLineDistance(circle_centroid, outer_lineString, {
                      units: "kilometers",
                    })
                  )
                : Math.round(
                    pointToLineDistance(circle_centroid, outer_lineString, {
                      units: "miles",
                    })
                  );
            
            _this.setState({radius: radius, feature: f.features[0]})
            _this.map.fitBounds(bbox(f.features[0]), {padding: 50})
            marker.setLngLat(f.features[0].properties.center);
            
            _this.map.setFilter('zipcode-boundary', null);
            _this.map.setPaintProperty("zipcode-boundary", 'line-color', "grey");
            _this.map.setPaintProperty("zipcode-boundary", 'line-width', 0.5);
            

            const pointOnMapLat = circle_centroid.geometry.coordinates[1]
            const pointOnMapLong = circle_centroid.geometry.coordinates[0]

            if(_this.map.style.z === undefined ){
                _this.map.setZoom(5)
            } else {
                _this.map.setZoom(_this.map.style.z)               
            }

            const location = await reverseGeocodeApi(pointOnMapLong, pointOnMapLat)
            const place = location.data.features.length >= 1 ? location.data.features[0].place_name : 'Location Not Found. Please choose another point on the map'
            
            _this.setState({ 
                ..._this.state ,
                pointOnMapLat : pointOnMapLat, 
                pointOnMapLong: pointOnMapLong, 
                placeLabel: place, 
            }) 
        }
        
        this.map.on('draw.create', updateArea)
        this.map.on('draw.update', updateArea);

        /*
        this.map.flyTo({
            center: [this.state.pointOnMapLat, pointOnMapLong],
            zoom: 8
        })
        */ 
    }

    componentWillUnmount() {
        this.map.remove();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        // if (this.state.radius !== prevState.radius) {
        //     this.setState({ radius : this.state.radius})
        //   }

    }

    updateRadius = () => {
        const {  circle_postcode ,  country } = this.state ;

        const radiusPlz = (data) => {

            return new Promise((resolve, reject) => {
                data = []
                this.map.queryRenderedFeatures({
                    layers: ['zipcode-boundary']
                })
                .map(each => {

                        const object = {
                            "type": "Feature",
                            "properties": {...each.properties},
                            "geometry": {...each.geometry}
                        }
                        
                        if( this.state.feature == null ) {
                            window.alert('choose point on the map'); 
                            reject('no selection on map')
                        } 

                        if (intersect(this.state.feature, object)) {
                            
                            if (data.indexOf(each.properties.postcode) === -1) {
                                data.push(each.properties.postcode)
                            }
                            
                            
                            return object
                        }
                    }
                )

                resolve(data) 
            }); 
        }
        
        radiusPlz(circle_postcode)
            .then((circle_postcode) => {
 
                if(circle_postcode.length === 0 ) {
                    
                    // You have chosen a point on the map out of the selected country
                    window.alert("You have chosen a point on the map out of the selected country")
                    return Promise.reject('No result') ;
                }
                else {
                    this.setState({ plzSelected : [...circle_postcode]}) ;
                    const { plzSelected , radius , radiusProp , pointOnMapLong , pointOnMapLat , placeLabel }= this.state ;  

                    this.props.history.push(`/poi/selections/${country}`, {
                        zcta :  circle_postcode, 
                        radius: radius, 
                        place: placeLabel,
                        lng: pointOnMapLong , 
                        lat: pointOnMapLat
                    });

                }
            })
            .catch((error) => {
                toast.warn("Something went wrong, try changing the radius!", {
                    autoClose: 5000,
                    closeOnClick: true
                })
            })
        }

        setThreeD = (val) => {

            setTimeout(()=>{
                const pitch = !val ? 90 : 0
                const bearing = !val ? 0 : 0
                this.map.setPitch(pitch)
                this.map.setBearing(bearing)
                this.setState({threeD : !val}) 
            } , 500)       
        }

        handleInstances = () => {     
                const { instanceLayers, showInstances } = this.state ; 
                if( instanceLayers.length >= 1 ){      
                    for (const layer of instanceLayers){
                        this.map.removeLayer(layer) 
                        this.map.removeSource(layer)
                    }
                    // console.log(this.map.getStyle().layers)
                    this.setState({ showInstances : !showInstances , instanceLayers:[] , instanceSources: [] })
                } else {
                    this.plotInstances(); 
                    this.setState({ showInstances : !showInstances })
                } 
        }

        plotInstances = async() => {
            const { instanceLayers , instanceSources, instanceMarkers } = this.state ; 
            const resp = await getAllInstances() ; 
            const instances = resp.data.inst ; 
            if (instances.length<1) return console.log('no object')
            
            for(let i = 0; i<instances.length; i++){
                let layer = `layer${i}` ; 
                switch (instances[i].type) {
                    case 'path' :
                    case 'pencil': {

                        let coordinates = JSON.parse(instances[i].info); 
                        let geoJson = coordinates.map(((v) => { return [v.lng , v.lat] })) ;
                        const color = instances[i].type === 'path' ? colorsMap[0].pathColor  : colorsMap[0].pencilColor 

                        this.map.addSource(layer, {
                            'type': 'geojson',
                                'data': {
                                'type': 'FeatureCollection',
                                'features': [
                                        {
                                        'type': 'Feature',
                                        'properties': {
                                            'color': color
                                        },
                                        'geometry': {
                                            'type': 'LineString',
                                            'coordinates': geoJson
                                        }
                                    }
                                ]
                            }
                        })
                        this.map.addLayer({
                                'id': layer,
                                'type': 'line',
                                'source': layer,
                                'paint': {
                                    'line-width': 2,
                                    'line-color': ['get', 'color']
                                }
                        });

                        instanceSources.push(layer)
                        instanceLayers.push(layer)
                        
                        break; 
                    }
                    case 'area' : {
 
                        let coordinates = JSON.parse(instances[i].info)
                        let geoJson = coordinates.map(((v) => { return [v.lng , v.lat] }))
                        
                        this.map.addSource(layer, {
                                'type': 'geojson',
                                'data': {
                                'type': 'Feature',
                                'geometry': {
                                    'type': 'Polygon',
                                    'coordinates': [ geoJson ]
                                    }
                                }
                        });
                        this.map.addLayer({
                                'id': layer,
                                'type': 'line',
                                'source': layer,
                                'layout': {},
                                'paint': {
                                    'line-color': colorsMap[0].areaColor ,
                                    'line-width': 2
                                }
                        });
                        instanceSources.push(layer)
                        instanceLayers.push(layer)
                        
                        break; 
                    }
                    case 'marker' : {
                        let coordinates = JSON.parse(instances[i].info).reverse()
                        this.map.addSource(layer, {
                            'type': 'geojson',
                            'data': {
                                'type': 'FeatureCollection',
                                'features': [
                                    {
                                        'type': 'Feature',
                                        'geometry': {
                                            'type': 'Point',
                                            'coordinates': coordinates
                                        },
                                        'properties': {
                                            icon: 'mapbox-marker-icon-blue',
                                            'vicinity': instances[i].vicinity, 
                                            'title': instances[i].name, 
                                            'desc': instances[i].desc, 
                                            'by': instances[i].by
                                        }
                                    }
                                ]
                            }
                        });

                        this.map.addLayer({
                                'id': layer,
                                'type': 'symbol',
                                'source': layer,
                                layout: {
                                    'icon-image': ['get', 'icon']
                                }
                        });

                        let popup = new mapboxgl.Popup({
                            closeButton: false,
                        });
                        
                        const _this = this;
                        this.map.on('mousemove', layer, function (e) {
                            
                            if (_this.state.plzTooltip === false) return ;
                            popup.setLngLat(e.lngLat).setHTML(
                                "<div style=color:#555555;text-align:center;font-family: Axiform;>" +
                                '<span style="text-transform:capitalize ; color: #555555 ; border-bottom:2px solid #1B72F5; font-weight:bolder">'+ e.features[0].properties.title + "</span>" +
                                '<div class="mt-1 p-1">' + e.features[0].properties.desc + "</div>" +
                                '<div class="mt-2"><img src="' + process.env.PUBLIC_URL + '/collab/marker-small-icon.svg">' + 
                                '<span class="ml-1" ">'+ e.features[0].properties.vicinity + '</span>' +   
                                '</div>' +
                                '<div class="mt-2"><img width="14px" src="' + process.env.PUBLIC_URL + '/collab/user-marker.png">' + 
                                '<span class="ml-1" ">'+ e.features[0].properties.by + '</span>' +   
                                '</div>' 
                            ).addTo(_this.map);
                        });

                        this.map.on('mouseleave', layer, function () {
                            popup.remove();
                        });

                        instanceSources.push(layer)
                        instanceLayers.push(layer)

                        break ; 
                    }
                }
            }            
        }

    
        render() {
        
            const { percentages , plzSelected , radius , radiusProp , country , pointOnMapLong , pointOnMapLat , placeLabel , download , threeD , young, old  } = this.state ; 
            const unit = country === "de" ? "kms" : "miles"

            return (
                <>
                    <div>
                        <Helmet> <title>{`Zipscore | Point of Interest `}</title> </Helmet>
                        <div
                            style={{width: '100%', height: '100vh',}}
                            ref={el => this.mapContainer = el}
                        >   
                            <div style={{position: 'absolute' , zIndex: 77 , width:'100%'}}>
                                <NavBar isAdd={true} />
                            </div>
                            <div style={{ position: 'absolute', width: '25%' , height: '16%' ,  marginLeft: "50px" , zIndex: 5}}>
                                {
                                    percentages.length === 0 && 
                                    <div className={" buttonContainer"}>
                                        <div className="mt-2 mb-2 p-2 text-center" style={{ color: "#363636" }}>

                                        {
                                            !pointOnMapLat && !pointOnMapLong && !placeLabel &&
                                            <div className="d-flex p-2 justify-content-around">
                                                <div className="text-left mr-3" style={{fontSize: "small"}}> 
                                                    Just click on the map. You can then change the radius by adjusting the circumference of circle or change your area selection by just dragging the marker.
                                                    <br/>
                                                    <b><u>Pro Tip</u></b> : Create markers on the map using our scribble functionality so that it is easier to identify your point-of-interests. 
                                                </div>
                                                <div className="">
                                                    <img src={infoImg} style={{}} alt="" />
                                                </div>
                                            </div>
                                        }

                                        {
                                            pointOnMapLat && pointOnMapLong && placeLabel && 
                                            <>

                                                <div className="">
                                                    <button
                                                        className={"loginButton"} 
                                                        onClick={ this.updateRadius }
                                                        > 
                                                        <span className="loginText"> Select Area </span>  
                                                    </button> 
                                                </div>

                                                <div className="mt-3">
                                                    <span style={{fontSize:'1rem' , fontWeight: 'bold'}} > { placeLabel } </span> <br/>
                                                    <span className="mt-2"> Radius : { radius} {unit} </span>
                                                </div>
                                            </>                                               
                                        }
                                        </div>

                                    </div>
                                }
                            </div>
                            <div onClick={ ()=>{ this.setThreeD(threeD)} }>
                                <Switch on={'2D'} off={'3D'} bottom={'26vh'} />
                            </div>  
                            <div onClick={ this.handleInstances }>
                                <Bulb on={this.state.showInstances} bottom={'39vh'} />
                            </div>  
                        </div>
                    </div>

                    {/* <div className="">
                        {
                            percentages.length > 0 && 
                            <PointPanel
                                changeData={this.dataChange}
                                percentages = { percentages }
                                radius = { radiusProp }
                                plzSelected = { plzSelected}
                                country = { country }
                                placeLabel = { placeLabel }
                                young= { young }
                                old= { old }
                                distanceUnit= { unit }
                            />
                        }
                        
                        { 
                            plzSelected.length>0 && download.length>0 && <BottomPanel data = { plzSelected } download = { download } />
                        }
                    </div> */}

            </>        

            );
        }
}

export default withRouter(PoiMap);