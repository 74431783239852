import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify"; 
// import { Offline, Online } from "react-detect-offline";
// import OfflineMessage from "./components/common/offlineMessage/offlineMessage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "font-awesome/css/font-awesome.css";
import "./components/auth/styles/auth.css"; 
import logger from "./services/logger";
import ProtectedRoute from "./components/common/protectedRoute/protectedRoute";

import Landing from "./components/landing-page/Landing";
// import ReactGA from "react-ga" ; //UNINSTALL 
import ReactGA from "react-ga4" ;
import "react-toastify/dist/ReactToastify.css" ;
import Explore from "./components/explore/explore" ; 
import PoiMap from "./components/point-of-interest/poiMap" ; 
// import LookupFree from "./components/free/lookup-landing/lookup" ;
// import Lookup from "./components/look-up/zip-lookup" ; 
import Lookup from "./components/look-up/lookupAuth" ; 
// import LookAlike from "./components/look-up/LookAlike/score";

import SignIn from './components/auth/SignIn'; 
import ForgotPassword from "./components/auth/ForgotPassword";
import SignUp from "./components/auth/Signup";
import Confirm from "./components/auth/Confirm" ; 
import ResendConfirm from "./components/auth/ResendConfirm" ; 
import ResetPwd from "./components/auth/ResetPwd" ; 
import Logout from "./components/auth/logout";
import Details from "./components/auth/Details";
import Blog from "./components/blog/blog" ; 
import BlogEntry from "./components/blog/blogEntry"; 
import CollaborateWrap from "./components/collaboration/collaborateWrap.js" ; 
import Company from "./components/landing-page/company";
import Areas from "./components/free/favorites/areas"; 
import NotFound from "././components/common/notfound/NotFound.jsx";
// import SearchFree from "./components/free/lookup-landing/search-free-de";
import HeroNew from "./components/landing-page/HeroNew"
import Autosearch from "./components/explore/autocomplete/AutoComplete";
import Audience from "./components/explore/autocomplete/result/audience" 
import { getCurrentUser } from "./services/userService";

import AutoCompleteNew from "./components/explore/autocomplete/AutoComplete";
import AutoCompleteDemo from "./components/explore/autocomplete/autoComplete.demo";


import Account from "./components/account/user" ; 
import Privacy from "./components/common/Privacy"; 

import UsZipcode from "./components/look-up/datas/us/Dashboards/nonauth/zipcode" ; 
import UsState from "./components/look-up/datas/us/Dashboards/nonauth/state.js" ; 
import UsCity from "./components/look-up/datas/us/Dashboards/nonauth/city.js" ;

import UsBillboardsList from "./components/outdoor/us/billboards/listing.js"
import UsBillboardDetail from "./components/outdoor/us/billboards/listingdetail/listingdetailpage.js"

import UsZipcodeAuth from "./components/look-up/datas/us/Dashboards/auth/zipcodeAuth.js" ; 
import UsStateAuth from "./components/look-up/datas/us/Dashboards/auth/stateAuth.js" ; 
import UsCityAuth from "./components/look-up/datas/us/Dashboards/auth/cityAuth.js" ;
// add redirection to the main page 

import DeZipcode from "./components/look-up/datas/de/Dashboards/nonauth/zipcode.js" ; 
import DeState from "./components/look-up/datas/de/Dashboards/nonauth/state.js" ; 
import DeCity from "./components/look-up/datas/de/Dashboards/nonauth/city.js" ;
// add deutschlands for auth users 

import DeZipcodeAuth from "./components/look-up/datas/de/Dashboards/auth/zipcodeAuth.js" ; 
import DeStateAuth from "./components/look-up/datas/de/Dashboards/auth/stateAuth.js" ; 
import DeCityAuth from "./components/look-up/datas/de/Dashboards/auth/cityAuth.js" ;

import UsPoiDashboard from "./components/look-up/datas/us/Dashboards/auth/poi.js" ;
import DePoiDashboard from "./components/look-up/datas/de/Dashboards/auth/poi" ;

import UsBrowseState from "./components/look-up/browse/us/nonauth/browsestate.js" ; 
import UsBrowseCensus from "./components/look-up/browse/us/nonauth/browsecensus.js" ; 
import UsBrowseZipcode from "./components/look-up/browse/us/nonauth/browsezipcode.js" ; 
import UsBrowseCity from "./components/look-up/browse/us/nonauth/browsecities.js" ; 

import UsBrowseStateAuth from "./components/look-up/browse/us/auth/browsestateAuth" ; 
import UsBrowseZipcodeAuth from "./components/look-up/browse/us/auth/browsezipcodeAuth.js" ; 
import UsBrowseCityAuth from "./components/look-up/browse/us/auth/browsecitiesAuth.js" ; 

import DeBrowseState from "./components/look-up/browse/de/nonauth/browsestate" ; 
import DeBrowseZipcode from "./components/look-up/browse/de/nonauth/browsezipcode.js" ; 
import DeBrowseCity from "./components/look-up/browse/de/nonauth/browsecities.js" ; 

import DeBrowseStateAuth from "./components/look-up/browse/de/auth/browsestateAuth" ; 
import DeBrowseZipcodeAuth from "./components/look-up/browse/de/auth/browsezipcodeAuth.js" ; 
import DeBrowseCityAuth from "./components/look-up/browse/de/auth/browsecitiesAuth.js" ; 

import Copilot from './components/copilot/main.jsx'
import PromptLibrary from './components/copilot/promptLibrary'

import Appsumo from "./components/common/Appsumo" ; 

class App extends Component {

  state = {};
  componentDidMount() {
    
    if (process.env.REACT_APP_ENVIRON === "production" ) {
      // old property ==>  REACT_APP_GA_TRACKING_ID=UA-182281564-1
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID) ; 
      // ReactGA.pageview( window.location.pathname + window.location.search ); 
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
      logger.init()
    
    }
  }

  render() {
    return (
      <React.Fragment>
            <ToastContainer />
            <Switch>
                <ProtectedRoute path="/explore/search/:country/:id" component={Audience} />
                <ProtectedRoute path="/explore/search/:country" component={Autosearch} />
                <ProtectedRoute path="/explore/filter/:country" component={Explore} />
                {/* <ProtectedRoute path="/poi/selections/:country" component={PoiDashboard} /> */}
                <ProtectedRoute path="/poi/selections/us" component={UsPoiDashboard} />
                <ProtectedRoute path="/poi/selections/de" component={DePoiDashboard} />
                <ProtectedRoute path="/poi/:country" component={PoiMap} />
                {/* <Route path="/lookup/search/:country/:zipcode/:lat/:lng" component={UsLookup} /> */}
                {/* <ProtectedRoute path="/lookalike/audience/:country/:score" component={LookAlike} /> */}
                <ProtectedRoute path="/collaborate/:country/:client" component={CollaborateWrap} />
                <ProtectedRoute path="/user/form" component={ Details } />

                
                <ProtectedRoute path="/home/de" component={AutoCompleteNew} />
                <ProtectedRoute path="/home" component={Copilot} />
                <ProtectedRoute path="/account" component={Account} />

                <ProtectedRoute path="/lookup/search/:country" component={Lookup} />

                <ProtectedRoute path="/lookup/browse/us/states" component={UsBrowseStateAuth} />
                <ProtectedRoute path="/lookup/browse/us/:statekey/zipcodes" component={UsBrowseZipcodeAuth} />
                <ProtectedRoute path="/lookup/browse/us/:statekey/cities" component={UsBrowseCityAuth} />
                
                <ProtectedRoute path="/lookup/browse/de/states" component={DeBrowseStateAuth} />
                <ProtectedRoute path="/lookup/browse/de/:statekey/zipcodes" component={DeBrowseZipcodeAuth} />
                <ProtectedRoute path="/lookup/browse/de/:statekey/cities" component={DeBrowseCityAuth} />

                <ProtectedRoute path="/lookup/us/:zipcode/:lat/:lng" component={UsZipcodeAuth} />
                <ProtectedRoute path="/lookup/us/:statekey/:citykey" component={UsCityAuth} />
                <ProtectedRoute path="/lookup/us/:statekey" component={UsStateAuth} />


                <ProtectedRoute path="/lookup/de/:zipcode/:lat/:lng" component={DeZipcodeAuth} />
                <ProtectedRoute path="/lookup/de/:statekey/:citykey" component={DeCityAuth} />
                <ProtectedRoute path="/lookup/de/:statekey" component={DeStateAuth} />

                {/* Co-pilot */}
                <ProtectedRoute path="/copilot/us/prompt-library" component={PromptLibrary} />
                <ProtectedRoute path="/copilot/us" component={Copilot} />

                <ProtectedRoute path="/app-sumo/code/redeem" component={Appsumo} />

                {/* REDIRECT IF USER LOGGED IN  */}
                <Route path="/logout" component={ Logout }  />
                <Route path="/login" component={ SignIn }  />
                <Route path="/confirm/resend" component={ ResendConfirm } />
                <Route path="/confirm" component={Confirm} />
                <Route path="/forgotpassword" component={ ForgotPassword }  />
                <Route path="/pwd/reset" component={ResetPwd} />
                <Route path="/register" component={ SignUp }  />
                <Route path="/gigaparsec-datalab" component={Company} />
                <Route exact path="/cookies" render={() => {window.location.href="cookies.html"}} />
                <Route exact path="/terms" render={() => {window.location.href="terms.html"}} />
                {/* <Route exact path="/privacy" render={() => {window.location.href="privacy.html"}} /> */}
                <Route exact path="/privacy" component={Privacy} />

                <Route path="/ai-copilot/market-analysis/prompts/library" component={PromptLibrary} />
                
                {/* billboards ++++++++  */}
                {/* <Route path="/us/advertising/billboards/:geolevel/:location" component={UsBillboardsList} /> 
                <Route path="/us/advertising/billboard/:ref" component={UsBillboardDetail} /> */}

                <Route path="/us/:zipcode/zip-code/:lat/:lng" component={UsZipcode} />
                <Route path="/us/:statekey/:citykey/zip-code-map" component={UsCity} />
                <Route path="/us/:statekey/zip-code-map" component={UsState} />


                <Route path="/de/:zipcode/:lat/:lng" component={DeZipcode} />
                <Route path="/de/:statekey/:citykey" component={DeCity} />
                <Route path="/de/:statekey" component={DeState} />

                {/* <Route path="/de/plz/suche" component={SearchFree} /> */}
                <Route path="/areas/:country/:label" component={Areas} />
                {/* <Route path='/blog/entry/:entryId' component={BlogEntry}/>  */}
                <Route path='/blog/:entryId' component={BlogEntry}/> 
                <Route path="/blog" component={Blog} />
                <Route path="/404" component={NotFound} />

                {/* <Route path="/browse/us/states" component={UsBrowseState} /> 
                <Route path="/browse/us/:statekey/zipcodes" component={UsBrowseZipcode} />
                <Route path="/browse/us/:statekey/cities" component={UsBrowseCity} /> */}

                <Route path="/browse/us/census-data" component={UsBrowseCensus} /> 
                <Route path="/browse/us/zip-code-map" component={UsBrowseState} /> 
                <Route path="/browse/us/:statekey/zip-codes" component={UsBrowseZipcode} />
                <Route path="/browse/us/:statekey/cities" component={UsBrowseCity} />

                <Route path="/browse/de/census-data" component={DeBrowseState} />
                <Route path="/browse/de/states" component={DeBrowseState} />
                <Route path="/browse/de/:statekey/zipcodes" component={DeBrowseZipcode} />
                <Route path="/browse/de/:statekey/cities" component={DeBrowseCity} />

                <Route path="/:country" component={Landing} />
                <Route path="/" component={Landing} />
            </Switch>
      </React.Fragment>
    );
  }
}

export default App;
